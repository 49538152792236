import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalProps } from 'react-bootstrap';
import { BaseModalProps } from '../../../core/Interface/elements/modalInterface';

/**
 * BaseModal es un componente reutilizable para mostrar modales.
 *
 * Props:
 * - isOpen: booleano que indica si el modal está abierto o cerrado.
 * - toggle: función para alternar el estado del modal.
 * - title: título del modal.
 * - children: contenido del cuerpo del modal.
 * - footer: contenido opcional del pie del modal.
 * - headerClassName: clase CSS opcional para el encabezado del modal.
 * - bodyClassName: clase CSS opcional para el cuerpo del modal.
 * - footerClassName: clase CSS opcional para el pie del modal.
 * - ...modalProps: cualquier otra prop adicional que se puede pasar al componente Modal de reactstrap.
 */

const BaseModal: React.FC<BaseModalProps> = ({
  show,
  onClose,
  title,
  children,
  footer,
  headerClassName = 'pb-0',
  bodyClassName,
  footerClassName,
  ...modalProps
}) => {
  return (
    <Modal show={show} onHide={onClose} {...modalProps}>
      <ModalHeader closeButton className={headerClassName}>
        <Modal.Title>{title}</Modal.Title>
      </ModalHeader>
      <ModalBody className={bodyClassName}>
        {children}
      </ModalBody>
      {footer && <ModalFooter className={footerClassName}>{footer}</ModalFooter>}
    </Modal>
  );
};

export default BaseModal;