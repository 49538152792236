import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ServiceInformationProps as Props } from '../../../core/Interface/interfaceComponents';
import { usePackages } from '../../../core/hooks/getData/usePackagesPayment';
import CategoryFilter from './CategoryFilter';
import ProductList from './ProductList';
import { all_routes } from '../../../core/data/routes/all_routes';

const SelectProduct: React.FC<Props> = ({ nextTab, onUsersSelection, selectedProducts }) => {
  const [selectedCount, setSelectedCount] = useState<number | undefined>();
  const [warning, setWarning] = useState<string | null>();
  const { sortedCategories = [], productIds, packages, setPackages } = usePackages(true, selectedProducts);
  const [filteredPackages, setFilteredPackages] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const navigate = useNavigate();
  const routes = all_routes;
  const query = new URLSearchParams(useLocation().search);
  const retry = query.get('retry') === 'true';

  useEffect(() => {
    if (packages && packages.length > 0 && filteredPackages?.length === 0) {
      setFilteredPackages(packages);
    }
      if (sortedCategories.length > 0 && selectedCount === undefined) {
      setSelectedCategory(sortedCategories[0].id);
      filterByCategory(sortedCategories[0].id);
    }
  }, [packages, sortedCategories]);

  const toggleSelection = (id: number) => {
    const updatedPackages = packages?.map((group) => {
      const [groupKey, groupPackages] = Object.entries(group)[0];
      const updatedGroupPackages = (groupPackages as any[]).map((pkg) =>
        pkg.id === id ? { ...pkg, selected: !pkg.selected } : pkg
      );
      return { [groupKey]: updatedGroupPackages };
    });
    setPackages(updatedPackages);

    const selectedPackages = (updatedPackages || []).flatMap((group) => {
      const [groupKey, groupPackages] = Object.entries(group)[0];
      return groupPackages.filter((pkg) => pkg.selected);
    });
    setSelectedCount(selectedPackages.length);
    onUsersSelection(selectedPackages);

    if (selectedCategory !== null) {
      const filtered = (updatedPackages ?? []).filter((group) => {
        const [groupKey] = Object.entries(group)[0];
        return parseInt(groupKey) === selectedCategory;
      });
      setFilteredPackages(filtered);
    } else {
      setFilteredPackages(updatedPackages);
    }
  };

  const deselectAll = () => {
    const updatedPackages = packages?.map((group) => {
      const [groupKey, groupPackages] = Object.entries(group)[0];
      const updatedGroupPackages = (groupPackages as any[]).map((pkg) => ({
        ...pkg,
        selected: false,
      }));
      return { [groupKey]: updatedGroupPackages };
    });
    setPackages(updatedPackages);
    setSelectedCount(0);
    onUsersSelection([]);

    if (selectedCategory !== null) {
      const filtered = (updatedPackages ?? []).filter((group) => {
        const [groupKey] = Object.entries(group)[0];
        return parseInt(groupKey) === selectedCategory;
      });
      setFilteredPackages(filtered);
    } else {
      setFilteredPackages(updatedPackages);
    }
  };

  const getSummaryData = () => {
    return {
      selectedProducts: packages.flatMap((group) => {
        const [groupKey, groupPackages] = Object.entries(group)[0];
        return groupPackages.filter((pkg) => pkg.selected);
      }),
    };
  };

  const handleNextTab = () => {
    if (selectedProducts.length === 0 || selectedProducts === undefined) {
      setWarning('Debes seleccionar al menos un producto antes de continuar.');
    } else {
      setWarning(null);
      nextTab(getSummaryData());
    }
  };

  const filterByCategory = (categoryId: number) => {
    setSelectedCategory(categoryId);
    const filtered = (packages ?? []).filter((group) => {
      const [groupKey] = Object.entries(group)[0];
      return parseInt(groupKey) === categoryId;
    });
    setFilteredPackages(filtered);
  };

  const handleBack = () => {
    navigate(routes.salesHistory);
  };

  return (
    <div className="service-information">
      <fieldset id="first-field">
        <div className="container-service space-service pb-2">
          <div className="sub-title mb-4 d-flex justify-content-between">
            <div>
              <h4 className="text-secondary">Seleccionar Productos</h4>
              <p>Elige los productos que quiere sumar a la compra.</p>
              <button
                style={{ height: 'fit-content' }}
                className="btn btn-secondary mb-3"
                type="button"
                onClick={deselectAll}
              >
                <i className="fa fa-minus-square me-2"></i>
                Deseleccionar Todo
              </button>
              <button
                style={{ height: 'fit-content' }}
                className="btn text-success ms-3 mb-3"
                type="button"
                disabled
              >
                Seleccionados: {selectedCount || 0}
              </button>
            </div>
            <div>
              {retry && (
                <button
                  style={{ height: 'fit-content' }}
                  className="btn btn-secondary me-2"
                  type="button"
                  onClick={handleBack}
                >
                  <i className="fas fa-arrow-left me-2" />
                  Volver
                </button>
              )}
            </div>
          </div>

          <div className="card card-shadow review-item">
            <div className="card-body pb-0">
              <CategoryFilter
                sortedCategories={sortedCategories}
                selectedCategory={selectedCategory || null}
                filterByCategory={filterByCategory}
              />
              <ProductList
                filteredPackages={filteredPackages || []}
                toggleSelection={toggleSelection}
                productIds={productIds || []}
              />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            {warning && (
              <div className="alert alert-warning mb-3" role="alert">
                {warning}
              </div>
            )}
            <div className="text-end">
              <button
                className="btn btn-primary-admin next_btn mb-4"
                type="button"
                onClick={handleNextTab}
              >
                Siguiente <i className="fas fa-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default SelectProduct;