import React, { useEffect, useState } from 'react';
import { formatDateToFull, capitalizeFirstLetter } from '../../../../core/utils/utils';
import { paymentMethodOptionsList } from '../../../../core/data/options/options';
import * as Icon from 'react-feather';
import BaseModal from './baseModal';

const ShowDetailPurchaseModal: React.FC<any> = ({ selectedPurchaseData }) => {
    const [show, setShow] = useState<boolean>(false);
    const [purchaseData, setPurchaseData] = useState<any>();

    useEffect(() => {
        if (selectedPurchaseData) {
            setPurchaseData(selectedPurchaseData);
            setShow(true);
        }
    }, [selectedPurchaseData]);

    const handleClose = () => {
        setShow(false);
    };

    return (
        <BaseModal
            show={show || false}
            title="Detalle de Compra"
            onClose={handleClose}
            showButtons={false}
        >
            <div className="modal-body pt-0">
                <div className="text-center col-md-12 m-auto">
                    <div className="text-center mb-2">
                        <Icon.CheckCircle size={50} color="#28a745" className="text-success" />
                        <h4 className="mt-0">{purchaseData?.fullName}</h4>
                        <p className="text-muted mb-0">{purchaseData?.email}</p>
                        <p className="text-muted mb-0">{purchaseData?.dni || 'DNI no disponible'}</p>
                    </div>
                    <div className="mt-2">
                        {purchaseData?.purchaseDate && (
                            <table className="table table-bordered mt-2">
                                <tbody>
                                    <tr>
                                        <th>Fecha de Compra</th>
                                        <td>{capitalizeFirstLetter(formatDateToFull(purchaseData.purchaseDate))}</td>
                                    </tr>
                                    <tr>
                                        <th>Edad</th>
                                        <td>{purchaseData.age}</td>
                                    </tr>
                                    <tr>
                                        <th>Producto</th>
                                        <td>{purchaseData.productName?.split(',')[0].replace('Producto:', '').trim() || 'No disponible'}</td>
                                    </tr>
                                    <tr>
                                        <th>Tipo de Adquisición</th>
                                        <td>{purchaseData.productName?.split(',')[2]?.replace('Tipo de Adquisición:', '').trim() || 'No disponible'}</td>
                                    </tr>
                                    <tr>
                                        <th>Código de Cupón</th>
                                        <td>{purchaseData.discountCoupon || 'No disponible'}</td>
                                    </tr>
                                    <tr>
                                        <th>Metodo de Pago</th>
                                        <td>
                                            {paymentMethodOptionsList.find(option => option.value === purchaseData.paymentMethod)?.label || 'Desconocido'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Precio Original</th>
                                        <td>S/.{purchaseData?.originalPrice || 0}</td>
                                    </tr>
                                    <tr>
                                        <th>Precio Final</th>
                                        <td>S/.{purchaseData?.finalPrice || 0}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4">
                    <button
                        type="button"
                        className="btn w-sm btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={handleClose}
                    >
                        Cerrar
                    </button>
                </div>
            </div>
        </BaseModal>
    );
}

export default ShowDetailPurchaseModal;