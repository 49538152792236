import { apiRepository } from "../../api/apiRepository";
import { getPaymentDetails } from "./mercadoPagoService";
import Cookies from 'js-cookie';

export const fetchPaymentDetails = async (paymentId: string, token: string) => {
  try {
    return await getPaymentDetails(paymentId, token);
  } catch (error) {
    console.error('Error al obtener los detalles del pago:', error);
    return error;
  }
};

export const handlePaymentSuccess = async (
  paymentInfo: any,
  cartContext: any,
  setCart: any,
  openModal: any,
) => {
  let response;
  let attempts = 0;

  while (attempts < 100) { // Retry up to 100 times
    try {
      response = await apiRepository.createPayments(paymentInfo);

      if (response.status >= 200 && response.status < 300) { // Valid response
        cartContext.clearCart(setCart);
        openModal("payment-success");

        // Remove the paymentToken cookie
        Cookies.remove('paymentToken');

        if (response.data.pathPdf) {
          return response.data.pathPdf;
        }

        return null;
      }
    } catch (error: any) {
      if (error.code === "ERR_NETWORK") {
        console.warn('Network error, retrying...', error);
        attempts++;
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second before retrying
        continue;
      } else if (error.response && (error.response.status >= 400 && error.response.status < 600)) {
        console.error('Server error:', error.response.status, error.response.data);
        throw error; // Stop retrying on server errors
      } else {
        console.error('Unexpected error:', error);
        throw error;
      }
    }
  }

  console.error('Max retry attempts reached. Could not complete the request.');
  throw new Error('Max retry attempts reached');
};

export const handlePaymentFailed = async (paymentInfo: any, openModal: any) => {
  try {
    await apiRepository.createPayments(paymentInfo);
    openModal("payment-error");
    return null;
  } catch (error) {
    openModal("payment-error");
  }
};

export const createPaymentInfo = (user: any, paymentDetails: any, codesCoupons: any) => {
  return {
    userId: user?.userId || "Usuario no logeado",
    jsonResponse: JSON.stringify({ status: 'success' }),
    status: 'COMPLETED',
    paymentDetails,
    codesCoupons,
  };
};

export const parseBackUrlParams = (urlParams: any) => {
  const itemIds = urlParams.itemIds.split(',');
  const quantities = urlParams.quantity.split(',');
  const amountRaw = urlParams.amountRaw.split(',');
  const discountValue = urlParams.discountValue.split(',');
  const codesCoupons = urlParams.codesCoupons ? urlParams.codesCoupons.split(',') : [];
  const amount_total_raw = urlParams.amount_total_raw;
  const discount_general_value = urlParams.discount_general_value;
  const codesCouponsGeneral = urlParams.codesCouponsGeneral ? urlParams.codesCouponsGeneral.split(',') : [];
  const userId = urlParams.userId;
  const maxLength = Math.max(itemIds.length, quantities.length, amountRaw.length, discountValue.length);
  const amount_with_discount = parseFloat(amount_total_raw) - parseFloat(discount_general_value);

  const paymentDetails = [];
  const totalAmountRaw = amountRaw.reduce((sum: number, amt: string, index: number) => sum + parseFloat(amt) * parseInt(quantities[index]), 0);

  for (let i = 0; i < maxLength; i++) {
    const quantity = parseInt(quantities[i]) || 1;
    const unitPrice = parseFloat(amountRaw[i]) || 0;
    const amount = (amount_with_discount * unitPrice * quantity) / totalAmountRaw;
    paymentDetails.push({
      packetId: Number(itemIds[i]) || null,
      quantity: quantity,
      amount: amount || 0,  // Se divide para al multiplicar por la cantidad, se obtenga el monto total del producto
      amountDiscount: Number(discountValue[i]) || 0,
      coupon: codesCoupons[i] || "",
    });
  }

  const totalAmount = paymentDetails.reduce((sum: number, item: any) => sum + item.amount, 0);  // Se suma cada monto total de los productos

  const jsonResponse = {
    userId: Number(userId) || "Usuario no logeado",
    jsonResponse: JSON.stringify({ status: "success" }),
    status: "COMPLETED",
    typePayment:"MERCADO PAGO",
    operationNumber: '',
    amount: totalAmount || 0,
    amountDiscount: parseFloat(discount_general_value) || 0,
    paymentDetails,
    codesCoupons: codesCouponsGeneral[0] ? [codesCouponsGeneral[0]] : [],
  };

  return jsonResponse;
};

export const preparePaymentInfo = (paymentData: any, couponDiscount: any, user: any) => {
  const paymentDetails = paymentData.additional_info.items.map((item: any) => {
    const specificDiscount = couponDiscount.specificDiscount?.find(
      (discount: any) => discount.packageId === item.id
    );

    return {
      packetId: Number(item.id),
      quantity: Number(item.quantity),
      amount: parseFloat(item.unit_price * item.quantity),
      amountDiscount: parseFloat(item.discount || 0),
      coupon: specificDiscount ? specificDiscount.couponName : "",
    };
  });

  const totalAmount = paymentDetails.reduce((sum: number, item: any) => sum + (item.amount * item.quantity), 0);
  const totalDiscount = paymentDetails.reduce((sum: number, item: any) => sum + item.amountDiscount, 0);

  const generalCouponCode = couponDiscount.generalDiscount?.couponName || "";

  const codesCoupons = [
    generalCouponCode,
    ...paymentDetails
      .filter((item: any) => item.coupon)
      .map((item: any) => item.coupon)
  ];

  return {
    userId: user?.userId || "Usuario no logeado",
    jsonResponse: JSON.stringify({ status: paymentData.status }),
    status: paymentData.status.toUpperCase(),
    amount: totalAmount,
    amountDiscount: totalDiscount,
    paymentDetails: paymentDetails,
    codesCoupons: codesCoupons,
    typePayment:"MERCADO PAGO",
    operationNumber: '',
  };
};
