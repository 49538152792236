import React, { useState } from 'react';
import FormModal from '../../../components/modal/FormModal';
import { TextInput, TimePickerField, DatePicker, DropdownInput, AutoCompleteInput } from '../../../components/inputs';
import useOptionFetch from '../../../../core/hooks/api/useOptionFetch';
import API_ENDPOINTS  from '../../../../core/constant/API_ENDPOINTS';
import { ServiceFormModalProps } from '../../../../core/Interface/admin/modalFormEdit';
import { MINUTES_SUGGESTINOS } from '../../../../core/data/options/options';
import useFetch from '../../../../core/hooks/api/useFetch';
import { TYPE_OF_RECURRENCI } from '../../../../core/data/options/options';

const ServiceFormModal: React.FC<ServiceFormModalProps> = ({
  show,
  onClose,
  onConfirm,
  formData,
  setFormData,
  errors,
  handleInputChange,
  loading
}) => {
  const userParams = {
    fullName: '',
    profile: 'provider',
    email: '',
    active: '',
    birthdate: '',
    hourStart: '',
    typeRecurrent: '',
    quantityRecurrent: '',
  };
  const { options: userOptions } = useOptionFetch(API_ENDPOINTS.users, 'name', userParams);
  const { data: dataPackageTypes } = useFetch(API_ENDPOINTS.packageTypes);
  const filteredIds = dataPackageTypes?.filter((item: { type: number }) => item.type === 4).map((item: { id: number }) => item.id) || [];
  const { data: dataPackage} = useFetch(API_ENDPOINTS.package + "/active");
  const filteredPackages = dataPackage?.filter(
    (item: { packageTypeId: number }) => filteredIds?.includes(item.packageTypeId)
  ) || [];
  const packageOptions = filteredPackages?.map((item: { id: number; name: string }) => ({
    value: item.id,
    label: item.title,
  }));
  
  const [filteredSuggestions, setFilteredSuggestions] = useState(MINUTES_SUGGESTINOS);
  const handleSearch = (query: string) => {
      setFilteredSuggestions(
          MINUTES_SUGGESTINOS.filter((option) =>
              option.label.toLowerCase().includes(query.toLowerCase())
          )
      );
  };

  const handleTimeStartChange = (newValue: string) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      hourStart: newValue.target.value,
    }));
  };

  return (
    <FormModal
      show={show}
      onClose={onClose}
      onConfirm={onConfirm}
      title={formData?.id ? "Editar Servicio" : "Crear Servicio"}
      loading={loading}
      error={errors?.submit}
      className="admin"
    >
      <div className="row">
        <div className="col-md-6">
          <DatePicker
            label="Fecha"
            value={formData?.date ? new Date(formData?.date) : undefined}
            onChange={(date) => setFormData({ ...formData, date })}
            name="date"
            required
            error={errors?.date}
          />
        </div>
        <div className="col-md-6">
          <TextInput
            label="Título"
            value={formData?.title || ''}
            onChange={(e) => handleInputChange(e, setFormData)}
            name="title"
            placeholder="Título del Servicio"
            required
            error={errors?.title}
          />
        </div>
        <div className="col-md-6">
          <TimePickerField
            label="Hora de Inicio"
            value={formData?.hourStart || ''}
            onChange={handleTimeStartChange}
            name="hourStart"
            format="hh:mm a"
            required
            error={errors?.hourStart}
          />
        </div>
        <div className="col-md-6">
            <AutoCompleteInput
                label="Duración de la sesión en minutos"
                value={formData.minutes || ''}
                suggestions={filteredSuggestions}
                name="minutes"
                onChange={(val) => setFormData(prevFormData => ({
                    ...prevFormData,
                    minutes: val,
                }))}
                onSearch={handleSearch}
                placeholder="Selecciona la duración en minutos"
                error={errors?.minutes}
            />
        </div>
        <div className="col-md-6">
          <DropdownInput
            label="Servicio"
            value={formData?.serviceId || ''}
            onChange={(e) => handleInputChange(e, setFormData)}
            name="serviceId"
            options={userOptions}
            placeholder="Seleccione un Servicio"
            required
            error={errors?.serviceId}
          />
        </div>
        <div className="col-md-6">
          <DropdownInput
            label="Paquete"
            value={formData?.packetId || ''}
            onChange={(e) => handleInputChange(e, setFormData)}
            name="packetId"
            options={packageOptions}
            placeholder="Seleccione un Paquete"
            required
            error={errors?.packetId}
          />
        </div>
            {!formData.id && (
                <>
                  <div className="col">
                      <DropdownInput
                          label="Tipo de Recurrencia"
                          name="typeRecurrent"
                          required={false}
                          options={TYPE_OF_RECURRENCI}
                          value={formData.typeRecurrent}
                          onChange={(e) => handleInputChange(e, setFormData)}
                          placeholder="Selecciona el tipo de recurrencia (si aplica)"
                          error={errors?.typeRecurrent}
                      />
                  </div>
                  {formData.typeRecurrent && (
                      <div className="col-md-6">
                          <TextInput
                              label="Número de recurrencia"
                              name="quantityRecurrent"
                              value={formData.quantityRecurrent || ''}
                              onChange={(e) => handleInputChange(e, setFormData)}
                              placeholder="Indica la cantidad de recurrencia"
                              error={errors?.quantityRecurrent}

                          />
                      </div>
                  )}
                  {formData.typeRecurrent && (
                      <div className="alert alert-warning mt-2" role="alert">
                          <i className="fa fa-exclamation-triangle me-2"></i>
                          Verifica los campos de recurrencia, esta acción no es reversible.
                      </div>
                  )}
              </>
            )}
      </div>
    </FormModal>
  );
};

export default ServiceFormModal;
