import React, { useState, useEffect, useContext } from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import { useOutletContext } from 'react-router-dom';
import { AuthContext } from '../../../../core/context/AuthContext';
import { BreadcrumbContext } from '../../../../core/Interface/contextInterface';
import { useProfile } from '../../../../core/context/ProfileContext';

/**
 * Componente para canjear una GiftCard.
 * @returns {JSX.Element} Componente de canje de GiftCard.
 */
const RedeemGiftCard = () => {
    const authContext = useContext(AuthContext);
    if (!authContext) {
      return <div>Error: Auth context not found!</div>;
    }

  const { user } = authContext;
  const { setBreadcrumbData } = useOutletContext<BreadcrumbContext>();
  const { refetchPaymentDays, refetchPurchasedServices } = useProfile(); // Use ProfileContext
  const [giftCardCode, setGiftCardCode] = useState('');
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setBreadcrumbData({ title: 'Canjear GiftCard', item2: 'GiftCard' });
  }, [setBreadcrumbData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGiftCardCode(e.target.value);
  };

  const handleRedeem = async () => {
    setSuccessMessage('');
    setErrorMessage('');

    const trimmedCode = giftCardCode.trim();

    if (!trimmedCode) {
      setErrorMessage('Por favor, ingrese un código válido.');
      return;
    }

    if (/\s/.test(trimmedCode)) {
      setErrorMessage('El código no puede contener espacios.');
      return;
    }

    setIsRedeeming(true);

    try {
      const pyload = { "idCustomer": user?.userId, "code": trimmedCode };
      const response = await apiRepository.redeemGiftCard(pyload);
      if (response.data.success) {
        setSuccessMessage('El código fue canjeado exitosamente.');
      } else {
        setErrorMessage('El código es inválido o ya fue utilizado.');
      }
    } catch (error) {

      let errorMessage = 'Ocurrió un error al canjear el código. Inténtelo nuevamente.';
      if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
          errorMessage = errorMessage.replace(/Error interno del servidor:/, '');
      }

      setErrorMessage(errorMessage);
    } finally {
      setIsRedeeming(false);
      await refetchPaymentDays();
      await refetchPurchasedServices();
    }
  };

  return (
    <div className="redeem-giftcard">
      <h4>Canjear GiftCard</h4>
      <div className="card card-shadow shadow-none mt-3">
        <div className="card-body">
          <div className="mb-3">
            <label htmlFor="giftCardCode" className="form-label">
              Código de GiftCard
            </label>
            <input
              type="text"
              id="giftCardCode"
              className="form-control"
              value={giftCardCode}
              onChange={handleInputChange}
              placeholder="Ingrese el código de su GiftCard"
            />
          </div>
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
          <button
            className="btn btn-primary"
            onClick={handleRedeem}
            disabled={isRedeeming}
          >
            {isRedeeming ?             <>
              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
              Canjeando...
            </> : 'Canjear'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RedeemGiftCard;