import { useState, useCallback } from "react";
import { putData as apiPutData } from "../../services/api";
import { UsePut } from "../../Interface/hooks/useRequest";

const usePut = (endpoint: string): UsePut => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const put = useCallback(async (id: string | number, data: any, useFormData = false) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiPutData(endpoint, id, data, useFormData);
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [endpoint]);

  return { data, loading, error, put, setError };
};

export default usePut;