import { convertStringToLocalDate, convertDateToLocalDateString } from "../../../../../core/utils/dateUtils";
import { validateForm } from '../../../../../core/form/formValidationRules';
import moment from 'moment-timezone';

export const handleFormSubmit = async (
  event: React.FormEvent<HTMLFormElement>,
  formData: any,
  validationRules: any,
  selectedClass: any,
  post: any,
  setErrors: any,
  setShowModal: any,
  setShowSuccessModal: any,
  setShowErrorModal: any
) => {
  event.preventDefault();
  const newErrors = validateForm(formData, validationRules, {}, new Date(selectedClass.endDate));
  if (Object.keys(newErrors).length > 0) {
    setErrors(newErrors);
    return false;
  } else {
    const startDate = convertStringToLocalDate(formData.dateRange[0]);
    const endDate = convertStringToLocalDate(formData.dateRange[1]);
    const quantityDays = moment(endDate).diff(moment(startDate), 'days') + 1;

    const payload = {
      paymentDaysId: selectedClass.id,
      customerId: selectedClass.userId,
      reason: formData.reason,
      quantityDays,
      startDateFreeze: convertDateToLocalDateString(startDate),
      endDateFreeze: convertDateToLocalDateString(endDate)
    };
    try {
      await post(payload);
      setErrors({});
      setShowModal(false);
      setShowSuccessModal(true);
      return true;
    } catch (err: any) {
      const errorMessage = err.response?.data?.message || 'Error al enviar la solicitud. Inténtalo de nuevo.';
      setErrors({ submit: errorMessage });
      setShowModal(false);
      setShowErrorModal(true);
      return false;
    }
  }
};