import { useState, useEffect } from 'react';

export const useResponsiveColumns = (columns) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getColumns = () => {
    let displayedColumns = [];

    if (screenWidth < 600) {
      displayedColumns = columns.slice(0, 1);
    } else if (screenWidth < 800) {
      displayedColumns = columns.slice(0, 2);
    }  else if (screenWidth < 900) {
      displayedColumns = columns.slice(0, 3);
    } else if (screenWidth < 1300) {
      displayedColumns = columns.slice(0, 4);
    } else if (screenWidth < 1500) {
      displayedColumns = columns.slice(0, 5);
    } else if (screenWidth < 1750) {
      displayedColumns = columns.slice(0, 6);
    } else if (screenWidth < 1850) {
      displayedColumns = columns.slice(0, 7);
    } else {
      displayedColumns = columns;
      return displayedColumns;
    }

    return displayedColumns;
  };

  return getColumns;
};
