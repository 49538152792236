import React from 'react';

const CategoryFilter: React.FC<{
  sortedCategories: any[];
  selectedCategory: number | null;
  filterByCategory: (categoryId: number) => void;
}> = ({ sortedCategories, selectedCategory, filterByCategory }) => (
  <div className="row mb-4">
    {sortedCategories.map((category) => (
      <div key={category.id} className="col-md-4 mb-3">
        <button
          className={`btn w-100 ${selectedCategory === category.id ? 'btn-primary-blue' : 'btn-outline-secondary'}`}
          onClick={() => filterByCategory(category.id)}
        >
          {category.name}
        </button>
      </div>
    ))}
  </div>
);

export default CategoryFilter;
