import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { InputField } from '../../../admin/common/input/inputField';
import { useValidateCode } from '../../../../core/hooks/auth/useCodeValidate';
import { getErrorForField } from '../../../../core/utils/utils';
import { EmailOtpModalProps } from '../../../../core/Interface/modalInterface';

const EmailOtpModal: React.FC<EmailOtpModalProps> = ({
  modalId = "otp-email-modal",
  email,
}) => {

  const { formData, handleChange, resetForm, handleValidateCode, loading, serverError } =
    useValidateCode({ email: email || "" });

  useEffect(() => {
    const modalElement = document.getElementById(modalId);

    const handleModalClose = () => {
      resetForm();
    };

    if (modalElement) {
      modalElement.addEventListener("hide.bs.modal", handleModalClose);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener("hide.bs.modal", handleModalClose);
      }
    };
  }, [modalId, resetForm]);

  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <button
            type="button"
            className="d-none"
            data-bs-toggle="modal"
            data-bs-target="#reset-password"
          ></button>
          <div className="modal-header d-flex align-items-center justify-content-end pb-0 border-0">
            <Link to="#" data-bs-dismiss="modal" aria-label="Cerrar">
              <i className="ti ti-circle-x-filled fs-20" />
            </Link>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={handleValidateCode} className="digit-group">
              <div className="text-center mb-3">
                <h3 className="mb-2">Verificación de identidad por correo electrónico</h3>
                <p className="fs-14">Codigo enviado a tu dirección de correo electrónico</p>
              </div>
              <div className="text-left">
                <div className="mb-3">
                  <InputField
                    label="Ingresa tu código privado"
                    name="code"
                    value={formData?.code || ""}
                    onChange={(e) => handleChange(e)}
                    error={getErrorForField("code", serverError || [])}
                  />
                  {getErrorForField('code', serverError || []) && (
                    <div className="invalid-feedback">{getErrorForField('code', serverError || [])}</div>
                  )}
                </div>
                <div className="mb-3">
                  {serverError?.find((error) => error.field === 'server') && (
                    <small className="text-danger">
                      {serverError?.find((error) => error.field === 'server')?.message}
                    </small>
                  )}
                </div>
                <button disabled={loading} type="submit" className="btn btn-lg btn-linear-primary w-100">
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="ms-2">Verificando...</span>
                    </>
                  ) : (
                    'Verificar y continuar'
                  )}
                </button>
                <div className="d-flex justify-content-center mt-3">
                  <p>
                    ¿Quieres utilizar otro correo electronico?{" "}
                    <Link
                      to="#"
                      className="link-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#forgot-modal"
                    >
                      Cambiar correo
                    </Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailOtpModal;