/**
 * Componente EditLegal
 * 
 * Este componente permite editar los términos legales, incluyendo los Términos y Condiciones, Política de Privacidad y Política de Cookies.
 * Utiliza `ReactQuill` para la edición de texto enriquecido.
 * 
 * Estado:
 * - htmlView: Indicador para mostrar la vista HTML.
 * - errors: Lista de errores de validación.
 * - loading: Indicador de carga.
 * 
 * Funciones:
 * - handleUpdate: Maneja la actualización de los términos legales.
 * - handleToggleView: Alterna entre la vista HTML y la vista de usuario.
 * 
 * Hooks:
 * - usePageLegal: Hook personalizado para obtener y gestionar los datos de la página legal.
 */

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ValidationError } from '../../../core/form/validationForm';
import { apiRepository } from '../../../api/apiRepository';
import { usePageLegal } from '../../../core/hooks/getData/usePageLegal';
import Quill from 'quill';

const Link = Quill.import('formats/link');
class CustomLink extends Link {
  static sanitize(url: string) {
    if (url.startsWith('/')) {
      return url;
    } else if (url.match(/^https?:\/\//)) {
      return url;
    }
    return `http://${url}`;
  }
}

Quill.register(CustomLink, true);

const EditLegal = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  const { pageData, setPageData } = usePageLegal(Number(id));
  const titlePage = id === '1' ? 'Términos y Condiciones' : id === '2' ? 'Política de Privacidad' : 'Política de Cookies';
  const [htmlView, setHtmlView] = useState<boolean>(false);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setPageData([]); // Limpia el estado cuando el id cambia
  }, [id, setPageData]);

  const handleUpdate = async () => {
    const newErrors: ValidationError[] = [];

    if (!pageData?.description || !pageData?.description.trim()) {
      newErrors.push({
        field: 'error',
        message: 'La descripción no puede estar vacía.',
      });
      setErrors(newErrors);
      return;
    }

    try {
      setLoading(true);
      await apiRepository.updatePage(Number(id), pageData);
      newErrors.push({
        field: 'success',
        message: '¡Condiciones actualizadas con éxito!',
      });
    } catch (error) {
      newErrors.push({
        field: 'error',
        message: 'Error al actualizar las condiciones.',
      });
    } finally {
      setErrors(newErrors);
      setLoading(false);
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['bold', 'italic', 'underline'],
      ['link'],
    ],
  };

  const handleToggleView = () => {
    setHtmlView(!htmlView);
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-lg-11 col-sm-12 m-auto">
            <div className="content-page-header">
              <h5>Editar términos legales</h5>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <div className="row">
                    <div className="col">
                      <input
                        type="text"
                        className="form-control col-2"
                        value={titlePage}
                        readOnly
                      />
                    </div>
                    <button
                      className="btn btn-secondary form-group mb-0 col-md-3 col-6"
                      type="button"
                      onClick={handleToggleView}
                    >
                      {htmlView ? 'Ver como usuario' : 'Ver como HTML'}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Descripción<span className="text-danger">  *</span></label>
                  {htmlView ? (
                    <textarea
                      className="form-control"
                      value={pageData?.description || ''}
                      onChange={(e) => setPageData({ ...pageData, description: e.target.value })}
                      rows={10}
                    />
                  ) : (
                    <>
                      <ReactQuill
                        theme="snow"
                        value={pageData?.description || ''}
                        onChange={(value) => setPageData({ ...pageData, description: value })}
                        modules={modules}
                      />
                      {errors?.find((value) => value.field === 'description') && (
                        <small className="text-danger">
                          {errors.find((value) => value.field === 'description')?.message}
                        </small>
                      )}
                    </>
                  )}
                </div>
                {errors?.find((value) => value.field === 'success') && (
                  <div
                    className="alert alert-success mt-3 mb-3"
                    role="alert"
                  >
                    {
                      errors.find((value) => value.field === 'success')
                        ?.message
                    }
                  </div>
                )}
                {errors?.find((value) => value.field === 'error') && (
                  <div
                    className="alert alert-danger mt-3 mb-3"
                    role="alert"
                  >
                    {errors.find((value) => value.field === 'error')?.message}
                  </div>
                )}
              </div>

              <div className="col-lg-12">
                <div className="btn-path">
                  <button
                    className="btn btn-primary-admin mb-3"
                    type="button"
                    onClick={handleUpdate}
                  >
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Guardando...
                      </>
                    ) : (
                      'Guardar cambios'
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLegal;