import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DropdownInputProps } from '../../../core/Interface/elements/inputInterface';
import InputWrapper from './InputWrapper';

const DropdownInput: React.FC<DropdownInputProps> = ({ label, value, onChange, options, error, name, placeholder, disabled = false, required = false }) => {
  return (
    <InputWrapper label={label} required={required} error={error}>
      <Dropdown
        value={value}
        name={name}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        disabled={disabled}
        className="w-100"
        appendTo="self"
      />
    </InputWrapper>
  );
};

export default DropdownInput;
