/**
 * Componente PaymentDays
 * 
 * Este componente se encarga de mostrar y gestionar los días de pago de los usuarios.
 * Permite filtrar los días de pago por nombre de usuario y paginar los resultados.
 * 
 * Funcionalidades:
 * - Filtrado por nombre de usuario.
 * - Paginación de resultados.
 * - Edición de días de pago.
 * 
 * Hooks:
 * - useState: Para manejar el estado local del componente.
 * - useEffect: Para realizar la carga inicial de datos.
 * 
 * Dependencias:
 * - apiRepository: Para realizar las llamadas a la API.
 * - DataTableWrapper: Componente para mostrar los datos en una tabla.
 * - PaymentDaysModal: Componente modal para editar días de pago.
 */

import React, { useState } from 'react';
import usePaginatedFetch from '../../../core/hooks/api/usePaginatedFetch';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumnsCoupons';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import PaymentDaysModal from '../common/modals/users-payment-day-modal';
import { InputField } from '../common/input/inputField';

const PaymentDays = () => {
  const [selectedPaymentDays, setSelectedPaymentDays] = useState<string | null>(null);
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>(undefined);
  const [email, setEmail] = useState<string>(undefined);

  const { data, totalElements, first, rows, onPageChange, refetch } = usePaginatedFetch(
    'payment-days',
    10,
    0,
    { fullName, email }
  );

  const handleEditClick = (rowData: any) => {
    setSelectedPaymentDays(null);
    setTimeout(() => setSelectedPaymentDays(rowData), 0);
  };

  const columns = [
    { field: "id", header: "ID" },
    { field: "fullName", header: "Usuario" },
    { field: "quantity", header: "Cantidad de Clases" },
    {
      field: "endDate",
      header: "Fecha de Vencimiento",
      body: (rowData: any) => (
        rowData.endDate && rowData.endDate !== ''
          ? rowData.endDate.split('T')[0]
          : 'Fecha sin definir'
      )
    }, { field: "type", header: "Tipo" },
    {
      field: "active",
      header: "Estado",
      body: (rowData: any) => (
        <span className={rowData.status ? 'badge-active' : 'badge-inactive'}>
          {rowData.status ? 'Activo' : 'Inactivo'}
        </span>
      )
    },
  ];

  const getColumns = useResponsiveColumns(columns);

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          <h5>Clases Asignadas</h5>
          <div className="list-btn">
            <ul>
              <li>
                <button
                  className="btn btn-outline-info"
                  onClick={() => setFiltersVisible(!filtersVisible)}
                >
                  {filtersVisible ? 'Ocultar Filtros' : 'Mostrar Filtros'}
                </button>
              </li>

            </ul>
          </div>
        </div>

        {filtersVisible && (
          <div className="search-filters card card-shadow shadow-none p-3 mb-4 shadow-sm">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h6 className="text-dark">Filtros de búsqueda</h6>
              <button
                className="btn btn-outline-primary rounded-3"
                onClick={() => {
                  setFullName('');
                }}
                title="Limpiar filtros"
              >
                <i className="fa fa-eraser me-2" />
                Limpiar
              </button>
            </div>
            <div className="d-flex w-100 align-items-center gap-3">
              <div className="row w-100">
                <div className="col-md-6">
                  <InputField
                    label="Nombre"
                    name="fullName"
                    placeholder="Ejemplo: Juan"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    required={false}
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    label="Correo"
                    name="email"
                    placeholder="Ejemplo: juan@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required={false}
                  />
                </div>
              </div>
            </div>
          </div>

        )}

        <DataTableWrapper
          data={data}
          columns={getColumns()}
          onEdit={handleEditClick}
          paginator
          showGridlines
          rowsPerPage={rows}
          totalRecords={totalElements}
          first={first}
          onPageChange={onPageChange}
          emptyMessage="No se encontraron clases asignadas."
        />
      </div>
      <PaymentDaysModal selectedPaymentDays={selectedPaymentDays} onUpdateSuccess={() => refetch(0, rows)} />
    </div>
  );
};

export default PaymentDays;