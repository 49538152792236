import React, { useState, useEffect, useContext } from 'react';
import AddPayment from '../../../admin/add-payment';
import { useOutletContext } from 'react-router-dom';
import { BreadcrumbContext } from '../../../../core/Interface/contextInterface';

/**
 * Componente para mostrar el historial de compras del cliente.
 * @returns {JSX.Element} Componente de historial de compras.
 */
const PurchaseHistory = () => {
  const { setBreadcrumbData } = useOutletContext<BreadcrumbContext>();

  useEffect(() => {
    setBreadcrumbData({ title: "Generar Venta", item1: "Perfil", item2: "Generar Venta" });
  }, [setBreadcrumbData]);

  return (
      <div className="">
        <AddPayment customer={true} />
      </div>
  );
};

export default PurchaseHistory;