export const isPaymentSuccessful = (success, paymentId) => {
    return success === 'success' || success === 'approved' && paymentId;
};

export const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    return {
        success: params.get('success'),
        paymentId: params.get('payment_id'),
    };
};

export const extractUsedCoupons = (cart) => {
    return cart.filter(item => item.coupon).map(item => item.coupon);
};

export const preparePaymentDetails = (items: any[]) =>
    items.map((item: any) => ({
        packetId: item.id,
        quantity: item.quantity,
        amount: item.price,
        amountDiscount: item.discount_value,
        couponCode: item.codesCoupons.join(",") || "",
    }));

export const createPaymentInfo = (user: any, paymentDetails: any[], codesCoupons: string[]) => ({
    userId: user?.userId || "Usuario no logeado",
    jsonResponse: JSON.stringify({ status: "success" }),
    status: "COMPLETED",
    paymentDetails,
    codesCoupons,
});