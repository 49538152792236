import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { isValidId } from '../../utils/validationUtils';
import { ProfileContextType } from '../Interface/context/ProfileContextType';
import { getData } from '../services/api';
import API_ENDPOINTS from '../constant/API_ENDPOINTS';

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const ProfileProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [profileData, setProfileData] = useState<any>(null);
  const [userId, setUserId] = useState<number | null>(null); // Store userId in state
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const fetchPaymentDays = useCallback(async (id: number, shouldSetProfileData = true) => {
    try {
      const paymentDaysResponse = await getData(`${API_ENDPOINTS.paymentDays}?userId=${id}`);
      const paymentDaysData = paymentDaysResponse.data.data.content;

      if (shouldSetProfileData) {
        setProfileData((prevData: any) => ({
          ...prevData,
          paymentDays: paymentDaysData,
        }));
      }

      return paymentDaysData;
    } catch (err) {
      console.error('Error fetching payment days:', err);
      throw err;
    }
  }, []);

  const fetchPurchasedServices = useCallback(async (id: number, shouldSetProfileData = true) => {
    try {
      const purchasedServicesResponse = await getData(`${API_ENDPOINTS.purchasedServices}/customer/${id}/service-count`);
      const purchasedServicesData = purchasedServicesResponse.data.data;

      if (shouldSetProfileData) {
        setProfileData((prevData: any) => ({
          ...prevData,
          purchasedServices: purchasedServicesData,
        }));
      }

      return purchasedServicesData;
    } catch (err) {
      console.error('Error fetching purchased services:', err);
      throw err;
    }
  }, []);

  const fetchProfile = async (id: number) => {
    if (!isValidId(id)) {
      setError('Invalid user ID');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await getData(`${API_ENDPOINTS.users}/${id}`);
      const profile = response.data.data;

      if (profile) {
        const paymentDaysData = await fetchPaymentDays(id);
        const purchasedServicesData = await fetchPurchasedServices(id);

        setProfileData({
          ...profile,
          paymentDays: paymentDaysData,
          purchasedServices: purchasedServicesData,
        });
        setUserId(id); // Store the userId in state
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const refetchUser = async () => {
    if (!userId) {
      console.warn('User ID is not set for refetching user data');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await getData(`${API_ENDPOINTS.users}/${userId}`);
      const profile = response.data.data;

      if (profile) {
        setProfileData((prevData: any) => ({
          ...prevData,
          ...profile, // Update only user-related data
        }));
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const refetchPaymentDays = async () => {
    if (!userId) {
      console.warn('User ID is not set for refetching payment days');
      return;
    }

    try {
      const paymentDaysData = await fetchPaymentDays(userId, false);
      setProfileData((prevData: any) => ({
        ...prevData,
        paymentDays: paymentDaysData,
      }));
    } catch (err) {
      console.error('Error refetching payment days:', err);
    }
  };

  const refetchPurchasedServices = async () => {
    if (!userId) {
      console.warn('User ID is not set for refetching purchased services');
      return;
    }

    try {
      const purchasedServicesData = await fetchPurchasedServices(userId, false);
      setProfileData((prevData: any) => ({
        ...prevData,
        purchasedServices: purchasedServicesData,
      }));
    } catch (err) {
      console.error('Error refetching purchased services:', err);
    }
  };

  const clearProfileData = () => {
    setProfileData(null);
    setUserId(null);
  };

  return (
    <ProfileContext.Provider value={{ profileData, loading, error, fetchProfile, refetchUser, refetchPaymentDays, refetchPurchasedServices, clearProfileData, fetchPaymentDays, fetchPurchasedServices }}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = (): ProfileContextType => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};
