import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../core/img/ImageWithBasePath';
import * as Icon from 'react-feather';
import { set_is_mobile_sidebar } from '../../../core/data/redux/action';
import { useDispatch } from 'react-redux';
import { all_routes } from '../../../core/data/routes/all_routes';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../core/context/AuthContext';

const AdminHeader = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { logout } = authContext;
  const [isFullscreen, setIsFullscreen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
  };

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      // Request fullscreen
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      }
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }

    // Toggle the state
    setIsFullscreen(!isFullscreen);
  };
  // const mobileSidebar = useSelector((state : any) => state.mobileSidebar)
  const dispatch = useDispatch();
  const routes = all_routes
  return (
    <div className="admin-header">
      <div className="header-left">
      </div>
      <Link
        className="mobile_btn"
        id="mobile_btn"
        to="#"
        onClick={() => {
          dispatch(set_is_mobile_sidebar(true));
        }}
      >
        <i className="ms-3 mt-3 fas fa-align-left" />
      </Link>
      <div className="header-split">
        <div className="page-headers">

        </div>
        <ul className="nav admin-user-menu">
          {/* Notifications */}
          <li className="nav-item">
            <Link to={routes.index} target='_blank' className="viewsite">
              <Icon.Globe className="react-feather-custom me-2"></Icon.Globe>
              Ver Pagina
            </Link>
          </li>
          <li className="nav-item  has-arrow dropdown-heads ">
            <Link
              onClick={toggleFullscreen}
              to="#"
              className="win-maximize header-feather-icons"
            >
              <Icon.Maximize className="react-feather-custom "></Icon.Maximize>
            </Link>
          </li>
          <li className="nav-item">
            <button
              className="btn btn-light ms-2"
              type="button"
              onClick={handleLogout}
          >
              <Icon.LogOut className="react-feather-custom me-2"></Icon.LogOut>
              Cerrar Sesión
            </button>
          </li>
          {/* User Menu */}
          <li className="nav-item dropdown">
            <Link
              to="#"
              className="user-link  nav-link"
              data-bs-toggle="dropdown"
            >
            </Link>
            <div className="dropdown-menu menu-drop-user">
              <div className="profilemenu ">
                <div className="user-detials">
                  <Link to="account">
                    <span className="profile-image">
                      <ImageWithBasePath
                        src="assets/admin/img/user.jpg"
                        alt="img"
                        className="profilesidebar"
                      />
                    </span>
                    <span className="profile-content">
                      <span>John Smith</span>
                      <span>John@example.com</span>
                    </span>
                  </Link>
                </div>
                <div className="subscription-menu">
                  <ul>
                    <li>
                      <Link to="account-settings">Profile</Link>
                    </li>
                    <li>
                      <Link to="localization">Settings</Link>
                    </li>
                  </ul>
                </div>
                <div className="subscription-logout">
                  <Link to="signin">Log Out</Link>
                </div>
              </div>
            </div>
          </li>
          {/* /User Menu */}
        </ul>
      </div>
    </div>
  );
};

export default AdminHeader;
