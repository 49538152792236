import { ValidationRule, CustomFormData, Errors } from '../Interface/validationsInterfaces';
import { validationMethods } from './validationMethods';

const defaultMessages = {
  required: 'Este campo es obligatorio.',
  dateRange: 'Seleccione un rango de fechas válido.',
  minDateRange: 'El rango de fechas debe ser de al menos 5 días.',
  username: 'El nombre de usuario no es válido.',
  imageFormat: 'El formato de la imagen no es válido.',
  minCurrentDateRange: 'El rango de fechas no puede ser menor a la fecha actual.',
  maxEndDateRange: 'El rango de fechas no puede ser mayor a la fecha de finalización.'
};

const getNestedValue = (obj: any, path: string) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

export const validateForm = (formData: CustomFormData, rules: ValidationRule[], customMessages: { [key: string]: string } = {}, endDate: Date) => {
  const errors: Errors = {};

  rules.forEach(rule => {
    const value = getNestedValue(formData, rule.field);
    rule.methods.forEach(method => {
      if (!validationMethods[method](value, formData, endDate)) {
        errors[rule.field] = customMessages[rule.field] || defaultMessages[method];
      }
    });
  });

  return errors;
};