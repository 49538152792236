import React, { createContext, useContext, useEffect, useState } from 'react';
import { InitialFetchContextType } from '../Interface/context/InitialFetchContextType';
import API_ENDPOINTS from '../constant/API_ENDPOINTS';
import useFetch from '../hooks/api/useFetch';
import { img_api } from '../../environment';
import { filterByStatus, sortByPosition } from '../../utils/dataUtils';

const InitialFetchContext = createContext<InitialFetchContextType | undefined>(undefined);

export const InitialFetchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data: enterpriseData, loading: enterpriseLoading, error: enterpriseError } = useFetch(API_ENDPOINTS.enterpriseInformation + "/1");
  const { data: bannersData, loading: bannersLoading, error: bannersError } = useFetch(API_ENDPOINTS.banners);
  const [svgContent, setSvgContent] = useState("");
  const banners = sortByPosition(filterByStatus(bannersData, true));

  useEffect(() => {
    const fetchSVG = async (url: string) => {
      try {
        const res = await fetch(url);
        if (!res.ok) throw new Error('Network response was not ok');
        const data = await res.text();
        setSvgContent(data);
      } catch (error) {
        console.error('Fetch SVG failed:', error);
      }
    };

    if (enterpriseData?.image?.endsWith('.svg')) {
      const svgUrl = `${img_api}enterprise/${enterpriseData.image}`;
      fetchSVG(svgUrl);
    }
  }, [enterpriseData]);

  return (
    <InitialFetchContext.Provider
      value={{
        enterpriseData,
        svgContent,
        banners,
        loading: enterpriseLoading || bannersLoading,
        error: enterpriseError || bannersError,
      }}
    >
      {children}
    </InitialFetchContext.Provider>
  );
};

export const useInitialFetch = (): InitialFetchContextType => {
  const context = useContext(InitialFetchContext);
  if (!context) {
    throw new Error('useInitialFetch must be used within an InitialFetchProvider');
  }
  return context;
};
