import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import { getDefaultMonthRange, convertDateToLocalDateString } from '../../../core/utils/dateUtils';
import { CalendarRangeField } from '../common/input/inputField';
import { TextInput, AutoCompleteInput } from '../../components/inputs';
import ShowDetailPurchaseModal from '../common/modals/show-detail-purchase-modal';
import { useGetPurchaseReportExelFile } from '../../../core/hooks/getReports/useGetPurchaseReportExelFile';
import { downloadExcelFile } from '../../../core/utils/fileUtils';
import moment from 'moment';
import usePaginatedFetch from '../../../core/hooks/api/usePaginatedFetch';
import API_ENDPOINTS from '../../../core/constant/API_ENDPOINTS';
import useFetch from '../../../core/hooks/api/useFetch';
import { paymentMethodOptionsList } from '../../../core/data/options/options';

const PurchaseReport = () => {
  const [dateRange, setDateRange] = useState<[Date, Date]>(getDefaultMonthRange() as [Date, Date]);
  const [selectedPurchase, setSelectedPurchase] = useState<any>();
  const [fullNameFilter, setFullNameFilter] = useState<string>('');
  const [selectedUserLabel, setSelectedUserLabel] = useState<string>('');
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { refetchPurchaseReport } = useGetPurchaseReportExelFile(dateRange);
  const { data: customers, loading } = useFetch(`${API_ENDPOINTS.users}?fullName=&email=${searchQuery}&profile=customer&active=&birthdate=`);

  const userSuggestions = customers?.content?.map((customer: { email: string, id: string | number }) => ({
      label: customer.email,
      value: customer.id
    }))
    .filter((customer: { label: string }) => {
      const query = searchQuery.toLowerCase();
      return customer.label.toLowerCase().includes(query);
    })
    .sort((a: { label: string }, b: { label: string }) => {
      const query = searchQuery.toLowerCase();
      const aMatch = a.label.toLowerCase().indexOf(query);
      const bMatch = b.label.toLowerCase().indexOf(query);
      if (aMatch === -1) return 1;
      if (bMatch === -1) return -1;
      return aMatch - bMatch;
    });

  const handleUserComplete = (() => {
    let typingTimeout: NodeJS.Timeout;

    return (e: any) => {
      if (typingTimeout) clearTimeout(typingTimeout);
      typingTimeout = setTimeout(() => {
        setSearchQuery(e.query);
      }, 1500);
    };
  })();

  const handleUserChange = (value: string | { label: string, value: number | null }) => {
    setSelectedUserLabel(typeof value === 'string' ? value : value?.label || '');
  };

  const params = dateRange?.length === 2 && dateRange.every(date => date instanceof Date && !isNaN(date.getTime())) ? {
    startDate: convertDateToLocalDateString(dateRange[0]),
    endDate: convertDateToLocalDateString(dateRange[1]),
    ...(selectedUserLabel ? { email: selectedUserLabel } : {}),
    ...(fullNameFilter ? { fullName: fullNameFilter } : {})
  } : null;

  const {
    data: purchaseReport,
    totalElements,
    first,
    rows,
    onPageChange
  } = usePaginatedFetch( dateRange?.length === 2 && dateRange.every(date => date instanceof Date && !isNaN(date.getTime())) ? API_ENDPOINTS.reportPurchase : null, 10, 0, params);

  const columns = [
    { field: "fullName", header: "Cliente" },
    { field: "dni", header: "DNI" },
    { field: "email", header: "Correo Electrónico" },
    {
      field: "paymentMethod",
      header: "Metodo de Pago",
      body: (rowData: any) =>
        paymentMethodOptionsList.find(option => option.value === rowData.paymentMethod)?.label || 'Desconocido'
    },
    { field: "purchaseDate", header: "Fecha de Compra", body: (rowData: any) => moment(rowData.purchaseDate).format('DD/MM/YYYY') },
    { field: "finalPrice", header: "Precio Final", body: (rowData: any) => `S/. ${rowData.finalPrice}` },
  ];

  const handleShowInfo = (rowData: any) => {
    setSelectedPurchase(null);
    setTimeout(() => setSelectedPurchase(rowData), 0);
  };

  const handleDownloadExcel = async () => {
    if (dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined)) {
      const file = await refetchPurchaseReport();
      downloadExcelFile(file, 'purchase-report');
    }
  };

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          <h5>Informe de Compras</h5>
        </div>
        <div className="card card-status mb-3">
          <div className="card-head-blk border-bottom-0 pb-0">
            <div className="row align-items-center">
              <div className="col-md-12 d-flex justify-content-end">
                <button
                  className="btn btn-outline-primary rounded-3 mb-3 me-2"
                  onClick={() => {
                    setDateRange(getDefaultMonthRange() as [Date, Date]);
                    setSelectedUserLabel('');
                    setSearchQuery('');
                    setFullNameFilter('');
                  }}
                >
                  <i className="fa fa-eraser me-2" />
                  Limpiar
                </button>
                <button
                  className="btn btn-primary-admin rounded-3 mb-3 align-items-center d-flex me-2"
                  onClick={handleDownloadExcel}
                >
                  <Icon.FileText className="react-feather-icon me-2" />
                  <span>Descargar como Excel</span>
                </button>
              </div>
              <div className="col-xl-12 col-md-12 d-flex">
                <div className="earning-group gap-3 w-100">
                  <CalendarRangeField
                    value={dateRange || [new Date(), new Date()]}
                    onChange={(e: any) => setDateRange(e.value)}
                  />
                  <AutoCompleteInput
                    placeholder="Busca un correo..."
                    value={selectedUserLabel}
                    suggestions={userSuggestions}
                    completeMethod={handleUserComplete}
                    onChange={handleUserChange}
                    required={false}
                    loading={loading} // Pass loading state to show animation
                  />
                  <TextInput
                    value={fullNameFilter}
                    onChange={(e: any) => setFullNameFilter(e.target.value)}
                    placeholder="Busca un nombre..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <DataTableWrapper
            data={purchaseReport || []}
            sortField='purchaseDate'
            lazy={true}
            sortOrder={-1}
            columns={columns}
            customIcon={Icon.Eye}
            onCustomAction={handleShowInfo}
            paginator={true}
            rowsPerPage={rows}
            first={first}
            totalRecords={totalElements}
            onPageChange={onPageChange}
            showGridlines
            emptyMessage="No se encontraron compras."
          />
        </div>
        <ShowDetailPurchaseModal selectedPurchaseData={selectedPurchase} />
      </div>
    </div>
  );
};

export default PurchaseReport;