import React, { useState } from 'react';
import * as Icon from 'react-feather';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import useFetch from '../../../core/hooks/api/useFetch';
import API_ENDPOINTS from '../../../core/constant/API_ENDPOINTS';
import { getDefaultMonthRange } from '../../../core/utils/dateUtils';
import { CalendarRangeField } from '../common/input/inputField';
import { useGetTrainerClassSummaryExcelFile } from '../../../core/hooks/getReports/useGetTrainerClassSummaryExcelFile';
import { downloadExcelFile } from '../../../core/utils/fileUtils';
import { convertDateToLocalDateString } from '../../../core/utils/dateUtils';

const TrainerClassSummary = () => {
  const [dateRange, setDateRange] = useState<[Date, Date]>(getDefaultMonthRange() as [Date, Date]);

  const { data: trainerClassSummaryReport } = useFetch(
    API_ENDPOINTS.trainerClassSummaryreportHistory,
    dateRange?.every(date => date instanceof Date && !isNaN(date.getTime()))
      ? {
          startDate: convertDateToLocalDateString(dateRange[0]),
          endDate: convertDateToLocalDateString(dateRange[1]),
        }
      : null
  );

  const { refetchTrainerClassSummaryReport } = useGetTrainerClassSummaryExcelFile(dateRange);

  const columns = [
    { field: "trainerName", header: "Entrenador" },
    { field: "totalClasses", header: "Clases Totales" },
    { field: "totalHours", header: "Horas" },
    { field: "totalMinutes", header: "Minutos" },
  ];

  const handleDownloadExcel = async () => {
    if (dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined)) {
      const file = await refetchTrainerClassSummaryReport();
      downloadExcelFile(file, 'trainer-class-summary-report');
    }
  };

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          <h5>Informe Resumen de Clases por Entrenador</h5>
        </div>
        <div className="card card-status mb-3">
          <div className="card-head-blk border-bottom-0 pb-0">
            <div className="row align-items-center">
              <div className="col-md-12 d-flex justify-content-end">
                <button
                  className="btn btn-outline-primary rounded-3 mb-3 me-2"
                  onClick={() => setDateRange(getDefaultMonthRange() as [Date, Date])}
                >
                  <i className="fa fa-eraser me-2" />
                  Limpiar
                </button>
                <button
                  className="btn btn-primary-admin rounded-3 mb-3 align-items-center d-flex me-2"
                  onClick={handleDownloadExcel}
                >
                  <Icon.FileText className="react-feather-icon me-2" />
                  <span>Descargar como Excel</span>
                </button>
                <CalendarRangeField
                  value={dateRange || [new Date(), new Date()]}
                  onChange={(e: any) => setDateRange(e.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
            <DataTableWrapper
            data={trainerClassSummaryReport || []}
            lazy={false}
            columns={columns}
            paginator={false}
            showGridlines
            emptyMessage="No se encontraron entrenadores."
            />
        </div>
      </div>
    </div>
  );
};

export default TrainerClassSummary;
