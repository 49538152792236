import { useState, useEffect } from "react";
import { apiRepository } from "../../../api/apiRepository";
import { Package } from "../../Interface/repositoryInterfaces";
import { useCategoriesList } from "./useCategoriesList";

export const usePackages = (orderCategories: boolean, selectedProducts: any[] = []) => {
    const { sortedCategories = [], productIds } = useCategoriesList(orderCategories);
    const [packages, setPackages] = useState<any[]>([]);

    useEffect(() => {
        const fetchPackages = async () => {
            try {
                const response = await apiRepository.getIdPackageList();
                if (!response?.data?.data) return;

                const groupedByPackageTypeId = response.data.data.reduce(
                    (acc: any, item: Package) => {
                        const { packageTypeId } = item;
                        if (!acc[packageTypeId]) {
                            acc[packageTypeId] = [];
                        }
                        acc[packageTypeId].push(item);
                        return acc;
                    },
                    {}
                );

                Object.keys(groupedByPackageTypeId).forEach((key) => {
                    groupedByPackageTypeId[key].sort((a: Package, b: Package) => a.price - b.price);
                });

                const orderedGroupedByPackageTypeId = sortedCategories.map(
                    (category) => ({
                        [category.id]: groupedByPackageTypeId[category.id] || [],
                    })
                );

                const updatedPackages = orderedGroupedByPackageTypeId.map((group) => {
                    const [groupKey, groupPackages] = Object.entries(group)[0];
                    const updatedGroupPackages = (groupPackages as any[]).map((pkg) => ({
                        ...pkg,
                        selected: selectedProducts.some((product) => product.id === pkg.id),
                    }));
                    return { [groupKey]: updatedGroupPackages };
                });

                setPackages(updatedPackages);
            } catch (error) {
                console.error("Error al obtener los paquetes:", error);
                setPackages([]);
            }
        };

        if (sortedCategories.length > 0) {
            fetchPackages();
        }
    }, [sortedCategories, selectedProducts]);

    return { sortedCategories, productIds, packages, setPackages };
};