import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { AuthContextType } from '../../Interface/contextInterface';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchPaymentDetails, handlePaymentSuccess, handlePaymentFailed, parseBackUrlParams } from '../../services/paymentService';
import { getQueryParams } from '../../utils/utils';
import { isPaymentSuccessful } from '../../utils/paymentUtils';
import { openModal } from '../../utils/modalsUtils';
import { useProfile } from '../../context/ProfileContext';
import { decryptAES } from "../../../core/utils/encryptUtils";


export const useCartLogic = (cartContext: any, authContext: AuthContextType, token: string | null | undefined) => {
  const { user } = authContext;
  const { setCart } = cartContext;
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchPurchasedServices, fetchPaymentDays } = useProfile();
  const [pathPdf, setPathPdf] = useState();
  const [errorPayment, setErrorPayment] = useState();
  const [hasExecuted, setHasExecuted] = useState<boolean>();
  const [isProcessing, setIsProcessing] = useState<boolean>(true);

  // Determine the valid token
  let validToken = typeof token === 'string' && token.trim() !== '' ? token : null;
  if (!validToken) {
    const encryptedToken = Cookies.get('paymentToken');
    if (!encryptedToken) {
      console.warn("Token not found in cookies");
    } else {
      const secretKey = "encryption-key";
      const decryptedData = decryptAES(encryptedToken, secretKey);
      validToken = decryptedData;
    }
  }

  const clearCartIfSuccess = async () => {
    if (hasExecuted) return;
    const { success, paymentId, ...rest } = getQueryParams(location.search);
    const formattedPaymentDetails = parseBackUrlParams({ ...rest, paymentId });
    if (!isPaymentSuccessful(success, paymentId)) {
      setIsProcessing(false);
      console.debug(success, paymentId);
      await handlePaymentFailed({
        ...formattedPaymentDetails,
        jsonResponse: "{\"status\":\"failed\"}",
        status: "FAILED",
      }, openModal);
      return;
    }

    try {
      const paymentData = await fetchPaymentDetails(paymentId, validToken || '');
      if (!paymentData) {
        setIsProcessing(false);
        return;
      }

      const pathPdf = await handlePaymentSuccess(
        { ...formattedPaymentDetails, paymentMpId: paymentData.id, jsonResponse: JSON.stringify(paymentData) },
        cartContext,
        setCart,
        openModal,
      );

      await fetchPaymentDays(user?.userId || 0);
      await fetchPurchasedServices(user?.userId || 0);
      if (pathPdf) setPathPdf(pathPdf);

      navigate(location.pathname, { replace: true });
    } catch (error) {
      setErrorPayment(error?.response?.data.message || "Error al procesar el pago");
      openModal("payment-error");
    }

    setHasExecuted(true);
    setIsProcessing(false);
  };

  useEffect(() => {
    const { success, paymentId } = getQueryParams(location.search);
    if (!success && !paymentId) {
      setIsProcessing(false);
      return;
    }

    if (validToken) {
      clearCartIfSuccess();
    }
  }, [location.search, validToken]);

  return { pathPdf, isProcessing, errorPayment };
};
