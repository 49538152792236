import React from 'react';
import { capitalizeFirstLetter } from '../../../../core/utils/utils';

interface EventDetailsModalProps {
  isOpen: boolean;
  eventDetails: any;
  onClose: () => void;
  handleCancelReservation: () => void;
  userRole: string;
  assistList: [];
}

const EventDetailsModal: React.FC<EventDetailsModalProps> = ({
  isOpen,
  eventDetails,
  onClose,
  handleCancelReservation,
  userRole,
  assistList
}) => {
  return (
    <div className={`toggle-sidebar ${isOpen && "sidebar-popup"}`}>
      <div className="sidebar-layout">
        <div className="sidebar-header pb-2">
          <h5>Detalle de la Clase</h5>
          <a href="#" onClick={onClose} className="btn-close border border-0"></a>
        </div>
        <div className="modal-content">
          {eventDetails ? (
            <div className="sidebar-body">
              <div className="book-confirm bk-wrap">
                <div className="d-flex mt-3 justify-content-between">
                  <h6 className="mb-2">{eventDetails.title}</h6>
                  {/* {eventDetails.serviceId} aaaa */}

                </div>
                <ul className="p-0">
                  <li>
                    <span className="bk-date">
                      <i className="fa fa-calendar" /> Fecha de Clase:
                    </span>{" "}
                    {capitalizeFirstLetter(
                      eventDetails?.start.toLocaleDateString('es-ES', {
                        weekday: 'long', day: 'numeric', month: 'long', year: 'numeric',
                      })
                    )}
                  </li>
                  <li>
                    <span className="bk-date">
                      <i className="fa fa-clock" /> Hora de inicio:
                    </span>{" "}
                    {eventDetails?.start?.toLocaleTimeString('es-ES', {
                      hour: '2-digit', minute: '2-digit', hour12: true
                    })}
                  </li>
                  <li>
                    <span className="bk-date">
                      <i className="fa fa-clock" /> Hora de finalización:
                    </span>{" "}
                    {eventDetails?.end?.toLocaleTimeString('es-ES', {
                      hour: '2-digit', minute: '2-digit', hour12: true
                    })}
                  </li>
                  <li>
                    <span className="bk-date">
                      <i className="fa fa-user" /> Instructor:
                    </span>{" "}
                    {eventDetails.trainerName || eventDetails.fullNameService}
                  </li>
                  {/* <li>
                    <span className="bk-date">
                      <i className="fa fa-info-circle" /> Estado de la clase:
                    </span>
                    <span className={`badge ms-2 ${getBadgeClass(eventDetails.status)}`}>
                      {CLASS_STATUS.find(status => status.value === eventDetails.status)?.label || "Estado desconocido"}
                    </span>
                  </li> */}
                  {/* <li>
                    <span className="bk-date">
                      <i className="fa fa-users" /> Capacidad:
                    </span>{" "}
                    {eventDetails.quantity}
                  </li> */}
                  {/* <li>
                    <span className="bk-date">
                      <i className="fa fa-check" /> Reservas:
                    </span>{" "}
                    {eventDetails.reservation}
                  </li> */}
                  <li>
                    <span className="bk-date">
                      <i className="fa fa-check" /> Estado de reserva:
                    </span>{" "}
                    {eventDetails.waitlist === true ? (
                      <span className="badge badge-pending ms-2">
                        {"En lista de Espera"}
                      </span>
                    ) : eventDetails.waitlist === false ? (
                      <span className="badge badge-active ms-2">
                        {"Reserva confirmada"}
                      </span>
                    ) : null}
                  </li>
                  {eventDetails.position && (
                    <li>
                      <span className="bk-date">
                        <i className="fa fa-users" /> {eventDetails.waitlist ? "Posición en lista de espera:": "Posición en lista de reservas:"} 
                      </span>{" "}
                      {eventDetails.position}
                    </li>
                  )}
                  <li>
                    <span className="bk-date">
                      <i
                        className="fa fa-star"
                        style={{ color: eventDetails.isReformer === "SI" ? 'gold' : 'gray' }}
                      /> Tipo de clase:
                    </span>{" "}
                    {eventDetails.isReformer === "NO" ? "Clase estándar" : eventDetails.isReformer === "SI" ? "Reformer" : "Desconocido"}
                  </li>
                </ul>

                {userRole === "trainer" && (
                  <div className="accordion mt-0" id="reservationsAccordion">
                    {eventDetails.assistList && eventDetails.assistList.length > 0 ? (
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingReservations">
                          <button
                            className="accordion-button pt-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseReservations"
                            aria-expanded="true"
                            aria-controls="collapseReservations"
                          >
                            Lista de Reservas
                          </button>
                        </h2>
                        <div
                          id="collapseReservations"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingReservations"
                          data-bs-parent="#reservationsAccordion"
                        >
                          <div className="accordion-body">
                            {eventDetails.assistList
                              .sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                              .slice(0, eventDetails.quantity)
                              .map((assist: any, index: any) => (
                                <div key={index} className="mb-2">
                                  <p><strong>Nombre:</strong> {assist.customerName}</p>
                                  <p><strong>Condición Médica / Embarazo:</strong> {assist.conditionMedical || "No especificada"}</p>
                                  <p><strong>Fecha de Reserva:</strong> {new Date(assist.createdAt).toLocaleString()}</p>
                                  <hr />
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="alert alert-info">
                        <p>Por ahora, no hay reservas para esta clase.</p>
                      </div>
                    )}
                  </div>
                )}

                {(eventDetails.status === 1 || (eventDetails.status === 2 && eventDetails.serviceId)) && userRole !== 'trainer' && (
                  <div className="mt-2">
                    <button
                      className="btn btn-danger"
                      data-bs-toggle="modal"
                      data-bs-target="#cancel_appointment"
                      onClick={handleCancelReservation}
                    >
                      Cancelar Reservación
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="modal-body">
              <p>No se seleccionó ningún evento.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventDetailsModal;