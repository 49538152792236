import { convertDateToLocalDateString } from '../utils/dateUtils';
import { formatClass, isFutureClass, sortClassesByTime } from '../utils/classUtils';
export const getNextClass = (todayClasses: any[]) => {
  const futureClassesToday = todayClasses
    .filter((cls) => isFutureClass(cls))
    .sort((a, b) => sortClassesByTime(a, b));

  if (futureClassesToday.length > 0) {
    const next = futureClassesToday[0];
    return formatClass(next);
  }

  return null;
};

export const filterClassesToday = (schedules: any[]) => {
    const today = convertDateToLocalDateString(new Date());
    return schedules.filter((cls) => cls.date === today);
};

export const getEventColor = (start: string, waitlist: boolean | undefined) => {
  const now = new Date();
  const eventDate = new Date(start);

  now.setHours(0, 0, 0, 0);
  eventDate.setHours(0, 0, 0, 0);

  if (waitlist) {
    return { backgroundColor: '#ffa500', textColor: '#000000', border: 'none' }; // Orange for waitlist
  }

  if (now.getTime() === eventDate.getTime()) {
    return { backgroundColor: '#fbc746', textColor: '#000000', border: 'none' };
  }

  const milisegundosPorDia = 1000 * 60 * 60 * 24;

  const diferenciaMilisegundos = eventDate.getTime() - now.getTime();
  const diffInDays = Math.floor(diferenciaMilisegundos / milisegundosPorDia);

  if (diffInDays < 0) {
    return { backgroundColor: '#ff3030', textColor: '#FFFFFF', daysUntil: diffInDays, border: 'none' };
  } else if (diffInDays === 0) {
    return { backgroundColor: '#fbc746', textColor: '#000000', daysUntil: diffInDays, border: 'none' };
  } else {
    return { backgroundColor: '#2ac538', textColor: '#FFFFFF', daysUntil: diffInDays, border: 'none' };
  }
};

export const getEventColorService = (start: string, status: number) => {
  const now = new Date();
  const eventDate = new Date(start);

  now.setHours(0, 0, 0, 0);
  eventDate.setHours(0, 0, 0, 0);

  if (now.getTime() === eventDate.getTime()) {
    return { backgroundColor: '#fbc746', textColor: '#000000', border: 'none' };
  }

  const milisegundosPorDia = 1000 * 60 * 60 * 24;

  const diferenciaMilisegundos = eventDate.getTime() - now.getTime();
  const diffInDays = Math.floor(diferenciaMilisegundos / milisegundosPorDia);

  let backgroundColor;
  switch (status) {
    case 1:
      backgroundColor = '#2ac538'; // success
      break;
    case 2:
      backgroundColor = '#fbc746'; // warning
      break;
    case 3:
      backgroundColor = '#ff3030'; // error
      break;
    default:
      backgroundColor = '#2ac538'; // default to success
  }

  return { backgroundColor, textColor: '#FFFFFF', daysUntil: diffInDays, border: 'none' };
};