import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomersRoutes from './customers.routes';
import NewFooter from '../home/footer/main-footer';
import HomeHeader from '../home/header/main-header';
import { all_routes } from '../../../core/data/routes/all_routes';
import { AuthContext } from '../../../core/context/AuthContext';
import { useProfile } from '../../../core/context/ProfileContext';
import AuthModals from '../home/header/authModals';
import { useLocation } from 'react-router-dom';

/**
 * Componente principal para la gestión de clientes.
 * @returns {JSX.Element} Componente de clientes.
 */
const Customers = () => {
  const routes = all_routes;
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const authContext = useContext(AuthContext);
  const { fetchProfile, clearProfileData } = useProfile();
  const location = useLocation();

  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { isAuthenticated, user } = authContext;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (user?.userId) {
      fetchProfile(user.userId);
    } else {
      clearProfileData();
    }
  }, [user]);

  useEffect(() => {
    if (!isAuthenticated && currentPath !== '/perfil/cart') {
      navigate(routes.login);
    }

    if (
      (currentPath === '/perfil/schedules' || currentPath === '/perfil/attendance-management') &&
      !(user?.role === 'trainer' || user?.role === 'admin' || user?.role === 'provider')
    ) {
      navigate('/perfil');
    }

    if (currentPath === '/perfil/reservation' && user?.role === 'trainer') {
      navigate('/perfil');
    }

    if (
      (currentPath === '/perfil/receptionist-payment' || currentPath === '/perfil/recepcion-users' || currentPath === '/perfil/recepcion-schedules') &&
      !(user?.role === 'receptionist' || user?.role === 'admin')
    ) {
      navigate('/perfil');
    }
  }, [currentPath, navigate, isAuthenticated, user, routes.login]);

  return (
    <>
      <HomeHeader />
      <CustomersRoutes />
      <NewFooter />
      { !user?.userId && <AuthModals /> }
    </>
  );
};

export default Customers;