import React, { useState, useEffect } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { AutoCompleteInputProps } from '../../../core/Interface/elements/inputInterface';
import InputWrapper from './InputWrapper';

const AutoCompleteInput: React.FC<AutoCompleteInputProps> = ({ value, suggestions, completeMethod, onChange, error, label, placeholder, required = false, loading = false }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState<any[]>([]);

  useEffect(() => {
    setFilteredSuggestions(suggestions || []);
  }, [suggestions]);

  return (
    <InputWrapper label={label} required={required} error={error}>
      <div className="autocomplete-container">
        <AutoComplete
          value={value}
          suggestions={
            loading
              ? [{ label: "Cargando...", value: null }] // Show "Cargando..." if loading is true
              : filteredSuggestions.length
              ? filteredSuggestions
              : [{ label: "No se encuentran coincidencias", value: null }] // Show "No se encuentran coincidencias" if empty
          }
          completeMethod={(e) => {
            if (typeof completeMethod === 'function') {
              completeMethod(e);
            }
            const query = e.query.toLowerCase();
            setFilteredSuggestions(
              (suggestions || []).filter(suggestion => suggestion.label.toLowerCase().includes(query))
            );
          }}
          onChange={(e) => onChange(e.value)}
          field="label"
          placeholder={placeholder || "Escribe para buscar"}
          className="w-100 shadow-none select"
          dropdown
          appendTo="self"
          minLength={1}
        />
      </div>
    </InputWrapper>
  );
};

export default AutoCompleteInput;
