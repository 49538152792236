import React from 'react';
import { Calendar } from 'primereact/calendar';
import { DatePickerProps } from '../../../core/Interface/elements/inputInterface';
import InputWrapper from './InputWrapper';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DatePicker: React.FC<DatePickerProps & { blockPastDates?: boolean }> = ({
  value,
  onChange,
  error,
  label,
  name,
  required = false,
  blockPastDates = false,
}) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    <InputWrapper label={label} required={required} error={error}>
      <Calendar
        value={value}
        onChange={(e) => onChange(e.value)}
        name={name}
        readOnlyInput
        showIcon
        dateFormat="yy/mm/dd"
        placeholder="Seleccione una fecha"
        className="w-100"
        locale="es"
        minDate={blockPastDates ? today : undefined}
        icon={<FontAwesomeIcon icon={faCalendarAlt} />}
      />
    </InputWrapper>
  );
};

export default DatePicker;
