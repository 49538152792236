import { useState, useCallback } from "react";
import { postData as apiPostData } from "../../services/api";
import { UseRequest } from "../../Interface/hooks/useRequest";

const usePost = (endpoint: string): UseRequest => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const post = useCallback(async (data: any, useFormData = false) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiPostData(endpoint, data, useFormData);
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [endpoint]);

  return { data, loading, error, post };
};

export default usePost;