import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { InputField } from "../../../admin/common/input/inputField";
import { useResetPassword } from "../../../../core/hooks/auth/useResetPassword";
import { getErrorForField } from "../../../../core/utils/utils";
import { ResetPasswordModalProps } from "../../../../core/Interface/modalInterface";

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  modalId = "reset-password",
  email,
  authLoginContext
}) => {
  const { formData, handleChange, resetForm, handleResetPassword, loading, serverError } = useResetPassword({ modalId, email: email || "", authLoginContext });

  useEffect(() => {
    const modalElement = document.getElementById(modalId);

    const handleModalClose = () => {
      resetForm();
    };

    if (modalElement) {
      modalElement.addEventListener("hide.bs.modal", handleModalClose);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener("hide.bs.modal", handleModalClose);
      }
    };
  }, [modalId, resetForm]);


  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header d-flex align-items-center justify-content-end pb-0 border-0">
            <Link to="#" data-bs-dismiss="modal" aria-label="Cerrar">
              <i className="ti ti-circle-x-filled fs-20" />
            </Link>
            <button
              type="button"
              className="d-none"
              data-bs-toggle="modal"
              data-bs-target="#success-modal"
            ></button>
          </div>
          <div className="modal-body p-4 pt-0">
            <div className="text-center mb-3">
              <h3 className="mb-2">Restablecer Contraseña</h3>
              <p className="fs-14">
                Tu nueva contraseña debe ser diferente a las contraseñas usadas previamente.
              </p>
            </div>
            <form onSubmit={handleResetPassword}>
              <div className="input-block mb-3">
                <InputField
                  label="Nueva Contraseña"
                  name="password"
                  type="password"
                  value={formData?.password || ''}
                  onChange={handleChange}
                  error={getErrorForField("password", serverError || [])}
                />
                <InputField
                  label="Confirmar Contraseña"
                  name="confirmPassword"
                  type="password"
                  value={formData?.confirmPassword || ''}
                  onChange={handleChange}
                  error={getErrorForField("confirmPassword", serverError || [])}
                />
              </div>
              <div>
                <button
                  disabled={loading}
                  type="submit"
                  className="btn btn-lg btn-linear-primary w-100"
                >
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="ms-2">Guardando...</span>
                    </>
                  ) : (
                    "Guardar Contraseña"
                  )}
                </button>
              </div>
              <div className="mb-3">
                {serverError?.find((error) => error.field === "server") && (
                  <small className="text-danger mb-3">
                    {
                      serverError?.find(
                        (error) => error.field === "server"
                      )?.message
                    }
                  </small>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordModal;