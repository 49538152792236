/**
 * Componente UsersManage
 * 
 * Este componente se encarga de mostrar y gestionar los usuarios.
 * Permite filtrar usuarios por diferentes criterios, y paginar los resultados.
 * 
 * Funcionalidades:
 * - Filtrado por nombre, correo electrónico, rol, estado y fecha de nacimiento.
 * - Paginación de resultados.
 * - Edición y eliminación de usuarios.
 * - Activación de usuarios.
 * 
 * Hooks:
 * - useState: Para manejar el estado local del componente.
 * - useUsersData: Hook personalizado para manejar la lógica de usuarios.
 * 
 * Dependencias:
 * - apiRepository: Para realizar las llamadas a la API.
 * - DataTableWrapper: Componente para mostrar los datos en una tabla.
 * - UsersModal: Componente modal para agregar y editar usuarios.
 * - DeleteCustomersModal: Componente modal para eliminar usuarios.
 * - BaseModal: Componente modal base para mostrar mensajes de éxito y error.
 */

import React, { useState, useContext } from 'react';
import { Users } from '../../../core/Interface/repositoryInterfaces';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import UsersModal from '../common/modals/users-modal';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumns';
import { InputField, DropdownField, BooleanField, CalendarField } from '../../admin/common/input/inputField';
import { TYPE_ROLES_USERS } from '../../../core/data/options/options';
import { useUsersData } from '../../../core/hooks/getData/useUsersData';
import { apiRepository } from '../../../api/apiRepository';
import BaseModal from '../common/modals/baseModal';
import DeleteCustomersModal from '../common/modals/delete-user-modal';
import { convertDateToLocalDate } from '../../../core/utils/dateUtils';
import { AuthContext } from '../../../core/context/AuthContext';

const UsersManage = () => {
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>();
  const [showErrorModal, setShowErrorModal] = useState<boolean>();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [loadingActivate, setLoadingActivate] = useState<boolean>();
  const [email, setEmail] = useState<string>();
  const [profile, setProfile] = useState<string>();
  const [fullName, setFullName] = useState<string>();
  const [userIdToActivate, setUserIdToActivate] = useState<number | null>();
  const [selectedUserIdToDelete, setSelectedUserIdToDelete] = useState<any>(null);
  const [active, setActive] = useState<boolean | null>(null);
  const [birthdate, setBirthdate] = useState<Date | null>(null);

  const { user } = useContext(AuthContext);

  const profileFilter = user?.role === 'admin' ? '' : 'customer';

  const {
    data,
    selectedUser,
    totalUsers,
    first,
    rows,
    filtersVisible,
    setSelectedUser,
    setFirst,
    setRows,
    setCurrentPage,
    setFiltersVisible,
    fetchUsers,
  } = useUsersData(fullName || '', email || '', profile || profileFilter, active, birthdate);

  const handleActivateClick = async () => {
    if (userIdToActivate === null) return;
    try {
      setLoadingActivate(userIdToActivate);
      await apiRepository.activateUser(userIdToActivate, 13);
      setShowSuccessModal(true);
      fetchUsers();
    } catch (error: any) {
      setErrorMessage(error.response?.data?.message || 'Error activating user');
      setShowErrorModal(true);
    } finally {
      setLoadingActivate(null);
      setUserIdToActivate(null);
      setShowConfirmModal(false);
    }
  };

  const handleConfirmActivateClick = (userId: number) => {
    setUserIdToActivate(userId);
    setShowConfirmModal(true);
  };

  const handleClearFilters = () => {
    setFullName('');
    setEmail('');
    setProfile('');
    setActive(null);
    setBirthdate(null);
  };

  const columns = [
    {
      field: "name",
      header: "Nombre Completo",
      body: (rowData: Users) => (
        <span>
          {rowData.name} {rowData.lastname}
        </span>
      ),
    },
    { field: "phone", header: "Teléfono" },
    { field: "profile", header: "Rol" },
    {
      field: "typeDocument",
      header: "Documento",
      body: (rowData: Users) => (
        <span>
          {rowData.typeDocument?.toUpperCase()} {rowData.numberDocument}
        </span>
      ),
    },
    { field: "email", header: "Correo electrónico" },
    {
      field: "active",
      header: "Estado",
      body: (rowData: Users) => (
        <span className={rowData.active ? "badge-active" : "badge-inactive"}>
          {rowData.active ? "Activo" : "Inactivo"}
        </span>
      ),
    },
    { field: "birthdate", header: "Nacimiento" },
    {
      field: "activate",
      header: "Activar",
      body: (rowData: Users) => {
        if (rowData.profile === 'customer') {
          if (rowData.activationDateTime && rowData.activationUser) {
            return (
              <div className="d-flex flex-column">
                <div className="text-success d-flex align-items-center gap-2 mb-1">
                  <i className="fa fa-calendar-check"></i>
                  <small>
                    Activado el: <strong>{new Date(rowData.activationDateTime).toLocaleDateString()}</strong>
                  </small>
                </div>
                <div className="text-secondary d-flex align-items-center gap-2">
                  <i className="fa fa-user"></i>
                  <small>
                    Por: <strong>{rowData.activationUser}</strong>
                  </small>
                </div>
              </div>
            );
          }
          if (rowData.classFree) {
            return (
              <button
                className="btn btn-outline-secondary d-flex align-items-center"
                onClick={() => handleConfirmActivateClick(rowData.id)}
                disabled={loadingActivate === rowData.id}
              >
                Activar
              </button>
            );
          }
          return <span className={`badge badge-warning`}> <i className="fa fa-times-circle me-1"></i> No Solicitada </span>;
        }
        return  <span>Valido solo para clientes</span>;
      },
    },
  ];

  const handleEditClick = (rowData: any) => {
    setSelectedUser(null);
    setTimeout(() => setSelectedUser(rowData), 0);
  };

  const handleDeleteClick = (rowData: Users) => {
    setSelectedUser(null);
    setTimeout(() => setSelectedUserIdToDelete(rowData.id), 0);
  };

  const getColumns = useResponsiveColumns(columns);

  return (
    <div className="page-wrapper page-settings">
      <div className="content w-100">
        <div className="content-page-header content-page-headersplit">
          <h5>Usuarios</h5>
          <div className="list-btn">
            <ul>
              <li>
                <button
                  className="btn btn-outline-info"
                  onClick={() => setFiltersVisible(!filtersVisible)}
                >
                  {filtersVisible ? 'Ocultar Filtros' : 'Mostrar Filtros'}
                </button>
              </li>
              <li>
                <div className="list-btn">
                  <button className="btn btn-primary-admin" onClick={handleEditClick}>
                    <i className="fa fa-plus me-2" /> Agregar Usuario
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>

        {filtersVisible && (
          <div className="search-filters card card-shadow shadow-none p-3 mb-4 shadow-sm">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h6 className="text-dark">Filtros de búsqueda</h6>
              <button
                className="btn btn-outline-primary"
                onClick={handleClearFilters}
                title="Limpiar filtros"
              >
                <i className="fa fa-eraser me-2" />
                Limpiar
              </button>
            </div>
            <div className="d-flex align-items-center gap-3">
              <div className="flex-grow-1 position-relative">
                <InputField
                  label="Nombre"
                  name="fullName"
                  placeholder="Ejemplo: Juan"
                  value={fullName || ''}
                  onChange={(e) => setFullName(e.target.value)}
                  required={false}
                />
              </div>
              <div className="flex-grow-1 position-relative">
                <InputField
                  label="Correo Electrónico"
                  name="email"
                  placeholder="Ejemplo: juan@gmail.com"
                  value={email || ''}
                  onChange={(e) => setEmail(e.target.value)}
                  required={false}
                />
              </div>
              { user?.role === 'admin' && (
                <div className="flex-grow-1 position-relative">
                  <DropdownField
                    label="Rol del usuario"
                    name="profile"
                    placeholder="Ejemplo: Cliente"
                    value={profile || ''}
                    options={TYPE_ROLES_USERS}
                    onChange={(event: { value: any }) => setProfile(event.value)}
                    required={false}
                    className="mb-3"
                  />
                </div>
              )}
              <div className="flex-grow-1 position-relative">
                <CalendarField
                  label="Fecha de Nacimiento"
                  name="birthdate"
                  value={birthdate || ""}
                  onChange={(e) => setBirthdate(convertDateToLocalDate(new Date(e.target.value)))}
                  required={false}
                />
              </div>
              <div className="flex-grow-1 position-relative">
                <BooleanField
                  label="Usuario activos"
                  name="active"
                  value={active || false}
                  onChange={(e) => setActive(!active)}
                  required={false}
                />
              </div>
            </div>
          </div>
        )}
        <DataTableWrapper
          data={data || []}
          columns={getColumns()}
          onEdit={handleEditClick}
          onDelete={handleDeleteClick}
          paginator
          showGridlines
          rowsPerPage={rows}
          totalRecords={totalUsers}
          first={first}
          onPageChange={(event) => {
            setFirst(event.first);
            setRows(event.rows);
            const pageNumber = event.first / event.rows;
            setCurrentPage(pageNumber);
            fetchUsers(pageNumber, event.rows);
          }}
          emptyMessage="No se encontraron usuarios."
        />
      </div>
      <UsersModal selectedUser={selectedUser} onUpdateSuccess={fetchUsers} />
      <DeleteCustomersModal selectedUserId={selectedUserIdToDelete} onDeleteSuccess={fetchUsers}
      />
      <BaseModal
        show={showSuccessModal || false}
        title="Éxito"
        onClose={() => setShowSuccessModal(false)}
        onConfirm={() => setShowSuccessModal(false)}
        showButtons={false}
      >
        <div className="d-flex align-items-center gap-3">
          <i className="fa fa-check-circle text-success fs-4"></i>
          <p className="mb-0">Clases gratuita activada correctamente.</p>
        </div>
      </BaseModal>
      <BaseModal
        show={showErrorModal || false}
        title="Error"
        onClose={() => setShowErrorModal(false)}
        onConfirm={() => setShowErrorModal(false)}
        showButtons={false}
      >
        <div className="d-flex align-items-center gap-3">
          <i className="fa fa-exclamation-circle text-danger fs-4"></i>
          <p className="mb-0">{errorMessage}</p>
        </div>
      </BaseModal>
      <BaseModal
        show={showConfirmModal || false}
        loading={loadingActivate || false}
        title="Confirmar Activación"
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleActivateClick}
      >
        <div className="text-center">
          <i className="fa fa-question-circle text-warning fs-4 mb-3"></i>
          <p>¿Está seguro de que desea activar la prueba gratuita para este usuario?</p>
        </div>
      </BaseModal>

    </div>
  );
};

export default UsersManage;