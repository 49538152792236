import { useState, useEffect, useCallback } from "react";
import { getData as apiGetData } from "../../services/api";

const useFetch = (endpoint: string, params: Record<string, any> | null = {}): { data: any, loading: boolean, error: any, refetch: () => void } => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  const fetchData = useCallback(async () => {
    if (params === null) {
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const response = await apiGetData(endpoint, params);
      setData(response.data.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [endpoint, JSON.stringify(params)]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading, error, refetch: fetchData };
};

export default useFetch;