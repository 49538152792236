import React from 'react';
import { InputWrapperProps } from '../../../core/Interface/elements/inputInterface';

const InputWrapper: React.FC<InputWrapperProps> = ({ label, children, required = false, error }) => {
  return (
    <div className="input-wrapper mb-3">
      {label && (
        <label className="form-label w-100">
          {label}
          {required && <span className="text-danger"> *</span>}
        </label>
      )}
      {children}
      {error && <small className="text-danger">{error}</small>}
    </div>
  );
};

export default InputWrapper;