export const TYPE_DISCOUNT = [
    { label: 'Porcentaje', value: true },
    { label: 'Monto Fijo', value: false },
];

export const LIMIT_PER_USER = [
    { label: 'Uso limitado por usuario', value: true },
    { label: 'Uso ilimitado', value: false },
];

export const TYPE_OF_RECURRENCI = [
    { label: 'Diario', value: "DIAS" },
    { label: 'Semanal', value: "SEMANAL" },
    { label: 'Mensual', value: "MENSUAL" },
];

export const TYPE_GENERAL_PRODUCT = [
    { label: 'Descuento General', value: false },
    { label: 'Descuento de Paquete', value: true },
];

export const TYPE_ROLES_USERS = [
    { label: 'Cliente', value: 'customer' },
    { label: 'Entrenador', value: 'trainer' },
    { label: 'Administrador', value: 'admin' },
    { label: 'Aliados', value: 'provider' },
    { label: 'Recepción', value: 'receptionist' },
];

export const TYPE_ACTIONS_USERS = [
    { label: 'Aprobar', value: true },
    { label: 'Denegar', value: false },
];

export const IS_REFORMER = [
    { label: 'Clase Reformer', value: "SI" },
    { label: 'Clase Estandar', value: "NO" },
];

export const CLASS_STATUS = [
    { value: 1, label: 'Pendiente' },
    { value: 2, label: 'Atención' },
    { value: 3, label: 'Finalizada' },
    { value: 4, label: 'Cancelada' },
    { value: 5, label: 'Asistencia Terminada' },
];

export const TYPE_PACKAGE = [
    { label: 'Servicio', value: 1 },
    { label: 'Producto', value: 2 },
    { label: 'GiftCard', value: 3 },
    { label: 'Servicios Adicionales', value: 4 },
];

export const TYPE_SUB_SERVICE = [
    { label: 'Clases', value: "Clases" },
    { label: 'Membresias', value: "Membresias" },
    { label: 'Clases Reformer', value: "Clases Reformer" },
    { label: 'Membresias Premium', value: "Membresias Premium" },
];

export const TYPE_IDENTIFIER = [
    { label: 'DNI', value: "DNI" },
    { label: 'RUC', value: "RUC" },
    { label: 'CE', value: "CE" },
    { label: 'PASAPORTE', value: "PASAPORTE" },
];

export const CLASSE_IS_PREMIUM = [
    { label: 'Clases Premium', value: true },
    { label: 'Clase Estandar', value: false },
];

export const MINUTES_SUGGESTINOS = [
    { label: '30', value: '30' },
    { label: '60', value: '60' },
    { label: '90', value: '90' },
];

export const CANCELLATION_REASONS = [
    'Cambio de planes',
    'Problemas de salud',
    'Conflicto de horario',
    'Otro'
];

export const purchaseStatusOptions = [
    { label: 'Pendiente', value: 'pending' },
    { label: 'Completado', value: 'completed' },
  ];

export const paymentMethodOptions = [
    { label: 'POS', value: 'pos' },
    { label: 'Yape', value: 'yape' },
    { label: 'Efectivo', value: 'cash' },
    { label: 'MercadoPago', value: 'MERCADO PAGO' },
];

export const paymentMethodOptionsList = [
    { label: 'POS', value: 'pos' },
    { label: 'Yape', value: 'yape' },
    { label: 'Efectivo', value: 'cash' },
    { label: 'MercadoPago', value: 'MERCADO PAGO' },
    { label: 'MercadoPago', value: 'MERCADO PAGO' },
];