import React, { useContext, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWindowSize from '../../../../core/hooks/WindowSize/useWindowSize';
import { AppState } from '../../../../core/models/interface';
import { AuthContext } from '../../../../core/context/AuthContext';
import { set_toggleSidebar_data } from '../../../../core/data/redux/action';
import SidebarToggle from './sidebarToggle';
import { Link, useLocation } from 'react-router-dom';
import AuthModals from './authModals';
import NavbarLogo from './navbarLogo';
import { all_routes } from '../../../../core/data/routes/all_routes';

const HomeHeader: React.FC = () => {
  const toggle_data = useSelector((state: AppState) => state.toggleSidebar);
  const authContext = useContext(AuthContext);
  const isSmallScreen = useWindowSize();
  const dispatch = useDispatch();
  const routes = all_routes;
  const location = useLocation();

  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { logout, isAuthenticated, user } = authContext;

  const toggleSidebar = useCallback(() => {
    dispatch(set_toggleSidebar_data(!toggle_data));
  }, [toggle_data, dispatch]);

  useEffect(() => {
    if (isSmallScreen) {
      dispatch(set_toggleSidebar_data(false));
    }
  }, [location.pathname]);

  return (
    <>
      <header>
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg header-nav">
            <div className="navbar-header">
              <SidebarToggle toggleSidebar={toggleSidebar} />
                <NavbarLogo imageUrl={""} />
            </div>

            <div className="main-menu-wrapper" style={{ boxShadow: toggle_data ? "0px 1px 15px rgba(0, 0, 0, 0.5)" : "none" }}>
              <div className="menu-header">
                <Link onClick={toggleSidebar} id="menu_close" className="menu-close" to="#">
                  <i className="fas fa-times" />
                </Link>
              </div>
              <ul className={`main-nav align-items-lg-center ${isSmallScreen ? '' : 'd-none'}`}>
                {isAuthenticated ? (
                  <>
                    <li className="nav-item">
                      <button
                        onClick={logout}
                        className="nav-link w-100 text-start d-flex justify-content-between p-3"
                      >
                        Cerrar Sesión <i className="fas fa-sign-out-alt me-2" />
                      </button>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={user?.role === 'admin' ? routes.homeBannerSlider : routes.customerDashboard}
                        className="nav-link"
                      >
                        {user?.username} <i className="ti ti-user-filled me-2" />
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="nav-item">
                      <Link to={routes.login} target="_self" className="nav-link">
                        Iniciar Sesión <i className="ti ti-lock me-2" />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to={routes.userSignup} target="_self" className="nav-link">
                        <i className="ti ti-user-filled me-2" />
                        Regístrate
                      </Link>
                    </li>
                  </>
                )}
                <li className="nav-item">
                  <Link to={routes.customerCart} target="_self" className="nav-link">
                    <i className="ti ti-shopping-cart me-2" />
                    Carrito de compras
                  </Link>
                </li>
              </ul>
            </div>

            <ul className="nav header-navbar-rht">
              {isAuthenticated ? (
                <>
                  <li className="nav-item pe-1">
                    <button className="nav-link btn btn-light" onClick={logout}>
                      <i className="fas fa-sign-out-alt me-2" />
                      Cerrar Sesión
                    </button>
                  </li>
                  <li className="nav-item pe-1">
                    <Link
                      className="nav-link btn btn-primary-solid"
                      to={user?.role === 'admin' ? routes.homeBannerSlider : routes.customerDashboard}
                    >
                      <i className="ti ti-user-filled me-2" />
                      {user?.username}
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item pe-1">
                    <Link className="nav-link btn btn-light" to="#" data-bs-toggle="modal" data-bs-target="#login-modal">
                      <i className="ti ti-lock me-2" />
                      Ingresar
                    </Link>
                  </li>
                  <li className="nav-item pe-1">
                    <Link className="nav-link btn btn-primary-solid" to="/" data-bs-toggle="modal" data-bs-target="#register-modal">
                      <i className="ti ti-user-filled me-2" />
                      Regístrate
                    </Link>
                  </li>
                </>
              )}
              <li className="nav-item">
                <Link className="nav-link btn btn-linear-primary" to={routes.customerCart}>
                  <i className="feather icon-shopping-cart" />
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <AuthModals />
    </>
  );
};

export default HomeHeader;