import React from 'react';
import * as Icon from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { closeModal } from '../../../../core/utils/modalsUtils';
import { PaymentSuccessModalProps } from '../../../../core/Interface/modalInterface';

const PaymentSuccessModal: React.FC<PaymentSuccessModalProps> = ({ message, pathPdf, errorPayment }) => {
  const routes = all_routes;
  const navigate = useNavigate();

  const handleGoToDashboard = () => {
    closeModal("payment-success");
    closeModal("payment-error");
    navigate(routes.customerDashboard);
  };

  const handleDownloadPdf = () => {
    window.open(pathPdf, '_blank');
    closeModal("payment-success");
    closeModal("payment-error");
  };

  const handleGoToHome = () => {
    closeModal("payment-error");
    closeModal("payment-success");
    navigate(routes.index + "#package-section");
  };

  return (
    <div>
        <div className="modal fade" id="payment-success">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Pago Exitoso</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                    </div>
                    <div className="modal-body text-center pt-0">
                        <Icon.CheckCircle size={45} color='#28a745' className="text-success fs-1" />
                        <div className="mt-4">
                            <h4>{message}</h4>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-4">
                        <button
                            type="button"
                            className="btn w-sm btn-secondary"
                            data-bs-dismiss="modal"
                            onClick={handleGoToHome}
                        >
                            Volver a comprar
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-success"
                            onClick={handleDownloadPdf}
                        >
                            Descargar Recibo Electronico
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="payment-error">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Pago Sin Éxito</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                    </div>
                    <div className="modal-body text-center pt-0">
                        <Icon.CreditCard size={45} color='#ee1919' className="text-danger fs-1" />
                        <div className="mt-4">
                            <h4>{errorPayment ? errorPayment : "La compra no se concretó."}</h4>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-4">
                        <button
                            type="button"
                            className="btn w-sm btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            Volver a Intentar
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-danger"
                            onClick={handleGoToDashboard}
                        >
                            Ir a mi Dashboard
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default PaymentSuccessModal;