/**
 * Componente Admin
 * 
 * Este componente es el contenedor principal para la sección de administración.
 * Incluye la lógica de autenticación y la carga de componentes de cabecera y barra lateral.
 * 
 * Estado:
 * - isLoading: Indicador de carga.
 * 
 * Funciones:
 * - useEffect: Maneja la lógica de carga y autenticación.
 * 
 * Hooks:
 * - useLocation: Hook de React Router para obtener la ubicación actual.
 * - useNavigate: Hook de React Router para la navegación.
 * - useContext: Hook de React para obtener el contexto de autenticación.
 */

import React, { useEffect, useState, useContext } from 'react';
import AdminRoutes from './admin.routes';
import AdminHeader from './common/header';
import AdminSidebar from './common/sidebar';
import { useLocation } from 'react-router-dom';
import PageLoader from '../../core/loader';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../core/context/AuthContext';

const Admin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { user, isAuthenticated } = authContext;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const delay = 2000;
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, delay);

    return () => clearTimeout(timeout);
  }, [location.pathname]);

  useEffect(() => {
    if (!isAuthenticated || user?.role !== 'admin') {
      navigate('/');
      return;
    }

  }, [location.pathname]);

  return (
    <>
      {isLoading && <PageLoader />}
      <AdminHeader />
      <AdminSidebar />
      <AdminRoutes />
    </>
  );
};

export default Admin;