import React, { useRef, useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import esLocale from '@fullcalendar/core/locales/es';
import { apiRepository } from '../../../../api/apiRepository';
import { useOutletContext } from 'react-router-dom';
import { AuthContext } from '../../../../core/context/AuthContext';
import { getEventColorService } from '../../../../core/services/classService';
import CancelReservationModal from './modalCancelConfirm';
import EventDetailsModal from './modalClassInformation';
import { closeModal, openModal } from '../../../../core/utils/modalsUtils';
import { BreadcrumbContext } from '../../../../core/Interface/contextInterface';
import useFetch from '../../../../core/hooks/api/useFetch';
import API_ENDPOINTS from '../../../../core/constant/API_ENDPOINTS';
import { getDefaultMonthRange } from '../../../../core/utils/dateUtils';
import { useProfile } from '../../../../core/context/ProfileContext';

const CustomerBookingCalendar = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const [searchParams] = useSearchParams();
  const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);
  const [events, setEvents] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);
  const [loading, setLoading] = useState(false);
  const calendarRef = useRef(null);
  const { user } = authContext;
  const userId = user?.userId;
  const { setBreadcrumbData } = useOutletContext<BreadcrumbContext>();
  const { refetchPaymentDays, refetchPurchasedServices } = useProfile(); // Use ProfileContext

  const [startDate, endDate] = getDefaultMonthRange();
  const [startDateString, setStartDateString] = useState(startDate.toISOString().split("T")[0]);
  const [endDateString, setEndDateString] = useState(endDate.toISOString().split("T")[0]);

  const { data: additionalServices, loading: loadingAdditionalServices, error: errorAdditionalServices } = useFetch(
    API_ENDPOINTS.aditionalServices,
    { startDate: startDateString, endDate: endDateString }
  );

  useEffect(() => {
    setBreadcrumbData({ title: "Rerservar Servicios", item2: "Servicio" });
  }, [setBreadcrumbData]);

  const resetFields = () => {
    setMessage('');
    setMessageType('');
    setLoading(false);
  };

  useEffect(() => {
    const addModalElement = document.getElementById('cancel_appointment');
    addModalElement?.addEventListener('hide.bs.modal', resetFields);

    return () => {
      addModalElement?.removeEventListener('hide.bs.modal', resetFields);
    };
  }, []);

  const fetchSchedules = async () => {
    try {
      if (additionalServices && additionalServices.content) {
        const additionalEvents = additionalServices.content
          .filter(service => service.status !== 4) // Exclude events with status 4
          .map(service => {
            const startDate = new Date(`${service.date}T${service.hourStart}:00`);
            const endDate = new Date(`${service.date}T${service.hourEnd}:00`);

            return {
              title: service.title,
              id: service.id,
              status: service.status,
              start: startDate.toISOString(),
              end: endDate.toISOString(),
              trainerName: service.fullNameService,
              quantity: service.quantity,
              reservation: service.reservation,
              className: service.namePacket,
              isReformer: null,
              ...getEventColorService(startDate.toISOString(), service.status),
            };
          });

        setEvents(additionalEvents);
      }
    } catch (error) {
      console.error("Error al obtener los datos de horarios:", error);
    }
  };

  const fetchClassList = async (schedulesId: number) => {
    try {
      const response = await apiRepository.getReservation(schedulesId);

      try {
        const classList = response.data.data.map(item => ({
          conditionMedical: item.conditionMedical,
          customerName: item.customerName,
          createdAt: item.createdAt
        }));

        return classList;

      } catch {
        console.error('No se pudo obtener la lista de servicios');
      }
    } catch (error) {
      console.error("Error al obtener los datos de horarios:", error);
    }
  };

  const handleDatesSet = (arg) => {
    setStartDateString(arg.start.toISOString().split("T")[0]);
    setEndDateString(arg.end.toISOString().split("T")[0]);
  };

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const start = calendarApi.view.activeStart;
      const end = calendarApi.view.activeEnd;

      setStartDateString(start.toISOString().split("T")[0]);
      setEndDateString(end.toISOString().split("T")[0]);
    }
  }, []);

  useEffect(() => {
    if (additionalServices) {
      fetchSchedules();
    }
  }, [startDateString, endDateString, additionalServices]);

  const confimrCancelReservation = async (reason: string) => {
    try {
      const reservation = await apiRepository.getReservation(eventDetails.id);
      const listUsers = reservation.data.data;
      const userReservation = listUsers.find(user => user.customerId === userId);

      const currentDate = new Date();
      const classDate = new Date(eventDetails.start);

      if (classDate < currentDate) {
        setMessage("No se puede cancelar servicios que ya pasaron.");
        setMessageType("error");
        return;
      }

      setLoading(true);
      let deleteResponse;

      if (user?.role == "trainer") {
        deleteResponse = await apiRepository.deleteSchedule(eventDetails.id);
      } else {
        deleteResponse = await apiRepository.deleteReservation(userReservation.id, reason);
      }

      if (deleteResponse.status == 200 || deleteResponse.status == 204) {
        handleEventDetailsClose();
        closeModal("cancel_appointment");
        handleEventDetailsClose();
        await refetchPaymentDays();
        await refetchPurchasedServices();
        fetchSchedules();

        setMessage("Reservación cancelada con éxito.");
        setMessageType("success");
      } else {
        throw new Error("La eliminación de la reservación no fue exitosa.");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        const cleanedMessage = error.response.data.message.replace(/^Bad request: /, '');
        setMessage(cleanedMessage);
      } else {
        setMessage('Hubo un error al intentar cancelar la reservación.');
      }
      setMessageType("error");
    } finally {
      setLoading(false);
    }
  };

  const handleEventClick = async (info) => {
    const assistList = await fetchClassList(info.event.id);
    setEventDetails({
      assistList: assistList,
      title: info.event.title,
      date: info.event.extendedProps.date,
      start: info.event.start,
      end: info.event.end,
      id: info.event.id,
      classId: info.event.extendedProps.classId,
      isReformer: info.event.extendedProps.isReformer,
      location: info.event.extendedProps.location || "No especificado",
      userName: info.event.extendedProps.userName || "Anónimo",
      trainerName: info.event.extendedProps.trainerName || "Desconocido",
      quantity: info.event.extendedProps.quantity || 0,
      reservation: info.event.extendedProps.reservation || 0,
      className: info.event.extendedProps.className || "Descripción no disponible",
      status: info.event.extendedProps.status || 0,
    });
    setShowEventDetailsModal(true);
  };

  const handleEventDetailsClose = () => {
    setShowEventDetailsModal(false);
    setLoading(false);
  };

  const startOfWeek = new Date();
  startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);
    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  const eventContent = (eventInfo) => {
    const { backgroundColor, textColor } = getEventColorService(eventInfo.event.start.toISOString(), eventInfo.event.extendedProps.status);
    const style = {
      backgroundColor,
      color: "#fff",
      border: 'none',
    };

    if (eventInfo.view.type === 'dayGridMonth') {
      return (
        <div className="fc-daygrid-event-harness" style={{ padding: '0px' }}>
          <a className="fc-daygrid-event fc-daygrid-dot-event p-3" style={style}>
            <div className="fc-daygrid-event-dot" style={{ borderColor: backgroundColor, color: '#fff' }}></div>
            <div className="fc-event-time" style={{ fontSize: '13px', color: '#fff' }}>{eventInfo.timeText}</div>
            <div className="fc-event-title" style={{ fontSize: '13px', color: '#fff' }}>{eventInfo.event.title}</div>
          </a>
        </div>
      );
    } else {
      return (
        <div className="fc-event-main" style={style}>
          <div className="fc-event-main-frame">
            <div className="fc-event-time">{eventInfo.timeText}</div>
            <div className="fc-event-title-container">
              <div className="fc-event-title fc-sticky">{eventInfo.event.title}</div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3 mb-4">
        <h4>Servicios</h4>
        {message && messageType == "success" && (
          <div className={`alert alert-success w-100`} role="alert">
            ¡El servicio se reservó con éxito!
          </div>
        )}
      </div>
      <div id="calendar-book">
        <FullCalendar
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin
          ]}
          events={events}
          headerToolbar={{
            left: 'title, prev,today next',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
          }}
          locale="es"
          locales={[esLocale]}
          eventClick={handleEventClick}
          datesSet={handleDatesSet}
          ref={calendarRef}
          eventContent={eventContent} // Add this line
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: 'short',
          }}
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: isMobile ? false : true,
          }}
          allDaySlot={false}
          slotMinTime="05:00:00"
          slotMaxTime="22:00:00"
          views={{
            timeGridWeek: {
              dayHeaderFormat: isMobile && { weekday: 'short' },
            },
          }}
        />
      </div>
      <EventDetailsModal
        isOpen={showEventDetailsModal}
        eventDetails={eventDetails}
        onClose={handleEventDetailsClose}
        userRole={user?.role}
      />
      <CancelReservationModal
        onConfirm={confimrCancelReservation}
        loading={loading}
        messageType={messageType}
        message={message}
      />
      <div className={`sidebar-overlay ${showEventDetailsModal && 'opened'}`} onClick={handleEventDetailsClose}></div>
    </>
  );
};

export default CustomerBookingCalendar;