import React, { useState, useEffect } from 'react';
import { CancelReservationModalProps } from '../../../../core/Interface/modalInterface';
import { CANCELLATION_REASONS } from '../../../../core/data/options/options';

const CancelReservationModal: React.FC<CancelReservationModalProps> = ({ isOpen, onClose, onConfirm, loading, messageType, message }) => {
  const [reason, setReason] = useState<string | undefined>(CANCELLATION_REASONS[0]);

  useEffect(() => {
    setReason(CANCELLATION_REASONS[0]);
  }, [isOpen]);

  return (
    <div className={`modal fade custom-modal ${isOpen ? 'show' : ''}`} id="cancel_appointment" tabIndex={-1} aria-hidden={!isOpen}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header d-flex align-items-center justify-content-between border-bottom">
            <h5 className="modal-title">Cancelar Reservación</h5>
            <a
              href="#"
              aria-label="Cerrar"
              data-bs-dismiss="modal"
              onClick={onClose}
            >
              <i className="ti ti-circle-x-filled fs-20" />
            </a>
          </div>
          <form>
            <div className="modal-body">
              <p>
                ¿Estás seguro de que quieres cancelar la reservación de esta clase?
              </p>
              {CANCELLATION_REASONS.map((reasonOption) => (
                <div key={reasonOption} className="form-check">
                  <input
                    onChange={() => setReason(reasonOption)}
                    className="form-check-input"
                    type="radio"
                    value={reasonOption}
                    checked={reason === reasonOption}
                  />
                  <label  style={{ cursor: 'pointer' }} className="form-check-label" onClick={() => setReason(reasonOption)}>
                    {reasonOption}
                  </label>
                </div>
              ))}
              {messageType === "error" && (
                <div className={`alert alert-danger mt-2 mb-3 alert-dismissible fade show`} role="alert">
                  {message}
                </div>
              )}
            </div>
            <div className="modal-footer">
              <div className="acc-submit">
                <a
                  href="#"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                  onClick={onClose}
                >
                  Descartar
                </a>
                <button
                  type="button"
                  className="btn btn-dark"
                  onClick={() => onConfirm(reason || '')}
                >
                  {loading ? (
                    <>
                      <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      Cancelando...
                    </>
                  ) : (
                    'Sí, Cancelar'
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CancelReservationModal;