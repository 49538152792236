import React, { useEffect } from 'react';
import ClassSchedules from '../../../admin/class-schedules/class-schedules';
import { useOutletContext } from 'react-router-dom';
import { BreadcrumbContext } from '../../../../core/Interface/contextInterface';

/**
 * Componente para mostrar el historial de compras del cliente.
 * @returns {JSX.Element} Componente de historial de compras.
 */
const RecepcionUsers = () => {
  const { setBreadcrumbData } = useOutletContext<BreadcrumbContext>();

  useEffect(() => {
    setBreadcrumbData({ title: "Horarios", item1: "Perfil", item2: "Horarios" });
  }, [setBreadcrumbData]);

  return (
      <div className="">
        <ClassSchedules />
      </div>
  );
};

export default RecepcionUsers;