/**
 * Componente UserSuscriptions
 * 
 * Este componente se encarga de mostrar y gestionar las suscripciones de usuarios.
 * Permite filtrar las suscripciones por nombre y correo electrónico, y paginar los resultados.
 * 
 * Funcionalidades:
 * - Filtrado por nombre y correo electrónico.
 * - Paginación de resultados.
 * - Edición y eliminación de suscripciones.
 * 
 * Hooks:
 * - useState: Para manejar el estado local del componente.
 * 
 * Dependencias:
 * - apiRepository: Para realizar las llamadas a la API.
 * - DataTableWrapper: Componente para mostrar los datos en una tabla.
 * - SuscriptionsModal: Componente modal para editar suscripciones.
 * - DeleteUserModal: Componente modal para eliminar suscripciones.
 */

import React, { useState } from 'react';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import SuscriptionsModal from '../common/modals/user-suscriptions-modal';
import DeleteUserModal from '../common/modals/delete-user-suscriptions-modal';
import { InputField } from '../../admin/common/input/inputField';
import usePaginatedFetch from '../../../core/hooks/api/usePaginatedFetch';

const UserSuscriptions = () => {
    const [selectedUserId, setSelectedUserId] = useState<any>(null);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [fullName, setFullName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [filtersVisible, setFiltersVisible] = useState<boolean>(false);

    const { data, totalElements, first, rows, onPageChange, refetch } = usePaginatedFetch(
        'subscriptions',
        10,
        0,
        { fullName, email }
    );

    const handleEditClick = (rowData: any) => {
        setSelectedUser(null);
        setTimeout(() => setSelectedUser(rowData), 0);
    };

    const handleDeleteClick = (rowData: any) => {
        setSelectedUserId(null);
        setTimeout(() => setSelectedUserId(rowData.id), 0);
    };

    const columns = [
        { field: 'fullName', header: 'Nombre Completo' },
        { field: 'email', header: 'Correo Electrónico' },
    ];

    return (
        <div className="page-wrapper page-settings">
            <div className="content mb-5">
                <div className="content-page-header mb-2 content-page-headersplit">
                    <h5>Suscripciones</h5>
                    <div className="list-btn">
                        <button className="btn btn-primary-admin" onClick={handleEditClick}>
                            <i className="fa fa-plus me-2" /> Agregar Suscripción
                        </button>
                    </div>
                </div>

                <button
                    className="btn btn-outline-info mb-4"
                    onClick={() => setFiltersVisible(!filtersVisible)}
                >
                    {filtersVisible ? 'Ocultar Filtros' : 'Mostrar Filtros'}
                </button>

                {filtersVisible && (
                    <div className="search-filters card card-shadow shadow-none p-3 mb-4 shadow-sm">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h6 className="text-dark">Filtros de búsqueda</h6>
                            <button
                                className="btn btn-outline-primary btn-sm rounded-3"
                                onClick={() => {
                                    setFullName('');
                                    setEmail('');
                                }}
                                title="Limpiar filtros"
                            >
                                <i className="fa fa-eraser me-2" />
                                Limpiar
                            </button>
                        </div>
                        <div className="d-flex align-items-center gap-3">
                            <div className="flex-grow-1 position-relative">
                                <InputField
                                    label="Nombre"
                                    name="fullName"
                                    placeholder="Ejemplo: Juan"
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                />
                            </div>
                            <div className="flex-grow-1 position-relative">
                                <InputField
                                    label="Correo Electrónico"
                                    name="email"
                                    placeholder="Ejemplo: juan@gmail.com"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <DataTableWrapper
                    data={data}
                    columns={columns}
                    onEdit={handleEditClick}
                    onDelete={handleDeleteClick}
                    paginator
                    showGridlines
                    rowsPerPage={rows}
                    totalRecords={totalElements}
                    first={first}
                    onPageChange={onPageChange}
                    emptyMessage="No se encontraron suscripciones."
                />
                <SuscriptionsModal selectedUser={selectedUser} onUpdateSuccess={refetch} />
                <DeleteUserModal selectedUserId={selectedUserId} onDeleteSuccess={refetch} />
            </div>
        </div>
    );
};

export default UserSuscriptions;