import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { RangeCalendar, TextInput } from '../../../components/inputs';
import BaseModal from '../../../components/modal/baseModal';
import { PauseModalProps } from '../../../../core/Interface/frontend/customer';

const PauseModal: React.FC<PauseModalProps> = ({ formData, errors, handleInputChange, handleRangeCalendarChange, show, handleClose, selectedClass, handleSubmit, loading }) => {

  return (
    <BaseModal
      show={show}
      onClose={handleClose}
      title={`Pausar ${selectedClass?.type}`}
      centered
    >
      <Form onSubmit={(e) => handleSubmit(e)}>
        <TextInput
          label="Razón para pausar"
          name="reason"
          value={formData.reason}
          onChange={handleInputChange}
          error={errors.reason}
          placeholder="Ingrese la razón"
        />
        <RangeCalendar
          label="Rango de fechas"
          name="dateRange"
          value={formData.dateRange || undefined}
          onChange={(e) => handleRangeCalendarChange('dateRange', e.value)}
          error={errors?.dateRange || ''}
        />
        <Button variant="primary" type="submit" className="mt-3 btn-primary" disabled={loading}>
          {loading ? 'Cargando...' : 'Confirmar'}
        </Button>
      </Form>
    </BaseModal>
  );
};

export default PauseModal;