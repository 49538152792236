/**
 * Componente CartComponent
 * 
 * Este componente renderiza toda la página del carrito del cliente. Incluye los componentes de carrito de compras y resumen de compra.
 * También maneja la lógica para gestionar el carrito, aplicar cupones y confirmar el pago.
 * 
 * Contextos:
 * - AuthContext: Proporciona datos y funciones relacionadas con la autenticación.
 * - CartContext: Proporciona datos y funciones relacionadas con el carrito.
 * - BreadcrumbContext: Proporciona datos de breadcrumb para la navegación.
 * 
 * Variables de Estado:
 * - updateDays: Booleano para activar actualizaciones.
 * - couponCode: Cadena que representa el código de cupón ingresado.
 * - couponDiscount: Número que representa el descuento aplicado por el cupón.
 * - generalCouponUsed: Booleano que indica si se ha utilizado un cupón general.
 * - customerCart: Objeto que contiene detalles del carrito como monto, descuentos y artículos.
 * 
 * Funciones:
 * - wrappedValidateCode: Función envolvente para validar el código de cupón.
 * - wrappedConfirmPay: Función envolvente para confirmar el pago.
 * 
 * Efectos:
 * - useEffect: Actualiza los datos de breadcrumb cuando el componente se monta.
 * - useEffect: Inicializa MercadoPago con la clave secreta.
 * - useEffect: Actualiza el estado del carrito cuando el carrito o el descuento del cupón cambian.
 * 
 * El componente está estructurado con una sección de carrito de compras y una sección de resumen de compra. También incluye lógica para
 * gestionar el carrito, aplicar cupones y confirmar el pago.
 */

import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from '../../../../core/context/CartContext';
import { AuthContext } from '../../../../core/context/AuthContext';
import { useOutletContext } from 'react-router-dom';
import { initMercadoPago } from '@mercadopago/sdk-react';
import PaymentSuccessModal from '../../common/modals/succes-payment';
import { useSecretKeyInfo } from "../../../../core/hooks/getData/useSecretKeyInfo";
import { BreadcrumbContext } from '../../../../core/Interface/contextInterface';
import { useConfirmPay } from '../../../../core/hooks/form/useConfirmPay';
import { useCartLogic } from '../../../../core/hooks/form/useCartLogic';
import PurchaseSummary from './purchaseSummary';
import ShoppingCart from './shoppingCartComponent';
import { useCouponValidation } from '../../../../core/hooks/cart/useCouponValidation';
import './CartComponent.css';
import ConfirmActionModal from '../../../components/modal/ConfirmActionModal';

const CartComponent: React.FC = () => {
  const authContext = useContext(AuthContext);
  const cartContext = useContext(CartContext);
  if (!authContext || !cartContext) {
    return <div>Error: Auth and Cart context not found!</div>;
  }

  const { cart, addToCart, setCart, removeFromCart, removeProductFromCart } = cartContext;
  const { user } = authContext;
  const [couponCode, setCouponCode] = useState<string>();
  const [couponDiscount, setCouponDiscount] = useState(0);
  const { setBreadcrumbData } = useOutletContext<BreadcrumbContext>();
  const { user: userSecretKey, token, isLoading: secretKeyLoading } = useSecretKeyInfo();
  const [generalCouponUsed, setGeneralCouponUsed] = useState<boolean>(false);
  const [customerCart, setCustomerCart] = useState<any>([]);
  const { confirmPay, processPayment, loadingPay, info: infoValidate, setInfo: setInforValidate } = useConfirmPay(user, cart, customerCart, couponDiscount, token);
  const { pathPdf, isProcessing, errorPayment } = useCartLogic(cartContext, authContext, token);
  const { validateCode, info, loading, removeCoupon, setInfo } = useCouponValidation({ cart, setCouponDiscount, generalCouponUsed, setGeneralCouponUsed, couponCode });
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const wrappedValidateCode = async () => {
    setInfo([]);
    setInforValidate([]);
    await validateCode();
  };

  const wrappedConfirmPay = async () => {
    setInforValidate([]);
    setInfo([]);
    const success = await confirmPay();
    if (success) {
      setShowConfirmModal(true);
    }
  };

  useEffect(() => {
    setBreadcrumbData({ title: "Carrito", item2: "Carrito"});
  }, [setBreadcrumbData]);

  useEffect(() => {
    if (userSecretKey) {
      initMercadoPago(userSecretKey);
    }
  }, [userSecretKey]);

  // useEffect(() => {
  //   setupdateDays(!updateDays);
  // }, [pathPdf, isProcessing])

  useEffect(() => {
    const updatedCart = cart.map((item) => {
      const specificDiscount = (couponDiscount.specificDiscount || []).find((discount) => discount.packageId === item.id);
      const specificDiscountAmount = specificDiscount
        ? specificDiscount.type === "percentage"
          ? (item.price * item.quantity) * specificDiscount.couponDiscount
          : specificDiscount.couponDiscount
        : 0;

      const productPrice = item.price;
      const amountRaw = item.price * item.quantity;
      const amountDiscount = amountRaw - specificDiscountAmount;

      const codesCoupons = specificDiscount ? [specificDiscount.couponCode] : [];

      return {
        ...item,
        amount: productPrice,
        subtotal: amountDiscount,
        amount_raw: amountRaw,
        discount_value: specificDiscountAmount,
        codesCoupons: codesCoupons,
      };
    });

    const amount_raw = updatedCart.reduce((acc, item) => acc + item.amount_raw, 0);

    const generalDiscountValue =
      couponDiscount.generalDiscount?.type === "percentage"
        ? amount_raw * couponDiscount.generalDiscount.couponDiscount
        : couponDiscount.generalDiscount?.couponDiscount || 0;

    const discount_value = updatedCart.reduce((acc, item) => acc + item.discount_value, 0) + generalDiscountValue;
    const amount_with_discount = updatedCart.reduce((acc, item) => acc + item.subtotal, 0) - generalDiscountValue;

    const codesCoupons = couponDiscount?.generalDiscount?.couponName ? [couponDiscount.generalDiscount.couponName] : [];

    setCustomerCart({
      amount_raw: amount_raw,
      discount_value: discount_value,
      discount_general_value: generalDiscountValue,
      amount_with_discount: amount_with_discount,
      codesCoupons: codesCoupons,
      items: updatedCart,
    });
  }, [cart, couponDiscount]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isProcessing) {
        event.preventDefault();
        event.returnValue = 'Estas seguro que deseas actualizar, esto puede generar problemas en el pago';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isProcessing]);

  return (
    <div className="">
      {isProcessing && <div className="overlay" style={{ zIndex: '99999999' }}><div className="loader"></div></div>}
      <div className={isProcessing ? "content disabled" : "content"}>
        <div className="row">
          <ShoppingCart
            cart={cart}
            setCart={setCart}
            removeFromCart={removeFromCart}
            addToCart={addToCart}
            removeProductFromCart={removeProductFromCart}
          />
          <PurchaseSummary
            customerCart={customerCart}
            couponDiscount={couponDiscount || {}}
            couponCode={couponCode || ''}
            setCouponCode={setCouponCode || ''}
            validateCode={wrappedValidateCode}
            loading={loading || false}
            info={info || []}
            infoValidate={infoValidate || []}
            confirmPay={wrappedConfirmPay}
            loadingPay={loadingPay || false}
            removeCoupon={removeCoupon}
          />
          <div id="succes-pay-confirm">
            <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#payment-success"></button>
            <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#payment-error"></button>
          </div>

          <PaymentSuccessModal pathPdf={pathPdf || ''} message="¡La compra se realizo con exito!" errorPayment={errorPayment} />
        </div>
      </div>
      <ConfirmActionModal
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        title="Pago Confirmado"
        text="Después de realizar su pago en la plataforma de Mercado Pago, debe esperar a que se le redirija a LIFT para finalizar su compra. ¿Deseas continuar?"
        onConfirm={async () => {
          setShowConfirmModal(false);
          await processPayment();
        }}
        centered
      />
    </div>
  );
};

export default CartComponent;