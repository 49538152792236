import React, { useRef, useEffect, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { RangeCalendarProps } from '../../../core/Interface/elements/inputInterface';
import InputWrapper from './InputWrapper';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RangeCalendar: React.FC<RangeCalendarProps> = ({ value, onChange, error, label, name, required = false }) => {
  const calendarRef = useRef<any>(null);
  const [viewDate, setViewDate] = useState<Date | null>(null);

  useEffect(() => {
      if (value && value[0]) {
          setViewDate(value[0]);
      }
  }, [value]);

  return (
    <InputWrapper label={label} required={required} error={error}>
      <Calendar
        ref={calendarRef}
        value={value}
        onChange={(e) => {
          onChange(e);
          if (e.value && e.value[0]) {
            setViewDate(e.value[0]);
          }
          if (e.value && e.value[0] && e.value[1]) {
            calendarRef.current.hide();
          }
        }}
        selectionMode="range"
        appendTo="self"
        readOnlyInput
        showIcon
        dateFormat="yy/mm/dd"
        placeholder="Seleccione el rango de fechas"
        className="w-100"
        locale="es"
        prevIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        nextIcon={<FontAwesomeIcon icon={faChevronRight} />}
        viewDate={viewDate}
      />
    </InputWrapper>
  );
};

export default RangeCalendar;