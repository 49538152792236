import React, { useState, useEffect } from 'react';
import { DropdownField } from '../common/input/inputField';
import { AutoCompleteInput, TextInput } from '../../components/inputs';
import { useUsersData } from '../../../core/hooks/getData/useUsersData';
import { FormData, Props } from './types';
import { paymentMethodOptions } from '../../../core/data/options/options';

const SelectUser: React.FC<Props> = ({ prevTab, nextTab, onEmailData, emailData, formData, setFormData }) => {
  const [errors, setErrors] = useState<{ user?: string; purchaseStatus?: string; operationNumber?: string, paymentMethods?: string }>({});
  const { data: users, fetchUsers, loading } = useUsersData('', '', 'customer', true, '');

  const userSuggestions = users?.map(user => ({
      label: user.email,
      value: user.id
    }))
    .filter(user => {
      const query = typeof formData.user === 'string' ? formData.user.toLowerCase() : '';
      return user.label.toLowerCase().includes(query);
    }) // Asegurarse de que el filtro no elimine opciones válidas
    .sort((a, b) => {
      const query = typeof formData.user === 'string' ? formData.user.toLowerCase() : '';
      const aMatch = a.label.toLowerCase().indexOf(query);
      const bMatch = b.label.toLowerCase().indexOf(query);
      if (aMatch === -1) return 1;
      if (bMatch === -1) return -1;
      return aMatch - bMatch;
    });

  const handleInputChange = (field: string, value: any) => {
    setFormData((prevState: any) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleUserComplete = (() => {
    let typingTimeout: NodeJS.Timeout;

    return (e: any) => {
      if (typingTimeout) clearTimeout(typingTimeout);
      typingTimeout = setTimeout(() => {
        fetchUsers(0, 100, e.query); // Trigger the request after delay
      }, 1500); // 2-second delay
    };
  })();

  useEffect(() => {
    onEmailData(formData);
  }, [formData]);

  const getSummaryData = () => {
    const selectedUser = users.find(user => user.id === formData.user);
    return {
      user: selectedUser ? selectedUser.email : formData.user,
      purchaseStatus: formData.purchaseStatus,
      coupons: formData.coupons,
      paymentMethods: formData.paymentMethods,
      operationNumber: formData.operationNumber
    };
  };

  const handleNext = () => {
    const newErrors: { user?: string; purchaseStatus?: string; paymentMethods?: string; operationNumber?: string } = {};

    if (
      !formData.user ||
      (typeof formData.user === 'object' && !('value' in formData.user))
    ) {
      newErrors.user = 'Usuario es requerido y debe ser válido';
    }
    if (!formData.paymentMethods) newErrors.paymentMethods = 'El metodo de pago es requerido';
    if (!formData.paymentMethods?.includes('cash') && !formData.operationNumber) {
      newErrors.operationNumber = 'El número de operación es requerido';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    nextTab(getSummaryData());
  };

  return (
    <>
      <div className="card-section">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-service">
              <h4 className="text-secondary mb-2">Seleccionar Usuario y Detalles de la Compra</h4>
            </div>
          </div>
          <div className="col-md-12">
            <AutoCompleteInput
              label="Usuario"
              placeholder="Buscar usuario"
              value={formData?.user || ''}
              suggestions={userSuggestions}
              completeMethod={handleUserComplete}
              onChange={(value) => handleInputChange('user', value)}
              error={errors?.user}
              loading={loading} // Pass loading state to show animation
            />
          </div>
          <div className="col-md-12">
            <DropdownField
              required={false}
              label="Métodos de Pago"
              value={formData.paymentMethods}
              options={paymentMethodOptions}
              onChange={(e) => handleInputChange('paymentMethods', e.value)}
              placeholder="Selecciona métodos de pago"
              error={errors?.paymentMethods}
            />
          </div>

          <div className="col-md-12">
            <TextInput
              label="Número de Operación"
              placeholder="Ingrese el número de operación"
              value={formData.operationNumber || ''}
              onChange={(e) => handleInputChange('operationNumber', e.target.value)}
              error={errors.operationNumber}
              required={!formData.paymentMethods?.includes('cash')}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="bottom-btn">
            <div className="field-btns">
              <button
                className="btn btn-prev prev_btn"
                type="button"
                onClick={prevTab}
              >
                <i className="fas fa-arrow-left" /> Anterior
              </button>
              <button
                className="btn btn-primary-admin next_btn"
                type="button"
                onClick={handleNext}
              >
                Siguiente <i className="fas fa-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectUser;