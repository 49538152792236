import { useState, useEffect, useCallback, useRef } from "react";
import { getData as apiGetData } from "../../services/api";
import { PaginatedFetchResult } from "../../Interface/useRequest.types";

const usePaginatedFetch = (endpoint: string | null, initialSize = 10, initialPage = 0, params: Record<string, any> | null = {} ): PaginatedFetchResult => {
  const [data, setData] = useState<any[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);
  const [first, setFirst] = useState<number>(initialPage * initialSize);
  const [rows, setRows] = useState<number>(initialSize);
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);
  const isFirstLoad = useRef(true);

  const fetchData = useCallback(async (page = initialPage, size = initialSize, fetchParams = params) => {
    if (!endpoint) return;
    setLoading(true);
    try {
      const response = await apiGetData(endpoint, { ...fetchParams, size, page });
      setData(response.data.data.content);
      setTotalElements(response.data.data.totalElements);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [endpoint]);

  const isValidDate = (dateString: string) => {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date.getTime());
  };

  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
      return;
    }
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    const timeout = setTimeout(() => {
      if (params && params.startDate && params.endDate) {
        if (isValidDate(params.startDate) && isValidDate(params.endDate)) {
          fetchData(0, rows, params);
        }
      } else {
        fetchData(0, rows, params);
      }
    }, 900);
    setFirst(0);
    setSearchTimeout(timeout);
    return () => clearTimeout(timeout);
  }, [JSON.stringify(params)]);

  const refetchData = () => {
    fetchData(first / rows, rows, params);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
    const pageNumber = event.first / event.rows;
    fetchData(pageNumber, event.rows, params);
  };

  return { data, loading, totalElements, error, refetch: refetchData, first, rows, onPageChange };
};

export default usePaginatedFetch;