import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import { getDefaultMonthRange, convertDateToLocalDateString } from '../../../core/utils/dateUtils';
import { CalendarRangeField } from '../common/input/inputField';
import {AutoCompleteInput} from '../../components/inputs';
import { useGetClassReservationHistoryExcel } from '../../../core/hooks/getReports/useGetClassReservationHistoryExcel';
import { downloadExcelFile } from '../../../core/utils/fileUtils';
import moment from 'moment';
import useFetch from '../../../core/hooks/api/useFetch';
import usePaginatedFetch from '../../../core/hooks/api/usePaginatedFetch';
import API_ENDPOINTS from '../../../core/constant/API_ENDPOINTS';
import ShowDetailReservationHistoryModal from '../common/modals/show-detail-reservation-history-modal';
import { formatDateToFull, capitalizeFirstLetter } from '../../../core/utils/utils';

const ClassReservationHistoryReport = () => {
  const [dateRange, setDateRange] = useState<[Date, Date]>(getDefaultMonthRange() as [Date, Date]);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedUserLabel, setSelectedUserLabel] = useState<string>('');
  const [selectedReservation, setSelectedReservation] = useState<any>();
  const { data: customers, loading } = useFetch(`${API_ENDPOINTS.users}?fullName=&email=${searchQuery}&profile=customer&active=&birthdate=`);

  const userSuggestions = customers?.content?.map((customer: { email: string, id: string | number }) => ({
      label: customer.email,
      value: customer.id
    }))
    .filter((customer: { label: string }) => {
      const query = searchQuery.toLowerCase();
      return customer.label.toLowerCase().includes(query);
    })
    .sort((a: { label: string }, b: { label: string }) => {
      const query = searchQuery.toLowerCase();
      const aMatch = a.label.toLowerCase().indexOf(query);
      const bMatch = b.label.toLowerCase().indexOf(query);
      if (aMatch === -1) return 1;
      if (bMatch === -1) return -1;
      return aMatch - bMatch;
    });

  const handleUserComplete = (() => {
    let typingTimeout: NodeJS.Timeout;

    return (e: any) => {
      if (typingTimeout) clearTimeout(typingTimeout);
      typingTimeout = setTimeout(() => {
        setSearchQuery(e.query);
      }, 1500);
    };
  })();

  const handleUserChange = (value: string |  { label: string, value: number | null }) => {
    const selectedUser = userSuggestions.find((option: { label: string, value: number | null }) =>
      typeof value === 'object' && value !== null && 'label' in value && option.label === value.label
    );
    setSelectedUserId(selectedUser?.value || null);
    setSelectedUserLabel(typeof value === 'string' ? value : value?.label || '');
  };

  const params = dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined) ? {
    startDate: convertDateToLocalDateString(dateRange[0]),
    endDate: convertDateToLocalDateString(dateRange[1]),
    ...(selectedUserId !== undefined && selectedUserId !== null ? { customerId: selectedUserId } : {})
  } : null;

  const {
    data: classReservationHistory,
    totalElements,
    first,
    rows,
    onPageChange
  } = usePaginatedFetch(API_ENDPOINTS.reportHistory, 10, 0, params);

  const { refetchClassReservationHistoryExcel } = useGetClassReservationHistoryExcel(dateRange, selectedUserId);

  const columns = [
    { field: "customerName", header: "Cliente" },
    { field: "registrationDate", header: "Fecha de Reserva", body: (rowData: any) => capitalizeFirstLetter(formatDateToFull(rowData.registrationDate))},
    { field: "classDate", header: "Fecha de Clase", body: (rowData: any) => capitalizeFirstLetter(formatDateToFull(rowData.classDate))},
    { field: "classHour", header: "Hora de Clase", body: (rowData: any) => {
      const [start, end] = rowData.classHour.split(' - ');
      return `${moment(start, 'HH:mm').format('hh:mm A')} - ${moment(end, 'HH:mm').format('hh:mm A')}`;
    } },
    { field: "className", header: "Nombre de Clase" },
    { field: "trainerName", header: "Entrenadora" },
    {
      field: "attended",
      header: "Asistió",
      body: (rowData: any) => (
        <div className="d-flex align-items-center justify-content-center">
          {rowData.attended ? (
            <Icon.CheckCircle className="text-success" size={20} />
          ) : (
            <Icon.XCircle className="text-danger" size={20} />
          )}
        </div>
      )
    },
    {
      field: "waitlist",
      header: "Estado de reserva",
      body: (rowData: any) => (
        <div className="d-flex align-items-center justify-content-center">
          {rowData.waitlist ? (
            <span className="badge bg-warning text-dark">
              <Icon.Clock size={14} className="me-1" />
              Reserva en espera
            </span>
          ) : rowData.cancel ? (
            <span className="badge bg-danger" title={rowData.reason}>
              <Icon.XCircle size={14} className="me-1" />
              Reserva cancelada
            </span>
          ) : (
            <span className="badge bg-info">
              <Icon.CheckCircle size={14} className="me-1" />
              Reserva confirmada
            </span>
          )}
        </div>
      )
    },
  ];

  const handleShowInfo = (rowData: any) => {
    setSelectedReservation(null);
    setTimeout(() => setSelectedReservation(rowData), 0);
  };

  const handleDownloadExcel = async () => {
    if (dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined)) {
      const file = await refetchClassReservationHistoryExcel();
      downloadExcelFile(file, 'class-reservation-history');
    }
  };

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          <h5>Historial de Reservas de Clases</h5>
        </div>
        <div className="card card-status mb-3">
          <div className="card-head-blk border-bottom-0 pb-0">
            <div className="row align-items-center">
              <div className="col-md-12 d-flex justify-content-end">
                <button
                  className="btn btn-outline-primary rounded-3 mb-3 me-2"
                  onClick={() => {
                    setDateRange(getDefaultMonthRange() as [Date, Date]);
                    setSelectedUserId(null);
                  }}
                >
                  <i className="fa fa-eraser me-2" />
                  Limpiar
                </button>
                <button
                  className="btn btn-primary-admin rounded-3 mb-3 align-items-center d-flex me-2"
                  onClick={handleDownloadExcel}
                >
                  <Icon.FileText className="react-feather-icon me-2" />
                  <span>Descargar como Excel</span>
                </button>
              </div>
              <div className="col-xl-12 col-md-12 d-flex">
                <div className="earning-group gap-3 w-100">
                  <CalendarRangeField
                    label="Seleccionar las fechas de reservas"
                    value={dateRange || [new Date(), new Date()]}
                    onChange={(e: any) => setDateRange(e.value)}
                  />
                  <AutoCompleteInput
                    label="Seleccionar usuario"
                    placeholder="Escribe para buscar..."
                    value={selectedUserLabel}
                    suggestions={userSuggestions}
                    completeMethod={handleUserComplete}
                    onChange={handleUserChange}
                    required={false}
                    loading={loading} // Pass loading state to show animation
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <DataTableWrapper
            data={classReservationHistory || []}
            lazy={true}
            columns={columns}
            customIcon={Icon.Eye}
            onCustomAction={handleShowInfo}
            rowsPerPage={rows}
            paginator={true}
            first={first}
            totalRecords={totalElements}
            onPageChange={onPageChange}
            showGridlines
            emptyMessage="No se encontraron reservas."
          />
        </div>
      </div>
      <ShowDetailReservationHistoryModal selectedReservationData={selectedReservation} />
    </div>
  );
};

export default ClassReservationHistoryReport;