import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import PagesAuthHeader from './common/header';
import { all_routes } from '../../../../core/data/routes/all_routes';
import AuthFooter from './common/footer';
import { InputField, CalendarField, DropdownField } from '../../../admin/common/input/inputField';
import { AuthContext } from '../../../../core/context/AuthContext';
import { TYPE_IDENTIFIER } from '../../../../core/data/options/options';
import { convertStringToLocalDate, getCurrentLocalDate } from '../../../../core/utils/dateUtils';
import { useRegister } from "../../../../core/hooks/auth/useRegister";
import { getErrorForField } from '../../../../core/utils/utils';
import { useUbigeo } from "../../../../core/hooks/getData/useUbigeo";

const UserSignup = () => {
  const routes = all_routes;
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { login } = authContext;
  const { formData, handleChange, registerUser, loading, serverError } = useRegister(login, "register-modal");
  const { provinces, districts, formattedUbigeoData } = useUbigeo({ formData, handleChange });
  const [errors, setErrors] = useState(serverError);

  useEffect(() => {
    if (!formData?.birthdate || isNaN(new Date(formData.birthdate).getTime())) {
      handleChange({
        target: {
          name: 'birthdate',
          value: getCurrentLocalDate()
        }
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [formData.birthdate, handleChange]);

  const removeSpaces = (value: string) => value.replace(/\s/g, '');

  const handleChangeWithNoSpaces = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'email' || name === 'numberDocument') {
      handleChange({
        target: {
          name,
          value: removeSpaces(value)
        }
      });
    } else {
      handleChange(e);
    }
  };


  useEffect(() => {
    setErrors(serverError);
  }, [serverError]);

  const handleChangeWithValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setErrors(errors.filter(error => error.field !== e.target.name));
  };

  const handleCheckboxChangeWithValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    handleChange({
      target: {
        name,
        value: checked
      }
    } as React.ChangeEvent<HTMLInputElement>);
    setErrors(errors.filter(error => error.field !== name));
  };

  const handleDropdownChangeWithValidation = (e: any, name: string) => {
    handleChange({
      target: {
        name,
        value: e.value
      }
    } as React.ChangeEvent<HTMLInputElement>);
    setErrors(errors.filter(error => error.field !== name));
  };

  const handleCalendarChangeWithValidation = (e: any, name: string) => {
    handleChange({
      target: {
        name,
        value: e.value.toISOString().split('T')[0]
      }
    } as React.ChangeEvent<HTMLInputElement>);
    setErrors(errors.filter(error => error.field !== name));
  };

  return (
    <>
      <PagesAuthHeader />
      <div className="main-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-7 mx-auto">
              <form onSubmit={registerUser}>
                <div className="d-flex flex-column justify-content-center">
                  <div className="card card-shadow p-sm-4 my-4">
                    <div className="card-body">
                      <div className="text-center mb-3">
                        <h3 className="mb-2">Registro de Usuario</h3>
                        <p>Introduce tus datos para crear tu cuenta</p>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <InputField
                            label="Nombre"
                            name="name"
                            value={formData?.name || ""}
                            onChange={handleChangeWithValidation}
                            placeholder="Juan"
                            error={getErrorForField("name", errors || [])}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            label="Apellido"
                            name="lastname"
                            value={formData?.lastname || ""}
                            onChange={handleChangeWithValidation}
                            placeholder="Pérez"
                            error={getErrorForField("lastname", errors || [])}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            label="Correo Electrónico"
                            name="email"
                            value={formData?.email || ""}
                            onChange={handleChangeWithValidation}
                            placeholder="juan.perez@email.com"
                            error={getErrorForField("email", errors || [])}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            label="Número de teléfono"
                            name="phone"
                            value={formData?.phone || ""}
                            onChange={handleChangeWithValidation}
                            placeholder="987 654 321"
                            error={getErrorForField("phone", errors || [])}
                          />
                        </div>
                          <CalendarField
                            label="Fecha de nacimiento"
                            value={formData?.birthdate ? convertStringToLocalDate(formData.birthdate) : getCurrentLocalDate()}
                            onChange={(e: any) => handleCalendarChangeWithValidation(e, 'birthdate')}
                            error={getErrorForField("birthdate", errors || [])}
                          />
                        <div className="row">
                          <div className="col-md-3 pe-0">
                            <DropdownField
                              label="Tipo"
                              name="typeDocument"
                              options={TYPE_IDENTIFIER}
                              value={formData?.typeDocument}
                              onChange={(e: any) => handleDropdownChangeWithValidation(e, 'typeDocument')}
                              error={getErrorForField("typeDocument", errors || [])}
                            />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              label="Numero de Identidad"
                              name="numberDocument"
                              placeholder="82143841"
                              value={formData?.numberDocument || ''}
                              onChange={handleChangeWithValidation}
                              error={getErrorForField("numberDocument", errors || [])}
                            />
                          </div>
                        </div>
                        <InputField
                          label="Condición Medica / Embarazo"
                          required={false}
                          name="conditionMedical"
                          value={formData?.conditionMedical || ""}
                          onChange={handleChangeWithValidation}
                          placeholder="Tengo un embarazo de 10 semanas"
                          error={getErrorForField("conditionMedical", errors || [])}
                        />
                        <div className="col-md-4">
                          <DropdownField
                            label="Departamento"
                            name="department"
                            placeholder="Seleccione un departamento"
                            onChange={(e: any) => handleDropdownChangeWithValidation(e, 'department')}
                            options={formattedUbigeoData}
                            value={formData?.department}
                            error={getErrorForField("department", errors || [])}
                          />
                        </div>
                        <div className="col-md-4">
                          <DropdownField
                            label="Provincia"
                            name="province"
                            placeholder="Seleccione una provincia"
                            onChange={(e: any) => handleDropdownChangeWithValidation(e, 'province')}
                            options={provinces || []}
                            value={formData?.province}
                            disabled={!provinces}
                            error={getErrorForField("province", errors || [])}
                          />
                        </div>
                        <div className="col-md-4">
                          <DropdownField
                            label="Distrito"
                            name="district"
                            placeholder="Seleccione un distrito"
                            onChange={(e: any) => handleDropdownChangeWithValidation(e, 'district')}
                            options={districts || []}
                            value={formData?.district}
                            disabled={!formData?.province}
                            error={getErrorForField("district", errors || [])}
                          />
                        </div>
                        <InputField
                            label="Dirección"
                            name="address"
                            value={formData?.address || ""}
                            onChange={handleChangeWithValidation}
                            placeholder="Av. Example 123"
                            error={getErrorForField("address", errors || [])}
                          />
                        <div className="col-md-6">
                          <InputField
                            type="password"
                            name="password"
                            label="Nueva Contraseña"
                            value={formData?.password || ""}
                            onChange={handleChangeWithValidation}
                            error={getErrorForField("password", errors || [])}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            type="password"
                            name="newPassword"
                            label="Confirmar Contraseña"
                            value={formData?.newPassword || ""}
                            onChange={handleChangeWithValidation}
                            error={getErrorForField("newPassword", errors || [])}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="classFree"
                            checked={formData?.classFree}
                            onChange={handleCheckboxChangeWithValidation}
                          />
                          <label className="form-check-label">
                            Solicitar clase gratuita
                          </label>
                        </div>
                      </div>
                      <div className="mb-1">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="isAccepted"
                            checked={formData?.isAccepted}
                            onChange={handleCheckboxChangeWithValidation}
                          />
                          <label className="form-check-label">
                            Acepto los {" "}
                            <Link
                              target="_blank"
                              to={routes.termsCondition}
                              className="link-primary text-decoration-underline"
                            >
                              Términos de uso
                            </Link>{" "}
                            y {" "}
                            <Link
                              target="_blank"
                              to={routes.privacyPolicy}
                              className="link-primary text-decoration-underline"
                            >
                              la Política de privacidad
                            </Link>
                          </label>
                        </div>
                      </div>
                      <div className="mb-3">
                        {serverError?.find((error) => error.field === "isAccepted") && (
                          <small className="text-danger mb-3">
                            {getErrorForField("isAccepted", errors || [])}
                          </small>
                        )}
                      </div>
                      {errors?.find((error) => error.field === "server") && (
                        <div className="mb-3">
                          <small className="text-danger">
                            {serverError?.find((error) => error.field === "server")?.message}
                          </small>
                        </div>
                      )}
                      <div className="mb-3">
                        <button
                          type="submit"
                          className="btn btn-lg btn-linear-primary w-100"
                          disabled={loading}
                        >
                          {loading ? (
                            <>
                              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                              Registrando...
                            </>
                          ) : (
                            'Registrarse'
                          )}
                        </button>
                      </div>
                      <div className="d-flex justify-content-center">
                        <p>
                          ¿Ya tienes una cuenta?{" "}
                          <Link to={routes.login} className="link-primary">
                            Inicia sesión
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div>
                      <ImageWithBasePath
                        src="assets/img/bg/authentication-bg.png"
                        className="bg-left-top"
                        alt="Img"
                      />
                      <ImageWithBasePath
                        src="assets/img/bg/authentication-bg.png"
                        className="bg-right-bottom"
                        alt="Img"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <AuthFooter />
    </>
  );
};

export default UserSignup;