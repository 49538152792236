/**
 * Componente PauseDays
 * 
 * Este componente se encarga de mostrar y gestionar los días de pausa de los usuarios.
 * Permite filtrar los días de pausa por nombre de usuario y paginar los resultados.
 * 
 * Funcionalidades:
 * - Filtrado por nombre de usuario.
 * - Paginación de resultados.
 * - Edición de días de pausa.
 * 
 * Hooks:
 * - useState: Para manejar el estado local del componente.
 * - useEffect: Para realizar la carga inicial de datos.
 * 
 * Dependencias:
 * - DataTableWrapper: Componente para mostrar los datos en una tabla.
 * - PauseDaysModal: Componente modal para editar días de pausa.
 */

import React, { useState, useContext } from 'react';
import usePaginatedFetch from '../../../core/hooks/api/usePaginatedFetch';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import { RangeCalendar, DropdownInput, TextInput } from '../../components/inputs';
import API_ENDPOINTS from '../../../core/constant/API_ENDPOINTS';
import { getDefaultMonthRange, convertDateToLocalDateString } from '../../../core/utils/dateUtils';
import FormModal from '../../components/modal/FormModal';
import usePut from '../../../core/hooks/api/usePut';
import { AuthContext } from '../../../core/context/AuthContext';
import { handleInputChange } from '../../../core/form/handleInputChange';
import { ValidationRule } from '../../../core/Interface/validationsInterfaces';
import { validateForm } from '../../../core/form/formValidationRules';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faUserClock, faCheckCircle, faClock, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const PauseDays = () => {
  const [selectedPauseDays, setSelectedPauseDays] = useState<string | any>(null);
  const [dateRange, setDateRange] = useState<any>(getDefaultMonthRange());
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { put, loading } = usePut(API_ENDPOINTS.freezeRequests);
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [fullName, setFullName] = useState<string>('');

  const { data, totalElements, first, rows, onPageChange, refetch } = usePaginatedFetch(
    API_ENDPOINTS.freezeRequests,
    10,
    0,
    { 
      startDate: convertDateToLocalDateString(dateRange[0]), 
      endDate: convertDateToLocalDateString(dateRange[1]),
      fullName
    }
  );

  const handleEditClick = (rowData: any) => {
    setSelectedPauseDays(rowData);
    setShowConfirmModal(true);
  };

  const handleCloseClick = () => {
    setShowConfirmModal(false);
    setErrors({});
    setFormData({});
  };

  const validationRules: ValidationRule[] = [
    { field: 'status', methods: ['required'] },
    { field: 'rejectionReason', methods: ['required'] }
  ];

  const handleConfirmEdit = async () => {
    const currentValidationRules = validationRules.filter(rule => rule.field !== 'rejectionReason');
    if (formData.status === 'RECHAZADO') {
      currentValidationRules.push({ field: 'rejectionReason', methods: ['required'] });
    }

    const newErrors = validateForm(formData, currentValidationRules);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }

    if (selectedPauseDays && user) {
      const payload = {
        approvedByUserId: user.userId,
        status: formData.status,
        rejectionReason: formData.status === 'RECHAZADO' ? formData.rejectionReason : null
      };
      try {
        await put(selectedPauseDays.id, payload);
        refetch(0, rows);
        setShowConfirmModal(false)
        setErrors({});
        return true;
      } catch (err: any) {
        const errorMessage = err.response?.data?.message || 'Error al enviar la solicitud. Inténtalo de nuevo.';
        setErrors({ submit: errorMessage });
        return false;
      }
    }
  };

  const columns = [
    { field: "customerFullName", header: "Solicitante" },
    {
      field: "status",
      header: "Estado de la Solicitud",
      body: (rowData: any) => (
        <>
          {getStatusIcon(rowData.status)}
          <span className="ms-2">{rowData.status}</span>
        </>
      ),
    },
    { field: "reason", header: "Motivo de la Solicitud" },
    { field: "quantityDays", header: "Días Solicitados" },
    {
      field: "approvedByUserFullName",
      header: "Aprobador",
      body: (rowData: any) => (
        <>
          <FontAwesomeIcon icon={faUserCheck} className="me-2" />
          {rowData.approvedByUserFullName || "Solicitud en curso"}
        </>
      ),
    },
    {
      field: "rejectionReason",
      header: "Motivo del Rechazo",
      body: (rowData: any) => rowData.rejectionReason || "Solicitud en curso",
    },
    {
      field: "approvalRejectionDatetime",
      header: "Fecha de Decisión",
      body: (rowData: any) => {
        const formattedDate = format(new Date(rowData.approvalRejectionDatetime), 'dd \'de\' MMMM \'del\' yyyy', { locale: es });
        return rowData.approvalRejectionDatetime ? formattedDate : "Solicitud en curso";
      }
    },
    {
      field: "updatedAt",
      header: "Fecha de la Solicitud",
      body: (rowData: any) => {
        const formattedDate = format(new Date(rowData.updatedAt), 'dd \'de\' MMMM \'del\' yyyy', { locale: es });
        return rowData.updatedAt ? formattedDate : "Solicitud en curso";
      }
    }
  ];

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'APROBADO':
        return <FontAwesomeIcon icon={faCheckCircle} className="text-success" />;
      case 'PENDIENTE':
        return <FontAwesomeIcon icon={faClock} className="text-warning" />;
      case 'RECHAZADO':
        return <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />;
      default:
        return null;
    }
  };

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit align-items-start">
          <h5>Días de Pausa</h5>
          <div className="list-btn">
            <ul>
              <li>
              <div className="d-flex align-items-end gap-3">
                <RangeCalendar
                  label="Rango de Fechas"
                  name="dateRange"
                  value={dateRange}
                  onChange={(e) => setDateRange(e.target.value)}
                />
                <TextInput
                  label="Nombre del Usuario"
                  name="fullName"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  placeholder="Ingrese el nombre del usuario"
                />
                <button
                  className="btn btn-outline-primary rounded-3 mb-3"
                  onClick={() => {
                    setDateRange(getDefaultMonthRange());
                    setFullName('');
                  }}
                  title="Limpiar filtros"
                >
                  <i className="fa fa-eraser me-2" />
                  Limpiar
                </button>
              </div>
              </li>
            </ul>
          </div>
        </div>

        <DataTableWrapper
          data={data}
          columns={columns}
          onEdit={handleEditClick}
          paginator
          showGridlines
          rowsPerPage={rows}
          totalRecords={totalElements}
          first={first}
          onPageChange={onPageChange}
          emptyMessage="No se encontraron días de pausa."
        />
      </div>
      <FormModal
        show={showConfirmModal}
        onClose={handleCloseClick}
        onConfirm={handleConfirmEdit}
        title="Aprueba o Rechaza la Solicitud"
        loading={loading}
        error={errors.submit}
      >
        {selectedPauseDays?.status === 'APROBADO' || selectedPauseDays?.status === 'RECHAZADO' ? (
          <p>Esta solicitud ya ha sido procesada.</p>
        ) : (
          <>
            <DropdownInput
              label="Estado"
              value={formData.status}
              onChange={(e) => handleInputChange(e, setFormData)}
              options={[
                { label: 'Aprobado', value: 'APROBADO' },
                { label: 'Denegado', value: 'RECHAZADO' }
              ]}
              name="status"
              placeholder="Seleccione el estado"
              required
              error={errors.status}
            />
            {formData.status === 'RECHAZADO' && (
              <TextInput
                label="Razón de Rechazo"
                value={formData.rejectionReason || ''}
                onChange={(e) => handleInputChange(e, setFormData)}
                name="rejectionReason"
                placeholder="Ingrese la razón de rechazo"
                required
                error={errors.rejectionReason}
              />
            )}
          </>
        )}
      </FormModal>
    </div>
  );
};

export default PauseDays;