import { Banners } from '../../../../core/Interface/repositoryInterfaces';
import { Link } from 'react-router-dom';
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import React from 'react';

/**
 * Componente Banner
 * 
 * Este componente muestra un banner con una imagen, título, subtítulo y un botón que redirige a una ruta específica.
 * 
 * Props:
 * - banner: Objeto de tipo Banners que contiene la información del banner a mostrar.
 * 
 * El componente adapta la imagen a mostrar dependiendo del tamaño de la pantalla (versión móvil o de escritorio).
 * 
 * @param {Object} props - Propiedades del componente.
 * @param {Banners} props.banner - Información del banner a mostrar.
 * 
 * @returns {JSX.Element} Elemento JSX que representa el banner.
 */
export const Banner = ({ banner }: { banner: Banners }) => {
    const isMobile = window.innerWidth <= 768;
    const imageSrc = isMobile && banner.imageMobile ? banner.imageMobile : banner.image;

    return (
        <div className="slide-images">
            <div className="hero-content position-relative overflow-hidden">
                <div className="hero-image slide-images">
                    <ImageWithBaseApi
                        ladingClass="hero-loading"
                        routeName="banner"
                        src={imageSrc}
                        alt={banner.title || 'Banner Lift'}
                        className="shape-03"
                    />
                </div>
                <div className="container hero-action d-flex">
                    <div className="action-content col-md-6 col-xxl-8 col-xl-10 col-lg-11 text-left justify-content-center">
                        <h1 className="mb-2">{banner.title}</h1>
                        <p>{banner.subtitle}</p>
                        <div className="mb-2 appointment-btn">
                            <Link to={banner.path || '#'} className="btn btn-primary d-inline-flex align-items-center">
                                Ver más
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};