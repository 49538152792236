import React, { useState } from 'react';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import SubTypesModal from '../common/modals/sub-types.modal';
import API_ENDPOINTS from '../../../core/constant/API_ENDPOINTS';
import usePaginatedFetch from '../../../core/hooks/api/usePaginatedFetch';

const ClassesWithSubTypes = () => {
    const [selectedSubType, setSelectedSubType] = useState<any>();
    const { data: subTypeClassesData, totalElements, refetch, first, rows, onPageChange } = usePaginatedFetch(API_ENDPOINTS.subTypeClasses);

    const handleEditClick = (rowData: any) => {
        setSelectedSubType(null);
        setTimeout(() => setSelectedSubType(rowData), 0);
    };

    const columns = [
        { field: 'name', header: 'Nombre' },
        { field: 'className', header: 'Clase' },
    ];

    return (
        <div className="page-wrapper page-settings">
            <div className="content">
                <div className="content-page-header content-page-headersplit">
                    <h5>Tipo de Clases</h5>
                    <div className="list-btn">
                        <button
                            className="btn btn-primary-admin"
                            type="button"
                            onClick={handleEditClick}
                        >
                            <i className="fa fa-plus me-2" />
                            Agregar Tipo de Clase
                        </button>
                    </div>
                </div>
                <DataTableWrapper
                    data={subTypeClassesData || []}
                    columns={columns}
                    onEdit={handleEditClick}
                    first={first}
                    paginator
                    showGridlines
                    rowsPerPage={rows}
                    totalRecords={totalElements}
                    isTruncateTable
                    onPageChange={onPageChange}
                    emptyMessage="No se encontraron tipos de clases."
                />
            </div>
            <SubTypesModal
                selectedSubType={selectedSubType}
                onUpdateSuccess={refetch}
            />
        </div>
    );
};

export default ClassesWithSubTypes;