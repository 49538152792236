import React from 'react';
import BaseModal from './baseModal';
import { ConfirmActionModalProps } from '../../../core/Interface/elements/modalInterface';

const ConfirmActionModal: React.FC<ConfirmActionModalProps> = ({
  show,
  onClose,
  title = 'Confirmar Acción',
  text = '¿Estás seguro de que deseas continuar?',
  onConfirm,
  children,
  loading,
  error,
  ...modalProps
}) => {
  return (
    <BaseModal
      show={show}
      onClose={onClose}
      title={title}
      {...modalProps}
      footer={
        <>
          <button onClick={onClose} className="btn btn-secondary">
            Cancelar
          </button>
          <button onClick={onConfirm} className="btn btn-primary" disabled={loading}>
            {loading ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Confirmando...
              </>
            ) : (
              'Confirmar'
            )}
          </button>
        </>
      }
    >
      {children ? (
        children
      ) : (
        <div className="text-center">
          <i className="fas fa-question-circle text-warning fa-3x mb-3"></i>
          <p>{text}</p>
          {error && <p className="text-danger mt-2">{error.message}</p>}
        </div>
      )}
    </BaseModal>
  );
};

export default ConfirmActionModal;
