import React from 'react';
import { InputText } from 'primereact/inputtext';
import { TextInputProps } from '../../../core/Interface/elements/inputInterface';
import InputWrapper from './InputWrapper';

const TextInput: React.FC<TextInputProps> = ({ label, value, onChange, error, name, placeholder, disabled = false, required = false }) => {
  return (
    <InputWrapper label={label} required={required} error={error}>
      <InputText
        value={value}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        className="w-100 form-control"
      />
    </InputWrapper>
  );
};

export default TextInput;