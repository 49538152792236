import React, { useState } from 'react';
import PauseModal from '../../../common/modals/PauseModal';
import ErrorModal from '../../../../components/modal/ErrorModal';
import SuccessModal from '../../../../components/modal/SuccessModal';
import { ValidationRule } from '../../../../../core/Interface/validationsInterfaces';
import { SidebarClassesProps } from '../../../../../core/Interface/frontend/sidebarCustomerInterface';
import { handleFormSubmit } from './SidebarClassesHandlers';
import { handleInputChange, handleRangeCalendarChange } from '../../../../../core/form/handleInputChange';
import API_ENDPOINTS from '../../../../../core/constant/API_ENDPOINTS';
import useToggle from '../../../../../core/hooks/services/useToggle';
import usePost from '../../../../../core/hooks/api/usePost';
import ClassList from './ClassList';
import './style/SidebarClassesHover.css';
import './style/SidebarClasses.css';
import { all_routes } from '../../../../../core/data/routes/all_routes';
import { useNavigate } from 'react-router-dom';

const SidebarClasses: React.FC<SidebarClassesProps> = ({ filteredClasses }) => {
  const { isToggled: isCollapsed, toggle: toggleCollapse } = useToggle(true);
  const [errors, setErrors] = useState<any>({});
  const [formData, setFormData] = useState<any>({});
  const [selectedClass, setSelectedClass] = useState<any>(null);
  const [messagePlay, setMessagePlay] = useState<any>(null);

  const [showPauseModal, setShowPauseModal] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const { post, loading } = usePost(API_ENDPOINTS.freezeRequests);
  const navigate = useNavigate();

  const validationRules: ValidationRule[] = [
    { field: 'dateRange', methods: ['minCurrentDateRange', 'maxEndDateRange'] },
    { field: 'reason', methods: ['required'] }
  ];

  const handleFormSubmitWrapper = async (e: React.FormEvent<HTMLFormElement>) => {
    await handleFormSubmit(e, formData, validationRules, selectedClass, post, setErrors, setShowPauseModal, setShowSuccessModal, setShowErrorModal);
  };

  const handleRetry = () => {
    setShowErrorModal(false);
    navigate(all_routes.customerFreezeRequests);
  };

  const handleClosePauseModal = () => {
    setShowPauseModal(false);
    setSelectedClass(null);
    setErrors({});
    setFormData({});
  };

  const handleSuccessPauseModal = () => {
    setShowSuccessModal(false)
    setSelectedClass(null);
    setErrors({});
    setFormData({});
  };

  const handlePauseClick = (item: any) => {
    setSelectedClass(item);
    setShowPauseModal(true);
  };

  const handlePlayClick = () => {
    setMessagePlay('El congelamiento de tus clases ya se aprobo.');
    setShowSuccessModal(true);
  };

  return (
    <div className="card-body p-0 mt-1 mb-1">
      <ClassList
        filteredClasses={filteredClasses}
        isCollapsed={isCollapsed}
        toggleCollapse={toggleCollapse}
        handlePauseClick={(item: any) => handlePauseClick(item)}
        handlePlayClick={handlePlayClick} // Pass the new function
      />
      <PauseModal
        formData={formData}
        errors={errors}
        handleInputChange={(e) => handleInputChange(e, setFormData)}
        handleRangeCalendarChange={(name, value) => handleRangeCalendarChange(name, value, setFormData)}
        show={showPauseModal}
        handleClose={handleClosePauseModal}
        selectedClass={selectedClass}
        handleSubmit={handleFormSubmitWrapper}
        loading={loading}
      />
      <SuccessModal
        show={showSuccessModal}
        onClose={handleSuccessPauseModal}
        onConfirm={handleSuccessPauseModal}
        text={messagePlay || "La solicitud se ha enviado con éxito."}
        centered
      />
      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        onRetry={handleRetry}
        text={errors.submit}
        centered
      />
    </div>
  );
};

SidebarClasses.displayName = 'SidebarClasses';

export default SidebarClasses;