import React from 'react';
import BreadCrumb from '../../common/breadcrumb/breadCrumb';
import useFetch from '../../../../core/hooks/api/useFetch';
import API_ENDPOINTS from '../../../../core/constant/API_ENDPOINTS';
import { filterByStatus } from '../../../../utils/dataUtils';

const Faq = () => {
  const { data, loading } = useFetch(API_ENDPOINTS.faqs);

  if (loading) {
    return (
      <div className="loading-screen" style={{ height: '100vh' }}>
      </div>
    );
  }

  const faqs = filterByStatus(data, true);

  return (
    <>
      <BreadCrumb title='Preguntas Frecuentes' item1='Home' item2='Preguntas Frecuentes'/>
      <div className="page-wrapper my-5">
        <div className="content">
          <div className="container">
            <div className="row faq-content justify-content-center">
              <div className="col-md-10 mx-auto faq">
                <div className="accordion">
                  {faqs.map((faq: any, index: number) => {
                    const collapseId = `panelsStayOpen-collapse${index + 1}`;
                    return (
                      <div className="accordion-item" key={index + 1}>
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${collapseId}`}
                            aria-expanded="false"
                            aria-controls={collapseId}
                          >
                            {faq.title}
                          </button>
                        </h2>
                        <div
                          id={collapseId}
                          className={`accordion-collapse collapse ${index+1 === 0 ? 'show' : ''}`}
                        >
                          <div className="accordion-body">
                            <div dangerouslySetInnerHTML={{ __html: faq.description }} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;