import { api } from "../core/context/ApiProvider";
import { AxiosResponse } from "axios";
import * as RepoInterfaces from "../core/Interface/repositoryInterfaces"

const endpoints = {
  faqs: "faqs",
  coupons: "coupons",
  pages: "pages",
  packageTypes: "package-types",
  package: "packages",
  classes: "classes",
  users: "users",
  banners: "banners",
  auth: "auth",
  schedules: "schedules",
  complaint: "complaints",
  reservation: "class-lists",
  headquarters: "headquarters",
  payments: "payments",
  paymentDays: "payment-days",
  promotionSection: "home-sections",
  enterpriseInformation: "enterprises",
  subscriptions: "subscriptions",
  secretKeys: "secret-keys",
  giftcard: 'giftcards',
  campaigns: 'campaigns',
  locations: 'locations',
  reports: 'reports',
  subTypeClasses: 'class-types',
};

export const apiRepository = {

  getFaqs: (): Promise<AxiosResponse<RepoInterfaces.Faq[]>> => api.get(endpoints.faqs),
  createFaq: (data: RepoInterfaces.Faq): Promise<AxiosResponse<RepoInterfaces.Faq>> => api.post(endpoints.faqs, data),
  updateFaq: (id: number, data: RepoInterfaces.Faq): Promise<AxiosResponse<RepoInterfaces.Faq>> => api.put(`${endpoints.faqs}/${id}`, data),
  deleteFaq: (id: number): Promise<AxiosResponse<void>> => api.delete(`${endpoints.faqs}/${id}`),

  getCoupons: (page: number, size: number): Promise<AxiosResponse<RepoInterfaces.Coupon[]>> =>
    api.get(endpoints.coupons, {
      params: { page, size }
    }),

  validateCouponCode: (code: string): Promise<AxiosResponse<{ valid: boolean; message: string }>> =>
    api.get(`${endpoints.coupons}/code/${code}`),
  createCoupon: (data: RepoInterfaces.Coupon): Promise<AxiosResponse<RepoInterfaces.Coupon>> => api.post(endpoints.coupons, data),
  updateCoupon: (id: number, data: RepoInterfaces.Coupon): Promise<AxiosResponse<RepoInterfaces.Coupon>> => api.put(`${endpoints.coupons}/${id}`, data),
  deleteCoupon: (id: number): Promise<AxiosResponse<void>> => api.delete(`${endpoints.coupons}/${id}`),

  getPage: (id: number): Promise<AxiosResponse<RepoInterfaces.Pages>> => api.get(`${endpoints.pages}/${id}`),
  updatePage: (id: number, data: RepoInterfaces.Pages): Promise<AxiosResponse<RepoInterfaces.Pages>> => api.put(`${endpoints.pages}/${id}`, data),

  getPackageTypes: (): Promise<AxiosResponse<RepoInterfaces.packageTypes[]>> => api.get(endpoints.packageTypes),
  getIdPackageType: (id: number): Promise<AxiosResponse<RepoInterfaces.packageTypes[]>> => api.get(`${endpoints.packageTypes}/${id}`),
  createPackageTypes: (data: RepoInterfaces.packageTypes): Promise<AxiosResponse<RepoInterfaces.packageTypes>> => api.post(`${endpoints.packageTypes}`, data),
  updatePackageTypes: (id: number, data: RepoInterfaces.packageTypes): Promise<AxiosResponse<RepoInterfaces.packageTypes>> => api.put(`${endpoints.packageTypes}/${id}`, data),
  deletePackageTypes: (id: number): Promise<AxiosResponse<void>> => api.delete(`${endpoints.packageTypes}/${id}`),

  getCampaignss: (): Promise<AxiosResponse<RepoInterfaces.Email[]>> => api.get(endpoints.campaigns),
  getIdCampaigns: (id: number): Promise<AxiosResponse<RepoInterfaces.Email[]>> => api.get(`${endpoints.campaigns}/${id}`),
  createCampaignss: (data: RepoInterfaces.Email): Promise<AxiosResponse<RepoInterfaces.Email>> => api.post(`${endpoints.campaigns}`, data),
  updateCampaignss: (id: number, data: RepoInterfaces.Email): Promise<AxiosResponse<RepoInterfaces.Email>> => api.put(`${endpoints.campaigns}/${id}`, data),
  deleteCampaignss: (id: number): Promise<AxiosResponse<void>> => api.delete(`${endpoints.campaigns}/${id}`),


  getPackage: (page: number, size: number): Promise<AxiosResponse<RepoInterfaces.Package[]>> =>
    api.get(endpoints.package, {
      params: { page, size }
    }),
  getIdPackage: (id: string): Promise<AxiosResponse<RepoInterfaces.Users[]>> => api.get(`${endpoints.package}/${id}`),
  getIdPackageList: (): Promise<AxiosResponse<RepoInterfaces.Users[]>> => api.get(`${endpoints.package}/active`),
  createPackage: (data: RepoInterfaces.Package): Promise<AxiosResponse<RepoInterfaces.Package>> => api.post(`${endpoints.package}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }),
  updatePackage: (id: number, data: RepoInterfaces.Package): Promise<AxiosResponse<RepoInterfaces.Package>> => api.put(`${endpoints.package}/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),
  deletePackage: (id: number): Promise<AxiosResponse<void>> => api.delete(`${endpoints.package}/${id}`),

  getGiftCards: (page: number, size: number): Promise<AxiosResponse<RepoInterfaces.GiftCard[]>> =>
    api.get(endpoints.giftcard, {
      params: { page, size }
    }),
  getGiftCardById: (id: string): Promise<AxiosResponse<RepoInterfaces.GiftCard>> =>
    api.get(`${endpoints.giftcard}/${id}`),

  createGiftCard: (data: RepoInterfaces.GiftCard): Promise<AxiosResponse<RepoInterfaces.GiftCard>> => api.post(endpoints.giftcard, data),
  redeemGiftCard: (data: RepoInterfaces.RedeemGiftCard): Promise<AxiosResponse<RepoInterfaces.RedeemGiftCard>> => api.post(`${endpoints.giftcard}/redeem`, data),
  updateGiftCard: (id: number, data: RepoInterfaces.GiftCard): Promise<AxiosResponse<RepoInterfaces.GiftCard>> => api.put(`${endpoints.giftcard}/${id}`, data),

  getClasses: (): Promise<AxiosResponse<RepoInterfaces.Classes[]>> => api.get(endpoints.classes),
  createClasses: (data: FormData): Promise<AxiosResponse<RepoInterfaces.Classes>> => api.post(`${endpoints.classes}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),
  updateClasses: (id: number, data: FormData): Promise<AxiosResponse<RepoInterfaces.Classes>> => api.put(`${endpoints.classes}/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),
  deleteClasses: (id: number): Promise<AxiosResponse<void>> => api.delete(`${endpoints.classes}/${id}`),
  getUsers: (
    page: number,
    size: number,
    filters?: { email?: string; fullName?: string; profile?: string, active: boolean | any, birthdate: Date | any }
  ): Promise<AxiosResponse<RepoInterfaces.Users[]>> => {
    return api.get(endpoints.users, {
      params: { page, size, ...filters },
    });
  },

  getSubTypeClasses: (page: number, size: number): Promise<AxiosResponse<RepoInterfaces.SubTypeClasses[]>> => 
    api.get(endpoints.subTypeClasses, {
      params: { page, size }
  }),
  getSubTypeByClasses: (classId: number): Promise<AxiosResponse<RepoInterfaces.SubTypeClasses[]>> => api.get(`${endpoints.subTypeClasses}/by-class/${classId}`),
  createSubTypeClasses: (data: RepoInterfaces.SubTypeClasses): Promise<AxiosResponse<RepoInterfaces.SubTypeClasses>> => api.post(`${endpoints.subTypeClasses}`, data),
  updateSubTypeClasses: (id: number, data: RepoInterfaces.SubTypeClasses): Promise<AxiosResponse<RepoInterfaces.SubTypeClasses>> => api.put(`${endpoints.subTypeClasses}/${id}`, data),

  getIdUsers: (id: number): Promise<AxiosResponse<RepoInterfaces.Users[]>> => api.get(`${endpoints.users}/${id}`),
  createUser: (data: RepoInterfaces.Users): Promise<AxiosResponse<RepoInterfaces.Users>> => api.post(`${endpoints.users}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }),
  updateUser: (id: number, data: RepoInterfaces.Users): Promise<AxiosResponse<RepoInterfaces.Users>> => api.put(`${endpoints.users}/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }),
  deleteUser: (id: number): Promise<AxiosResponse<void>> => api.delete(`${endpoints.users}/${id}`),

  activateUser: (userId: number, activatedByUserId: number): Promise<AxiosResponse<void>> =>
    api.post(`${endpoints.users}/activate-class-free/${userId}`, null, {
      params: { activatedByUserId }
    }),

  getLocations: (): Promise<AxiosResponse<RepoInterfaces.Locations[]>> => api.get(endpoints.locations),


  getBanners: (): Promise<AxiosResponse<RepoInterfaces.Banners[]>> => api.get(endpoints.banners),
  createBanner: (data: RepoInterfaces.Banners): Promise<AxiosResponse<RepoInterfaces.Banners>> => api.post(`${endpoints.banners}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),
  updateBanner: (id: number, data: RepoInterfaces.Banners): Promise<AxiosResponse<RepoInterfaces.Banners>> => api.put(`${endpoints.banners}/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),
  deleteBanner: (id: number): Promise<AxiosResponse<void>> => api.delete(`${endpoints.banners}/${id}`),

  authUser: (data: RepoInterfaces.Auth): Promise<AxiosResponse<RepoInterfaces.Auth>> => api.post(`${endpoints.auth}/login`, data),
  forgotPassword: (data: RepoInterfaces.ForgotPassword): Promise<AxiosResponse<RepoInterfaces.ForgotPassword>> => api.post(`${endpoints.auth}/recover-password`, data),
  forgotValidateCode: (data: RepoInterfaces.PasswordValidateCode): Promise<AxiosResponse<RepoInterfaces.PasswordValidateCode>> => api.post(`${endpoints.auth}/validate-recovery-code`, data),
  forgotUpdatePassword: (data: RepoInterfaces.ForgotUpdatePassword): Promise<AxiosResponse<RepoInterfaces.ForgotUpdatePassword>> => api.post(`${endpoints.auth}/update-password`, data),

  getSchedules: (
    page: number,
    size: number,
    startDate?: string,
    endDate?: string,
    classTypeId?: number | string,
    trainerId?: number | string
  ): Promise<AxiosResponse<RepoInterfaces.Schedules[]>> =>
    api.get(endpoints.schedules, {
      params: {
        page,
        size,
        startDate,
        endDate,
        classTypeId,
        trainerId
      }
    }),
  getIdSchedules: (id: number): Promise<AxiosResponse<RepoInterfaces.Schedules[]>> => api.get(`${endpoints.schedules}/${id}`),
  getdateSchedules: (
      startDate: string,
      endDate: string,
      status: number
    ): Promise<AxiosResponse<RepoInterfaces.Schedules[]>> =>
      api.get(`${endpoints.schedules}/filter`, {
        params: {
          startDate,
          endDate,
          status
        }
      }),
  createSchedule: (data: RepoInterfaces.Schedules): Promise<AxiosResponse<RepoInterfaces.Schedules>> => api.post(`${endpoints.schedules}`, data),
  updateSchedule: (id: number, data: RepoInterfaces.Schedules): Promise<AxiosResponse<RepoInterfaces.Schedules>> => api.put(`${endpoints.schedules}/${id}`, data),
  deleteSchedule: (id: number): Promise<AxiosResponse<void>> => api.delete(`${endpoints.schedules}/${id}`),

  getTrainerSchedules: (
    trainerId: number,
    startDate?: string,
    endDate?: string
  ): Promise<AxiosResponse<RepoInterfaces.Schedules[]>> =>
    api.get(`${endpoints.schedules}/trainer/${trainerId}`, {
      params: { startDate, endDate },
    }),

  getUserSchedules: (
    userId: number,
    startDate?: string,
    endDate?: string
  ): Promise<AxiosResponse<RepoInterfaces.Schedules[]>> =>
    api.get(`${endpoints.schedules}/customer/${userId}`, {
      params: { startDate, endDate },
    }),
  getHeadquarters: (): Promise<AxiosResponse<RepoInterfaces.Headquarters[]>> => api.get(endpoints.headquarters),
  getIdHeadquarters: (id: string): Promise<AxiosResponse<RepoInterfaces.Headquarters[]>> => api.get(`${endpoints.headquarters}/${id}`),
  createHeadquarters: (data: RepoInterfaces.Headquarters): Promise<AxiosResponse<RepoInterfaces.Headquarters>> => api.post(`${endpoints.headquarters}`, data),
  updateHeadquarters: (id: string, data: RepoInterfaces.Headquarters): Promise<AxiosResponse<RepoInterfaces.Headquarters>> => api.put(`${endpoints.headquarters}/${id}`, data),
  deleteHeadquarters: (id: string): Promise<AxiosResponse<void>> => api.delete(`${endpoints.headquarters}/${id}`),

  getReservation: (id: number): Promise<AxiosResponse<RepoInterfaces.Reservation[]>> => api.get(`${endpoints.reservation}/schedule/${id}`),
  getIdReservation: (id: number): Promise<AxiosResponse<RepoInterfaces.Reservation[]>> => api.get(`${endpoints.reservation}/${id}`),
  createReservation: (data: RepoInterfaces.Reservation): Promise<AxiosResponse<RepoInterfaces.Reservation>> => api.post(`${endpoints.reservation}`, data),
  assistanceReservation: (data: RepoInterfaces.Reservation): Promise<AxiosResponse<RepoInterfaces.Reservation>> => api.post(`${endpoints.reservation}/update-attended`, data),
  deleteReservation: (id: string, reason?: string): Promise<AxiosResponse<void>> => 
    api.delete(`${endpoints.reservation}/${id}`, {
      params: reason ? { reason } : {}
    }),

  getComplaints: (): Promise<AxiosResponse<RepoInterfaces.Complaint[]>> => api.get(endpoints.complaint),
  createComplaint: (data: RepoInterfaces.Complaint): Promise<AxiosResponse<RepoInterfaces.Complaint>> => api.post(`${endpoints.complaint}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),

  validatePackages: (data: RepoInterfaces.Payments): Promise<AxiosResponse<RepoInterfaces.Payments>> => api.post(`${endpoints.payments}/validate`, data),
  createPayments: (data: RepoInterfaces.Payments): Promise<AxiosResponse<RepoInterfaces.Payments>> => api.post(endpoints.payments, data),
  getPayments: (startDate: string, endDate: string, page: number, size: number): Promise<AxiosResponse<RepoInterfaces.Payments[]>> =>
    api.get(endpoints.payments, {
      params: { startDate, endDate, page, size }
  }),
  getPaymentsId: (customerId: number, startDate: string, endDate: string, page: number, size: number): Promise<AxiosResponse<RepoInterfaces.Payments[]>> =>
    api.get(endpoints.payments, {
      params: { customerId, startDate, endDate, page, size }
  }),

  getPaymentDays: (page: number, size: number, userId?: string, fullName?: string): Promise<AxiosResponse<RepoInterfaces.PaymentDays[]>> => 
    api.get(endpoints.paymentDays, {
      params: {
        page,
        size,
        userId,
        fullName
    }
  }),
  getPaymentDayById: (id: number): Promise<AxiosResponse<RepoInterfaces.PaymentDays>> => api.get(`${endpoints.paymentDays}/${id}`),
  getPaymentDayByUserId: (userId: number): Promise<AxiosResponse<RepoInterfaces.PaymentDays>> =>
    api.get(`${endpoints.paymentDays}`, { params: { userId } }),
  createPaymentDay: (data: RepoInterfaces.PaymentDays): Promise<AxiosResponse<RepoInterfaces.PaymentDays>> => api.post(endpoints.paymentDays, data),
  updatePaymentDay: (id: number, data: RepoInterfaces.PaymentDays): Promise<AxiosResponse<RepoInterfaces.PaymentDays>> => api.put(`${endpoints.paymentDays}/${id}`, data),

  getPromotionSection: (): Promise<AxiosResponse<RepoInterfaces.PromotionSection>> => api.get(`${endpoints.promotionSection}/1`),
  updatePromotionSection: (id:number, data: RepoInterfaces.PromotionSection): Promise<AxiosResponse<RepoInterfaces.PromotionSection>> => api.put(`${endpoints.promotionSection}/${id}`, data,  {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),

  getEnterpriseInformation: (): Promise<AxiosResponse<RepoInterfaces.EnterpriseInformation>> => api.get(`${endpoints.enterpriseInformation}/1`),
  updateEnterpriseInformation: (id:number, data: RepoInterfaces.EnterpriseInformation): Promise<AxiosResponse<RepoInterfaces.EnterpriseInformation>> => api.put(`${endpoints.enterpriseInformation}/${id}`, data,  {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),

  getSubscriptions: (
    page: number,
    size: number,
    fullName?: string,
    email?: string
): Promise<AxiosResponse<RepoInterfaces.Subscriptions[]>> =>
    api.get(endpoints.subscriptions, {
        params: {
            page,
            size,
            fullName,
            email
        },
    }),

  getIdSubscriptions: (id: number): Promise<AxiosResponse<RepoInterfaces.Subscriptions[]>> => api.get(`${endpoints.subscriptions}/${id}`),
  createSubscriptions: (data: RepoInterfaces.Subscriptions): Promise<AxiosResponse<RepoInterfaces.Subscriptions>> => api.post(`${endpoints.subscriptions}`, data),
  updateSubscriptions: (id: number, data: RepoInterfaces.Subscriptions): Promise<AxiosResponse<RepoInterfaces.Subscriptions>> => api.put(`${endpoints.subscriptions}/${id}`, data),
  deleteSubscriptions: (id: number): Promise<AxiosResponse<void>> => api.delete(`${endpoints.subscriptions}/${id}`),

  getSecretKeyInformation: (): Promise<AxiosResponse<RepoInterfaces.SecretKeys[]>> => api.get(`${endpoints.secretKeys}/1`),
  updateSecretKeyInformation: (id: number, data: RepoInterfaces.SecretKeys): Promise<AxiosResponse<RepoInterfaces.SecretKeys>> => api.put(`${endpoints.secretKeys}/${id}`, data),

  getClassCapacityReport: (
    startDate: string,
    endDate: string,
    classId?: string,
    trainerId?: string,
    hourStart?: string,
    hourEnd?: string
  ): Promise<AxiosResponse<RepoInterfaces.ClassCapacityReport[]>> =>
    api.get(`${endpoints.reports}/class-capacity`, {
      params: {
        startDate,
        endDate,
        classId,
        trainerId,
        hourStart,
        hourEnd
      }
    }),

  getPurchaseReport: (
    startDate: string,
    endDate: string
  ): Promise<AxiosResponse<any>> => {
    return api.get(`${endpoints.reports}/purchase`, {
      params: {
        startDate,
        endDate
      }
    });
  },

  getCustomerPreferenceReport: (
    startDate: string,
    endDate: string,
    classId?: string,
    trainerId?: string,
    minAge?: string | number,
    maxAge?: string | number
  ): Promise<AxiosResponse<any>> => {
    return api.get(`${endpoints.reports}/customer-preference`, {
      params: {
        startDate,
        endDate,
        classId,
        trainerId,
        minAge,
        maxAge
      }
    });
  },

  getCancellationRateReport: (
    startDate: string,
    endDate: string
  ): Promise<AxiosResponse<any>> => {
    return api.get(`${endpoints.reports}/cancellation-rate`, {
      params: {
        startDate,
        endDate
      }
    });
  },

  getClassProfitabilityReport: (
    startDate: string,
    endDate: string,
    customerId?: string,
    minAge?: string,
    maxAge?: string
  ): Promise<AxiosResponse<any>> => {
    return api.get(`${endpoints.reports}/membership-class-reservation`, {
      params: {
        startDate,
        endDate,
        customerId,
        minAge,
        maxAge
      }
    });
  },

  getClassFreePurchaseReport: (
    startDate: string,
    endDate: string
  ): Promise<AxiosResponse<any>> => {
    return api.get(`${endpoints.reports}/class-free-purchase`, {
      params: {
        startDate,
        endDate
      }
    });
  },

  getClassFreePurchaseReportExcelFile: (
    startDate: string,
    endDate: string
  ): Promise<AxiosResponse<Blob>> => {
    return api.get(`${endpoints.reports}/class-free-purchase/excel`, {
      params: {
        startDate,
        endDate
      },
      responseType: 'blob',
    });
  },

  getClassCapacityReportExelFile: (
    startDate: string,
    endDate: string,
    classId?: string,
    trainerId?: string,
    hourStart?: string,
    hourEnd?: string
  ): Promise<AxiosResponse<Blob>> =>
    api.get(`${endpoints.reports}/class-capacity/excel`, {
      params: {
        startDate,
        endDate,
        classId,
        trainerId,
        hourStart,
        hourEnd
      },
      responseType: 'blob',
    }),

  getPurchaseReportExcelFile: (
    startDate: string,
    endDate: string,
    fullName?: string,
    email?: string
  ): Promise<AxiosResponse<Blob>> => {
    return api.get(`${endpoints.reports}/purchase/excel`, {
      params: {
        startDate,
        endDate,
        ...(fullName ? { fullName } : {}),
        ...(email ? { email } : {})
      },
      responseType: 'blob',
    });
  },

  getCustomerPreferenceReportExcelFile: (
    startDate: string,
    endDate: string,
    classId?: string,
    trainerId?: string,
    minAge?: string | number,
    maxAge?: string | number
  ): Promise<AxiosResponse<Blob>> => {
    return api.get(`${endpoints.reports}/customer-preference/excel`, {
      params: {
        startDate,
        endDate,
        classId,
        trainerId,
        minAge,
        maxAge
      },
      responseType: 'blob',
    });
  },

  getCancellationRateReportExcelFile: (
    startDate: string,
    endDate: string
  ): Promise<AxiosResponse<Blob>> => {
    return api.get(`${endpoints.reports}/cancellation-rate/excel`, {
      params: {
        startDate,
        endDate
      },
      responseType: 'blob',
    });
  },

  getClassProfitabilityReportExcelFile: (
    startDate: string,
    endDate: string,
    customerId?: string,
    minAge?: string,
    maxAge?: string
  ): Promise<AxiosResponse<Blob>> => {
    return api.get(`${endpoints.reports}/membership-class-reservation/excel`, {
      params: {
        startDate,
        endDate,
        customerId,
        minAge,
        maxAge
      },
      responseType: 'blob',
    });
  },
};