import { CustomFormData } from '../Interface/validationsInterfaces';

export const validationMethods = {
  required: (value: any, formData: CustomFormData) => value !== undefined && value !== null && value !== '',
  dateRange: (value: any, formData: CustomFormData) => value && value[0] && value[1],
  minDateRange: (value: any, formData: CustomFormData) => {
    if (value && value[0] && value[1]) {
      const diffTime = Math.abs(value[1].getTime() - value[0].getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays >= 4;
    }
    return false;
  },
  minCurrentDateRange: (value: any, formData: CustomFormData) => {
    if (value && value[0] && value[1]) {
      const currentDate = new Date();
      return value[0] >= currentDate && value[1] >= currentDate;
    }
    return false;
  },
  maxEndDateRange: (value: any, formData: CustomFormData, endDate: Date) => {
    if (value && value[0] && value[1]) {
      return value[1] <= endDate;
    }
    return false;
  },
  username: (value: any, formData: CustomFormData) => /^[a-zA-Z0-9_]+$/.test(value),
  imageFormat: (value: any, formData: CustomFormData) => {
    const allowedFormats = ['image/jpeg', 'image/png', 'image/gif'];
    return (typeof value === 'string' || (value && allowedFormats.includes(value.type)));
  }
};