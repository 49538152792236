import { useState, useCallback } from 'react';

const useToggle = (initialState = false) => {
  const [isToggled, setIsToggled] = useState(initialState);

  const toggle = useCallback(() => {
    setIsToggled((prev) => !prev);
  }, []);

  return { isToggled, toggle, setIsToggled };
};

export default useToggle;