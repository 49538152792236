import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { InputField, CalendarField, DropdownField } from "../../../admin/common/input/inputField";
import { useRegister } from "../../../../core/hooks/auth/useRegister";
import { TYPE_IDENTIFIER } from '../../../../core/data/options/options';
import { getErrorForField } from "../../../../core/utils/utils";
import { RegisterModalProps } from "../../../../core/Interface/modalInterface";
import { convertStringToLocalDate, getCurrentLocalDate } from "../../../../core/utils/dateUtils";
import { useUbigeo } from "../../../../core/hooks/getData/useUbigeo";

const RegisterModal: React.FC<RegisterModalProps> = ({
  modalId = "register-modal",
  title = "Registro",
  subtitle = "Ingresa tus datos para acceder a tu cuenta",
  routes,
  showClassFreeCheckbox = true,
  showTermsCheckbox = true,
  authLoginContext
}) => {
  const { formData, handleChange, resetForm, registerUser, loading, serverError } = useRegister(authLoginContext, modalId);
  const { provinces, districts, formattedUbigeoData } = useUbigeo({ formData, handleChange });
  const [errors, setErrors] = useState(serverError);

  const removeSpaces = (value: string) => value.replace(/\s/g, '');

  const handleChangeWithNoSpaces = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'email' || name === 'numberDocument') {
      handleChange({
        target: {
          name,
          value: removeSpaces(value)
        }
      });
    } else {
      handleChange(e);
    }
  };

  useEffect(() => {
    setErrors(serverError);
  }, [serverError]);

  const handleChangeWithValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setErrors(errors.filter(error => error.field !== e.target.name));
  };

  const handleCheckboxChangeWithValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setErrors(errors.filter(error => error.field !== e.target.name));
  };

  const handleDropdownChangeWithValidation = (e: any, name: string) => {
    handleChange({
      target: {
        name,
        value: e.value
      }
    } as React.ChangeEvent<HTMLInputElement>);
    setErrors(errors.filter(error => error.field !== name));
  };

  const handleCalendarChangeWithValidation = (e: any, name: string) => {
    handleChange({
      target: {
        name,
        value: e.value.toISOString().split('T')[0]
      }
    } as React.ChangeEvent<HTMLInputElement>);
    setErrors(errors.filter(error => error.field !== name));
  };

  useEffect(() => {
    if (!formData?.birthdate || isNaN(new Date(formData.birthdate).getTime())) {
      handleChange({
        target: {
          name: 'birthdate',
          value: getCurrentLocalDate()
        }
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [formData.birthdate, handleChange]);

  useEffect(() => {
    const modalElement = document.getElementById(modalId);

    const handleModalClose = () => {
      resetForm();
    };

    if (modalElement) {
      modalElement.addEventListener("hide.bs.modal", handleModalClose);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener("hide.bs.modal", handleModalClose);
      }
    };
  }, [modalId, resetForm]);

  return (
    <div className="modal fade" id={modalId} tabIndex={-1} data-bs-backdrop="static" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <ModalHeader />
          <div className="modal-body p-4 pt-0">
            <form onSubmit={registerUser}>
              <ModalTitle title={title} subtitle={subtitle} />
              <div className="row">
                <InputFields formData={formData} handleChange={handleChangeWithValidation} serverError={errors} />
                <CalendarField
                  label="Fecha de nacimiento"
                  value={formData?.birthdate ? convertStringToLocalDate(formData.birthdate) : getCurrentLocalDate()}
                  onChange={(e: any) => handleCalendarChangeWithValidation(e, 'birthdate')}
                  error={getErrorForField("birthdate", errors || [])}
                />
                <DocumentFields formData={formData} handleChange={handleChangeWithValidation} serverError={errors} />
                <AddressFields formData={formData} handleChange={handleChangeWithValidation} serverError={errors} formattedUbigeoData={formattedUbigeoData} provinces={provinces} districts={districts} />
                <PasswordFields formData={formData} handleChange={handleChangeWithValidation} serverError={errors} />
              </div>
              {showClassFreeCheckbox && <ClassFreeCheckbox formData={formData} handleChange={handleCheckboxChangeWithValidation} />}
              {showTermsCheckbox && <TermsCheckbox formData={formData} handleChange={handleCheckboxChangeWithValidation} routes={routes} serverError={errors} />}
              {serverError?.find((error) => error.field === "server") && (
                <div className="mb-3">
                  <small className="text-danger">
                    {serverError.find((error) => error.field === "server")?.message}
                  </small>
                </div>
              )}
              <SubmitButton loading={loading} />
              <LoginLink />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalHeader: React.FC = () => (
  <div className="modal-header d-flex align-items-center justify-content-end pb-0 border-0">
    <Link to="#" data-bs-dismiss="modal" aria-label="Close">
      <i className="ti ti-circle-x-filled fs-20" />
    </Link>
  </div>
);

const ModalTitle: React.FC<{ title: string, subtitle: string }> = ({ title, subtitle }) => (
  <div className="text-center mb-3">
    <h3 className="mb-2">{title}</h3>
    <p>{subtitle}</p>
  </div>
);

const InputFields: React.FC<{ formData: any, handleChange: any, serverError: any }> = ({ formData, handleChange, serverError }) => (
  <>
    <div className="col-md-6">
      <InputField
        label="Nombre"
        name="name"
        value={formData?.name || ""}
        onChange={handleChange}
        placeholder="Juan"
        error={getErrorForField("name", serverError || [])}
      />
    </div>
    <div className="col-md-6">
      <InputField
        label="Apellido"
        name="lastname"
        value={formData?.lastname || ""}
        onChange={handleChange}
        placeholder="Pérez"
        error={getErrorForField("lastname", serverError || [])}
      />
    </div>
    <div className="col-md-6">
      <InputField
        label="Correo Electrónico"
        name="email"
        value={formData?.email || ""}
        onChange={handleChange}
        placeholder="juan.perez@email.com"
        error={getErrorForField("email", serverError || [])}
      />
    </div>
    <div className="col-md-6">
      <InputField
        label="Número de teléfono"
        name="phone"
        value={formData?.phone || ""}
        onChange={handleChange}
        placeholder="987 654 321"
        error={getErrorForField("phone", serverError || [])}
      />
    </div>
  </>
);

const DocumentFields: React.FC<{ formData: any, handleChange: any, serverError: any }> = ({ formData, handleChange, serverError }) => (
  <div className="row">
    <div className="col-md-3 pe-0">
      <DropdownField
        label="Tipo"
        name="typeDocument"
        options={TYPE_IDENTIFIER}
        value={formData?.typeDocument}
        onChange={(e: any) => handleChange(e, 'typeDocument')}
        error={getErrorForField("typeDocument", serverError || [])}
      />
    </div>
    <div className="col-md-9">
      <InputField
        label="Numero de Identidad"
        name="numberDocument"
        placeholder="82143841"
        value={formData?.numberDocument || ''}
        onChange={handleChange}
        error={getErrorForField("numberDocument", serverError || [])}
      />
    </div>
  </div>
);

const AddressFields: React.FC<{ formData: any, handleChange: any, serverError: any, formattedUbigeoData: any, provinces: any, districts: any }> = ({ formData, handleChange, serverError, formattedUbigeoData, provinces, districts }) => (
  <>
    <InputField
      label="Condición Medica / Embarazo"
      required={false}
      name="conditionMedical"
      value={formData?.conditionMedical || ""}
      onChange={handleChange}
      placeholder="Tengo un embarazo de 10 semanas"
    />
    <div className="col-md-4">
      <DropdownField
        label="Departamento"
        name="department"
        placeholder="Seleccione un departamento"
        onChange={(e: any) => handleChange(e, 'department')}
        options={formattedUbigeoData || []}
        value={formData?.department}
        error={getErrorForField("department", serverError || [])}
      />
    </div>
    <div className="col-md-4">
      <DropdownField
        label="Provincia"
        name="province"
        placeholder="Seleccione una provincia"
        onChange={(e: any) => handleChange(e, 'province')}
        options={provinces || []}
        value={formData?.province}
        disabled={!formData?.department}
        error={getErrorForField("province", serverError || [])}
      />
    </div>
    <div className="col-md-4">
      <DropdownField
        label="Distrito"
        name="district"
        placeholder="Seleccione un distrito"
        onChange={(e: any) => handleChange(e, 'district')}
        options={districts || []}
        value={formData?.district}
        disabled={!formData?.province}
        error={getErrorForField("district", serverError || [])}
      />
    </div>
    <InputField
      label="Dirección"
      name="address"
      value={formData?.address || ""}
      onChange={handleChange}
      placeholder="Av. Example 123"
      error={getErrorForField("address", serverError || [])}
    />
  </>
);

const PasswordFields: React.FC<{ formData: any, handleChange: any, serverError: any }> = ({ formData, handleChange, serverError }) => (
  <>
    <div className="col-md-6">
      <InputField
        type="password"
        name="password"
        label="Nueva Contraseña"
        value={formData?.password || ""}
        onChange={handleChange}
        error={getErrorForField("password", serverError || [])}
      />
    </div>
    <div className="col-md-6">
      <InputField
        type="password"
        name="newPassword"
        label="Confirmar Contraseña"
        value={formData?.newPassword || ""}
        onChange={handleChange}
        error={getErrorForField("newPassword", serverError || [])}
      />
    </div>
  </>
);

const ClassFreeCheckbox: React.FC<{ formData: any, handleChange: any }> = ({ formData, handleChange }) => (
  <div className="mb-3">
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        name="classFree"
        checked={formData?.classFree}
        onChange={handleChange}
      />
      <label className="form-check-label">
        Solicitar clase gratuita
      </label>
    </div>
  </div>
);

const TermsCheckbox: React.FC<{ formData: any, handleChange: any, routes: any, serverError: any }> = ({ formData, handleChange, routes, serverError }) => (
  <div className="mb-1">
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        name="isAccepted"
        checked={formData?.isAccepted}
        onChange={handleChange}
      />
      <label className="form-check-label">
        Acepto los {" "}
        <Link
          target="_blank"
          to={routes.termsCondition}
          className="link-primary text-decoration-underline"
        >
          Términos de uso
        </Link>{" "}
        y {" "}
        <Link
          target="_blank"
          to={routes.privacyPolicy}
          className="link-primary text-decoration-underline"
        >
          la Política de privacidad
        </Link>
      </label>
    </div>
    {serverError?.find((error) => error.field === "isAccepted") && (
      <small className="text-danger mb-3">
        {serverError?.find((error) => error.field === "isAccepted")?.message}
      </small>
    )}
  </div>
);

const SubmitButton: React.FC<{ loading: boolean }> = ({ loading }) => (
  <button type="submit" disabled={loading}  className="btn btn-lg btn-linear-primary w-100">
    {loading ? (
      <>
        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
        Registrando...
      </>
    ) : (
      'Registrarse'
    )}
  </button>
);

const LoginLink: React.FC = () => (
  <div className="d-flex justify-content-center mt-3">
    <p>
      ¿Ya tienes una cuenta?{" "}
      <Link
        to="#"
        className="link-primary"
        data-bs-target="#login-modal"
        data-bs-toggle="modal"
      >
        Iniciar sesión
      </Link>
    </p>
  </div>
);

export default RegisterModal;