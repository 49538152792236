import React from 'react';
import { Link } from 'react-router-dom';
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { useInitialFetch } from '../../../../core/context/InitialFetchContext';
import { isValidImage } from '../../../../utils/validationUtils';

interface NavbarLogoProps {
  imageUrl: string;
}

const NavbarLogo: React.FC<NavbarLogoProps> = ({ imageUrl }) => {
  const routes = all_routes;
  const { svgContent } = useInitialFetch();

  return (
    <>
      <Link to={routes.index} className="navbar-brand logo">
        {svgContent ? (
          <div
            className="img-fluid logo"
            dangerouslySetInnerHTML={{ __html: svgContent }}
          />
        ) : (
          isValidImage(imageUrl) && (
            <ImageWithBaseApi
              loadingIconFontSize="20px"
              src={imageUrl}
              routeName="enterprise"
              className="img-fluid logo"
              alt="Logo"
            />
          )
        )}
      </Link>
      <Link to={routes.index} className="navbar-brand logo-small">
        {svgContent ? (
          <div
            className="img-fluid logo"
            dangerouslySetInnerHTML={{ __html: svgContent }}
          />
        ) : (
          isValidImage(imageUrl) && (
            <ImageWithBaseApi
              loadingIconFontSize="20px"
              src={imageUrl}
              routeName="enterprise"
              className="img-fluid logo"
              alt="Logo"
            />
          )
        )}
      </Link>
    </>
  );
};

export default NavbarLogo;