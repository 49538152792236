import moment from 'moment-timezone';
import 'moment/locale/es';

export const convertDateToLocalDateString = (date: Date | string): string => {
  if (typeof date === 'string') {
    console.info('The provided date is already a string and does not require processing:', date);
    return date; // Return the string as is
  }
  return moment.tz(date, 'America/Lima').format('YYYY-MM-DD');
};

export const convertStringToLocalDate = (fecha: string): Date => {
  return moment.tz(fecha, 'YYYY-MM-DD', 'America/Lima').toDate();
};

export const formatDateToDayMonth = (dateString: string): string => {
  const date = moment.tz(dateString, 'America/Lima');
  return date.format('dddd, DD');
};

export const getDefaultWeekRange = () => {
  const today = moment.tz('America/Lima');
  const firstDayOfWeek = today.clone().startOf('isoWeek');
  const lastDayOfWeek = today.clone().endOf('isoWeek');

  return [firstDayOfWeek.toDate(), lastDayOfWeek.toDate()];
};

export const getWeekRangeFromToday = () => {
  const today = moment.tz('America/Lima');
  const endOfWeek = today.clone().add(6, 'days');

  return [today.toDate(), endOfWeek.toDate()];
};

export const getRangeFromToday = (quantityDays: number) => {
  const today = moment.tz('America/Lima');
  const endOfWeek = today.clone().add(quantityDays, 'days');

  return [today.toDate(), endOfWeek.toDate()];
};

export const getDefaultMonthRange = () => {
  const today = moment.tz('America/Lima');
  const firstDayOfMonth = today.clone().startOf('month');
  const lastDayOfMonth = today.clone().endOf('month');

  return [firstDayOfMonth.toDate(), lastDayOfMonth.toDate()];
};

export const getCurrentLocalDate = (): Date => {
  return moment.tz('America/Lima').toDate();
};

export const convertDateToLocalDate = (date: Date): Date => {
  return moment.tz(date, 'America/Lima').toDate();
};