import { useEffect, useCallback } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import { FetchClassesProps, FetchSchedulesProps, FetchSchedulesListProps } from '../../Interface/hooksInterface';

export const useFetchClasses = ({ scheduleId, setClasses, refresh }: FetchClassesProps & { refresh?: boolean }) => {
    const fetchClasses = useCallback(async () => {
        try {
            const response = await apiRepository.getReservation(scheduleId);
            setClasses(response.data.data);
        } catch (error) {
            console.error('Error al obtener los datos de la asistencia:', error);
        }
    }, [scheduleId, setClasses]);

    useEffect(() => {
        if (scheduleId) {
            fetchClasses();
        }
    }, [scheduleId, fetchClasses, refresh]);
};

export const useFetchSchedules = ({ scheduleId, setSchedules }: FetchSchedulesProps) => {
    useEffect(() => {
        const fetchSchedules = async () => {
            try {
                const response = await apiRepository.getIdSchedules(scheduleId);
                const schedules = response.data.data;
                setSchedules(schedules);
            } catch (error) {
                console.error('Error al obtener los datos de los horarios:', error);
            }
        };

        if (scheduleId) {
            fetchSchedules();
        }
    }, [scheduleId, setSchedules]);
};

export const useFetchSchedulesList = ({ user, setSchedulesClass }: FetchSchedulesListProps) => {
    useEffect(() => {
        const fetchSchedulesList = async () => {
            try {
                let response;

                if (user?.role === "trainer") {
                    response = await apiRepository.getTrainerSchedules(user?.userId);
                } else {
                    response = await apiRepository.getUserSchedules(user?.userId);
                }

                const schedulesClass = response.data.data.map(schedule => ({
                    value: schedule.id,
                    label: schedule.title || schedule.name,
                }));
                setSchedulesClass(schedulesClass);
            } catch (error) {
                console.error('Error al obtener los datos de usuarios:', error);
            }
        };

        fetchSchedulesList();
    }, [user, setSchedulesClass]);
};
