import { useState } from "react";
import Cookies from 'js-cookie';
import { handleError } from '../../../core/utils/errorUtils';
import { createPreference } from '../../../core/services/mercadoPagoService';
import { isUserLoggedIn, validateCartCombinations } from "../../utils/cartUtils";
import { apiRepository } from "../../../api/apiRepository";
import { openModal } from "../../utils/modalsUtils";
import { createPaymentInfo, preparePaymentDetails } from "../../utils/paymentUtils";
import { encryptAES } from "../../../core/utils/encryptUtils";


export const useConfirmPay = (user: any, cart: any, customerCart: any, couponDiscount: any, token: string) => {
  const [loadingPay, setLoadingPay] = useState<boolean>(false);
  const [info, setInfo] = useState<any[]>([]);

  const validateAndRedirect = async (paymentInfo: any, token: string) => {
    try {
      setInfo([]);

      // Encrypt and save the token in cookies
      const secretKey = "encryption-key";
      const encryptedToken = encryptAES(token, secretKey);
      Cookies.set('paymentToken', encryptedToken, {
        expires: 1,
        secure: false,
        sameSite: 'Lax',
      });

      await apiRepository.validatePackages(paymentInfo);

      const link = await createPreference(customerCart, couponDiscount, token, user);
      window.location.href = link;
    } catch (error) {
      handleError(error, setInfo, "error");
    } finally {
      setLoadingPay(false);
    }
  };

  const processPayment = async () => {
    const paymentDetails = preparePaymentDetails(customerCart.items);
    const paymentInfo = createPaymentInfo(user, paymentDetails, customerCart.codesCoupons);
    await validateAndRedirect(paymentInfo, token);
  };

  const confirmPay = async (): Promise<boolean> => {
    setInfo([]);
    const informationSucces: string[] = [];

    if (!isUserLoggedIn(user, informationSucces)) {
      openModal("login-modal");
      return false;
    }

    setLoadingPay(true);
    validateCartCombinations(cart, informationSucces);

    if (informationSucces.length > 0) {
      setInfo(informationSucces);
      setLoadingPay(false);
      return false;
    }

    // await processPayment();
    setLoadingPay(false);
    return true;
  };

  return { confirmPay, processPayment, loadingPay, info, setInfo };
};