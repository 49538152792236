import React, { useContext, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AuthContext } from '../../../../core/context/AuthContext';
import { CartContext } from '../../../../core/context/CartContext';
import { usePackages } from "../../../../core/hooks/getData/usePackages";
import PricingPlans from "../../common/pricingPlans/pricingPlans";
import PackageSlider from "../../common/packageSlider/packageSlider";
import SectionHeader from "../../common/sectionHeader/sectionHeader";
import ProductCarousel from "../../common/productCarousel/productCarousel";

/**
 * El componente PreferredSection obtiene y muestra paquetes según el contexto del usuario.
 * Categoriza los paquetes y los muestra en diferentes formatos como deslizadores o planes de precios.
 * @param {Object} props - Propiedades del componente.
 * @param {string} props.id - El ID de la sección para propósitos de desplazamiento.
 */
const PreferredSection = ({ id }: { id: string }) => {
  const authContext = useContext(AuthContext);
  const cartContext = useContext(CartContext);
  const { sortedCategories = [], productIds, packages } = usePackages(true);
  if (!authContext) {
    return <div>Error: ¡Contexto de autenticación no encontrado!</div>;
  }
  if (!cartContext) {
    return <div>Error: ¡Contexto del carrito no encontrado!</div>;
  }

  const { user } = authContext;

  const formatId = (title: string) => 
    title
      .normalize("NFD") // Decompose accented characters
      .replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
      .replace(/^-|-$/g, ''); // Trim leading/trailing hyphens

  useEffect(() => {
    const hash = window.location.hash; // Obtiene el hash actual de la URL
    if (hash) {
      const elementId = hash.replace("#", ""); // Remueve el '#' para obtener el id
      const element = document.getElementById(elementId);
      if (element) {
        const headerHeight = document.querySelector('.fixed-header')?.clientHeight || 150; // Ajusta la clase según tu encabezado fijo
        const offset = 0; // Margen adicional para mayor claridad
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - headerHeight;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [packages, id, user]);

  return (
    <section className="section" id={id}>
      <div className="container col-11">
        <div className="row justify-content-center">
          {packages?.map((group, index) => {
            const [groupKey, groupPackages] = Object.entries(group)[0];
            if (!Array.isArray(groupPackages) || groupPackages.length === 0) {
              return null;
            }

            const sectionId = formatId(sortedCategories[index]?.title || `paquetes-tipo-${groupKey}`);

            if (productIds?.includes(Number(groupKey))) {
              return (
                <div key={index} className="col-12 my-4" id={sectionId}>
                  <SectionHeader
                    title={sortedCategories[index]?.title || `Paquetes tipo ${groupKey}`}
                    subtitle={sortedCategories[index]?.subtitle || ""}
                  />
                  <ProductCarousel
                    products={groupPackages}
                    cartContext={cartContext}
                    authContext={authContext}
                  />
                </div>
              );
            }

            return (
              <div key={index} className="col-12 my-4 text-home" id={sectionId}>
                <SectionHeader
                  title={sortedCategories[index]?.title || `Paquetes tipo ${groupKey}`}
                  subtitle={sortedCategories[index]?.subtitle || ""}
                />
                {groupPackages.length === 3 ? (
                  <PricingPlans
                    packages={groupPackages}
                    cartContext={cartContext}
                    authContext={authContext}
                  />
                ) : (
                  <div className="home-package">
                    <PackageSlider
                      packages={groupPackages}
                      cartContext={cartContext}
                      authContext={authContext}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default PreferredSection;