import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import * as Icon from 'react-feather';
import { useDispatch } from 'react-redux';
import { all_routes } from '../../../core/data/routes/all_routes';
import ImageWithBasePath from '../../../core/img/ImageWithBasePath';
import { adminSidebar } from '../../../core/data/json/admin_sidebar_data';
import { SideBarData } from '../../../core/models/interface';
import { set_is_mobile_sidebar } from '../../../core/data/redux/action';

const AdminSidebar = () => {
  const routes = all_routes;
  const dispatch = useDispatch();
  const Location = useLocation();

  const [sidebarData, setSidebarData] = useState(adminSidebar);
  const togle = () => {
    dispatch(set_is_mobile_sidebar(false));
  };

  const searchParams = new URLSearchParams(Location.search);
  const idParam = searchParams.get('id');
  const filteredSearchParams = new URLSearchParams();
  if (idParam) {
    filteredSearchParams.set('id', idParam);
  }
  const filteredSearch = filteredSearchParams.toString();
  const fullPath = `${Location.pathname}${filteredSearch ? '?' + filteredSearch : ''}`;

  const activeRouterPath = (route: string) => {
    const currentPath = Location.pathname + Location.search;
    const matchPattern = /edit-legal\?id=1/;
    return matchPattern.test(currentPath) && matchPattern.test(route);
  };

  const activeRouterMenu = (menu: string) => {
    return Location.pathname.includes(menu.toLowerCase());
  };
  const expandSubMenus = (menu: SideBarData) => {
    sessionStorage.setItem('menuValue', menu.menuValue);
    const updatedAdminSidebar = sidebarData.map((section) => {
      const updatedSection = { ...section };
      updatedSection.menu = section.menu.map((menuItem) =>
        menu.menuValue != menuItem.menuValue
          ? {
            ...menuItem,
            showSubRoute: false,
          }
          : {
            ...menuItem,
            showSubRoute: !menu.showSubRoute,
          },
      );
      return updatedSection;
    });
    setSidebarData(updatedAdminSidebar);
  };

  const sidebarRef = useRef(null);

  useEffect(() => {
    const activeElement = sidebarRef.current.querySelector('.active');
    if (activeElement) {
      activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [Location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        dispatch(set_is_mobile_sidebar(false));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dispatch]);

  return (
    <div
      className="admin-sidebar"
      id="sidebar"
      onMouseLeave={togle}
      ref={sidebarRef}
    >
      <div className="admin-sidebar-header pb-0">
        <div className="admin-sidebar-logo">
          <Link to={routes.dashboard}>
            <ImageWithBasePath
              src="logo-lift-white.svg"
              className="img-fluid logo"
              alt="Logo"
            />
          </Link>
          <Link to={routes.dashboard}>
            <ImageWithBasePath
              src="logo-lift-white.svg"
              className="img-fluid logo-small"
              alt="Logo"
            />
          </Link>
        </div>
      </div>
      <div className="admin-sidebar-inner slimscroll">
        <Scrollbars>
          <div id="sidebar-menu" className="admin-sidebar-menu mb-5">
            <ul>
              {sidebarData.map((mainTittle: any) => (
                <React.Fragment key={mainTittle.id || mainTittle.tittle}>
                  <li className="menu-title mt-4 mb-0">
                    <h6>{mainTittle.tittle}</h6>
                  </li>
                  {mainTittle.menu.map((menu: SideBarData) => (
                    <React.Fragment key={menu.id || menu.menuValue}>
                      {menu.hasSubRoute === false ? (
                        <li key={menu.id || menu.menuValue}>
                          <Link
                            to={menu.route}
                            className={`${fullPath === menu.route ? 'active' : ''}`}
                          >
                            {menu.icon} <span>{menu.menuValue}</span>
                          </Link>
                        </li>
                      ) : (
                        <li className="submenu" key={menu.id || menu.menuValue}>
                          <Link
                            to="#"
                            onClick={() => expandSubMenus(menu)}
                            className={`${menu.showSubRoute ? 'subdrop' : ''} ${activeRouterMenu(menu.menuValue) ? 'active' : ''}`}
                          >
                            {menu.icon}
                            <span>{menu.menuValue}</span>
                            <span className="menu-arrow">
                              {menu.showSubRoute ? (
                                <Icon.ChevronDown className="react-feather-custom" />
                              ) : (
                                <Icon.ChevronRight className="react-feather-custom" />
                              )}
                            </span>
                          </Link>
                          <ul
                            style={{
                              display: menu.showSubRoute ? 'block' : 'none',
                            }}
                          >
                            {menu.subMenus.map((subMenu: any) => (
                              <li key={subMenu.id || subMenu.route}>
                                <Link
                                  to={subMenu.route}
                                  className={`${activeRouterPath(subMenu.route) ? 'active' : ''}`}
                                >
                                  {subMenu.menuValue}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      )}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </ul>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default AdminSidebar;
