import { AxiosRequestConfig, AxiosResponse } from "axios";
import { api } from "./apiConfig";

export const getData = (endpoint: string, params: Record<string, any> = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
  return api.get(endpoint, { params, ...config });
};

export const postData = (endpoint: string, data: any = {}, useFormData = false): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = useFormData
    ? { headers: { "Content-Type": "multipart/form-data" } }
    : {};
  return api.post(endpoint, data, config);
};

export const putData = (endpoint: string, id: string | number, data: any = {}, useFormData = false): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = useFormData
    ? { headers: { "Content-Type": "multipart/form-data" } }
    : {};
  const url = id ? `${endpoint}/${id}` : endpoint;
  return api.put(url, data, config);
};

export const putRequet = (endpoint: string, id: string, data: any = {}, useFormData = false): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = useFormData
    ? { headers: { "Content-Type": "multipart/form-data" } }
    : {};
  const url = `${endpoint}/${id}`;
  return api.put(url, data, config);
};

export const deleteData = (endpoint: string, params: Record<string, any> = {}): Promise<AxiosResponse> => {
  return api.delete(endpoint, { params });
};

export default api;