import React from 'react';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { useNavigate } from 'react-router-dom';
import { closeModal } from '../../../../core/utils/modalsUtils';

export interface ConfirmSuccesClassModalProps {
    message: string;
}

const ErrorClassModal: React.FC<ConfirmSuccesClassModalProps> = ({ message }) => {
  const routes = all_routes;
  const navigate = useNavigate();

  const handleGoToReservations = () => {
    closeModal('error-class');
    navigate(routes.index + "#package-section");
  };

  return (
    <div>
        <div className="modal fade" id="error-class">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Error</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                    </div>
                    <div className="modal-body text-center pt-0">
                        <Icon.XCircle size={60} color='#dc3545' className="text-danger fs-1" />
                        <div className="mt-4">
                            <h4>{message}</h4>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-4">
                        <button
                            type="button"
                            className="btn w-sm btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            Seguir Mirando clases
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-success"
                            onClick={handleGoToReservations}
                        >
                            Comprar Clase
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
};

export default ErrorClassModal;
