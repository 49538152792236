import React from 'react';
import BaseModal from './baseModal';
import { ErrorModalProps } from '../../../core/Interface/elements/modalInterface';

const ErrorModal: React.FC<ErrorModalProps> = ({
  show,
  onClose,
  title = 'Error',
  text = 'La acción no se realizó con éxito.',
  onRetry,
  children,
  ...modalProps
}) => {
  return (
    <BaseModal
      show={show}
      onClose={onClose}
      title={title}
      {...modalProps}
      footer={
        <>
          <button onClick={onClose} className="btn btn-secondary">
            Cancelar
          </button>
          <button onClick={onRetry} className="btn btn-danger">
            Ir a detalles
          </button>
        </>
      }
    >
      {children ? (
        children
      ) : (
        <div className="text-center">
          <i className="fas fa-times-circle text-danger fa-3x mb-3"></i>
          <p>{text}</p>
        </div>
      )}
    </BaseModal>
  );
};

export default ErrorModal;
