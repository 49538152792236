import CryptoJS from "crypto-js";

export const decryptResponse = (responseData: any, secretKey: string) => {
  const keysToDecrypt = ["devUser", "devToken", "prodUser", "prodToken"];
  const decryptedData: { [key: string]: any } = {};

  Object.entries(responseData).forEach(([key, value]) => {
    if (keysToDecrypt.includes(key)) {
      try {
        if (value) {
          decryptedData[key] = decryptAES(value as string, secretKey);
        } else {
          decryptedData[key] = null;
        }
      } catch (error) {
        console.error(`Error desencriptando ${key}:`, error);
        decryptedData[key] = null;
      }
    } else {
      decryptedData[key] = value;
    }
  });

  return decryptedData;
};

export const encryptRequest = (dataToEncrypt: any, secretKey: string) => {
  const keysToEncrypt = ["devUser", "devToken", "prodUser", "prodToken"];
  const encryptedData: { [key: string]: any } = {};

  Object.entries(dataToEncrypt).forEach(([key, value]) => {
    if (keysToEncrypt.includes(key)) {
      try {
        encryptedData[key] = encryptAES(value as string, secretKey);
      } catch (error) {
        console.error(`Error encriptando ${key}:`, error);
        encryptedData[key] = null;
      }
    } else {
      encryptedData[key] = value;
    }
  });

  return encryptedData;
};

export const encryptAES = (text: string, secretKey: string) => {
  if (!secretKey) {
    console.error('La clave secreta no está definida');
    return '';
  }

  const encrypted = CryptoJS.AES.encrypt(text, secretKey, {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  return encrypted.toString();
};

export const decryptAES = (encryptedData: string, secretKey: string) => {
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, secretKey, {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

  return decryptedText;
};