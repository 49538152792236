import React from 'react';
import * as Icon from 'react-feather';
import { all_routes } from '../routes/all_routes';
const routes = all_routes;
export const adminSidebar = [
  {
    tittle: 'Contenido Web',
    showAsTab: false,
    separateRoute: false,
    menu: [
      // {
      //   menuValue: 'Pages',
      //   hasSubRoute: true,
      //   showSubRoute: false,
      //   icon: <Icon.File className="react-feather-icon" />,
      //   subMenus: [
      //     {
      //       menuValue: 'Add Page',
      //       route: routes.addPages,
      //     },
      //     {
      //       menuValue: 'Pages',
      //       route: routes.pagesList,
      //     },
      //     {
      //       menuValue: 'Pages List',
      //       route: routes.pageList,
      //     },
      //   ],
      // },
      {
        menuValue: 'Banner Principal',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.homeBannerSlider,
        icon: <Icon.Home className="react-feather-icon" />,
      },
      {
        menuValue: 'Sección de Promoción',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.promotionSection,
        icon: <Icon.Gift className="react-feather-icon" />,
      },
      // {
      //   menuValue: 'Home',
      //   hasSubRoute: true,
      //   showSubRoute: false,
      //   icon: <Icon.Home className="react-feather-icon" />,
      //   subMenus: [
      //     {
      //       menuValue: 'Countries',
      //       route: routes.countries,
      //     },
      //     {
      //       menuValue: 'States',
      //       route: routes.state,
      //     },
      //     {
      //       menuValue: 'Cities',
      //       route: routes.cities,
      //     },
      //   ],
      // },
      // {
      //   menuValue: 'Blog',
      //   hasSubRoute: true,
      //   showSubRoute: false,
      //   icon: <Icon.FileText className="react-feather-icon" />,
      //   subMenus: [
      //     {
      //       menuValue: 'All Blog',
      //       route: routes.allBlog,
      //     },
      //     {
      //       menuValue: 'Add Blog',
      //       route: routes.addBlog,
      //     },
      //     {
      //       menuValue: 'Categories',
      //       route: routes.blogsCategories,
      //     },
      //     {
      //       menuValue: 'Blog Comments',
      //       route: routes.blogComments,
      //     },
      //   ],
      // },
      {
        menuValue: 'FAQ',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.faqadmin,
        icon: <Icon.HelpCircle className="react-feather-icon" />,
      },
      {
        menuValue: 'Terminos y Condiciones',
        hasSubRoute: false,
        showSubRoute: false,
        route: `${routes.editLegal}?id=1`,
        icon: <Icon.FileText className="react-feather-icon" />,
      },
      {
        menuValue: 'Politicas de Privacidad',
        hasSubRoute: false,
        showSubRoute: false,
        route: `${routes.editLegal}?id=2`,
        icon: <Icon.Shield  className="react-feather-icon" />,
      },
      {
        menuValue: 'Cookies del Navegador',
        hasSubRoute: false,
        showSubRoute: false,
        route: `${routes.editLegal}?id=3`,
        icon: <Icon.Chrome  className="react-feather-icon" />,
      },
      {
        menuValue: 'Quejas',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.complaintAdmin,
        icon: <Icon.XCircle className="react-feather-icon" />,
      },
    ],
  },
  {
    tittle: 'ADMINISTRACIÓN',
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: 'Usuarios',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.users,
        icon: <Icon.User className="react-feather-icon" />,

      },
      {
        menuValue: 'Establecimientos',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.headquarters,
        icon: <Icon.MapPin className="react-feather-icon" />,

      },
      {
        menuValue: 'Información',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.information,
        icon: <Icon.Info className="react-feather-icon" />,

      },
      {
        menuValue: 'Clases Asignadas',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.paymentDays,
        icon: <Icon.Calendar className="react-feather-icon" />,

      },
      {
        menuValue: 'Congelamiento de Días',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.pauseDays,
        icon: <Icon.PauseCircle className="react-feather-icon" />,

      },
      {
        menuValue: 'Punto de Venta',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.addPayment,
        icon: <Icon.DollarSign className="react-feather-icon" />,
      },
      {
        menuValue: 'Historial de compras',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.salesHistory,
        icon: <Icon.BarChart className="react-feather-icon" />,
      },
      // {
      //   menuValue: 'Roles & Permissions',
      //   hasSubRoute: false,
      //   showSubRoute: false,
      //   route: routes.roles,
      //   icon: <Icon.File className="react-feather-icon" />,
      // },
    ],
  },
  {
    tittle: 'SERVICIOS',
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: 'Horarios de Clases',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.classSchedules,
        icon: <Icon.Clock  className="react-feather-icon" />,

      },
      {
        menuValue: 'Tipos de Clases',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.subTypeClasses,
        icon: <Icon.List className="react-feather-icon" />,
      },
      {
        menuValue: 'Clases',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.classes,
        icon: <Icon.BookOpen className="react-feather-icon" />,
      },
      {
        menuValue: 'Servicios',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.adiotionalServices,
        icon: <Icon.Slack className="react-feather-icon" />,
      },
      {
        menuValue: 'Categorias',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.categoriesList,
        icon: <Icon.FileText className="react-feather-icon" />,
      },
      {
        menuValue: 'Paquetes',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.package,
        icon: <Icon.Package className="react-feather-icon" />,
      },
      {
        menuValue: 'GiftCard',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.giftcard,
        icon: <Icon.Gift className="react-feather-icon" />,
      },
    ],
  },
  {
    tittle: 'MARKETING',
    showAsTab: false,
    separateRoute: false,
    menu: [
      // {
      //   menuValue: 'Coupons',
      //   hasSubRoute: false,
      //   showSubRoute: false,
      //   route: routes.coupons,
      //   icon: <Icon.Bookmark className="react-feather-icon" />,
      // },
      {
        menuValue: 'Cupones',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.coupons,
        icon: <Icon.Bookmark className="react-feather-icon" />,
      },
      {
        menuValue: 'Suscripciones',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.userSuscriptions,
        icon: <Icon.Paperclip className="react-feather-icon" />,
      },
      {
        menuValue: 'Correos',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.addEmail,
        icon: <Icon.Mail className="react-feather-icon" />,
      },
      {
        menuValue: 'Lista de Correos',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.listEmail,
        icon: <Icon.List className="react-feather-icon" />,
      },
      // {
      //   menuValue: 'Service Offers',
      //   hasSubRoute: false,
      //   showSubRoute: false,
      //   route: routes.offer,
      //   icon: <Icon.Briefcase className="react-feather-icon" />,
      // },
      // {
      //   menuValue: 'Featured Services',
      //   hasSubRoute: false,
      //   showSubRoute: false,
      //   route: routes.featureServices,
      //   icon: <Icon.Briefcase className="react-feather-icon" />,
      // },
    ],
  },
  // {
  //   tittle: 'MEMBERSHIP',
  //   showAsTab: false,
  //   separateRoute: false,
  //   menu: [
  //     {
  //       menuValue: 'Membership',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.membership,
  //       icon: <Icon.User className="react-feather-icon" />,
  //     },
  //     {
  //       menuValue: 'Membership Addons',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.membershipaddons,
  //       icon: <Icon.UserPlus className="react-feather-icon" />,
  //     },
  //   ],
  // },
  // {
  //   tittle: 'Booking',
  //   showAsTab: false,
  //   separateRoute: false,
  //   menu: [
  //     {
  //       menuValue: 'Bookings',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.booking,
  //       icon: <Icon.Grid className="react-feather-icon" />,
  //     },
  //   ],
  // },
  // {
  //   tittle: 'FINANCE & ACCOUNTS',
  //   showAsTab: false,
  //   separateRoute: false,
  //   menu: [
  //     {
  //       menuValue: 'Payouts',
  //       hasSubRoute: true,
  //       showSubRoute: false,
  //       icon: <Icon.CreditCard className="react-feather-icon" />,
  //       subMenus: [
  //         {
  //           menuValue: 'Payout Requests',
  //           route: routes.payoutRequest,
  //         },
  //         {
  //           menuValue: 'Payout Settings',
  //           route: routes.payoutSettings,
  //         },
  //       ],
  //     },
  //     {
  //       menuValue: 'Bank Transfer',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.bankTransferList,
  //       icon: <Icon.File className="react-feather-icon" />,
  //     },
  //     {
  //       menuValue: 'Wallet',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.wallet,
  //       icon: <Icon.CreditCard className="react-feather-icon" />,
  //     },
  //     {
  //       menuValue: 'Refund Request',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.refundRequest,
  //       icon: <Icon.GitPullRequest className="react-feather-icon" />,
  //     },
  //     {
  //       menuValue: 'Cash on Delivery',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.cashOnDelivery,
  //       icon: <Icon.DollarSign className="react-feather-icon" />,
  //     },
  //     {
  //       menuValue: 'Sales Transactions',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.salestransactions,
  //       icon: <Icon.BarChart className="react-feather-icon" />,
  //     },
  //   ],
  // },
  // {
  //   tittle: 'Others',
  //   showAsTab: false,
  //   separateRoute: false,
  //   menu: [
  //     {
  //       menuValue: 'Chat',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.chat,
  //       icon: <Icon.MessageSquare className="react-feather-icon" />,
  //     },
  //   ],
  // },
  {
    tittle: 'REPORTES',
    showAsTab: false,
    separateRoute: false,
    menu: [
      // {
      //   menuValue: 'Capacidad de Clases',
      //   hasSubRoute: false,
      //   showSubRoute: false,
      //   route: routes.adminEarnings,
      //   icon: <Icon.BarChart className="react-feather-icon" />,
      // },
      {
        menuValue: 'Historial de Reservas',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.reservationHistory,
        icon: <Icon.Users className="react-feather-icon" />,
      },
      {
        menuValue: 'Capacidad de Clases',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.classCapacity,
        icon: <Icon.BarChart className="react-feather-icon" />,
      },
      {
        menuValue: 'Reporte de Compras',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.purchaseReport,
        icon: <Icon.DollarSign className="react-feather-icon" />,
      },
      {
        menuValue: 'Preferencias de Clientes',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.customerPreference,
        icon: <Icon.User className="react-feather-icon" />,
      },
      {
        menuValue: 'Rentabilidad de Clases',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.profitabilityClasses,
        icon: <Icon.PieChart className="react-feather-icon" />,
      },
      {
        menuValue: 'Cancelación de Reservas',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.cancellationRate,
        icon: <Icon.XCircle className="react-feather-icon" />,
      },
      {
        menuValue: 'Compra de Clases Gratis',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.classFreePurchase,
        icon: <Icon.CheckCircle className="react-feather-icon" />,
      },
      {
        menuValue: 'Clases de Entrenadores',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.trainerClassSummary,
        icon: <Icon.PieChart className="react-feather-icon" />,
      },
      // {
      //   menuValue: 'Provider Earnings',
      //   hasSubRoute: false,
      //   showSubRoute: false,
      //   route: routes.providerEarning,
      //   icon: <Icon.DollarSign className="react-feather-icon" />,
      // },
      // {
      //   menuValue: 'Provider Sales',
      //   hasSubRoute: false,
      //   showSubRoute: false,
      //   route: routes.providerSales,
      //   icon: <Icon.DollarSign className="react-feather-icon" />,
      // },

      // {
      //   menuValue: 'Provider Wallet',
      //   hasSubRoute: false,
      //   showSubRoute: false,
      //   route: routes.providerWallet,
      //   icon: <Icon.CreditCard className="react-feather-icon" />,
      // },
      // {
      //   menuValue: 'Customer Wallet',
      //   hasSubRoute: false,
      //   showSubRoute: false,
      //   route: routes.adminCustomerWallet,
      //   icon: <Icon.User className="react-feather-icon" />,
      // },
      // {
      //   menuValue: 'Membership Transaction',
      //   hasSubRoute: false,
      //   showSubRoute: false,
      //   route: routes.membershipTransaction,
      //   icon: <Icon.Tv className="react-feather-icon" />,
      // },
      // {
      //   menuValue: 'Refund Report',
      //   hasSubRoute: false,
      //   showSubRoute: false,
      //   route: routes.refundReport,
      //   icon: <Icon.FileText className="react-feather-icon" />,
      // },
      // {
      //   menuValue: 'Register Report',
      //   hasSubRoute: false,
      //   showSubRoute: false,
      //   route: routes.registerreport,
      //   icon: <Icon.GitPullRequest className="react-feather-icon" />,
      // },
      // {
      //   menuValue: 'Sales Report',
      //   hasSubRoute: false,
      //   showSubRoute: false,
      //   route: routes.salesReport,
      //   icon: <Icon.BarChart className="react-feather-icon" />,
      // },
    ],
  },
  // {
  //   tittle: 'MANAGEMENT',
  //   showAsTab: false,
  //   separateRoute: false,
  //   menu: [
  //     {
  //       menuValue: 'Cache System',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.cacheSystem,
  //       icon: <Icon.User className="react-feather-icon" />,
  //     },
  //     {
  //       menuValue: 'Email Templates',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.emailTemplates,
  //       icon: <Icon.Mail className="react-feather-icon" />,
  //     },
  //     {
  //       menuValue: 'Sms Templates',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.smsTemplate,
  //       icon: <Icon.MessageSquare className="react-feather-icon" />,
  //     },
  //     {
  //       menuValue: 'Menu Management',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.menuManagement,
  //       icon: <Icon.FileText className="react-feather-icon" />,
  //     },
  //     {
  //       menuValue: 'Widgets',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.websiteSettings,
  //       icon: <Icon.CreditCard className="react-feather-icon" />,
  //     },
  //     {
  //       menuValue: 'Create Menu',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.createMenu,
  //       icon: <Icon.List className="react-feather-icon" />,
  //     },
  //     {
  //       menuValue: 'Plugin Managers',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.pluginManager,
  //       icon: <Icon.Tv className="react-feather-icon" />,
  //     },
  //   ],
  // },
  // {
  //   tittle: 'SUPPORT',
  //   showAsTab: false,
  //   separateRoute: false,
  //   menu: [
  //     {
  //       menuValue: 'Contact Messages',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.contactMessages,
  //       icon: <Icon.MessageSquare className="react-feather-icon" />,
  //     },
  //     {
  //       menuValue: 'Abuse Reports',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.abuseReport,
  //       icon: <Icon.FileText className="react-feather-icon" />,
  //     },
  //     {
  //       menuValue: 'Announcements',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.announcements,
  //       icon: <Icon.Volume2 className="react-feather-icon" />,
  //     },
  //   ],
  // },
  // {
  //   tittle: 'SETTINGS',
  //   showAsTab: false,
  //   separateRoute: false,
  //   menu: [
  //     {
  //       menuValue: 'Settings',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       base: 'settings',
  //       route: routes.localization,
  //       icon: <Icon.Settings className="react-feather-icon" />,
  //     },
  //     {
  //       menuValue: 'Logout',
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: routes.signin,
  //       icon: <Icon.LogOut className="react-feather-icon" />,
  //     },
  //   ],
  // },
];
