import { apiRepository } from "../../../api/apiRepository";
import { useState, useEffect } from "react";
import { UseTrainersOptions } from "../../Interface/hooksInterface";

export const useTrainers = ({ shouldOptions = false }: UseTrainersOptions) => {
    const [trainersData, setTrainersData] = useState<{ label: string; value: any; }[]>(shouldOptions ? [] : []);
    const [isLoading, setIsLoading] = useState(true);

    const fetchTrainers = async () => {
        try {
            const response = await apiRepository.getUsers(0, 1000, { fullName:'', profile: 'trainer', email:'' });
            const rawData = response.data.data.content;

            if (shouldOptions) {
                const optionsData = rawData.map((item: any) => ({
                    label: item.name + ' ' + item.lastname,
                    value: item.id,
                }));
                setTrainersData(optionsData);
            } else {
                setTrainersData(rawData);
            }
        } catch (error) {
            console.error("Error al obtener los datos de las clases:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchTrainers();
    }, [shouldOptions]);

    return { trainersData, isLoading, fetchTrainers: fetchTrainers };
};