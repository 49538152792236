import React, { useState, useContext, useEffect } from 'react';
import useFetch from '../../../../core/hooks/api/useFetch';
import usePaginatedFetch from '../../../../core/hooks/api/usePaginatedFetch';
import usePost from '../../../../core/hooks/api/usePost';
import API_ENDPOINTS from '../../../../core/constant/API_ENDPOINTS';
import { AuthContext } from '../../../../core/context/AuthContext';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClock, faTimesCircle, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { Tooltip, OverlayTrigger, Form, Button, Spinner } from 'react-bootstrap';
import { ValidationRule } from '../../../../core/Interface/validationsInterfaces';
import * as Icon from 'react-feather';
import { RangeCalendar, TextInput } from '../../../components/inputs';
import { validateForm } from '../../../../core/form/formValidationRules';
import moment from 'moment-timezone';
import 'moment'; // Import Peruvian Spanish locale
import './FreezeRequests.css'; // Import the CSS file
import { BreadcrumbContext } from '../../../../core/Interface/contextInterface';

const FreezeRequests = () => {
    const authContext = useContext(AuthContext);
    if (!authContext) {
        throw new Error("AuthContext is undefined. Ensure that AuthContextProvider is wrapping this component.");
    }
    const { user } = authContext;
    const navigate = useNavigate();
    const { setBreadcrumbData } = useOutletContext<BreadcrumbContext>();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedPaymentDayId, setSelectedPaymentDayId] = useState<number | null>(null);
    const [overlayVisible, setOverlayVisible] = useState(false);
    const [cardVisible, setCardVisible] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState({ reason: '', dateRange: null });
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState<string | null>(null);
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
    const [showUnfreezeForm, setShowUnfreezeForm] = useState(false);
    // const [unfreezeReason, setUnfreezeReason] = useState('');
    const customerId = user?.userId;
    const [dateRange, setDateRange] = useState<any>([
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate()
    ]);

    useEffect(() => {
        if (dateRange[0] && dateRange[1]) {
            setStartDate(moment(dateRange[0]).format('YYYY-MM-DD'));
            setEndDate(moment(dateRange[1]).format('YYYY-MM-DD'));
        }
    }, [dateRange]);

    const { data: freezeRequests, loading: loadingFreezeRequests } = usePaginatedFetch(
        startDate && endDate ? `${API_ENDPOINTS.freezeRequests}?startDate=${startDate}&endDate=${endDate}&customerId=${customerId}&fullName=` : null
    );

    const { data: paymentDays, loading: loadingPaymentDays } = useFetch(
        `${API_ENDPOINTS.paymentDays}?userId=${customerId}`
    );

    const { post, loading: loadingPost } = usePost(API_ENDPOINTS.freezeRequests);

    const getMaxCardHeight = () => {
        const cardElements = document.querySelectorAll('.freeze-card');
        let maxHeight = 0;
        cardElements.forEach(card => {
            const cardHeight = card.getBoundingClientRect().height;
            if (cardHeight > maxHeight) {
                maxHeight = cardHeight;
            }
        });
        return maxHeight;
    };

    useEffect(() => {
        const maxHeight = getMaxCardHeight();
        const cardElements = document.querySelectorAll('.freeze-card');
        cardElements.forEach(card => {
            card.style.height = `${maxHeight}px`;
        });
    }, [loadingFreezeRequests, loadingPaymentDays]);

    useEffect(() => {
        setBreadcrumbData({ title: "Clientes", item2: "Solicitudes de Congelación" });
    }, [setBreadcrumbData]);

    if (loadingFreezeRequests || loadingPaymentDays) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    const validPaymentDays = paymentDays?.content?.filter((day: any) => day.endDate && new Date(day.endDate) >= new Date());
    const expiredPaymentDays = paymentDays?.content?.filter((day: any) => !day.endDate || new Date(day.endDate) < new Date());

    const handlePaymentDayClick = (paymentDayId: number) => {
        if (selectedPaymentDayId === paymentDayId) {
            setCardVisible(false);
            setOverlayVisible(false);
            setShowForm(false);
            setFormData({ reason: '', dateRange: null }); // Reset form data
            setErrors({});
            setTimeout(() => setSelectedPaymentDayId(null), 300); // Delay to allow transition
        } else {
            setSelectedPaymentDayId(paymentDayId);
            setCardVisible(true);
            setOverlayVisible(true);
            setShowForm(false);
            setShowUnfreezeForm(false);
            setIsSuccess(null);
            setMessage(null);
            setFormData({ reason: '', dateRange: null }); // Reset form data
            setErrors({});
        }
    };

    const validationRules: ValidationRule[] = [
        { field: 'dateRange', methods: ['minCurrentDateRange', 'maxEndDateRange'] },
        { field: 'reason', methods: ['required'] }
    ];

    const handlePauseClick = (paymentDayId: number, e: React.MouseEvent) => {
        e.stopPropagation();
        setSelectedPaymentDayId(paymentDayId);
        setCardVisible(true);
        setOverlayVisible(true);
        setShowForm(true);
        setIsSuccess(null);
        setMessage(null);
    };

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleRangeCalendarChange = (name: string, value: any) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const newErrors = validateForm(formData, validationRules, {}, moment(paymentDays.content?.find(day => day.id === selectedPaymentDayId).endDate).toDate());
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return false;
        } else {
            const startDate = moment(formData.dateRange[0]).toDate();
            const endDate = moment(formData.dateRange[1]).toDate();
            const quantityDays = moment(endDate).diff(moment(startDate), 'days') + 1;

            const payload = {
                paymentDaysId: selectedPaymentDayId,
                customerId: user?.userId,
                reason: formData.reason,
                quantityDays,
                startDateFreeze: moment(startDate).format('YYYY-MM-DD'),
                endDateFreeze: moment(endDate).format('YYYY-MM-DD')
            };
            try {
                await post(payload);
                setErrors({});
                setShowForm(false);
                setIsSuccess(true);
                setMessage("La solicitud se ha enviado con éxito.");
                return true;
            } catch (err: any) {
                const errorMessage = err.response?.data?.message || 'Error al enviar la solicitud. Inténtalo de nuevo.';
                setErrors({ submit: errorMessage });
                setShowForm(false);
                setShowUnfreezeForm(false);
                setIsSuccess(false);
                setMessage(errorMessage);
                return false;
            }
        }
    };

    const getStatusIcon = (status: string) => {
        switch (status) {
            case 'APROBADO':
                return <FontAwesomeIcon icon={faCheckCircle} className="text-success" />;
            case 'PENDIENTE':
                return <FontAwesomeIcon icon={faClock} className="text-warning" />;
            case 'RECHAZADO':
                return <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />;
            default:
                return null;
        }
    };

    const TooltipComponent: React.FC<{ itemType: string }> = ({ itemType }) => {
        const TooltipContent = (props: any) => (
            <Tooltip id="button-tooltip" {...props}>
                Poner pausa a {itemType}
            </Tooltip>
        );
        TooltipContent.displayName = 'TooltipContent';
        return <TooltipContent />;
    };

    const handleRetry = () => {
        setIsSuccess(null);
        setMessage(null);
        setShowForm(true); // Show the form again
    };

    const handleSuccessConfirm = () => {
        setIsSuccess(null);
        setMessage(null);
        setShowForm(false);
        setCardVisible(true);
        setOverlayVisible(true);
    };

    return (
        <div>
            <div className="content-page-header content-page-headersplit align-items-center mb-1">
                <h1 className="mb-3">Solicitudes de Congelación</h1>
                <div className="list-btn">
                    <ul className="mb-0">
                        <li>
                            <div className="d-flex align-items-end">
                                <RangeCalendar
                                    label="Filtrar por rango de fechas"
                                    name="dateRangeFilter"
                                    value={dateRange}
                                    onChange={(e) => setDateRange(e.target.value)}
                                />
                                <button
                                    className="btn btn-outline-primary rounded-3 mb-3 ms-3"
                                    onClick={() => {
                                        setDateRange([
                                            moment().startOf('month').toDate(),
                                            moment().endOf('month').toDate()
                                        ]);
                                    }}
                                    title="Limpiar filtros"
                                >
                                    <i className="fa fa-eraser me-2" />
                                    Limpiar
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <div className="row" style={{ position: 'relative' }}>
                    <div className={`overlay-freeze ${overlayVisible ? 'visible' : 'hidden'}`} onClick={() => handlePaymentDayClick(selectedPaymentDayId)}></div>
                    {[...validPaymentDays, ...expiredPaymentDays].map((day) => {
                        const isExpired = !day.endDate || new Date(day.endDate) < new Date();
                        const isSelected = selectedPaymentDayId === day.id;
                        return (
                            <div key={day.id} className={`col-md-6`}>
                                <div
                                    className={`freeze-card mb-3 p-3 border rounded shadow-sm bg-white ${isSelected && cardVisible ? 'selected-card' : ''}`}
                                    style={{ cursor: 'pointer', transition: 'transform 0.3s ease-in-out' }}
                                    onClick={() => handlePaymentDayClick(day.id)}
                                    onTouchStart={(e) => {
                                        if (!isSelected && e.currentTarget) {
                                            e.currentTarget.classList.add('touch-active');
                                        }
                                    }}
                                    onTouchEnd={(e) => {
                                        if (!isSelected && e.currentTarget) {
                                            e.currentTarget.classList.remove('touch-active');
                                            setTimeout(() => {
                                                if (e.currentTarget) {
                                                    e.currentTarget.classList.remove('touch-active');
                                                }
                                            }, 300); // Ensure touch effect is removed after transition
                                        }
                                    }}
                                >
                                    <div className={`${isExpired ? 'text-muted' : ''}`} style={{ opacity: isExpired ? 0.5 : 1 }}>
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <span className="badge bg-primary">{day.type}</span>
                                            {day.type && !isExpired && (
                                                day.paymentDaysFreeze && day.paymentDaysFreeze.active && day.paymentDaysFreeze.quantityDays && day.paymentDaysFreeze.startDateFreeze ? (
                                                    <div className="d-flex align-items-center bg-icon-play">
                                                        <Icon.PlayCircle className="pause-icon pause-page" size={20}
                                                        // onClick={(e) => handleUnfreezeClick(day.id, e)} 
                                                        />
                                                    </div>
                                                ) : (
                                                    <OverlayTrigger placement="top" overlay={<TooltipComponent itemType={day.type} />}>
                                                        <div className="d-flex align-items-center bg-icon-pause" onClick={(e) => handlePauseClick(day.id, e)}>
                                                            <Icon.PauseCircle className="pause-icon pause-page" size={20} />
                                                        </div>
                                                    </OverlayTrigger>
                                                )
                                            )}
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p className="m-0">
                                                {day.type ? (
                                                    day.type === 'Membresias Premium' ? (
                                                        <>Cantidad: <i className="fas fa-crown text-warning" /></>
                                                    ) : day.type === 'Membresias' ? (
                                                        <>Cantidad: <i className="fas fa-infinity text-primary" /></>
                                                    ) : (
                                                        <>Cantidad: {day.quantity}</>
                                                    )
                                                ) : (
                                                    <>Cantidad: {day.quantity}</>
                                                )}
                                            </p>
                                        </div>
                                        {day.endDate && (
                                            <div className="d-flex justify-content-between align-items-center mt-0">
                                                <span>Fecha de Finalización:</span>
                                                <span className={`badge ${new Date(day.endDate) < new Date() ? 'bg-danger' : 'bg-success'} text-white`}>
                                                    {new Date(day.endDate).toLocaleDateString()}
                                                </span>
                                            </div>
                                        )}
                                        {day.paymentDaysFreeze && (
                                            <>
                                                <h5 className="mb-1 mt-2">Detalles de Congelación</h5>
                                                <div className="d-flex justify-content-between align-items-center mt-0">
                                                    <span>Inicio de Congelación:</span>
                                                    <span className="badge bg-secondary text-white">
                                                        {day.paymentDaysFreeze.startDateFreeze ? new Date(day.paymentDaysFreeze.startDateFreeze).toLocaleDateString() : 'N/A'}
                                                    </span>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center mt-0">
                                                    <span>Fin de Congelación:</span>
                                                    <span className="badge bg-secondary text-white">
                                                        {day.paymentDaysFreeze.endDateFreeze ? new Date(day.paymentDaysFreeze.endDateFreeze).toLocaleDateString() : 'N/A'}
                                                    </span>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center mt-0">
                                                    <span>Días Congelados:</span>
                                                    <span className="badge bg-secondary text-white">{day.paymentDaysFreeze.quantityDaysFrozen}</span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {isExpired && (
                                        <div>
                                            <p>Sin compras validas</p>
                                            <button onClick={() => navigate('/#package-section')} className="btn btn-primary">
                                                Comprar Membresía
                                            </button>
                                        </div>
                                    )}
                                    {isSelected && !showForm && isSuccess === null && (
                                        <div className={`floating-table w-100 ${isSelected ? 'below' : 'hidden'} ${overlayVisible ? 'visible' : 'hidden'}`}>
                                            <h3 className="mb-3">Solicitudes de Congelación para {day.type}</h3>
                                            <div className="table-responsive bg-white">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Estado</th>
                                                            <th>Razón</th>
                                                            <th>Cantidad de Días</th>
                                                            <th>Decisión de</th>
                                                            <th>Fecha de Aprobación/Rechazo</th>
                                                            {/* <th style={{ position: 'sticky', right: 0, background: 'white' }}>Cancelar Congelamiento</th> */}
                                                            <th>Fecha de Creación</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {freezeRequests?.filter(request => request.paymentDaysId === day.id).length > 0 ? (
                                                            freezeRequests
                                                                .filter(request => request.paymentDaysId === day.id)
                                                                .map((request) => (
                                                                    <tr key={request.id}>
                                                                        <td>
                                                                            {getStatusIcon(request.status)}
                                                                            <span className="ms-2">{request.status}</span>
                                                                        </td>
                                                                        <td>{request.reason}</td>
                                                                        <td>{request.quantityDays}</td>
                                                                        <td>{request.approvedByUserFullName || 'Aún en proceso...'}</td>
                                                                        <td>
                                                                            {request.approvalRejectionDatetime
                                                                                ? moment(request.approvalRejectionDatetime).format('DD/MM/YYYY')
                                                                                : 'Aún en proceso...'}
                                                                        </td>
                                                                        <td>
                                                                            {request.createdAt
                                                                                ? moment(request.createdAt).format('DD/MM/YYYY')
                                                                                : 'N/A'}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="6" className="text-start">Sin solicitudes de congelamiento aún</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                    {isSelected && showForm && (
                                        <div className={`floating-table w-100 ${isSelected ? 'below' : 'hidden'} ${overlayVisible ? 'visible' : 'hidden'}`} onClick={(e) => e.stopPropagation()}>
                                            <h3 className="mb-3">Pausar {day.type}</h3>
                                            <Form onSubmit={handleSubmit}>
                                                <TextInput
                                                    label="Razón para pausar"
                                                    name="reason"
                                                    value={formData.reason}
                                                    onChange={handleInputChange}
                                                    error={errors.reason}
                                                    placeholder="Ingrese la razón"
                                                />
                                                <RangeCalendar
                                                    label="Rango de fechas"
                                                    name="dateRange"
                                                    value={formData.dateRange || undefined}
                                                    onChange={(e) => handleRangeCalendarChange('dateRange', e.value)}
                                                    error={errors?.dateRange || ''}
                                                />
                                                <Button variant="secondary" className="mt-3" onClick={() => setShowForm(false)}>
                                                    Cancelar
                                                </Button>
                                                <Button variant="primary" type="submit" className="mt-3  ms-3 btn-primary" disabled={loadingFreezeRequests || loadingPost}>
                                                    {loadingFreezeRequests || loadingPost ? 'Cargando...' : 'Confirmar'}
                                                </Button>
                                            </Form>
                                        </div>
                                    )}
                                    {isSelected && isSuccess !== null && !showForm && (
                                        <div className={`floating-table w-100 ${isSelected ? 'below' : 'hidden'} ${overlayVisible ? 'visible' : 'hidden'}`} onClick={(e) => e.stopPropagation()}>
                                            {isSuccess ? (
                                                <div className="text-center">
                                                    <i className="fas fa-check-circle text-success fa-3x mb-3"></i>
                                                    <p>{message}</p>
                                                    <button onClick={handleSuccessConfirm} className="btn btn-success">
                                                        Aceptar
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className="text-center">
                                                    <i className="fas fa-times-circle text-danger fa-3x mb-3"></i>
                                                    <p>{message}</p>
                                                    <button onClick={handleSuccessConfirm} className="btn btn-secondary">
                                                        Cancelar
                                                    </button>
                                                    <button onClick={handleRetry} className="btn btn-danger ms-3">
                                                        Reintentar
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {/* {isSelected && showUnfreezeForm && (
                                        <div className={`floating-table w-100 ${isSelected ? 'below' : 'hidden'} ${overlayVisible ? 'visible' : 'hidden'}`} onClick={(e) => e.stopPropagation()}>
                                            <h3 className="mb-3">Descongelar Solicitud</h3>
                                            <Form onSubmit={handleUnfreezeSubmit}>
                                                <TextInput
                                                    label="Razón para descongelar"
                                                    name="unfreezeReason"
                                                    value={unfreezeReason}
                                                    onChange={(e) => setUnfreezeReason(e.target.value)}
                                                    error={errors.unfreezeReason}
                                                    placeholder="Ingrese la razón"
                                                />
                                                <Button variant="primary" type="submit" className="mt-3 btn-primary" disabled={loadingFreezeRequests || loadingPost}>
                                                    {loadingFreezeRequests || loadingPost ? 'Cargando...' : 'Aceptar'}
                                                </Button>
                                                <Button variant="secondary" className="mt-3 ms-3" onClick={() => setShowUnfreezeForm(false)}>
                                                    Cancelar
                                                </Button>
                                            </Form>
                                        </div>
                                    )} */}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default FreezeRequests;