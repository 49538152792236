import { Users, Schedules, Classes, Coupon, Banners, Faq, PaymentDays, Subscriptions, GiftCard, EnterpriseInformation, SecretKeys, SubTypeClasses } from '../../core/Interface/repositoryInterfaces'
import { TYPE_SUB_SERVICE } from '../data/options/options';
import moment from 'moment';

export interface ValidationError {
    id?: string;
    field: string;
    message: string;
}

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const validateEnterpriseInformation = (data: EnterpriseInformation) => {
    const errors: ValidationError[] = [];

    if (!data.phone) {
      errors.push({
        field: 'phone',
        message: 'El teléfono es requerido'
      });
    }

     if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
      errors.push({
        field: 'email',
        message: 'El correo electrónico no es válido'
      });
    }

    if (!/^\d+$/.test(data.whatsapp)) {
        errors.push({
            field: 'whatsapp',
            message: 'El número de WhatsApp solo debe contener números'
        });
    } else if (data.whatsapp.length < 9) {
        errors.push({
            field: 'whatsapp',
            message: 'El número de WhatsApp debe tener al menos 9 dígitos'
        });
    }

    return errors;
  };

  export const validateSecretKeys = (data: SecretKeys) => {
    const errors: ValidationError[] = [];

    if (!data.devUser) {
      errors.push({
        field: 'devUser',
        message: 'El usuario de desarrollo es requerido'
      });
    }

    if (!data.devToken) {
      errors.push({
        field: 'devToken',
        message: 'El token de desarrollo es requerido'
      });
    }

    if (!data.prodUser) {
      errors.push({
        field: 'prodUser',
        message: 'El usuario de producción es requerido'
      });
    }

    if (!data.prodToken) {
      errors.push({
        field: 'prodToken',
        message: 'El token de producción es requerido'
      });
    }

    return errors;
  };

export const validateLogin = (user: { email: string, password: string }): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!user.email || !user.email.trim()) {
        errors.push({ field: 'email', message: 'El correo electrónico no puede estar vacío.' });
    } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(user.email)) {
        errors.push({ field: 'email', message: 'El correo electrónico no es válido.' });
    }

    if (!user.password || !user.password.trim()) {
        errors.push({ field: 'password', message: 'La contraseña no puede estar vacía.' });
    } else if (user.password.trim().length < 4) {
        errors.push({ field: 'password', message: 'La contraseña debe tener al menos 4 caracteres.' });
    }

    return errors;
};

export const validateForgotPassword = (user: { email: string }): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!user.email || !user.email.trim()) {
        errors.push({ field: 'email', message: 'El correo electrónico no puede estar vacío.' });
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(user.email)) {
        errors.push({ field: 'email', message: 'El correo electrónico no es válido.' });
    }

    return errors;
};

export const validateResetPassword = (user: { email: string, password: string, confirmPassword: string }): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!user.password || !user.password.trim()) {
        errors.push({ field: 'password', message: 'La contraseña no puede estar vacía.' });
    } else if (user.password.trim().length < 4) {
        errors.push({ field: 'password', message: 'La contraseña debe tener al menos 4 caracteres.' });
    }

    if (!user.confirmPassword || !user.confirmPassword.trim()) {
        errors.push({ field: 'confirmPassword', message: 'La confirmación de contraseña no puede estar vacía.' });
    } else if (user.confirmPassword.trim().length < 4) {
        errors.push({ field: 'confirmPassword', message: 'La confirmación de contraseña debe tener al menos 4 caracteres.' });
    }

    if (user.password !== user.confirmPassword) {
        errors.push({ field: 'confirmPassword', message: 'Las contraseñas no coinciden.' });
    }

    return errors;
};

export const validateCode = (user: { email: string, code: string }): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!user.email || !user.email.trim()) {
        errors.push({ field: 'email', message: 'El correo electrónico no puede estar vacío.' });
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(user.email)) {
        errors.push({ field: 'email', message: 'El correo electrónico no es válido.' });
    }

    if (!user.code || !user.code.trim()) {
        errors.push({ field: 'code', message: 'El código no puede estar vacío.' });
    }

    return errors;
};

export const validateHeadquarters = (headquartersData: { name: string, address: string, phone: string }): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!headquartersData.name || !headquartersData.name.trim()) {
        errors.push({ field: "name", message: "El nombre de la sede no puede estar vacío." });
    }

    if (!headquartersData.address || !headquartersData.address.trim()) {
        errors.push({ field: "address", message: "La dirección de la sede no puede estar vacía." });
    }

    if (!headquartersData.phone || !headquartersData.phone.trim()) {
        errors.push({ field: "phone", message: "El teléfono de la sede no puede estar vacío." });
    }

    return errors;
};

export const validateFaq = (faqData: Faq): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!faqData.title || !faqData.title.trim()) {
        errors.push({ field: "title", message: "El título no puede estar vacío." });
    }

    if (!faqData.description || !faqData.description.trim()) {
        errors.push({ field: "description", message: "La respuesta no puede estar vacía." });
    }

    return errors;
};

export const validateGiftCard = (giftCardData: {
    userId: number;
    code: string;
    quantityDate: number;
    endDate: string;
    type: string;
    quantity: number;
}): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!giftCardData.quantityDate || isNaN(Number(giftCardData.quantityDate)) || giftCardData.quantityDate <= 0) {
        errors.push({ field: "quantityDate", message: "La cantidad de días debe ser un número válido y mayor a cero." });
    }

    if (!giftCardData.endDate || isNaN(new Date(giftCardData.endDate).getTime())) {
        errors.push({ field: "endDate", message: "La fecha de expiración no es válida." });
    }

    if (!giftCardData.type || !giftCardData.type.trim()) {
        errors.push({ field: "type", message: "El tipo no puede estar vacío." });
    } else {
        const validType = TYPE_SUB_SERVICE.find(option => option.value === giftCardData.type);
        if (!validType) {
            errors.push({ field: "type", message: "El tipo seleccionado no es válido." });
        }
    }

    if (giftCardData.type !== "Membresias" && giftCardData.type !== "Membresias Premium") {
        if (!giftCardData.quantity || giftCardData.quantity <= 0) {
            errors.push({ field: "quantity", message: "La cantidad de días debe ser mayor a cero." });
        }
    }

    return errors;
};

export const validateCategory = (categoryData: { name: string, type: number }): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!categoryData.name || !categoryData.name.trim()) {
        errors.push({ field: "name", message: "El nombre no puede estar vacío." });
    }

    if (!categoryData.type || isNaN(Number(categoryData.type)) || Number(categoryData.type) <= 0) {
        errors.push({ field: "type", message: "La cantidad debe ser un número válido y mayor que cero." });
    }

    return errors;
};

export const validatePromotion = (promotionData: { description: string, image: File | null }): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!promotionData.description || !promotionData.description.trim()) {
        errors.push({ field: "description", message: "La descripción no puede estar vacía." });
    }

    if (!promotionData.image) {
        errors.push({ field: "image", message: "La imagen del banner no puede estar vacía." });
    } else if (!(promotionData.image instanceof File || typeof promotionData.image === "string")) {
        errors.push({ field: "image", message: "La imagen debe ser un archivo válido o una URL." });
    }
    return errors;
};

export const validatePackage = (
    packageData: {
        subtitle: string;
        packageTypeId: number | string;
        title: string;
        feature1: string;
        price: string | number;
        status: boolean;
        type: number | string;
        typeValidate: number | string;
        image: File | null;
        quantityDate: string | number;
        quantity: number;
        freezeDays: number;
    },
): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (['Clases', 'Clases Reformer'].includes(packageData.type)) {
        if (!packageData.quantity || isNaN(Number(packageData.quantity)) || Number(packageData.quantity) <= 0) {
            errors.push({ field: "quantity", message: "La cantidad debe ser un número mayor a 0." });
        }
    }

    if (!packageData.title || !packageData.title.trim()) {
        errors.push({ field: "title", message: "El título no puede estar vacío." });
    }

    if (!packageData.packageTypeId || packageData.packageTypeId === "") {
        errors.push({ field: "packageTypeId", message: "La categoría no puede estar vacía." });
    }

    // if (!packageData.feature1 || !packageData.feature1.trim()) {
    //     errors.push({ field: "feature1", message: "La descripción no puede estar vacía." });
    // }

    if (!packageData.price || Number(packageData.price) <= 0) {
        errors.push({ field: "price", message: "El precio debe ser mayor a 0." });
    }
    if (packageData.typeValidate === 2 || packageData.typeValidate === 3) {
        if (!packageData.image) {
            errors.push({ field: "image", message: "La imagen del paquete no puede estar vacía." });
        } else if (!(packageData.image instanceof File || typeof packageData.image === "string")) {
            errors.push({ field: "image", message: "La imagen debe ser un archivo válido o una URL." });
        }
    }

    if (packageData.typeValidate !== 2 && packageData.typeValidate !== 4) {
        if (isNaN(Number(packageData.quantityDate)) || Number(packageData.quantityDate) <= 0) {
            errors.push({ field: "quantityDate", message: "La cantidad de días debe ser un número mayor a 0." });
        }
        if (!packageData.type) {
            errors.push({ field: "type", message: "El tipo no puede estar vacío." });
        }
        if (!packageData.freezeDays || isNaN(packageData.freezeDays) || packageData.freezeDays <= 0) {
            errors.push({
                field: "freezeDays",
                message: "El congelamiento debe ser un número mayor que 0." 
            });
        }
        if (packageData.typeValidate !== 3) {
            if (!packageData.subtitle || !packageData.subtitle.trim()) {
                errors.push({ field: "subtitle", message: "El subtítulo no puede estar vacío." });
            }
        }

    }

    return errors;
};

export const validateSubTypeClasses = (subTypeClasses: SubTypeClasses): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!subTypeClasses.name || !subTypeClasses.name.trim()) {
        errors.push({ field: "name", message: "El nombre no puede estar vacío." });
    }

    return errors;
};

export const validateClasses = (classes: Classes): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!classes.title || !classes.title.trim()) {
        errors.push({ field: "title", message: "El titulo no puede estar vacío." });
    }

    if (!classes.headquartersId) {
        errors.push({ field: "headquartersId", message: "El establecimiento no puede estar vacío." });
    }

    if (!classes.description || !classes.description.trim()) {
        errors.push({ field: "description", message: "La descripción no puede estar vacía." });
    }

    if (!classes.isReformer) {
        errors.push({ field: "isReformer", message: "La opción premium no puede estar vacía." });
    }

    if (!classes.image) {
        errors.push({ field: "image", message: "La imagen de la clase no puede estar vacía." });
    } else if (!(classes.image instanceof File || typeof classes.image === "string")) {
        errors.push({ field: "image", message: "La imagen debe ser un archivo válido o una URL." });
    }

    if (!classes.headquartersId) {
        errors.push({ field: "selectedHeadquarters", message: "La establecimiento no puede estar vacía." });
    }

    return errors;
};

export const validateClassesList = (services: Service[]): ValidationError[] => {
    let allErrors: ValidationError[] = [];
    services.forEach((service) => {
      const errors = validateClasses(service).map((error) => ({
        ...error,
        id: service.id,
      }));
      allErrors = allErrors.concat(errors);
    });
    return allErrors;
  };

export const validateCoupon = (couponData: Coupon): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!couponData.name || !(typeof couponData.name === 'string' && couponData.name.trim())) {
        errors.push({ field: "name", message: "El nombre no puede estar vacío." });
    }

    if (!couponData.code || !(typeof couponData.code === 'string' && couponData.code.trim())) {
        errors.push({ field: "code", message: "El código no puede estar vacío." });
    }

    if (!couponData.amount || isNaN(Number(couponData.amount)) || Number(couponData.amount) <= 0) {
        errors.push({ field: "amount", message: "El monto debe ser un número válido mayor que 0." });
    }

    const expirationDate = new Date(couponData.dateExpired);
    if (!couponData.dateExpired || !(expirationDate instanceof Date) || isNaN(expirationDate.getTime())) {
        errors.push({ field: "dateExpired", message: "La fecha de expiración no es válida." });
    } else if (expirationDate < new Date()) {
        errors.push({ field: "dateExpired", message: "La fecha de expiración debe ser futura." });
    }

    if (typeof couponData.typeDiscount !== "boolean") {
        errors.push({ field: "typeDiscount", message: "El tipo de descuento debe ser un valor valido." });
    }

    if (typeof couponData.limitPerUser !== "boolean") {
        errors.push({ field: "limitPerUser", message: "El limite de uso debe tener un valor valido." });
    }

    if (typeof couponData.typeGeneralProduct !== "boolean") {
        errors.push({ field: "typeGeneralProduct", message: "La aplicación del descuento debe ser un valor booleano." });
    }
    return errors;
};

export const validateSubscriptions = (subscriptionsData: Subscriptions): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!subscriptionsData.fullName ) {
        errors.push({ field: "fullName", message: "El nombre no puede estar vacío." });
    }

    if (!subscriptionsData.email ) {
        errors.push({ field: "email", message: "El correo no puede estar vacío." });
    }  else if (!emailRegex.test(subscriptionsData.email)) {
        errors.push({ field: "email", message: "El correo electrónico debe tener un formato válido." });
    }

    return errors;
};

export const validateSchedule = (schedule: Schedules): ValidationError[] => {
    const errors: ValidationError[] = [];
    const phoneRegex = /^\d+$/;

    if (!schedule.title || !(typeof schedule.title === 'string' && schedule.title.trim())) {
        errors.push({ field: "title", message: "El título no puede estar vacío." });
    }

    if (!schedule.headquarterId) {
        errors.push({ field: "headquarterId", message: "El establecimiento no puede estar vacío." });
    }

    if (!schedule.classTypeId) {
        errors.push({ field: "classTypeId", message: "El tipo de clase no puede estar vacío." });
    }

    if (!schedule.date) {
        errors.push({ field: "date", message: "La fecha no puede estar vacía." });
    } else if (!moment(schedule.date, 'YYYY-MM-DD', true).isValid()) {
        errors.push({ field: "date", message: "La fecha debe tener un formato válido (YYYY-MM-DD)." });
    }

    if (!schedule.hourStart) {
        errors.push({ field: "hourStart", message: "La hora de inicio no puede estar vacía." });
    } else if (!moment(schedule.hourStart, 'HH:mm', true).isValid()) {
        errors.push({ field: "hourStart", message: "La hora de inicio debe tener un formato válido (HH:mm)." });
    }

    if (!schedule.minutes) {
        errors.push({ field: "minutes", message: "La duración total no puede estar vacía." });
    } else if (!phoneRegex.test(schedule.minutes)) {
        errors.push({ field: "minutes", message: "Ingrese un formato correcto." });
    }

    if (!schedule.quantity || isNaN(Number(schedule.quantity)) || Number(schedule.quantity) <= 0) {
        errors.push({ field: "quantity", message: "La cantidad debe ser un número válido y mayor que cero." });
    }

    if (!schedule.classId) {
        errors.push({ field: "classId", message: "La clase no puede estar vacía." });
    }

    if (!schedule.trainerId) {
        errors.push({ field: "trainerId", message: "El entrenador no puede estar vacía." });
    }

    if (!schedule.status) {
        errors.push({ field: "status", message: "El estado de clase no puede estar vacía." });
    }

    if (schedule.typeRecurrent) {
        if (!schedule.quantityRecurrent) {
            errors.push({ field: "quantityRecurrent", message: "La cantidad de recurrencia es obligatoria." });
        } else if (isNaN(Number(schedule.quantityRecurrent)) || Number(schedule.quantityRecurrent) <= 0) {
            errors.push({ field: "quantityRecurrent", message: "El valor debe ser un número válido." });
        }
    }

    return errors;
};

export const validateUser = (user: Users): ValidationError[] => {
    const errors: ValidationError[] = [];
    const phoneRegex = /^\d{9,}$/;
    const dniRegex = /^\d{8}$/;
    const rucRegex = /^\d{11}$/;

    if (!user.name?.trim()) {
        errors.push({ field: "name", message: "El nombre no puede estar vacío." });
    }

    if (!user.lastname?.trim()) {
        errors.push({ field: "lastname", message: "El apellido no puede estar vacío." });
    }

    if (!user.email?.trim()) {
        errors.push({ field: "email", message: "El correo no puede estar vacío." });
    } else if (/\s/.test(user.email?.trim())) {
        errors.push({ field: "email", message: "El correo no puede contener espacios." });
    } else if (!emailRegex.test(user.email)) {
        errors.push({ field: "email", message: "Verifica el formato y los espacios." });
    }

    if (!user.id && !user.password) {
        errors.push({ field: "password", message: "La contraseña no puede estar vacía." });
    } else if (user.password && user.password.trim().length < 4) {
        errors.push({ field: "password", message: "La contraseña debe tener al menos 4 caracteres." });
    }

    if (user.password?.trim() && user.newPassword?.trim() !== user.password?.trim()) {
        errors.push({ field: "newPassword", message: "La contraseña debe coincidir." });
    } else if (user.newPassword && user.newPassword.trim().length < 4) {
        errors.push({ field: "newPassword", message: "La contraseña debe tener al menos 4 caracteres." });
    }

    if (!user.profile?.trim()) {
        errors.push({ field: "profile", message: "El perfil no puede estar vacío." });
    }

    if (!user.phone?.trim()) {
        errors.push({ field: "phone", message: "El teléfono no puede estar vacío." });
    } else if (!phoneRegex.test(user.phone)) {
        errors.push({ field: "phone", message: "Ingrese un formato correcto." });
    }

    if (user.active === undefined || user.active === null) {
        errors.push({ field: "active", message: "El estado activo no puede estar vacío." });
    }

    if (!user.id) {
        if (user.isAccepted === undefined || user.isAccepted === false) {
            errors.push({ field: "isAccepted", message: "Debes aceptar los términos y condiciones." });
        }
    }

    if (!user.numberDocument?.trim()) {
        errors.push({ field: "numberDocument", message: "El número de indentidad no puede estar vacío." });
    } else {
        if (user.typeDocument === "DNI" && !dniRegex.test(user.numberDocument)) {
            errors.push({ field: "numberDocument", message: "El número de DNI debe tener 8 dígitos." });
        } else if (user.typeDocument === "RUC" && !rucRegex.test(user.numberDocument)) {
            errors.push({ field: "numberDocument", message: "El número de RUC debe tener 11 dígitos." });
        } else if (user.typeDocument === "CE" && !dniRegex.test(user.numberDocument)) {
            errors.push({ field: "numberDocument", message: "El número de CE debe tener 8 dígitos." });
        }
    }

    if (!user.birthdate) {
        errors.push({ field: "birthdate", message: "La fecha de nacimiento no puede estar vacía." });
    } else {
        const birthDate = new Date(user.birthdate);

        if (isNaN(birthDate.getTime())) {
            errors.push({ field: "birthdate", message: "La fecha de nacimiento es inválida." });
        } else {
            const today = new Date();
            let age = today.getFullYear() - birthDate.getFullYear();
            const month = today.getMonth() - birthDate.getMonth();

            if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            if (age < 10) {
                errors.push({ field: "birthdate", message: "Ingresa una fecha de nacimiento válida." });
            }
        }
    }

    if (!user.id) {
        if (!user.department) {
            errors.push({ field: "department", message: "El departamento no puede estar vacío." });
        }

        if (!user.province) {
            errors.push({ field: "province", message: "La provincia no puede estar vacía." });
        }

        if (!user.district) {
            errors.push({ field: "district", message: "El distrito no puede estar vacío." });
        }
    }

    if (!user.address) {
        errors.push({ field: "address", message: "La dirección no puede estar vacía." });
    }
    return errors;
};

export const validateUserPhoto = (user: Users): ValidationError[] => {
    const errors: ValidationError[] = [];
    const phoneRegex = /^\d{9,}$/;
    const dniRegex = /^\d{8}$/;
    const rucRegex = /^\d{11}$/;

    if (!user.name?.trim()) {
        errors.push({ field: "name", message: "El nombre no puede estar vacío." });
    }

    if (!user.lastname?.trim()) {
        errors.push({ field: "lastname", message: "El apellido no puede estar vacío." });
    }

    if (!user.email?.trim()) {
        errors.push({ field: "email", message: "El correo no puede estar vacío." });
    } else if (/\s/.test(user.email?.trim())) {
        errors.push({ field: "email", message: "El correo no puede contener espacios." });
    } else if (!emailRegex.test(user.email)) {
        errors.push({ field: "email", message: "Verifica el formato y los espacios." });
    }

    return errors;
};

export const validatePaymentDays = (paymentDays: PaymentDays): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!['Membresias', 'Membresias Premium'].includes(paymentDays.type)) {
        if (paymentDays.quantity === undefined || paymentDays.quantity === null || paymentDays.quantity === '') {
            errors.push({ field: "quantity", message: "Ingrese un formato correcto." });
        } else if (isNaN(Number(paymentDays.quantity))) {
            errors.push({ field: "quantity", message: "La cantidad debe ser un número válido." });
        }
    }

    if (paymentDays.days && !paymentDays.endDate) {
        errors.push({ field: "endDate", message: "La fecha de vencimiento no puede estar vacía." });
    } else {
        const endDate = new Date(paymentDays.endDate);

        if (isNaN(endDate.getTime())) {
            errors.push({ field: "endDate", message: "La fecha ingresada es inválida." });
        } else {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            if (endDate < today) {
                errors.push({ field: "endDate", message: "La fecha debe ser hoy o en el futuro." });
            }
        }
    }

    return errors;
};

export const validateComplaint = (complaint: any): ValidationError[] => {
    const errors: ValidationError[] = [];

    const phoneRegex = /^(\+?\d{1,3}[-\s]?)?(\(?\d{1,4}\)?[-\s]?)?(\d{4}[-\s]?\d{4})$/;

    if (!complaint.firstName) {
        errors.push({ field: "firstName", message: "El nombre no puede estar vacío." });
    }

    if (!complaint.lastName) {
        errors.push({ field: "lastName", message: "El apellido no puede estar vacío." });
    }

    if (!complaint.documentType) {
        errors.push({ field: "documentType", message: "El tipo de documento no puede estar vacío." });
    }

    if (!complaint.documentNumber) {
        errors.push({ field: "documentNumber", message: "El número de documento no puede estar vacío." });
    } else if (!/^\d{6,}$/.test(complaint.documentNumber)) {
        errors.push({ field: "documentNumber", message: "El número de documento debe ser un número válido de al menos 6 dígitos." });
    }

    if (!complaint.email) {
        errors.push({ field: "email", message: "El correo electrónico no puede estar vacío." });
    } else if (!emailRegex.test(complaint.email)) {
        errors.push({ field: "email", message: "El correo electrónico debe tener un formato válido." });
    }

    if (!complaint.phoneNumber) {
        errors.push({ field: "phoneNumber", message: "El teléfono no puede estar vacío." });
    } else if (!phoneRegex.test(complaint.phoneNumber)) {
        errors.push({ field: "phoneNumber", message: "Ingrese un formato de teléfono válido." });
    }

    if (!complaint.department) {
        errors.push({ field: "department", message: "El departamento no puede estar vacío." });
    }

    if (!complaint.province) {
        errors.push({ field: "province", message: "La provincia no puede estar vacía." });
    }

    if (!complaint.district) {
        errors.push({ field: "district", message: "El distrito no puede estar vacío." });
    }

    if (!complaint.address) {
        errors.push({ field: "address", message: "La dirección no puede estar vacía." });
    }

    if (!complaint.reference) {
        errors.push({ field: "reference", message: "La referencia no puede estar vacía." });
    }

    if (!complaint.complaintType) {
        errors.push({ field: "complaintType", message: "El tipo de queja no puede estar vacío." });
    }

    if (!complaint.consumptionType) {
        errors.push({ field: "consumptionType", message: "El tipo de consumo no puede estar vacío." });
    }

    if (!complaint.receiptNumber) {
        errors.push({ field: "receiptNumber", message: "El número de recibo no puede estar vacío." });
    }

    if (!complaint.complaintDate) {
        errors.push({ field: "complaintDate", message: "La fecha de la queja no puede estar vacía." });
    }

    if (!complaint.provider) {
        errors.push({ field: "provider", message: "El proveedor no puede estar vacío." });
    }

    if (complaint.productCost === undefined || complaint.productCost === null || isNaN(complaint.productCost)) {
        errors.push({ field: "productCost", message: "El costo del producto debe ser un número válido." });
    }

    if (!complaint.productDescription) {
        errors.push({ field: "productDescription", message: "La descripción del producto no puede estar vacía." });
    }

    if (!complaint.purchaseDate) {
        errors.push({ field: "purchaseDate", message: "La fecha de compra no puede estar vacía." });
    }

    if (!complaint.consumptionDate) {
        errors.push({ field: "consumptionDate", message: "La fecha de consumo no puede estar vacía." });
    }

    if (!complaint.complaintDetails) {
        errors.push({ field: "complaintDetails", message: "Los detalles de la queja no pueden estar vacíos." });
    }

    if (!complaint.customerRequest) {
        errors.push({ field: "customerRequest", message: "La solicitud del cliente no puede estar vacía." });
    }

    return errors;
};

export const validateBanner = (banner: Banners): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!banner.title) {
        errors.push({ field: "title", message: "El título del banner no puede estar vacío." });
    }

    if (!banner.subtitle) {
        errors.push({ field: "subtitle", message: "La descripción del banner no puede estar vacía." });
    }

    if (!banner.path) {
        errors.push({ field: "path", message: "El link del banner no puede estar vacío." });
    } else if (/\s/.test(banner.path)) {
        errors.push({ field: "path", message: "El link no puede contener espacios." });
    }

    if (!banner.image) {
        errors.push({ field: "image", message: "La imagen del banner no puede estar vacía." });
    } else if (!(banner.image instanceof File || typeof banner.image === "string")) {
        errors.push({ field: "image", message: "La imagen debe ser un archivo válido o una URL." });
    }

    return errors;
};