import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import { getDefaultMonthRange } from '../../../core/utils/dateUtils';
import { CalendarRangeField } from '../common/input/inputField';
import ShowDetailClassProfitabilityModal from '../common/modals/show-detail-class-profitability-modal';
import { useGetProfitabilityClassesExelFile } from '../../../core/hooks/getReports/useGetProfitabilityClassesExelFile';
import { downloadExcelFile } from '../../../core/utils/fileUtils';
import usePaginatedFetch from '../../../core/hooks/api/usePaginatedFetch';
import API_ENDPOINTS from '../../../core/constant/API_ENDPOINTS';
import { convertDateToLocalDateString } from '../../../core/utils/dateUtils';
import useFetch from '../../../core/hooks/api/useFetch';
import { AutoCompleteInput } from '../../components/inputs';

const ProfitabilityClasses = () => {
  const [dateRange, setDateRange] = useState<[Date, Date]>(getDefaultMonthRange() as [Date, Date]);
  const [customerId, setCustomerId] = useState<string>();
  const [minAge, setMinAge] = useState<string>();
  const [maxAge, setMaxAge] = useState<string>();
  const [selectedReservation, setSelectedReservation] = useState<any>();
  const { refetchProfitabilityClasses } = useGetProfitabilityClassesExelFile(dateRange, customerId, minAge, maxAge);

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUserLabel, setSelectedUserLabel] = useState<string>('');

  const { data: customers, loading } = useFetch(`${API_ENDPOINTS.users}?fullName=&email=${searchQuery}&profile=customer&active=&birthdate=`);

  const userSuggestions = customers?.content?.map((customer: { email: string, id: string | number }) => ({
      label: customer.email,
      value: customer.id
    }))
    .filter((customer: { label: string }) => {
      const query = searchQuery.toLowerCase();
      return customer.label.toLowerCase().includes(query);
    })
    .sort((a: { label: string }, b: { label: string }) => {
      const query = searchQuery.toLowerCase();
      const aMatch = a.label.toLowerCase().indexOf(query);
      const bMatch = b.label.toLowerCase().indexOf(query);
      if (aMatch === -1) return 1;
      if (bMatch === -1) return -1;
      return aMatch - bMatch;
    });

  const handleUserComplete = (() => {
    let typingTimeout: NodeJS.Timeout;

    return (e: any) => {
      if (typingTimeout) clearTimeout(typingTimeout);
      typingTimeout = setTimeout(() => {
        setSearchQuery(e.query);
      }, 1500);
    };
  })();

  const handleUserChange = (value: string | { label: string, value: number | null }) => {
    const selectedUser = userSuggestions.find((option: { label: string, value: number | null }) =>
      typeof value === 'object' && value !== null && 'label' in value && option.label === value.label
    );
    setCustomerId(selectedUser?.value?.toString() || '');
    setSelectedUserLabel(typeof value === 'string' ? value : value?.label || '');
  };

  const params = dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined) ? {
    startDate: convertDateToLocalDateString(dateRange[0]),
    endDate: convertDateToLocalDateString(dateRange[1]),
    customerId: customerId || '',
    minAge: minAge || '',
    maxAge: maxAge || ''
  } : null;

  const {
    data: profitabilityClasses,
    totalElements: totalRecords,
    first,
    rows,
    onPageChange
  } = usePaginatedFetch(API_ENDPOINTS.reportProfitabilityClasses, 10, 0, params);


  const columns = [
    { field: "userName", header: "Cliente" },
    { field: "age", header: "Edad" },
    { field: "userEmail", header: "Correo Electrónico" },
    { field: "totalReservations", header: "Reservas Totales" },
  ];

  const handleShowInfo = (rowData: any) => {
    setSelectedReservation(null);
    setTimeout(() => setSelectedReservation(rowData), 0);
  };

  const handleDownloadExcel = async () => {
    if (dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined)) {
      const file = await refetchProfitabilityClasses();
      downloadExcelFile(file, 'reservation-report');
    }
  };

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          <h5>Rentabilidad de Clases</h5>
        </div>
        <div className="card card-status mb-3">
          <div className="card-head-blk border-bottom-0 pb-0">
            <div className="row align-items-center">
              <div className="col-md-12 d-flex justify-content-end">
                <button
                  className="btn btn-outline-primary rounded-3 mb-3 me-2"
                  onClick={() => {
                    setDateRange(getDefaultMonthRange() as [Date, Date]);
                    setCustomerId('');
                    setMinAge('');
                    setMaxAge('');
                  }}
                >
                  <i className="fa fa-eraser me-2" />
                  Limpiar
                </button>
                <button
                  className="btn btn-primary-admin rounded-3 mb-3 align-items-center d-flex me-2"
                  onClick={handleDownloadExcel}
                >
                  <Icon.FileText className="react-feather-icon me-2" />
                  <span>Descargar como Excel</span>
                </button>
              </div>
              <div className="col-xl-12 col-md-12 d-flex">
                <div className="earning-group gap-3 w-100">
                  <CalendarRangeField
                    value={dateRange || [new Date(), new Date()]}
                    onChange={(e: any) => setDateRange(e.value)}
                  />
                  <AutoCompleteInput
                    placeholder="Busca un buscar..."
                    value={selectedUserLabel}
                    suggestions={userSuggestions}
                    completeMethod={handleUserComplete}
                    onChange={handleUserChange}
                    required={false}
                    loading={loading} // Pass loading state to show animation
                  />
                  <div className="d-flex gap-3 mb-3">
                    <input
                      type="number"
                      className="form-control"
                      style={{ height: '40px', maxWidth: '200px' }}
                      value={minAge || ''}
                      onChange={(e) => setMinAge(e.target.value)}
                      placeholder="Edad mínima"
                    />
                    <input
                      type="number"
                      className="form-control"
                      style={{ height: '40px', maxWidth: '200px' }}
                      value={maxAge || ''}
                      onChange={(e) => setMaxAge(e.target.value)}
                      placeholder="Edad máxima"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <DataTableWrapper
            data={profitabilityClasses || []}
            sortField='userName'
            lazy={true}
            sortOrder={-1}
            columns={columns}
            customIcon={Icon.Eye}
            onCustomAction={handleShowInfo}
            paginator={true}
            showGridlines
            emptyMessage="No se encontraron clases."
            totalRecords={totalRecords}
            first={first}
            rowsPerPage={rows}
            onPageChange={onPageChange}
          />
        </div>
        <ShowDetailClassProfitabilityModal selectedClassData={selectedReservation} />
      </div>
    </div>
  );
};

export default ProfitabilityClasses;