import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import { AuthContext } from '../../../../core/context/AuthContext';
import UpdatePhotoModal from '../../common/modals/confirm-update-photo-modal';
import { customerSidebarData, trainerSidebarData, serviceProviderSidebarData, receptionistSidebarData, guestSidebarData } from '../common/sidebarData';
import { useFilteredClasses, useHandleUpdatePhoto } from '../../../../core/hooks/utils/sidebarHooks';
import SidebarHeader from './elements/SidebarHeader';
import SidebarClasses from './elements/SidebarClasses';
import SidebarLinks from './elements/SidebarLinks';
import { ClassItem } from '../../../../core/Interface/frontend/sidebarCustomerInterface';
import ClassList from './elements/ClassList';
import { useProfile } from '../../../../core/context/ProfileContext';

const CustomerSideBar = (updateDays: any) => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { user } = authContext;
  const location = useLocation();
  const { profileData, refetchUser: fetchUser } = useProfile(); // Use ProfileContext
  const [userImage, setUserImage] = useState<File | string>();
  const [showModal, setShowModal] = useState<boolean>();
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [loading, setLoading] = useState<boolean>();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const classesQuantity = profileData?.paymentDays || [];
  const serviceCounts = profileData?.purchasedServices || [];

  useEffect(() => {
    if (profileData?.image) {
      setUserImage(profileData.image);
    } else {
      setUserImage(null);
    }
  }, [profileData]);

  const activeRouterPath = (link: string) => {
    return link === location.pathname;
  };

  const sidebarData = () => {
    switch (user?.role) {
      case 'admin':
        return [...customerSidebarData, ...trainerSidebarData];
      case 'trainer':
        return trainerSidebarData;
      case 'provider':
        return serviceProviderSidebarData;
      case 'receptionist':
        return receptionistSidebarData;
      default:
        return user ? customerSidebarData : guestSidebarData;
    }
  };

  const { handleUpdatePhoto, handleConfirmUpdate } = useHandleUpdatePhoto({
    setSelectedFile,
    setShowModal,
    setLoading,
    fetchUser,
    userData: profileData ? { ...profileData, id: Number(profileData.id).toString() } : { id: '' },
    selectedFile: selectedFile || null,
  });

  const filteredClasses: ClassItem[] = useFilteredClasses(classesQuantity) || [];

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handlePauseClick = (item: any) => {
    // Implement the pause click functionality or leave a comment explaining why it's empty
  };

  const handlePlayClick = () => {
    // Implement the play click functionality or leave a comment explaining why it's empty
  };

  return (
    <>
      <StickyBox>
        <div className="card card-shadow slider-min user-sidebar mb-4">
          <SidebarHeader userImage={typeof userImage === 'string' ? userImage : ''} user={user} handleUpdatePhoto={handleUpdatePhoto} />
          {user?.role == 'customer' && (
            <>
              <SidebarClasses filteredClasses={filteredClasses} />
              <div className="mt-3">
                <ClassList
                  filteredClasses={serviceCounts}
                  isCollapsed={isCollapsed}
                  toggleCollapse={toggleCollapse}
                  handlePauseClick={handlePauseClick}
                  handlePlayClick={handlePlayClick}
                  title="Servicios Comprados"
                />
              </div>
            </>
          )}
          <SidebarLinks sidebarData={sidebarData()} activeRouterPath={activeRouterPath} />
        </div>
        <UpdatePhotoModal
          show={showModal || false}
          onClose={() => setShowModal(false)}
          onConfirm={handleConfirmUpdate}
          loading={loading || false}
        />
      </StickyBox>
    </>
  );
};

export default CustomerSideBar;