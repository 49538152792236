import React from "react";
import axios, { AxiosInstance } from "axios";
import { API_BASE_URL } from '../../environment';
import Cookies from 'js-cookie';
import { ApiProviderProps } from '../Interface/contextInterface';

export const api: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000, // 30 seconds
});

const handleResponseError = (error: any): Promise<any> => {
  if (error.response) {
    console.error(`Error ${error.response.status}: ${error.response.data}`);
  } else if (error.request) {
    console.error("No response received:", error.request);
  } else {
    console.error("Error setting up request:", error.message);
  }
  return Promise.reject(error);
};

export const ApiProvider: React.FC<ApiProviderProps> = ({ children }) => {
  api.interceptors.request.use(
    (config) => {
      const token = Cookies.get('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  api.interceptors.response.use(
    (response) => response,
    handleResponseError
  );

  return <>{children}</>;
};