import { apiRepository } from '../../../api/apiRepository';
import { ValidationError } from '../../form/validationForm';

export const useHandleStatusUpdate = ({ scheduleId, schedules, setLoading, setErrors }) => {
    return async (message = true) => {
        try {
            setLoading(true);
            setErrors([]);
            await apiRepository.updateSchedule(scheduleId, schedules);
            if (message) {
                const validationErrors: ValidationError[] = [];
                validationErrors.push({ field: 'succesStatus', message: '¡Estado actualizado con exito!' });
                setErrors(validationErrors);
            }
        } catch (error: any) {
            const validationErrors: ValidationError[] = [];
            console.error('Error al actualizar el estado:', error);
            if (error.response && error.response.data && error.response.data.message) {
                const cleanedMessage = error.response.data.message.replace(/^Error interno del servidor: /, '');
                validationErrors.push({ field: 'status', message: cleanedMessage });
            } else {
                validationErrors.push({
                    field: 'status',
                    message: 'Ocurrió un error al intentar actualizar el estado. Inténtalo de nuevo.',
                });
            }
            setErrors(validationErrors);
        } finally {
            setLoading(false);
        }
    };
};

export const useHandleSubmit = ({ scheduleId, classes, schedules, setErrors, setLoading, handleStatusUpdate, setSchedules }) => {
    return async () => {

        const attendedCustomers = classes
            .filter(classItem => classItem.attended)
            .map(classItem => classItem.customerId);


        const payload = {
            scheduleId: Number(scheduleId),
            customerIds: attendedCustomers
        };
        try {
            setLoading(true);
            await handleStatusUpdate(false);
            await apiRepository.assistanceReservation(payload);
            const updatedSchedules = { ...schedules, status: 5 };
            setSchedules(updatedSchedules);
            const validationErrors: ValidationError[] = [];
            validationErrors.push({ field: 'assistSuccess', message: '¡Asistencia enviada con exito!' });
            setErrors(validationErrors);
        } catch (error) {
            const validationErrors: ValidationError[] = [];
            validationErrors.push({ field: 'assist', message: error.response.data.message ||  'Error al enviar la asistencia' });
            setErrors(validationErrors);
        } finally {
            setLoading(false);
        }
    };
};
