import React from 'react';
import { Modal } from 'react-bootstrap';
import * as Icon from 'react-feather';
import { getErrorMessage } from '../../../../core/utils/errorUtils';
import { ConfirmEmailModalProps } from '../../../../core/Interface/modalInterface';

const ConfirmEmailModal: React.FC<ConfirmEmailModalProps> = ({
  onConfirm,
  onCancel,
  error,
  show = false,
  loading = false,
  message = '¿Estás seguro de que deseas enviar los correos electrónicos seleccionados?',
}) => {
  return (
    <Modal show={show} onHide={onCancel} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirmar Acción</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center pt-1">
        <Icon.Info size={95} color='#ffd100' className="text-success fs-1" />
        <p className="mt-3">{message}</p>
        {
          error.find((err: { field: string }) => err.field === 'confirm-email') && (
            <div className="alert alert-danger" role="alert">
              {getErrorMessage(error, "confirm-email")}
            </div>
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={onCancel}
        >
          Cancelar
        </button>
        <button
          type="button"
          className="btn btn-primary-admin"
          onClick={onConfirm}
        >
          {loading ? (
            <>
              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
              Enviando...
            </>
          ) : (
            'Confirmar'
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmEmailModal;