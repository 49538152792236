import React from 'react';
import { CSSTransition } from 'react-transition-group';
import * as Icon from 'react-feather';
import { ClassCard } from './SidebarElements';
import { convertStringToLocalDate, getCurrentLocalDate } from "../../../../../core/utils/dateUtils";

interface ClassListProps {
  filteredClasses: any[];
  isCollapsed: boolean;
  toggleCollapse: () => void;
  handlePauseClick: (item: any) => void;
  handlePlayClick: () => void;
  title?: string;
}

const ClassList: React.FC<ClassListProps> = ({ filteredClasses, isCollapsed, toggleCollapse, handlePauseClick, handlePlayClick, title = "Clases Disponibles" }) => {
  const renderClassCard = (item: any) => (
    <ClassCard
      key={item.id}
      item={item}
      handlePauseClick={handlePauseClick}
      handlePlayClick={handlePlayClick}
      convertStringToLocalDate={convertStringToLocalDate}
      getCurrentLocalDate={getCurrentLocalDate}
    />
  );

  return (
    <>
      {filteredClasses.length === 1 ? (
        <>
          <div className="d-flex align-items-center mb-3">
            <h5 className="mb-0">{title}</h5>
          </div>
          {renderClassCard(filteredClasses[0])}
        </>
      ) : (
        <>
          {filteredClasses.length > 0 && (
            <>
              <div className="d-flex align-items-center mb-3 position-relative" onClick={toggleCollapse} style={{ cursor: 'pointer' }}>
                <h5 className="m-0">{title}</h5>
                {isCollapsed ? <Icon.ChevronDown size={20} className="ms-3" /> : <Icon.ChevronUp className="ms-3" size={20} />}
              </div>
              <CSSTransition
                in={!isCollapsed}
                timeout={300}
                classNames="collapse"
                unmountOnExit
              >
                <div>
                  {filteredClasses.map(renderClassCard)}
                </div>
              </CSSTransition>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ClassList;
