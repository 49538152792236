export const getProductCarouselConfig = (slidesToShowCount: number) => ({
    dots: false,
    autoplay: false,
    slidesToShow: slidesToShowCount,
    speed: 500,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: slidesToShowCount,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: slidesToShowCount >= 3 ? 3 : slidesToShowCount,
            },
        },
        {
            breakpoint: 776,
            settings: {
                slidesToShow: slidesToShowCount >= 2 ? 2 : slidesToShowCount,
            },
        },
        {
            breakpoint: 567,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
});

export const getPackageSliderConfig = (slidesToShowCount: number) => ({
    dots: true,
    autoplay: false,
    slidesToShow: slidesToShowCount,
    speed: 500,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: slidesToShowCount,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: slidesToShowCount >= 3 ? 3 : slidesToShowCount,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: slidesToShowCount >= 3 ? 3 : slidesToShowCount,
            },
        },
        {
            breakpoint: 950,
            settings: {
                slidesToShow: slidesToShowCount >= 2 ? 2 : slidesToShowCount,
            },
        },
        {
            breakpoint: 567,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
});

export const getPricingPlansConfig = (slidesToShowCount: number) => ({
    dots: true,
    autoplay: false,
    slidesToShow: slidesToShowCount,
    speed: 500,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 776,
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 567,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
});

export const getFeatureSliderConfig = (slidesToShowCount: number) => ({
    dots: false,
    nav: false,
    infinite: true,
    speed: 500,
    swipe: true,
    slidesToShow: slidesToShowCount,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: slidesToShowCount,
            },
        },
        {
            breakpoint: 700,
            settings: {
                slidesToShow: slidesToShowCount >= 2 ? 2 : 1,
            },
        },
        {
            breakpoint: 550,
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 0,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
});