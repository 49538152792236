import React, { useState } from 'react';
import * as Icon from 'react-feather';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import usePaginatedFetch from '../../../core/hooks/api/usePaginatedFetch';
import API_ENDPOINTS from '../../../core/constant/API_ENDPOINTS';
import { getDefaultMonthRange } from '../../../core/utils/dateUtils';
import { CalendarRangeField } from '../common/input/inputField';
import ShowDetailCancelationRateModal from '../common/modals/show-detail-cancelation-rate-modal';
import { useGetCancellationRateReportExelFile } from '../../../core/hooks/getReports/useGetCancellationRateReportExelFile'; // Actualiza el hook si es necesario
import { downloadExcelFile } from '../../../core/utils/fileUtils';
import { convertDateToLocalDateString } from '../../../core/utils/dateUtils';

const CancellationRate = () => {
  const [dateRange, setDateRange] = useState<[Date, Date]>(getDefaultMonthRange() as [Date, Date]);
  const [selectedClass, setSelectedClass] = useState<any>();
  const {
  data: cancellationRateReport,
  totalElements,
  first,
  rows,
  onPageChange
} = usePaginatedFetch(API_ENDPOINTS.reportCancellationRate, 10, 0, dateRange?.length === 2 ? {
  startDate: convertDateToLocalDateString(dateRange[0]),
  endDate: convertDateToLocalDateString(dateRange[1])
} : null);

  const { refetchCancellationRateReport } = useGetCancellationRateReportExelFile(dateRange);

  const columns = [
    { field: "className", header: "Clase" },
    { field: "trainerName", header: "Entrenador" },
    { field: "totalReservations", header: "Reservas Totales" },
    { field: "totalCancellations", header: "Cancelaciones Totales" },
    {
      field: "cancellationRate",
      header: "Tasa de Cancelación",
      body: (rowData: any) => (
        <span className={`badge ${rowData.cancellationRate > 30 ? 'bg-danger' : 'bg-success'}`}>
          {rowData.cancellationRate.toFixed(2)}%
        </span>
      )
    },
  ];

  const handleShowInfo = (rowData: any) => {
    setSelectedClass(null);
    setTimeout(() => setSelectedClass(rowData), 0);
  };

  const handleDownloadExcel = async () => {
    if (dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined)) {
      const file = await refetchCancellationRateReport();
      downloadExcelFile(file, 'cancellation-rate-report');
    }
  };

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          <h5>Informe de Tasa de Cancelación</h5>
        </div>
        <div className="card card-status mb-3">
          <div className="card-head-blk border-bottom-0 pb-0">
            <div className="row align-items-center">
              <div className="col-md-12 d-flex justify-content-end">
                <button
                  className="btn btn-outline-primary rounded-3 mb-3 me-2"
                  onClick={() => {
                    setDateRange(getDefaultMonthRange() as [Date, Date]);
                  }}
                >
                  <i className="fa fa-eraser me-2" />
                  Limpiar
                </button>
                <button
                  className="btn btn-primary-admin rounded-3 mb-3 align-items-center d-flex me-2"
                  onClick={handleDownloadExcel}
                >
                  <Icon.FileText className="react-feather-icon me-2" />
                  <span>Descargar como Excel</span>
                </button>
                <CalendarRangeField
                  value={dateRange || [new Date(), new Date()]}
                  onChange={(e: any) => setDateRange(e.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <DataTableWrapper
            data={cancellationRateReport || []}
            sortField='className'
            lazy={true}
            sortOrder={-1}
            columns={columns}
            customIcon={Icon.Eye}
            onCustomAction={handleShowInfo}
            paginator={true}
            rowsPerPage={rows}
            first={first}
            totalRecords={totalElements}
            onPageChange={onPageChange}
            showGridlines
            emptyMessage="No se encontraron clases."
          />
        </div>
        <ShowDetailCancelationRateModal selectedClassData={selectedClass} />
      </div>
    </div>
  );
};

export default CancellationRate;