/**
 * Componente Complaint
 * 
 * Este componente permite gestionar las quejas, incluyendo la visualización de detalles de cada queja.
 * Utiliza `DataTable` para mostrar las quejas en una tabla.
 * 
 * Estado:
 * - selectedComplaint: Queja seleccionada para ver detalles.
 * 
 * Funciones:
 * - selectComplaintData: Selecciona una queja para ver detalles.
 * - handleUpdateSuccess: Maneja el éxito de la actualización de la queja.
 * - actionButton: Renderiza los botones de acción en la tabla.
 * 
 * Hooks:
 * - useComplaint: Hook personalizado para obtener y gestionar las quejas.
 */

import React, { useState, useEffect } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import ComplaintModal from '../common/modals/complaint-modal';
import { useComplaint } from '../../../core/hooks/getData/useComplaint';

const Complaint = () => {
  const [selectedComplaint, setSelectedComplaint] = useState<any>();
  const { complaintData: data, setComplaintData: setData, refetch } = useComplaint();

  const selectComplaintData = (data: any) => {
    setSelectedComplaint(data);
  };

  const handleUpdateSuccess = () => {
    refetch();
  };

  const actionButton = (rowData: any) => {
    const handleDeleteClick = () => {
      selectComplaintData(rowData);
    };

    return (
      <div className="table-actions d-flex">
        <Link
          className="delete-table me-2"
          onClick={handleDeleteClick}
          to="#"
          data-bs-toggle="modal"
          data-bs-target="#complaint-modal"
        >
          <Icon.Eye className="react-feather-custom" />
        </Link>

      </div>
    );
  };

  return (
    <div className="page-wrapper page-settings">
      <div className="content mb-4">
        <div className="content-page-header content-page-headersplit mb-4">
          <h5>Quejas</h5>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="table-responsive table-div table datatable pt-0">
              <DataTable
                value={data}
                showGridlines
                tableStyle={{ minWidth: '50rem' }}
              >
                <Column sortable field="firstName" header="Nombre"></Column>
                <Column sortable field="customerRequest" header="Queja"></Column>
                <Column sortable field="provider" header="Empleado"></Column>
                <Column sortable field="phoneNumber" header="Teléfono"></Column>
                <Column sortable field="documentNumber" header="Número de documento"></Column>
                <Column sortable field="purchaseDate" header="Fecha de Compra"></Column>
                <Column
                  field="action"
                  body={actionButton}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <ComplaintModal selectedComplaint={selectedComplaint} onUpdateSuccess={handleUpdateSuccess} />
    </div>
  );
};

export default Complaint;
