/**
 * Componente Faq
 * 
 * Este componente permite gestionar las preguntas frecuentes (FAQ) incluyendo la creación, edición y eliminación de las mismas.
 * Utiliza el componente `DataTableWrapper` para mostrar las FAQs en una tabla.
 * 
 * Estado:
 * - selectedId: ID de la FAQ seleccionada para eliminar.
 * - selectedFaq: FAQ seleccionada para editar.
 * 
 * Funciones:
 * - handleEditClick: Maneja el clic en el botón de editar.
 * - handleDeleteClick: Maneja el clic en el botón de eliminar.
 * 
 * Hooks:
 * - useFaq: Hook personalizado para obtener las FAQs.
 * - useResponsiveColumns: Hook personalizado para obtener las columnas de la tabla de manera responsiva.
 */

import React, { useState } from 'react';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import FaqModal from '../common/modals/faq-modal';
import DeleteFaqModal from '../common/modals/delete-faq-modal';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumnsFaq';
import useFetch from '../../../core/hooks/api/useFetch';
import API_ENDPOINTS from '../../../core/constant/API_ENDPOINTS';

const Faq = () => {
  const [selectedId, setSelectedId] = useState<any>();
  const [selectedFaq, setSelectedFaq] = useState<any>();
  const { data: faqData, refetch } = useFetch(API_ENDPOINTS.faqs);

  const handleEditClick = (rowData: any) => {
    setSelectedFaq(null);
    setTimeout(() => setSelectedFaq(rowData), 0);
  };

  const handleDeleteClick = (rowData: any) => {
    setSelectedId(null);
    setTimeout(() => setSelectedId(rowData.id), 0);
  };

  const columns = [
    { field: "title", header: "Pregunta" },
    {
      field: "status", header: "Estado", body: (rowData: any) => (
        <span className={rowData.status ? 'badge-active' : 'badge-inactive'}>
          {rowData.status ? 'Activo' : 'Inactivo'}
        </span>
      )
    }
  ];

  const getColumns = useResponsiveColumns(columns);

  return (
    <div className="page-wrapper page-settings">
      <div className="content mb-4">
        <div className="container-service col-lg-11 col-sm-12 m-auto">
          <div className="content-page-header content-page-headersplit">
            <h5>FAQ’s</h5>
            <div className="list-btn">
              <button
                className="btn btn-primary-admin"
                type="button"
                onClick={handleEditClick}
              >
                <i className="fa fa-plus me-2" />
                Agregar FAQ
              </button>
            </div>
          </div>
          <DataTableWrapper
            data={faqData || []}
            columns={getColumns()}
            onEdit={handleEditClick}
            onDelete={handleDeleteClick}
            paginator={false}
            showGridlines
            emptyMessage="No se encontraron preguntas frecuentes."
          />
        </div>
        <FaqModal selectedFaq={selectedFaq} onUpdateSuccess={refetch} />
        <DeleteFaqModal selectedId={selectedId} onDeleteSuccess={refetch} />
      </div>
    </div>
  );
};

export default Faq;