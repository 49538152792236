import React, { useState, useContext, useEffect, useCallback } from 'react';
import { ValidationError } from '../../../core/form/validationForm';
import { DropdownField, AutoCompleteDropdown } from '../../admin/common/input/inputField';
import { useLocation, useNavigate } from 'react-router-dom';
import { CLASS_STATUS } from '../../../core/data/options/options';
import { AuthContext } from '../../../core/context/AuthContext';
import { all_routes } from '../../../core/data/routes/all_routes';
import { useFetchClasses, useFetchSchedules, useFetchSchedulesList } from '../../../core/hooks/getData/useFetchData';
import { useHandleStatusUpdate, useHandleSubmit } from '../../../core/hooks/utils/useHandleActions';
import { Modal } from 'react-bootstrap';
import { deleteData, postData } from '../../../core/services/api';
import API_ENDPOINTS from '../../../core/constant/API_ENDPOINTS';
import useFetch from '../../../core/hooks/api/useFetch';

const ClassAttendanceManagement = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const [classes, setClasses] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [schedulesClass, setSchedulesClass] = useState([]);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const state = location.state as { dateRange: any, classId: string | number, trainerId: string | number, currentPage: number, rows: number, selectedClassLabel: string, selectedTrainerLabel: string };
  const [scheduleId, setScheduleId] = useState(queryParams.get('id'));
  const { user } = authContext;
  const routes = all_routes;

  const [selectAll, setSelectAll] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [classToDelete, setClassToDelete] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [showAddReservationModal, setShowAddReservationModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<{ label: string, value: number } | null>(null);
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [addReservationLoading, setAddReservationLoading] = useState(false);
  const [addReservationError, setAddReservationError] = useState(null);
  const [selectedUserLabel, setSelectedUserLabel] = useState<string>(''); // State for displaying the selected user label
  const [refreshClasses, setRefreshClasses] = useState(false);

  const navigate = useNavigate();

  useFetchSchedulesList({ user, setSchedulesClass });
  useFetchClasses({ scheduleId, setClasses, refresh: refreshClasses });
  useFetchSchedules({ scheduleId, setSchedules });

  const handleStatusUpdate = useHandleStatusUpdate({ scheduleId, schedules, setLoading, setErrors });
  const handleSubmit = useHandleSubmit({ scheduleId, classes, schedules, setErrors, setLoading, handleStatusUpdate, setSchedules });

  const handleStatusChange = (event) => {
    const newStatus = event.target.value;
    setSchedules({ ...schedules, status: newStatus });
  };

  const handleCheckboxToggle = async (id, attended) => {
    if (schedules.status === 5) {
      const validationErrors: ValidationError[] = [];
      validationErrors.push({ field: 'assist', message: 'No se puede enviar asistencia para clases canceladas' });
      setErrors(validationErrors);
      setLoading(false);
      return;
    }

    const updatedClasses = classes.map(classItem =>
      classItem.id === id
        ? { ...classItem, attended: attended !== undefined ? attended : (classItem.attended === null ? true : !classItem.attended) }
        : classItem
    );
    setClasses(updatedClasses);

    try {
      setLoading(true);
      const endpoint = `${API_ENDPOINTS.reservation}/attended/${id}/${updatedClasses.find(classItem => classItem.id === id)?.attended}`;
      await postData(endpoint);
    } catch (error) {
      const validationErrors: ValidationError[] = [];
      validationErrors.push({ field: 'assist', message: error.response?.data?.message || 'Error al enviar la asistencia' });
      setErrors(validationErrors);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAllToggle = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setClasses(classes.map(classItem => ({
      ...classItem,
      attended: newSelectAll,
    })));
  };

  const handleBackClick = () => {
    const filterState = {
      dateRange: state?.dateRange,
      classId: state?.classId,
      trainerId: state?.trainerId,
      currentPage: state?.currentPage,
      rows: state?.rows,
      selectedClassLabel: state?.selectedClassLabel,
      selectedTrainerLabel: state?.selectedTrainerLabel,
    };
    if (user.role === 'admin') {
      navigate(routes.classSchedules, { state: filterState, replace: true });
    } else {
      navigate(routes.receptionistSchedules, { state: filterState, replace: true });
    }
  };

  const attendedCount = classes.filter(classItem => classItem.attended === true).length;

  const handleConfirmSave = () => {
    handleSubmit();
    setShowConfirmModal(false);
  };

  const handleSubmitWithConfirmation = () => {
    setErrors([])
    const attendedCustomers = classes.filter(classItem => classItem.attended).map(classItem => classItem.customerId);
    if (attendedCustomers.length === 0) {
      setShowConfirmModal(true);
    } else {
      handleSubmit();
    }
  };

  const handleDeleteClick = (event, classItem) => {
    event.stopPropagation();
    setClassToDelete(classItem);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    setDeleteLoading(true);
    try {
      const reason = user.role === 'admin' ? "removido-por-administrador" : "removido-por-recepcion";
      await deleteData(`${API_ENDPOINTS.reservation}/${classToDelete.id}`, { reason });
      setClasses(prevClasses => prevClasses.filter(classItem => classItem.id !== classToDelete.id));
      setRefreshClasses(true); // Refresh classes after deletion
      setShowDeleteModal(false);
    } catch (err) {
      setDeleteError(err.response?.data?.message || 'Error al eliminar el registro');
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleConfirmAddReservation = async () => {
    if (selectedUser && scheduleId) {
      setAddReservationLoading(true);
      try {
        const response = await postData(API_ENDPOINTS.reservation, {
          scheduleId: parseInt(scheduleId, 10),
          customerId: selectedUser.value,
          profile: user.role // Add the profile field from AuthContext
        });
        setClasses(prevClasses => [...prevClasses, response.data.data]);
        setShowAddReservationModal(false);
      } catch (err) {
        setAddReservationError(err.response?.data?.message || 'Error al agregar la reserva');
      } finally {
        setAddReservationLoading(false);
      }
    }
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteError(null);
  };

  const { data: customers, loading: customersLoading, error: customersError } = useFetch(`${API_ENDPOINTS.users}?fullName=&email=${searchQuery}&profile=customer&active=&birthdate=`);

  useEffect(() => {
    if (customers) {
      const filtered = customers?.content?.filter(customer =>
        customer.email.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setUserSuggestions(filtered?.map(customer => ({
        label: customer.email,
        value: customer.id
      })));
    }
  }, [customers, searchQuery]);

  const handleAddReservation = () => {
    setShowAddReservationModal(true);
  };


  const handleCloseAddReservationModal = () => {
    setShowAddReservationModal(false);
    setSelectedUser(null);
    setAddReservationError(null);
    setSelectedUserLabel('');
  };

  const handleSearchUser = (query) => {
    setSearchQuery(query);
  };

  const handleUserChange = useCallback((value: string) => {
    const selectedUser = userSuggestions.find(option => option.label === value);
    setSelectedUser(selectedUser ? selectedUser : null);
    setSelectedUserLabel(value);
  }, [userSuggestions]);

  return (
    <>
      <div className="page-wrapper page-settings">
      <div className="content">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h4 className="mb-0">Administrar Clase</h4>
          <div>
            <button className="btn btn-primary me-2" onClick={handleAddReservation}>
              Agregar Reserva
            </button>
            <button className="btn btn-secondary" onClick={handleBackClick}>
              <i className="fa fa-arrow-left me-2"></i> Regresar
            </button>
          </div>
        </div>

        <div className="card p-3 shadow-sm mb-3">
          <p className="mb-1"><strong>Entrenador:</strong> {schedules.trainerName}</p>
          <p className="mb-1"><strong>Hora de inicio:</strong> {schedules.hourStart}</p>
          <p className="mb-1"><strong>Hora de fin:</strong> {schedules.hourEnd}</p>
          <p className="mb-0"><strong>Fecha:</strong> {schedules.date}</p>
        </div>


          {scheduleId && (
            <>
              <div className="card review-item mb-3">
                <div className="card-body">
                  <div className="review-info">
                    <div className="row align-items-center justify-content-between row-gap-3">
                      <h6 className="mb-0">Selecciona el estado de la clase {schedules.title}</h6>
                      <div className="col-12 col-md-auto">
                        <DropdownField
                          className={`mb-0 ${schedules.status !== 1 ? "mb-2" : ""}`}
                          options={CLASS_STATUS}
                          value={schedules.status}
                          onChange={handleStatusChange}
                          placeholder="Selecciona el estado de la clase"
                        />
                        {schedules.status !== 1 && (
                          <small className="text-gray mb-2">
                            Al seleccionar esta opción, los clientes ya no podrán inscribirse.
                          </small>
                        )}
                      </div>
                      <div className="col-12 col-md-auto filter-buttons">
                        <button className="btn btn-primary-admin w-100" onClick={handleStatusUpdate}>
                          {loading ? <i className="fa fa-spinner fa-spin me-2"></i> : <i className="fa fa-cog me-2" />}
                          Enviar estado de clase
                        </button>
                      </div>
                    </div>
                    {errors.find((error) => error.field === 'succesStatus') && (
                      <div className="col-12 alert alert-success mb-0 mt-3" role="alert">
                        {errors.find((error) => error.field === 'succesStatus')?.message}
                      </div>
                    )}
                    {errors.find((error) => error.field === 'status') && (
                      <div className="col-12 alert alert-danger mb-0 mt-3" role="alert">
                        {errors.find((error) => error.field === 'status')?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="card review-item mb-3">
                <div className="card-body">
                  <div className="review-info">
                    <div className="row align-items-center justify-content-between row-gap-3">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <h6 className="mb-0">
                          Completa la lista de asistencia 
                          (<span style={{ color: 'green' }}>Asistencias: {attendedCount}</span>) 
                        </h6>
                        <div className="d-flex">
                        {!selectAll ? (
                          <>
                            <button
                                className="btn btn-outline-secondary d-flex align-items-center me-2"
                                onClick={() => handleSelectAllToggle(true)}
                              >
                                <i className="fa fa-check-square me-2"></i>
                                Seleccionar Todo
                              </button>
                          </>
                        ) : (
                          <button
                            className="btn btn-secondary d-flex align-items-center me-2"
                            onClick={() => handleSelectAllToggle(false)}
                          >
                            <i className="fa fa-minus-square me-2"></i>
                            Desmarcar Todo
                          </button>
                        )}
                          <button className="btn btn-primary-admin" onClick={handleAddReservation}>
                            Agregar Reserva
                          </button>
                          </div>

                      </div>
                      <div className="reservation">
                        <ul className="list-group">
                          {classes.length === 0 ? (
                            <li className="list-group-item p-3 text-center">Sin registros</li>
                          ) : (
                            classes
                              .filter(classItem => !classItem.waitlist) // Filter normal list
                              .sort((a: any, b: any) => a.position - b.position) // Sort by position
                              .map((classItem, index) => (
                                <li
                                  key={classItem.id}
                                  className={`list-group-item item-select ${classItem.attended === true ? 'item-select-success' : classItem.attended === false ? 'item-select-warning' : ''} p-3`}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => handleCheckboxToggle(classItem.id)}
                                >
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                      <h6 className="mb-0">{classItem.position}. {classItem.customerName}</h6>
                                      <p className="mt-0 mb-2 fs-7">{classItem.titleClass}</p>
                                      <p className="m-0 fw-bold fs-7">{classItem.conditionMedical || "No tiene condición medica"}</p>
                                    </div>
                                    <div className="d-flex flex-column flex-md-row">
                                      <div className="me-3">
                                        <input
                                          type="checkbox"
                                          checked={classItem.attended === true}
                                          onChange={() => handleCheckboxToggle(classItem.id)}
                                          className="form-check-input"
                                        />
                                        <label className="ms-2">
                                          {classItem.attended ? "Asistió" : "No Asistió" }
                                        </label>
                                      </div>
                                      <button className="btn btn-danger ms-2" onClick={(event) => handleDeleteClick(event, classItem)}>
                                        Eliminar
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              ))
                          )}
                        </ul>

                        {classes.some(classItem => classItem.waitlist) && (
                          <div className="card mt-4">
                            <div className="card-body">
                              <h6 className="mb-3">Usuarios en lista de espera {schedules.status === 5 ? "(no seleccionables)" : "(seleccionables)"}:</h6>
                              <ul className="list-group">
                                {classes
                                  .filter(classItem => classItem.waitlist) // Filter waitlist
                                  .sort((a: any, b: any) => a.position - b.position) // Sort by position
                                  .map((classItem, index) => (
                                    <li
                                      key={classItem.id}
                                      className={`list-group-item item-select ${classItem.attended === true ? 'item-select-success' : classItem.attended === false ? 'bg-warning-light' : ''} p-3`}
                                      style={{ cursor: schedules.status === 5 ? 'default' : 'pointer' }}
                                      onClick={schedules.status !== 5 ? () => handleCheckboxToggle(classItem.id) : undefined}
                                    >
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                          <h6 className="mb-0">{classItem.position}. {classItem.customerName}</h6>
                                          <p className="mt-0 mb-2 fs-7">{classItem.titleClass}</p>
                                          <p className="m-0 fw-bold fs-7">{classItem.conditionMedical || "No tiene condición medica"}</p>
                                        </div>
                                        <div className="d-flex flex-column flex-md-row">
                                          <div className="me-3">
                                            <input
                                              type="checkbox"
                                              checked={classItem.attended === true}
                                              onChange={() => handleCheckboxToggle(classItem.id)}
                                              className="form-check-input"
                                            />
                                            <label className="ms-2">
                                              {classItem.attended ? "Asistió" : "No Asistió" }
                                            </label>
                                          </div>
                                          <button className="btn btn-danger ms-2" onClick={(event) => handleDeleteClick(event, classItem)}>
                                            Eliminar
                                          </button>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        )}

                        {errors.find((error) => error.field === 'assistSuccess') && (
                          <div className="alert alert-success mt-4" role="alert">
                            {errors.find((error) => error.field === 'assistSuccess')?.message}
                          </div>
                        )}
                        {errors.find((error) => error.field === 'assist') && (
                          <div className="alert alert-danger mt-4" role="alert">
                            {errors.find((error) => error.field === 'assist')?.message}
                          </div>
                        )}
                        <button
                          className="btn btn-success mt-4"
                          onClick={handleSubmitWithConfirmation}
                          disabled={loading}
                        >
                          {loading ? <i className="fa fa-spinner fa-spin me-2"></i> : <i className="fa fa-list-check  me-2" />}
                          Confirmar asistencia
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered>
        <Modal.Header className="pb-0" closeButton>
          <Modal.Title>Confirmar Asistencia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>No hay asistencias registradas. ¿Estás seguro de que quieres guardar la asistencia sin ninguna asistencia?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShowConfirmModal(false)}>Cancelar</button>
          <button className="btn btn-primary-admin" onClick={handleConfirmSave}>
            Confirmar asistencia
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
        <Modal.Header className="pb-0" closeButton>
          <Modal.Title>Confirmar Eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Estás seguro de que quieres eliminar este registro? Esta acción no se puede deshacer.</p>
          {classToDelete && (
            <div className="mb-3">
              <p><strong>Nombre del usuario:</strong> {classToDelete.customerName}</p>
              <p><strong>Clase:</strong> {classToDelete.titleClass}</p>
            </div>
          )}
          {deleteError && (
            <div className="alert alert-danger mt-3" role="alert">
              {deleteError}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleCloseDeleteModal}>Cancelar</button>
          <button className="btn btn-danger" onClick={handleConfirmDelete} disabled={deleteLoading}>
            {deleteLoading ? <i className="fa fa-spinner fa-spin me-2"></i> : "Eliminar"}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddReservationModal} onHide={handleCloseAddReservationModal} centered>
        <Modal.Header className="pb-0" closeButton>
          <Modal.Title>Agregar Reserva</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <AutoCompleteDropdown
              label="Seleccionar Usuario"
              value={selectedUserLabel} // Display the label of the selected user
              suggestions={userSuggestions}
              onChange={handleUserChange}
              onSearch={handleSearchUser}
              placeholder="Escribe para buscar..."
            />
            {addReservationError && (
              <div className="alert alert-danger mt-3" role="alert">
                {addReservationError}
              </div>
            )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleCloseAddReservationModal}>Cancelar</button>
          <button className="btn btn-primary" onClick={handleConfirmAddReservation} disabled={!selectedUser || addReservationLoading}>
            {addReservationLoading ? <i className="fa fa-spinner fa-spin me-2"></i> : "Confirmar"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClassAttendanceManagement;