import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import SelectProduct from './selectProduct';
import Location from './location';
import SelectUser from './selectUser';
import { apiRepository } from '../../../api/apiRepository';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const AddPayment = () => {
  const query = useQuery();
  const id = query.get('id');
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>('information');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [campaignData, setCampaignData] = useState(null);
  const [summaryData, setSummaryData] = useState({});
  const [formData, setFormData] = useState({
    user: '',
    purchaseStatus: '',
    coupons: [],
    paymentMethods: '',
    operationNumber : '',
  });

  useEffect(() => {
    if (id) {
      fetchCampaignData(id);
    }
    fetchUsers();
    if (location.state?.reset) {
      setActiveTab('information');
      setSelectedProducts([]);
      setEmailData([]);
      setSummaryData({});
      setFormData({
        user: '',
        purchaseStatus: '',
        coupons: [],
        paymentMethods: '',
        operationNumber : '',
      });
    }
  }, [id, location.state]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await apiRepository.getSubscriptions(0, 10);
      const updatedUsers = response.data.data.content.map((user) => ({
        ...user,
        selected: false,
      }));
      setUsers(updatedUsers);
    } catch (error) {
      console.error('Error al obtener los datos de los usuarios:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCampaignData = async (campaignId) => {
    setLoading(true);
    try {
      const response = await apiRepository.getIdCampaigns(campaignId);
      setCampaignData(response.data.data);
    } catch (error) {
      console.error('Error al obtener los datos de la campaña:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (tab, data) => {
    if (data) {
      setSummaryData((prevData) => ({ ...prevData, ...data }));
      setFormData((prevData) => ({ ...prevData, ...data }));
      if (data.selectedProducts) {
        setSelectedProducts(data.selectedProducts);
      }
    }
    setActiveTab(tab);
  };

  const handleUsersSelection = (selectedProducts) => {
    setSelectedProducts(selectedProducts);
  };

  const handleEmailData = (emailData) => {
    setEmailData(emailData);
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="page-wrapper">
      <div className="content pt-2">
        <ProgressBar activeTab={activeTab} />
        <div className="row">
          <div className="col-lg-12 m-auto">
            {activeTab === 'information' && (
              <SelectProduct
                nextTab={(data) => handleTabChange('seo', data)}
                onUsersSelection={handleUsersSelection}
                selectedProducts={selectedProducts}
              />
            )}
            {activeTab === 'seo' && (
              <SelectUser
                prevTab={() => handleTabChange('information')}
                nextTab={(data) => handleTabChange('location', data)}
                onEmailData={handleEmailData}
                emailData={emailData}
                formData={formData}
                setFormData={setFormData}
                name={campaignData?.name}
                subject={campaignData?.subject}
              />
            )}
            {activeTab === 'location' && (
              <Location
                prevTab={() => handleTabChange('seo')}
                selectedProducts={selectedProducts}
                emailData={emailData}
                summaryData={summaryData}
                campaign={campaignData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ProgressBar = ({ activeTab }: { activeTab: string }) => (
  <div className="progressbar-card">
    <ul id="progress-head">
      <li className={activeTab === 'information' ? 'active' : ''}>
        Agregar productos a la compra
      </li>
      <li className={activeTab === 'seo' ? 'active' : ''}>
        Detalles de la Compra
      </li>
      <li className={activeTab === 'location' ? 'active' : ''}>
        Resumen y Guardar Compra
      </li>
    </ul>
    <ul id="progressbar">
      <li className={activeTab === 'information' ? 'active' : ''}>
        <div className="multi-step-icon">
          <span>
            <i className="fas fa-shopping-cart" />
          </span>
        </div>
        <div className="multi-step-info">
          <h6>Seleccionar Productos</h6>
        </div>
      </li>
      <li className={activeTab === 'seo' ? 'active' : ''}>
        <div className="multi-step-icon">
          <span>
            <i className="fas fa-info-circle" />
          </span>
        </div>
        <div className="multi-step-info">
          <h6>Detalles de la Compra</h6>
        </div>
      </li>
      <li className={activeTab === 'location' ? 'active' : ''}>
        <div className="multi-step-icon">
          <span>
            <i className="far fa-paper-plane" />
          </span>
        </div>
        <div className="multi-step-info">
          <h6>Resumen y Enviar</h6>
        </div>
      </li>
    </ul>
  </div>
);

ProgressBar.propTypes = {
  activeTab: PropTypes.string.isRequired,
};

export default AddPayment;