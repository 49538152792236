import React, { useState, useContext } from 'react';
import CustomerSideBar from './sidebar/sidebar';
import BreadCrumb from '../common/breadcrumb/breadCrumb';
import { Outlet } from 'react-router-dom';
import { AuthContext } from '../../../core/context/AuthContext';

/**
 * Componente de diseño para la gestión de clientes.
 * @returns {JSX.Element} Componente de diseño de clientes.
 */
const CustomerLayout = () => {
    const authContext = useContext(AuthContext);
    if (!authContext) {
        return <div>Error: Auth context not found!</div>;
    }
    const { user } = authContext;
    const [breadcrumbData, setBreadcrumbData] = useState<any>({ title: "", item2: "", updateDays: "" });

    const roleLabels: { [key: string]: string } = {
        customer: "Perfil",
        trainer: "Entrenador",
        admin: "Administrador",
        provider: "Servicios",
        receptionist: "Recepción"
    };

    return (
        <>
            <BreadCrumb
                title={breadcrumbData.title}
                item1={roleLabels[user?.role] || "Perfil"}
                item2={breadcrumbData.item2}
            />
            <div className="">
                <div className="content mt-5">
                    <div className="">
                        <div className="row justify-content-center">
                            <div className="col-xl-2 col-lg-3 col-11 col-md-11 col-sm-10 mb-3" style={{ minWidth: '300px' }}>
                                <CustomerSideBar service={breadcrumbData.updateDays} />
                            </div>
                            <div className="col-xl-8 col-lg-8 col-11 col-md-11 col-sm-10 mb-5">
                                <Outlet context={{ setBreadcrumbData }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomerLayout;