import React from 'react';
import { Modal } from 'react-bootstrap';

type ErrorModalProps = {
  show: boolean;
  onClose: () => void;
  message: string;
};

const ErrorModal: React.FC<ErrorModalProps> = ({ show, onClose, message }) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={onClose}>
          Cerrar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
