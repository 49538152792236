import { useState } from "react";
import { useForm } from "../../hooks/utils/useForm";
import { validateUser } from "../../form/validationForm";
import { ValidationError } from "../../form/validationForm";
import { apiRepository } from "../../../api/apiRepository";
import { closeModal } from "../../utils/modalsUtils";
import { handleError } from "../../utils/errorUtils";
import { useLogin } from "./useLogin";
import { getLocationCode } from "../../services/formService";

export const useRegister = (authLoginContext: any, modalId: string) => {
    const initialFormData = {
        name: "",
        lastname: "",
        email: "",
        phone: "",
        birthdate: "",
        typeDocument: "DNI",
        numberDocument: "",
        conditionMedical: "",
        address: "",
        locationId: "",
        password: "",
        newPassword: "",
        department: "",
        province: "",
        district: "",
        classFree: false,
        isAccepted: false,
        id: 0,
        profile: "customer",
        active: true,
        image: null
    };
    const { formData, handleChange, resetForm: originalResetForm, setFormData } = useForm(initialFormData);
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState<ValidationError[]>([]);
    const { handleLogin } = useLogin(authLoginContext, modalId);

    const resetForm = () => {
        originalResetForm();
        setServerError([]);
    };

    const registerUser = async (event: React.FormEvent) => {
        event.preventDefault();
        setServerError([]);
        if (!formData) return;

        const validationErrors = validateUser(formData);
        if (validationErrors.length > 0) {
            setServerError(validationErrors);
            return;
        }

        try {
            setLoading(true);
            const locationId = await getLocationCode(formData, apiRepository.getLocations);
            const updatedFormData = { ...formData, locationId };
            const response = await apiRepository.createUser({...updatedFormData,
                email: updatedFormData.email.toLowerCase(),
            });
            const { success } = response.data;

            if (success) {
                await handleLogin(event, { email: formData.email.toLowerCase(), password: formData.password });
                closeModal(modalId);
            }
        } catch (error: any) {
            handleError(error, setServerError);
        } finally {
            setLoading(false);
        }
    };

    return { formData, handleChange, resetForm, registerUser, loading, serverError };
};