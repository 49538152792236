import React from 'react';
import ImageWithBaseApi from '../../../core/img/ImageWithBaseApi';

const ProductList: React.FC<{
  filteredPackages: any[];
  toggleSelection: (id: number) => void;
  productIds: number[];
}> = ({ filteredPackages, toggleSelection, productIds }) => (
  <div>
    {filteredPackages?.map((group, index) => {
      const [groupKey, groupPackages] = Object.entries(group)[0];
      if (!Array.isArray(groupPackages) || groupPackages.length === 0) {
        return null;
      }
      return (
        <div key={index} className="row">
          {groupPackages.map((product, index) => (
            <div key={index} className="col-md-4 mb-3">
              <div
                className={`card card-shadow shadow-none mb-0 ${product.selected ? 'border-success-admin bg-light' : ''}`}
                onClick={() => toggleSelection(product.id)}
                style={{
                  cursor: 'pointer',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.03)')}
                onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
              >
                <div className="card-body d-flex justify-content-between align-items-center p-4 py-3 px-3">
                  <div className="row w-100">
                    {productIds?.includes(Number(groupKey)) && (
                      <div className="col-5 col-xxl-3 p-0 ps-2">
                        <ImageWithBaseApi
                          routeName="packet"
                          src={product.image}
                          alt={product.title}
                          loadingIcon={<i className="fa fa-spinner fa-spin" style={{ fontSize: '25px', color: '#5c5c5c', margin: "27px 0px 27px 20px"}}></i>}
                        />
                      </div>
                    )}
                    <div className="col d-flex flex-column justify-content-center">
                      <h6 className="mb-1 text-dark">{product.title}</h6>
                      <p className="text-muted mb-0">S/. {product.price ? product.price.toFixed(2) : 'N/A'}</p>
                    </div>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={product.selected}
                      onChange={() => toggleSelection(product.id)}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    })}
  </div>
);

export default ProductList;
