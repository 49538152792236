import React, { useRef, useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import esLocale from '@fullcalendar/core/locales/es';
import { apiRepository } from '../../../../api/apiRepository';
import { useOutletContext } from 'react-router-dom';
import { AuthContext } from '../../../../core/context/AuthContext';
import { getEventColor } from '../../../../core/services/classService';
import CancelReservationModal from './modalCancelConfirm';
import EventDetailsModal from './modalClassInformation';
import { BreadcrumbContext } from '../../../../core/Interface/contextInterface';
import { getData, postData } from '../../../../core/services/api';
import { closeModal } from '../../../../core/utils/modalsUtils';
import './customer-booking-calendar.css';
import { useProfile } from '../../../../core/context/ProfileContext';

const CustomerBookingCalendar = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { user } = authContext;
  const { refetchPaymentDays, refetchPurchasedServices } = useProfile(); // Use ProfileContext to refetch payment days
  const [searchParams] = useSearchParams();
  const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);
  const [events, setEvents] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const calendarRef = useRef(null);
  const userId = user?.userId;
  const userProfile = user?.role;
  const { setBreadcrumbData } = useOutletContext<BreadcrumbContext>();

  useEffect(() => {
    setBreadcrumbData({ title: "Calendario", item2: "Calendario" });
  }, [setBreadcrumbData]);

  const resetFields = () => {
    setMessage('');
    setMessageType('');
    setLoading(false)
  };

  useEffect(() => {
    const addModalElement = document.getElementById('cancel_appointment');

    addModalElement?.addEventListener('hide.bs.modal', resetFields);

    return () => {
      addModalElement?.removeEventListener('hide.bs.modal', resetFields);
    };
  }, []);

const fetchSchedules = async () => {
  try {
    let response;
    let purchasedData = [];

    if (userProfile === 'customer') {
      if (startDate || endDate) {
        response = await apiRepository.getUserSchedules(userId, startDate, endDate);
      }
      if (startDate || endDate) {
        const purchasedResponse = await getData('purchased-service', { customerId: userId, startDate, endDate });
        purchasedData = purchasedResponse?.data || []; // Asegurar que siempre sea un array
      }
    } else if (userProfile === 'trainer') {
      response = await apiRepository.getTrainerSchedules(userId, startDate, endDate);
    } else {
      response = await apiRepository.getUserSchedules(userId, startDate, endDate);
    }

    const classes = await apiRepository.getClasses();

    const allEvents = userProfile === 'trainer'
      ? [...response.data.data] // Exclude purchasedData for trainers
      : [...response.data.data, ...purchasedData.data.content]; // Combine both datasets for others

    const transformedEvents = allEvents.map(event => {
      const startDate = new Date(`${event.date}T${event.hourStart}:00`);
      const endDate = new Date(`${event.date}T${event.hourEnd}:00`);

      const relatedClass = classes.data.data.find(cls => cls.id === event.classId);

      return {
        title: event.classTypeDescription || event.title,
        classId: event.classId,
        id: event.id,
        serviceId: event.serviceId,
        waitlist: event.waitlist,
        position: event.position,
        fullNameService: event.fullNameService,
        status: event.status,
        start: startDate.toISOString(),
        end: endDate.toISOString() || event.hourEnd,
        trainerName: event.trainerName || event.fullNameService,
        quantity: event.quantity,
        reservation: event.reservation,
        className: event.className,
        isReformer: relatedClass ? relatedClass.isReformer : null,
        ...getEventColor(startDate.toISOString(), event.waitlist),
      };
    });

    setEvents(transformedEvents);
  } catch (error) {
    console.error("Error al obtener los datos de horarios:", error);
  }
};


  const fetchClassList = async (schedulesId: number) => {
    try {
      const response = await apiRepository.getReservation(schedulesId);

      try {
        const classList = response.data.data.map(item => ({
          conditionMedical: item.conditionMedical,
          customerName: item.customerName,
          createdAt: item.createdAt
        }));

        return classList;

      } catch {
        console.error('No se pudo obtener la lista de clases');
      }
    } catch (error) {
      console.error("Error al obtener los datos de horarios:", error);
    }
  };

  const handleDatesSet = (arg) => {
    setStartDate(arg.start.toISOString().split("T")[0]);
    setEndDate(arg.end.toISOString().split("T")[0]);
  };

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const start = calendarApi.view.activeStart;
      const end = calendarApi.view.activeEnd;

      setStartDate(start.toISOString().split("T")[0]);
      setEndDate(end.toISOString().split("T")[0]);
    }
  }, []);

  useEffect(() => {
    fetchSchedules();
  }, [startDate, endDate]);

  const confimrCancelReservation = async (reason: string) => {
    try {
      const reservation = await apiRepository.getReservation(eventDetails.id);
      const listUsers = reservation.data.data;
      const userReservation = listUsers.find(user => user.customerId === userId);

      const currentDate = new Date();
      const classDate = new Date(eventDetails.start);

      if (classDate < currentDate) {
        setMessage("No se puede cancelar clases que ya pasaron.");
        setMessageType("error");
        return;
      }

      setLoading(true)
      let deleteResponse

      if (user?.role == "trainer") {
        deleteResponse = await apiRepository.deleteSchedule(eventDetails.id);
      } else if (eventDetails.serviceId) {
        deleteResponse = await postData('purchased-service/cancel-reservation', {
          customerId: userId,
          scheduleServiceId: eventDetails.id
        });
      } else {
        deleteResponse = await apiRepository.deleteReservation(userReservation.id, reason);
      }

        closeModal("cancel_appointment")
        handleEventDetailsClose()
        refetchPaymentDays();
        refetchPurchasedServices();
        fetchSchedules();

        setMessage("Reservación cancelada con éxito.");
        setMessageType("success");

    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        const cleanedMessage = error.response.data.message.replace(/^Bad request: /, '');
        setMessage(cleanedMessage);
      } else {
        setMessage('Hubo un error al intentar cancelar la reservación.');
      }
      setMessageType("error");
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, []);

  const handleEventClick = async (info) => {
    const assistList = await fetchClassList(info.event.id);
    setEventDetails({
      assistList: assistList,
      title: info.event.title,
      date: info.event.extendedProps.date,
      start: info.event.start,
      end: info.event.end,
      id: info.event.id,
      waitlist: info.event.extendedProps.waitlist,
      position: info.event.extendedProps.position,
      serviceId: info.event.extendedProps.serviceId,
      classId: info.event.extendedProps.classId,
      isReformer: info.event.extendedProps.isReformer,
      location: info.event.extendedProps.location || "No especificado",
      userName: info.event.extendedProps.userName || "Anónimo",
      trainerName: info.event.extendedProps.trainerName || "Desconocido",
      quantity: info.event.extendedProps.quantity || 0,
      reservation: info.event.extendedProps.reservation || 0,
      className: info.event.extendedProps.className || "Descripción no disponible",
      status: info.event.extendedProps.status || 0,
    });
    setShowEventDetailsModal(true);
  };

  const handleEventDetailsClose = () => {
    setShowEventDetailsModal(false);
    setLoading(false)
  };

  const startOfWeek = new Date();
  startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
  const initialView = searchParams.get('type') || 'timeGridWeek';

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);
    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3 mb-4">
        <h4>Clases</h4>
        {message && messageType == "success" && (
          <div className={`alert alert-success w-100`} role="alert">
            ¡La clase se cancelo con exito!
          </div>
        )}
      </div>
      <div id="calendar-container" className="calendar-container">
        <div id="calendar-book">
          <FullCalendar
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin
            ]}
            initialView={initialView}
            events={events}
            headerToolbar={{
              left: 'title, prev,today next',
              right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
            }}
            locale="es"
            locales={[esLocale]}
            eventClick={handleEventClick}
            datesSet={handleDatesSet}
            ref={calendarRef}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              meridiem: 'short',
            }}
            slotLabelFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: isMobile ? false : true,
            }}
            allDaySlot={false}
            slotMinTime="05:00:00"
            slotMaxTime="22:00:00"
            views={{
              timeGridWeek: {
                dayHeaderFormat: isMobile && { weekday: 'short' },
              },
            }}
          />
        </div>
      </div>
      <EventDetailsModal
        isOpen={showEventDetailsModal}
        eventDetails={eventDetails}
        onClose={handleEventDetailsClose}
        userRole={user?.role}
      />
      <CancelReservationModal
        onConfirm={confimrCancelReservation}
        loading={loading}
        messageType={messageType}
        message={message}
      />
      <div className={`sidebar-overlay ${showEventDetailsModal && 'opened'}`} onClick={handleEventDetailsClose}></div>
    </>
  );
};

export default CustomerBookingCalendar;