import React, { useRef, useEffect, useContext, useState } from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import { AuthContext } from '../../../../core/context/AuthContext';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { BreadcrumbContext } from '../../../../core/Interface/contextInterface';
import useFetch from '../../../../core/hooks/api/useFetch';
import API_ENDPOINTS from '../../../../core/constant/API_ENDPOINTS';

/**
 * Componente para la gestión de asistencia del entrenador.
 * @returns {JSX.Element} Componente de gestión de asistencia.
 */
const TrainerAttendanceManagement = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { user } = authContext;
  const [classes, setClasses] = useState([]);
  const location = useLocation();
  const state = location.state as { dateRange: any, status: string, filterFuture: boolean };
  const queryParams = new URLSearchParams(location.search);
  const scheduleId = useRef(queryParams.get('id'));
  const { setBreadcrumbData } = useOutletContext<BreadcrumbContext>();
  const navigate = useNavigate();
  const { data: schedulesData } = useFetch(API_ENDPOINTS.schedules + "/" + scheduleId.current)
  const routes = all_routes;

  useEffect(() => {
    if (user?.role !== 'trainer' && user?.role !== 'admin') {
      navigate(routes.customerDashboard);
    }
  }, [user, navigate, routes.customerDashboard]);

  useEffect(() => {
    if (!scheduleId.current) {
      navigate(routes.trainerSchedules);
    }
  }, [scheduleId, navigate, routes.trainerSchedules]);

  useEffect(() => {
    setBreadcrumbData({
      title: 'Clientes Registrados',
      item2: 'Clientes Registrados',
      updateDays: false
    });
  }, [setBreadcrumbData]);

  const fetchClasses = async (scheduleId) => {
    try {
      const response = await apiRepository.getReservation(scheduleId);
      setClasses(response.data.data);
    } catch (error) {
      console.error('Error al obtener los datos de la asistencia:', error);
    }
  };

  useEffect(() => {
    if (scheduleId.current) {
      fetchClasses(scheduleId.current);
    }
  }, [scheduleId]);

  return (
    <div className="page-wrapper page-settings">
      <div className="content pt-0 w-100">
        {scheduleId.current && (
          <div className="card card-shadow  review-item mb-3">
            <div className="card-body review-info">
              <div className="row align-items-center justify-content-between row-gap-3">
                <div className="d-flex justify-content-between align-items-center w-100">
                  <h6 className="mb-0">Clientes Registrados</h6>
                  <button
                    className="btn btn-secondary"
                    onClick={() => navigate(routes.trainerSchedules, { state: { dateRange: state.dateRange, status: state.status, filterFuture: state.filterFuture } })}
                  >
                    <i className="fa fa-arrow-left me-2"></i>
                    Regresar
                  </button>
                </div>
                <div className="card p-3 shadow-sm mb-3">
                  <p className="mb-1"><strong>Hora de inicio:</strong> {schedulesData?.hourStart}</p>
                  <p className="mb-1"><strong>Hora de fin:</strong> {schedulesData?.hourEnd}</p>
                  <p className="mb-0"><strong>Fecha:</strong> {schedulesData?.date}</p>
                </div>
                <div className="reservation">
                  <ul className="list-group">
                    {classes.length === 0 ? (
                      <li className="list-group-item p-3 text-center">Sin registros</li>
                    ) : (
                      <>
                        {classes
                          .filter(classItem => !classItem.waitlist) // Filter normal list
                          .sort((a: any, b: any) => a.position - b.position) // Sort by position
                          .map((classItem: any, index: number) => (
                            <li
                              key={classItem.id}
                              className="list-group-item item-select p-3"
                              style={{ cursor: 'pointer' }}
                            >
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <h6>{index + 1}. {classItem.customerName}</h6>
                                  <p className="mt-1">{classItem.titleClass}</p>
                                  <p className="mt-1">
                                    {classItem.medicalSpecifications
                                      ? `Condición Médica / Embarazo: ${classItem.medicalSpecifications}`
                                      : 'No hay condiciones médicas'}
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))}
                        {classes.some(classItem => classItem.waitlist) && (
                          <div className="card mt-4">
                            <div className="card-body">
                              <h6 className="mb-3">Usuarios en lista de espera:</h6>
                              <ul className="list-group">
                                {classes
                                  .filter(classItem => classItem.waitlist) // Filter waitlist
                                  .sort((a: any, b: any) => a.position - b.position) // Sort by position
                                  .map((classItem: any, index: number) => (
                                    <li
                                      key={classItem.id}
                                      className="list-group-item item-select p-3"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                          <h6>{index + 1}. {classItem.customerName}</h6>
                                          <p className="mt-1">{classItem.titleClass}</p>
                                          <p className="mt-1">
                                            {classItem.medicalSpecifications
                                              ? `Condición Médica / Embarazo: ${classItem.medicalSpecifications}`
                                              : 'No hay condiciones médicas'}
                                          </p>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrainerAttendanceManagement;