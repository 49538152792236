import React, { useState } from 'react';
import ConfirmEmailModal from '../common/modals/confirm-modal-action';
import ErrorModal from '../common/modals/error-modal';
import { Link, useNavigate } from 'react-router-dom';
import { getCurrentLocalDate, convertDateToLocalDateString } from '../../../core/utils/dateUtils';
import { closeModal } from '../../../core/utils/modalsUtils';
import { handleError } from '../../../core/utils/errorUtils';
import { ValidationError } from '../../../core/form/validationForm';
import { useLocation } from 'react-router-dom';
import { all_routes } from '../../../core/data/routes/all_routes';
import SuccessEmailModal from '../common/modals/success-buy-modal-admin';
import ImageWithBaseApi from '../../../core/img/ImageWithBaseApi';
import useFetch from '../../../core/hooks/api/useFetch';
import API_ENDPOINTS from '../../../core/constant/API_ENDPOINTS';
import { apiRepository } from '../../../api/apiRepository';
import { purchaseStatusOptions, paymentMethodOptions } from '../../../core/data/options/options';

type Props = {
  prevTab: CallableFunction;
  selectedProducts: any[];
  emailData: any[];
  summaryData: any;
};
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const getLabel = (options, value) => {
  const option = options.find(option => option.value === value);
  return option ? option.label : value;
};

const EmailSummary: React.FC<Props> = ({ prevTab, selectedProducts, emailData, summaryData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const query = useQuery();
  const id = query.get('id');
  const navigate = useNavigate();
  const routes = all_routes
  const [couponCode, setCouponCode] = useState<string>('');
  const [couponDiscount, setCouponDiscount] = useState<any>({});
  const [info, setInfo] = useState<any[]>([]);
  const [loadingCoupon, setLoadingCoupon] = useState<boolean>(false);
  const [products, setProducts] = useState(selectedProducts.map(product => ({
    ...product,
    quantity: 1
  })));

  const increaseQuantity = (productId: string) => {
    setProducts(products.map(product => {
      if (product.id === productId) {
        const newQuantity = product.quantity + 1;
        const newAmountDiscount = (product.amountDiscount / product.quantity) * newQuantity;
        return { ...product, quantity: newQuantity, amountDiscount: newAmountDiscount || 0 };
      }
      return product;
    }));
  };

  const decreaseQuantity = (productId: string) => {
    setProducts(products.map(product => {
      if (product.id === productId && product.quantity > 1) {
        const newQuantity = product.quantity - 1;
        const newAmountDiscount = (product.amountDiscount / product.quantity) * newQuantity
        return { ...product, quantity: newQuantity, amountDiscount: newAmountDiscount || 0 };
      }
      return product;
    }));
  };

  const calculateTotalAmount = () => {
    const total = products.reduce((total, product) => total + (product.price * product.quantity - (product.amountDiscount || 0)), 0);
    return total - (couponDiscount.discount || 0);
  };

  const sendMail = async () => {
    setInfo([]);
    const totalAmount = calculateTotalAmount();
    const totalDiscount = couponDiscount.discount || 0;
    const discountPerProduct = couponDiscount.discount / products.length;
    const paymentDetails = products.map(product => {
      return {
        packetId: product.id,
        quantity: product.quantity,
        amount: (product.price * product.quantity) - (Number(product.amountDiscount || 0) + Number(discountPerProduct || 0)),
        amountDiscount: product.amountDiscount || 0,
        coupon: product.coupon || '' // Include individual coupon code if applicable
      };
    });

    const totalAmountDiscount = products.reduce((total, product) => total + (product.amountDiscount || 0), 0) + totalDiscount;

    const paymentData = {
      userId: summaryData.user.value,
      jsonResponse: 'LIFT WEB',
      status: 'COMPLETED',
      amount: totalAmount,
      amountDiscount: totalAmountDiscount,
      paymentDetails,
      codesCoupons: couponDiscount.code ? [ couponDiscount.code ] : [],
      typePayment: summaryData.paymentMethods,
      paymentMpId: summaryData.paymentMethods === 'MERCADO PAGO' ? summaryData.operationNumber : undefined,
      operationNumber: Number(summaryData.operationNumber) || '',
    };

    try {
      setLoading(true);

      await apiRepository.createPayments(paymentData);
      setShowConfirmModal(false);
      setShowSuccessModal(true);
    } catch (error) {
      setErrorMessage(error.response.data.message || 'Error al guardar el pago. Por favor, inténtelo de nuevo.');
      setShowConfirmModal(false);
      setShowSuccessModal(false);
      setShowErrorModal(true);
      handleError(error, setErrors, "confirm-email");
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = () => {
    setShowConfirmModal(true);
  };

  const handleConfirmModalClose = () => {
    setShowConfirmModal(false);
  };

  const handleSuccessConfirm = () => {
    setShowSuccessModal(false);
    if (query.get('retry') === 'true') {
      navigate(routes.salesHistory);
    } else {
      setProducts(selectedProducts.map(product => ({
        ...product,
        quantity: 1,
        amountDiscount: 0,
        coupon: ''
      })));
      setCouponCode('');
      setCouponDiscount({});
      setInfo([]);
      navigate(routes.addPayment, { state: { reset: true } });
    }
  };

  const handleErrorModalClose = () => {
    setShowErrorModal(false);
  };

  const validateCoupon = async () => {
    setLoadingCoupon(true);
    try {
      const response = await apiRepository.validateCouponCode(couponCode);
      const couponData = response.data.data;

      if (!couponData.typeGeneralProduct && couponDiscount.typeGeneralProduct) {
        setInfo([{ field: 'error', message: 'Ya se ha aplicado un cupón general.' }]);
        setLoadingCoupon(false);
        return;
      }

      if (!couponData.typeGeneralProduct && Object.keys(couponDiscount).length > 0) {
        setInfo([{ field: 'error', message: 'Ya se ha aplicado un cupón general.' }]);
        setLoadingCoupon(false);
        return;
      }

      if (couponData.typeGeneralProduct) {
        // Apply discount to specific product
        const productExists = products.some(product => product.id === couponData.packetId);
        if (!productExists) {
          setInfo([{ field: 'error', message: 'El producto especificado en el cupón no está en los productos seleccionados.' }]);
          setLoadingCoupon(false);
          return;
        }
        const productAlreadyDiscounted = products.some(product => product.id === couponData.packetId && product.amountDiscount);
        if (productAlreadyDiscounted) {
          setInfo([{ field: 'error', message: 'Ya se ha aplicado un descuento a este producto.' }]);
          setLoadingCoupon(false);
          return;
        }

        setProducts(products.map(product => {
          if (product.id === couponData.packetId) {
            product.coupon = couponData.code; // Store individual coupon code
            if (couponData.typeDiscount) {
              product.amountDiscount = (product.price * (couponData.amount / 100)) * product.quantity;
            } else {
              product.amountDiscount = couponData.amount * product.quantity;
            }
          }
          return product;
        }));

      } else {
        // Apply discount to total amount
        let discount = 0;
        if (couponData.typeDiscount) {
          discount = calculateTotalAmount() * (couponData.amount / 100);
        } else {
          discount = couponData.amount;
        }
        setCouponDiscount({ ...couponData, discount });
      }

      setInfo([{ field: 'success', message: 'Cupón aplicado correctamente' }]);
    } catch (error) {
      setInfo([{ field: 'error', message: 'Error al aplicar el cupón' }]);
    } finally {
      setLoadingCoupon(false);
    }
  };

  const removeCoupon = () => {
    setCouponDiscount({});
    setCouponCode('');
    setProducts(products.map(product => {
      product.amountDiscount = 0;
      product.coupon = '';
      return product;
    }));
    setInfo([]);
  };

  return (
    <>
      <div className="addition-service card-section space-service">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-service">
              <h4 className="text-secondary mb-2">Resumen de la Compra</h4>
            </div>
          </div>
          <div className="col-md-7">
            <div className="form-group">
              <label>Usuario</label>
              <input
                type="text"
                className="form-control"
                value={summaryData.user.label}
                readOnly
              />
            </div>
            <div className="form-group">
              <label>Métodos de Pago</label>
              <input
                type="text"
                className="form-control"
                value={getLabel(paymentMethodOptions, summaryData.paymentMethods)}
                readOnly
              />
            </div>
            <div className="form-group">
              <label>Número de Operación</label>
              <input
                type="text"
                className="form-control"
                value={summaryData.operationNumber}
                readOnly
              />
            </div>
          </div>
          <div className="col-md-5">
            <div className="form-group">
              <h5 className="mb-2">Productos Seleccionados</h5>
              <ul className="list-group">
                {products.map((product) => (
                  <li key={product.id} className="list-group-item">
                    <div className="d-flex align-items-center">
                      {product.image && (
                        <ImageWithBaseApi routeName="packet" src={product.image} alt={product.title} className="me-2 p-1" style={{ width: '60px', height: '60px' }} />
                      )}
                      <div>
                        <h6 className="mb-0">
                          {product.amountDiscount ? (
                            <>
                              <span style={{ textDecoration: 'line-through' }}>S/.{product.price}</span> - S/.{(product.price * product.quantity) - product.amountDiscount}
                            </>
                          ) : (
                            `S/.${product.price * product.quantity}`
                          )}
                        </h6>
                          {product.amountDiscount > 0 && (
                            <>
                              <small className="text-success">Descuento: S/.{product.amountDiscount}</small>
                              <br />
                            </>
                          )}
                        <small>{product.subtitle}</small>
                      </div>
                      <div className="ms-auto">
                        <button className="btn btn-sm btn-outline-secondary me-2" onClick={() => decreaseQuantity(product.id)}>-</button>
                        <span>{product.quantity}</span>
                        <button className="btn btn-sm btn-outline-secondary ms-2" onClick={() => increaseQuantity(product.id)}>+</button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="form-group mt-3">
              <h5 className="mb-2">Detalles Generales</h5>
              <input
                type="text"
                className="form-control"
                value={`Total de la Compra: S/.${calculateTotalAmount()}`}
                readOnly
              />
              {couponDiscount.discount && (
                <input
                  type="text"
                  className="form-control mt-2"
                  value={`Descuento General: S/.${couponDiscount.discount}`}
                  readOnly
                />
              )}
            </div>
            <div className="form-group mt-3">
              <h5 className="mb-2">Aplicar Cupón</h5>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Código de Cupón"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                />
                <button className="btn btn-primary" onClick={validateCoupon} disabled={loadingCoupon}>
                  {loadingCoupon ? <i className="fa fa-spinner fa-spin"></i> : 'Aplicar'}
                </button>
              </div>
              {couponDiscount && (
                <div className="mt-2">
                  <button className="btn btn-danger" onClick={removeCoupon}>
                    Eliminar Cupón
                  </button>
                </div>
              )}
              {info.length > 0 && (
                <div className={`alert ${info[0].field === 'success' ? 'alert-success' : 'alert-danger'} mt-3`}>
                  {info[0].message}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="bottom-btn">
            <div className="field-btns">
              <button
                className="btn btn-prev prev_btn"
                type="button"
                onClick={prevTab}
              >
                <i className="fas fa-arrow-left" /> Anterior
              </button>
              <button
                className="btn btn-primary-admin next_btn"
                type="button"
                onClick={handleConfirm}
                disabled={loading}
              >
                {loading ? 'Guardando...' : 'Guardar y Enviar'} <i className="fas fa-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmEmailModal
        onConfirm={sendMail}
        onCancel={handleConfirmModalClose}
        error={[]}
        loading={loading}
        show={showConfirmModal}
        message="¿Estás seguro de que deseas confirmar la compra?"
      />
      <SuccessEmailModal show={showSuccessModal} onClose={() => setShowSuccessModal(false)} onConfirm={handleSuccessConfirm} />
      <ErrorModal show={showErrorModal} onClose={handleErrorModalClose} message={errorMessage} />
    </>
  );
};

export default EmailSummary;