import React, { useState, useEffect, useContext } from 'react';
import { Schedules } from '../../../../core/Interface/repositoryInterfaces';
import DataTableWrapper from '../../../admin/common/dataTable/dataTableWrapper';
import { useOutletContext } from 'react-router-dom';
import { AuthContext } from '../../../../core/context/AuthContext';
import { formatTimeTo12Hour } from '../../../../core/utils/utils';
import { getDefaultWeekRange } from '../../../../core/utils/dateUtils';
import { convertDateToLocalDateString } from '../../../../core/utils/dateUtils';
import { useLocation, useNavigate } from 'react-router-dom';
import { Eye } from 'react-feather';
import { all_routes } from '../../../../core/data/routes/all_routes';
import Filters from './schedulesFilters';
import { isFutureClass } from '../../../../core/utils/classUtils';
import { BreadcrumbContext } from '../../../../core/Interface/contextInterface';
import { getData } from '../../../../core/services/api';
import API_ENDPOINTS from '../../../../core/constant/API_ENDPOINTS';
import ConfirmActionModal from '../../../components/modal/ConfirmActionModal';
import BaseModal from '../../../components/modal/baseModal';
import usePut from '../../../../core/hooks/api/usePut';
import SchedulesModal from '../../../admin/common/modals/schedules-modal';
import { useFetchTrainers } from '../../../../core/hooks/getData/useFetchTrainers';

/**
 * Componente para mostrar los horarios del entrenador.
 * @returns {JSX.Element} Componente de perfil del cliente.
 */
const CustomerProfile = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }
  const { user } = authContext;
  const [data, setData] = useState<Schedules[]>([]);
  const { setBreadcrumbData } = useOutletContext<BreadcrumbContext>();
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { dateRange: any; status: string; filterFuture: boolean; };
  const [filterFuture, setFilterFuture] = useState(state?.filterFuture || false);
  const [status, setStatus] = useState<string>(state?.status || '');
  const [dateRange, setDateRange] = useState(state?.dateRange || getDefaultWeekRange());
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showBaseModal, setShowBaseModal] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState<any>(null);
  const [confirmAction, setConfirmAction] = useState<'edit' | 'cancel' | null>(null);
  const [cannotFinalizeMessage, setCannotFinalizeMessage] = useState<string | null>(null);
  const { put, loading: loadingService, error: errorService, setError: setErrorService } = usePut(API_ENDPOINTS.aditionalServices);
  const [showSchedulesModal, setShowSchedulesModal] = useState(false);
  const [trainers, setTrainers] = useState<any>();

  const routes = all_routes;

  const trainerEndpoint = `${API_ENDPOINTS.schedules}/trainer/${user?.userId}`;
  const providerEndpoint = `${API_ENDPOINTS.aditionalServices}`;
  const { fetchTrainers } = useFetchTrainers({ setTrainers });

  useEffect(() => {
    setBreadcrumbData({ title: "Horarios", item2: "Horarios", updateDays: false });
    fetchTrainers();
  }, [setBreadcrumbData]);

  const fetchSchedules = async () => {
    try {
      const [startDate, endDate] = dateRange;

      if (!startDate || !endDate) {
        return;
      }

      const params = {
        startDate: convertDateToLocalDateString(startDate),
        endDate: convertDateToLocalDateString(endDate)
      };

      if (user?.role === "trainer") {
        const response = await getData(trainerEndpoint, params);
        const filteredSchedules = filterSchedules(response.data.data);
        setData(filteredSchedules);
      } else if (user?.role === "provider") {
        const providerParams = { ...params, userId: user?.userId };
        const response = await getData(providerEndpoint, providerParams);
        const filteredSchedules = filterSchedules(response.data.data.content);
        setData(filteredSchedules);
      }
    } catch (error) {
      console.error("Error fetching schedules:", error);
    }
  };

  const filterSchedules = (schedules: any) => {
    return schedules?.filter(schedule => {
      const passesFutureFilter = !filterFuture || isFutureClass(schedule);
      const passesStatusFilter = !status || schedule.status === parseInt(status);

      return passesFutureFilter && passesStatusFilter;
    });
  };

  useEffect(() => {
    fetchSchedules();
  }, [filterFuture, dateRange, status]);

  const formatReservation = (reservation: number) => {
    return reservation > 0 ? reservation : 'Sin reservas';
  };

  const columns = [
    {
      field: "date",
      header: "Fecha y Horario",
      sortable: true,
      body: (rowData) => {
        return (
          <span>
            {rowData.date} - {formatTimeTo12Hour(rowData.hourStart)} a {formatTimeTo12Hour(rowData.hourEnd)}
          </span>
        );
      }
    },
    {
      field: "reservation",
      header: "Reservas",
      body: (rowData) => formatReservation(rowData.reservation),
    },
    { field: "classTypeDescription", header: "Tipo de clase" },
    { field: "title", header: "Nombre" },
    {
      field: "status",
      header: "Estado",
      body: (rowData) => {
        let label = '';
        let className = '';

        switch (rowData.status) {
          case 1:
            label = 'Pendiente';
            className = 'badge-pending';
            break;
          case 2:
            label = 'Lleno';
            className = 'badge-active';
            break;
          case 3:
            label = 'Terminado';
            className = 'badge-default';
            break;
          case 4:
            label = 'Cancelado';
            className = 'badge-delete';
            break;
          case 5:
              label = 'Asistencia finalizada';
              className = 'badge-active';
              break;
          default:
            label = 'Desconocido';
            className = 'badge-default';
        }

        return <span className={className}>{label}</span>;
      }
    },
    { field: "quantity", header: "Limite de Cupos" },
  ];

  const serviceColumns = [
    {
      field: "date",
      header: "Fecha y Horario",
      sortable: true,
      body: (rowData) => {
        return (
          <span>
            {rowData.date} - {formatTimeTo12Hour(rowData.hourStart)} a {formatTimeTo12Hour(rowData.hourEnd)}
          </span>
        );
      }
    },
    { field: "title", header: "Nombre del Servicio" },
    {
      field: "fullNameCustomer",
      header: "Nombre Completo del Cliente",
      body: (rowData) => rowData.fullNameCustomer || 'Sin reserva aún',
    },
    { field: "namePacket", header: "Nombre del Paquete" },
    { field: "quantity", header: "Cantidad" },
    {
      field: "status",
      header: "Estado",
      body: (rowData) => {
        let label = '';
        let className = '';

        switch (rowData.status) {
          case 1:
            label = 'Pendiente';
            className = 'badge-pending';
            break;
          case 2:
            label = 'Lleno';
            className = 'badge-active';
            break;
          case 3:
            label = 'Terminado';
            className = 'badge-default';
            break;
          case 4:
            label = 'Cancelado';
            className = 'badge-delete';
            break;
          default:
            label = 'Desconocido';
            className = 'badge-default';
        }

        return <span className={className}>{label}</span>;
      }
    },
  ];

  const handleCustomActionClick = (rowData: any) => {
    navigate(`${routes.schedulesList}?id=${rowData.id}`, { state: { dateRange: dateRange, status: status, filterFuture: filterFuture } });
  };
  const handleDateChange = (e) => {
    setDateRange(e.value);
  };

  const handleEditSchedule = (rowData: any) => {
    setSelectedSchedule(undefined);
    setTimeout(() => {
      setSelectedSchedule(rowData);
      if (user?.role === "trainer") {
        setShowBaseModal(false);
        setShowConfirmModal(false);
        setShowSchedulesModal(true);
      } else {
        setConfirmAction('edit');
        setCannotFinalizeMessage(null);
        setShowConfirmModal(true);
      }
    }, 0);
  };

  const handleCancelSchedule = (rowData: any) => {
    setSelectedSchedule(rowData);
    if (rowData.reservation > 0 || rowData.status !== 1) {
      setShowBaseModal(true);
    } else {
      setConfirmAction('cancel');
      setShowConfirmModal(true);
    }
  };

  const confirmActionHandler = async () => {
    try {
      if (confirmAction === 'edit' && (selectedSchedule.status === 1 || selectedSchedule.status === 4)) {
        setCannotFinalizeMessage("No se puede finalizar una clase que no está llena o ya está cancelada.");
        return;
      }

      const updatedSchedule = {
        date: selectedSchedule.date,
        hourStart: selectedSchedule.hourStart,
        hourEnd: selectedSchedule.hourEnd,
        title: selectedSchedule.title,
        serviceId: selectedSchedule.serviceId,
        status: confirmAction === 'edit' ? 3 : 4,
        packetId: selectedSchedule.packetId
      };
      await put(selectedSchedule.id, updatedSchedule);
      setShowConfirmModal(false);
      fetchSchedules();
    } catch (error) {
      console.error(`Error ${confirmAction} schedule:`, error);
    }
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
    setErrorService(null);
    setCannotFinalizeMessage(null);
  };

  return (
    <>
      <h4>Clases</h4>
      <div className="">
        <div className="d-flex justify-content-between mt-3 mb-3">
          <div className="filter-buttons me-1">
            <button
              className={`btn ${filterFuture ? 'btn-primary-solid' : 'btn-light'}`}
              onClick={() => setFilterFuture(prev => !prev)}>
              <i className="fa fa-eye me-2" />
              {filterFuture ? 'Mostrar todas las clases' : 'Mostrar solo clases futuras'}
            </button>
          </div>
          <div className="list-btn">
            <button
              className="btn btn-outline-info"
              onClick={() => setFiltersVisible(!filtersVisible)}
            >
              {filtersVisible ? 'Ocultar Filtros' : 'Mostrar Filtros'}
            </button>
          </div>
        </div>
      </div>

      {filtersVisible && (
        <Filters
          status={status}
          setStatus={setStatus}
          dateRange={dateRange}
          setDateRange={setDateRange}
          handleDateChange={handleDateChange}
          filterFuture={filterFuture}
          setFilterFuture={setFilterFuture}
        />
      )}

      <div className="row">
        <div className="col-12">
          <DataTableWrapper
            data={data}
            columns={user?.role === "provider" ? serviceColumns : columns}
            onEdit={user?.role === "provider" || user?.role === "trainer" ? handleEditSchedule : undefined}
            onDelete={user?.role === "provider" ? handleCancelSchedule : undefined}
            onCustomAction={user?.role === "trainer" ? handleCustomActionClick : undefined}
            customIcon={user?.role === "trainer" ? Eye : undefined}
            lazy={false}
            paginator={false}
            sortField="date"
            sortOrder={-1}
            showGridlines
            emptyMessage="No se encontraron horarios."
          />
        </div>
      </div>
      <ConfirmActionModal
        show={showConfirmModal}
        onClose={handleCloseConfirmModal}
        onConfirm={confirmActionHandler}
        loading={loadingService}
        error={errorService?.response?.data}
        title={confirmAction === 'edit' ? "Terminar Servicio" : "Confirmar Cancelación del Servicio"}
        text={confirmAction === 'edit' ? "¿Estás seguro de que deseas terminar este servicio?" : "¿Estás seguro de que deseas cancelar esta sesión?"}
      >
        {confirmAction === 'edit' && (
          <div className="text-center">
            <i className="fas fa-check-circle text-success fa-3x mb-3"></i>
            <p>Detalles del servicio:</p>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Fecha</th>
                  <td>{selectedSchedule?.date}</td>
                </tr>
                <tr>
                  <th>Hora de inicio</th>
                  <td>{selectedSchedule?.hourStart}</td>
                </tr>
                <tr>
                  <th>Hora de fin</th>
                  <td>{selectedSchedule?.hourEnd}</td>
                </tr>
                <tr>
                  <th>Título</th>
                  <td>{selectedSchedule?.title}</td>
                </tr>
                <tr>
                  <th>Cliente</th>
                  <td className={selectedSchedule?.fullNameCustomer ? "" : "text-danger"}>
                    {selectedSchedule?.fullNameCustomer || "Sin reserva"}
                  </td>
                </tr>
              </tbody>
            </table>
            {cannotFinalizeMessage && (
              <p className="text-danger mt-2">{cannotFinalizeMessage}</p>
            )}
          </div>
        )}
      </ConfirmActionModal>
      <BaseModal
        show={showBaseModal}
        onClose={() => setShowBaseModal(false)}
        title="No se puede cancelar la sesión"
        onConfirm={() => setShowBaseModal(false)}
        showButtons={false}
      >
        <div className="text-center">
          <i className="fas fa-exclamation-circle text-danger fa-3x mb-3"></i>
          <p>No se puede cancelar la sesión porque ya hay un usuario registrado o la sesión ya ha finalizado.</p>
        </div>
      </BaseModal>
      <SchedulesModal
        selectedSchedule={selectedSchedule}
        onUpdateSuccess={fetchSchedules}
        show={showSchedulesModal}
        onClose={() => setShowSchedulesModal(false)}
        trainerList={trainers}
      />
    </>
  );
};

export default CustomerProfile;