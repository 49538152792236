/**
 * Datos de la Barra Lateral
 * 
 * Este archivo contiene los datos para las barras laterales de clientes y entrenadores. Cada array de datos de la barra lateral incluye los enlaces,
 * nombres e íconos para los elementos de la barra lateral.
 * 
 * customerSidebarData:
 * - link: URL para el elemento de la barra lateral.
 * - name: Nombre para mostrar del elemento de la barra lateral.
 * - icon: Componente de ícono para el elemento de la barra lateral.
 * 
 * trainerSidebarData:
 * - link: URL para el elemento de la barra lateral.
 * - name: Nombre para mostrar del elemento de la barra lateral.
 * - icon: Componente de ícono para el elemento de la barra lateral.
 * 
 * Los arrays de datos de la barra lateral se utilizan para renderizar la navegación de la barra lateral para clientes y entrenadores.
 */

import React from 'react';
import * as Icon from 'react-feather';
import { all_routes } from '../../../../core/data/routes/all_routes';

export const customerSidebarData = [
  {
    link: all_routes.customerDashboard,
    name: 'Información Personal',
    icon: <i className="ti ti-layout-grid me-3" />,
  },
  {
    link: all_routes.customerCalendar,
    name: 'Agenda',
    icon: <Icon.Calendar className="sidebar-feather me-3" />,
  },
  {
    link: all_routes.customerCart,
    name: 'Carrito',
    icon: <Icon.ShoppingCart className="sidebar-feather me-3" />,
  },
  {
    link: all_routes.customerReservation,
    name: 'Reservar clase',
    icon: <Icon.Briefcase className="sidebar-feather me-3" />,
  },
  {
    link: all_routes.customerFreezeRequests,
    name: 'Consultas de Congelamiento',
    icon: <Icon.PauseCircle className="sidebar-feather me-3" />,
  },
  {
    link: all_routes.customerReservationService,
    name: 'Reservar servicio',
    icon: <Icon.Activity className="sidebar-feather me-3" />,
  },
  {
    link: "/#package-section",
    name: 'Comprar',
    icon: <Icon.ShoppingBag className="sidebar-feather me-3" />,
  },
  {
    link: all_routes.purchaseHistory,
    name: 'Historial de compra',
    icon: <Icon.Archive className="sidebar-feather me-3" />,
  },
  {
    link: all_routes.redeemGiftcard,
    name: 'Canjear GiftCard',
    icon: <Icon.Gift className="sidebar-feather me-3" />,
  },
];

export const trainerSidebarData = [
  {
    link: all_routes.customerDashboard,
    name: 'Editar Perfil',
    icon: <i className="ti ti-layout-grid me-2" />,
  },
  {
    link: all_routes.customerCalendar,
    name: 'Agenda',
    icon: <Icon.Calendar className="sidebar-feather me-3" />,
  },
  {
    link: all_routes.trainerSchedules,
    name: 'Clases',
    icon: <Icon.Activity className="sidebar-feather me-3" />,
  },
];

export const serviceProviderSidebarData = [
  {
    link: all_routes.customerDashboard,
    name: 'Editar Perfil',
    icon: <i className="ti ti-layout-grid me-2" />,
  },
  {
    link: all_routes.trainerSchedules,
    name: 'Servicios',
    icon: <i className="ti ti-layout-grid me-3" />,
  },
  {
    link: all_routes.customerCart,
    name: 'Carrito',
    icon: <Icon.ShoppingCart className="sidebar-feather me-3" />,
  },
  // {
  //   link: all_routes.serviceProviderCalendar,
  //   name: 'Agenda',
  //   icon: <Icon.Calendar className="sidebar-feather me-3" />,
  // },

];

export const receptionistSidebarData = [
  {
    link: all_routes.customerDashboard,
    name: 'Editar Perfil',
    icon: <i className="ti ti-layout-grid me-2" />,
  },
  {
    link: all_routes.receptionistPayment,
    name: 'Agregar Pago',
    icon: <Icon.DollarSign className="sidebar-feather me-2" />,
  },
  {
    link: all_routes.receptionistUsers,
    name: 'Clientes',
    icon: <Icon.User className="sidebar-feather me-2" />,
  },
  {
    link: all_routes.receptionistSchedules,
    name: 'Horarios',
    icon: <Icon.Clock className="sidebar-feather me-2" />,
  }
];

export const guestSidebarData = [
  {
    link: all_routes.customerCart,
    name: 'Carrito',
    icon: <Icon.ShoppingCart className="sidebar-feather me-3" />,
  },
];
