/**
 * Componente PurchaseSummary
 * 
 * Este componente renderiza la sección de resumen de compra de la página del carrito del cliente. Muestra el subtotal, descuentos,
 * y el monto total de los artículos en el carrito. También proporciona un campo de entrada para aplicar códigos de cupón y un botón
 * para proceder con el pago.
 * 
 * Props:
 * - customerCart: Objeto que contiene detalles del carrito como monto, descuentos y artículos.
 * - couponDiscount: Objeto que contiene detalles del descuento.
 * - couponCode: Cadena que representa el código de cupón ingresado.
 * - setCouponCode: Función para actualizar el estado del código de cupón.
 * - validateCode: Función para validar el código de cupón ingresado.
 * - loading: Booleano que indica si la validación del cupón está en progreso.
 * - info: Array de mensajes de información.
 * - infoValidate: Array de mensajes de validación.
 * - confirmPay: Función para confirmar el pago.
 * - loadingPay: Booleano que indica si el pago está en progreso.
 * - removeCoupon: Función para eliminar un cupón aplicado.
 * 
 * El componente está estructurado con una caja de resumen que muestra el subtotal, descuentos y el monto total. También incluye
 * un campo de entrada para ingresar códigos de cupón y un botón para aplicar el cupón. Adicionalmente, muestra mensajes de validación
 * e información, y un botón para proceder con el pago.
 */

import React from "react";
import ImageWithBasePath from "../../../../core/img/ImageWithBasePath";
import { PurchaseSummaryProps } from "../../../../core/Interface/interfaceComponents";
import { Alert } from 'react-bootstrap';

const PurchaseSummary: React.FC<PurchaseSummaryProps> = ({
  customerCart,
  couponDiscount,
  couponCode,
  setCouponCode,
  validateCode,
  loading,
  info,
  infoValidate,
  confirmPay,
  loadingPay,
  removeCoupon, // Add this prop
}) => {
  return (
    <div className="col-xl-4 col-lg-4">
      <h5 className="pay-title">Resumen de la compra</h5>
      <div className="summary-box">
        <div className="booking-summary">
          <ul className="booking-date m-0">
            <li>
              Subtotal
              <span>
                S/{" "}
                {customerCart.amount_raw?.toLocaleString("es-PE", {
                  minimumFractionDigits: 2,
                })}
              </span>
            </li>
            {couponDiscount.generalDiscount && (
              <li>
                <button onClick={() => removeCoupon("general")} className="remove-coupon-btn btn">
                  <i className="fa fa-times red"></i>
                </button>
                Descuento General:
                <span>
                  S/{" "}
                  {couponDiscount.generalDiscount.type === "percentage"
                    ? customerCart.discount_general_value?.toLocaleString(
                        "es-PE",
                        { minimumFractionDigits: 2 }
                      )
                    : couponDiscount.generalDiscount.couponDiscount?.toLocaleString(
                        "es-PE",
                        { minimumFractionDigits: 2 }
                      )}
                  ({couponDiscount.generalDiscount.type === "percentage"
                    ? "%"
                    : "Monto fijo"})
                </span>

              </li>
            )}
            {customerCart?.items?.some((item) => item.discount_value > 0) && (
              <>
                <li>Descuentos Específicos:</li>
                <ul>
                  {customerCart.items.map(
                    (item, index) =>
                      item.discount_value > 0 && (
                        <li key={index}>
                          <button onClick={() => removeCoupon(item.id)} className="remove-coupon-btn btn p-0 m-0 me-2">
                            <i className="fa fa-times red"></i>
                          </button>
                          {item.title}:
                          <span>
                            S/{" "}
                            {item.discount_value?.toLocaleString("es-PE", {
                              minimumFractionDigits: 2,
                            })}{" "}
                            ({item.discount_value > 0
                              ? "Aplicado"
                              : "Sin descuento"})
                          </span>

                        </li>
                      )
                  )}
                </ul>
              </>
            )}
          </ul>
          <div className="booking-total">
            <ul className="booking-total-list p-0 m-0">
              <li>
                <span>Total</span>
                <span className="total-cost">
                  S/{" "}
                  {customerCart.amount_with_discount?.toLocaleString("es-PE", {
                    minimumFractionDigits: 2,
                  })}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="booking-coupon mb-3">
        <div className="w-100">
          <div className="coupon-icon">
            <input
              type="text"
              className="form-control"
              placeholder="Código de Cupón"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
            />
            <span>
              <ImageWithBasePath
                src="assets/img/icons/coupon-icon.svg"
                alt="image"
              />
            </span>
          </div>
        </div>
        <button disabled={loading} onClick={validateCode} className="btn btn-dark">
          {loading ? <i className="fa fa-spinner fa-spin"></i> : "Aplicar"}
        </button>
      </div>
      {infoValidate.find((value) => value.field === "error") ? (
        <div className="alert alert-danger mt-3 mb-3" role="alert">
          {infoValidate.find((value) => value.field === "error")?.message}
        </div>
      ) : (
        info.length > 0 && (
          <div
            className={`alert ${
              info[info.length - 1].field === 'success'
                ? 'alert-success'
                : 'alert-danger'
            } mt-3 mb-3`}
            role="alert"
          >
            {info[info.length - 1].message}
          </div>
        )
      )}
      <button
        onClick={confirmPay}
        disabled={loadingPay}
        className="btn btn-dark w-100 pt-2 pb-2 mb-3"
      >
        {loadingPay ? (
          <i className="fa fa-spinner fa-spin"></i>
        ) : (
          <>
            Pagar S/{" "}
            {customerCart.amount_with_discount?.toLocaleString("es-PE", {
              minimumFractionDigits: 2,
            })}
          </>
        )}
      </button>
      <Alert  variant="warning">
        Después de realizar su pago en la plataforma de Mercado Pago, debe esperar a que se le redirija a LIFT para finalizar su compra.
      </Alert>
    </div>
  );
};

export default PurchaseSummary;
