import React, { useState } from 'react';
import * as Icon from 'react-feather';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import usePaginatedFetch from '../../../core/hooks/api/usePaginatedFetch';
import { getDefaultMonthRange } from '../../../core/utils/dateUtils';
import { useClasses } from '../../../core/hooks/getData/useClasses';
import { CalendarRangeField, DropdownField } from '../common/input/inputField';
import { useTrainers } from '../../../core/hooks/getData/useTrainersUsers';
import ShowDetailClassModal from '../common/modals/show-detail-class-modal';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { useGetClassCapacityReportExelFile } from '../../../core/hooks/getReports/useGetClassCapacityReportExelFile';
import { downloadExcelFile } from '../../../core/utils/fileUtils';
import { convertDateToLocalDateString } from '../../../core/utils/dateUtils';
import API_ENDPOINTS from '../../../core/constant/API_ENDPOINTS';

const ClassCapacityReport = () => {
  const [dateRange, setDateRange] = useState<[Date, Date]>(getDefaultMonthRange() as [Date, Date]);
  const [classId, setClassId] = useState<string>('');
  const [trainerId, setTrainerId] = useState<string>('');
  const [hourStart, setHourStart] = useState<string>('');
  const [hourEnd, setHourEnd] = useState<string>('');
  const [selectedClass, setSelectedClass] = useState<any>();

  const params = dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined) ? {
    startDate: convertDateToLocalDateString(dateRange[0]),
    endDate: convertDateToLocalDateString(dateRange[1]),
    classId,
    trainerId,
    hourStart,
    hourEnd
    } : null;

  const {
    data: classCapacity,
    loading,
    totalElements,
    first,
    rows,
    onPageChange
  } = usePaginatedFetch( API_ENDPOINTS.reportClassCapacity, 10, 0, params );

  const { classesData } = useClasses({ shouldOptions: true });
  const { trainersData } = useTrainers({ shouldOptions: true });
  const { refetchClassCapacity } = useGetClassCapacityReportExelFile(dateRange, classId, trainerId, hourStart, hourEnd);

  const columns = [
    { field: "className", header: "Clase" },
    { field: "trainerName", header: "Entrenador" },
    { field: "hour", header: "Hora" },
    { field: "date", header: "Fecha", sortable: true, body: (rowData: any) => new Date(rowData.date).toLocaleDateString('es-PE') },
    { field: "reservationCount", header: "Reservas" },
    { field: "attendanceCount", header: "Asistencias" },
    { field: "totalCapacity", header: "Capacidad Total" },
    { field: "reservedCapacityPercentage", header: "Capacidad Reservada (%)", body: (rowData: any) => `${rowData.reservedCapacityPercentage}%` },
    { field: "attendedCapacityPercentage", header: "Capacidad Asistida (%)", body: (rowData: any) => `${rowData.attendedCapacityPercentage}%` },
  ];

  const handleShowInfo = (rowData: any) => {
    setSelectedClass(null);
    setTimeout(() => setSelectedClass(rowData), 0);
  };

  const handleDownloadExcel = async () => {
    const isHourRangeValid = (hourStart && hourEnd) || (!hourStart && !hourEnd);
    if (dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined) && isHourRangeValid) {
      const file = await refetchClassCapacity();
      downloadExcelFile(file, 'class-capacity');
    }
  };

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          <h5>Capacidad de Clases</h5>
        </div>
        <div className="card card-status mb-3">
          <div className="card-head-blk border-bottom-0 pb-0">
            <div className="row align-items-center">
              <div className="col-md-12 d-flex justify-content-end">
                <button
                  className="btn btn-outline-primary rounded-3 mb-3 me-2"
                  onClick={() => {
                    setDateRange(getDefaultMonthRange() as [Date, Date]);
                    setClassId('');
                    setTrainerId('');
                    setHourStart('');
                    setHourEnd('');
                  }}
                >
                  <i className="fa fa-eraser me-2" />
                  Limpiar
                </button>
                <button
                  className="btn btn-primary-admin rounded-3 mb-3 align-items-center d-flex me-2"
                  onClick={handleDownloadExcel}
                >
                  <Icon.FileText className="react-feather-icon me-2" />
                  <span>Descargar como Excel</span>
                </button>
              </div>
              <div className="col-xl-12 col-md-12 d-flex">
                <div className="earning-group gap-3 w-100">
                  <CalendarRangeField
                    value={dateRange || [new Date(), new Date()]}
                    onChange={(e: any) => setDateRange(e.value)}
                  />
                  <DropdownField
                    value={classId}
                    onChange={(e: any) => setClassId(e.value)}
                    options={classesData || []}
                    placeholder="Selecciona una clase"
                    className="mb-3"
                  />
                  <DropdownField
                    value={trainerId}
                    onChange={(e: any) => setTrainerId(e.value)}
                    options={trainersData || []}
                    placeholder="Selecciona un entrenador"
                    className="mb-3"
                  />
                  <div className="mb-3">
                    <TimePicker
                      size='large'
                      className="timepicker input-group-text custom-timepicker"
                      onChange={(value) => setHourStart(value?.format('HH:mm'))}
                      value={hourStart ? dayjs(hourStart, 'HH:mm') : null}
                      format="h:mm A"
                      placeholder="Hora de inicio"
                    />
                  </div>
                  <div className="mb-3">
                    <TimePicker
                      size='large'
                      className="timepicker input-group-text custom-timepicker"
                      onChange={(value) => setHourEnd(value?.format('HH:mm'))}
                      value={hourEnd ? dayjs(hourEnd, 'HH:mm') : null}
                      format="h:mm A"
                      placeholder="Hora de fin"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <DataTableWrapper
            data={classCapacity || []}
            lazy={true}
            columns={columns}
            customIcon={Icon.Eye}
            onCustomAction={handleShowInfo}
            paginator
            rowsPerPage={rows}
            first={first}
            totalRecords={totalElements}
            onPageChange={onPageChange}
            showGridlines
            emptyMessage="No se encontraron clases."
          />
        </div>
        <ShowDetailClassModal selectedClassData={selectedClass} />
      </div>
    </div>
  );
};

export default ClassCapacityReport;