import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../../core/context/AuthContext';
import useFetch from '../../../../core/hooks/api/useFetch';
import API_ENDPOINTS from '../../../../core/constant/API_ENDPOINTS';

/**
 * El componente TrialSection renderiza una sección promocional para usuarios que no son clientes ni entrenadores.
 * Obtiene datos de promoción y los muestra junto con un enlace de reserva.
 */
const TrialSection = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: ¡Contexto de autenticación no encontrado!</div>;
  }

  const { user } = authContext;
  const userProfile = user?.role;
  const { data: promotionSectionData, loading: isLoading } = useFetch(API_ENDPOINTS.promotionSection + "/1");

  return (
    <>
      <section className="service-section">
        {userProfile !== 'customer' && userProfile !== 'trainer' && (
          <div className="container py-md-5 col-11 py-3" style={{ height: isLoading ? '800px' : 'auto' }}>
            <div className="row py-md-4 mt-0 align-items-center">
              <div className="col-12 d-flex justify-content-center">
                <div className="download-sec trial-section mt-3 mt-lg-0 mt-md-0 text-home text-center">
                  <div dangerouslySetInnerHTML={{ __html: promotionSectionData?.description || '' }} />
                  {!isLoading && (
                  <div className="downlaod-btn aos mt-3 d-flex justify-content-center align-items-center">
                    <div className="pricing-plans price-new no-min-height">
                      <Link to={promotionSectionData?.link || '/'} className="btn">
                        Reserva aquí
                      </Link>
                    </div>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default TrialSection;