import ClassCapacityReport from "../../feature-module/admin/reports/class-capacity";

const API_ENDPOINTS = {
    faqs: "faqs",
    coupons: "coupons",
    pages: "pages",
    packageTypes: "package-types",
    package: "packages",
    classes: "classes",
    paymentDetails: "payments/details",
    paymentActivate: "payments/activate-purchase",
    users: "users",
    banners: "banners",
    auth: "auth",
    schedules: "schedules",
    complaint: "complaints",
    reservation: "class-lists",
    headquarters: "headquarters",
    payments: "payments",
    paymentDays: "payment-days",
    promotionSection: "home-sections",
    enterpriseInformation: "enterprises",
    subscriptions: "subscriptions",
    secretKeys: "secret-keys",
    giftcard: 'giftcards',
    campaigns: 'campaigns',
    locations: 'locations',
    reports: 'reports',
    subTypeClasses: 'class-types',
    freezeRequests: 'freeze-requests',
    aditionalServices: 'purchased-service',
    purchasedServices: 'purchased-services',
    reportHistory: 'reports/class-reservation-history',
    reportClassCapacity: 'reports/class-capacity',
    reportPurchase:'reports/purchase',
    reportCustomerPreference:'reports/customer-preference',
    reportProfitabilityClasses:'reports/membership-class-reservation',
    reportCancellationRate:'reports/cancellation-rate',
    reportClassFreePurchase:'reports/class-free-purchase',
    reportHistoryExel: 'reports/class-reservation-history/excel',
    trainerClassSummaryreportHistoryExel: 'reports/trainer-class-summary/excel',
    trainerClassSummaryreportHistory: 'reports/trainer-class-summary',
};

export default API_ENDPOINTS;