import React from 'react';
import Slider from 'react-slick';
import { Banner } from './bannerMain';
import TrialSection from './trialSection';
import PackageSection from './packageSection';
import AuthModals from '../header/authModals';
import FeatureSection from './feature-section';
import MainFooter from '../footer/main-footer';
import HomeHeader from '../header/main-header';
import { sliderOptions } from '../../../../core/data/config/slider';
import { useInitialFetch } from '../../../../core/context/InitialFetchContext';

/**
 * El componente NewHome renderiza la página de inicio con varias secciones como banners, características, prueba y paquetes.
 * Obtiene datos de banners y los muestra en un deslizador.
 */
const NewHome = () => {
  const { banners, loading } = useInitialFetch();

  if (loading) {
    return (
      <div className="loading-screen">
      </div>
    );
  }

  return (
    <div className="home-page">
      <HomeHeader />
      <Slider {...sliderOptions} className="img-slider nav-center hero-section slick-slider slide-arrows-hero bg-section-secondary slide-arrow-primary">
        {banners.map((banner: any, index: any) => (
          <Banner key={index} banner={banner} />
        ))}
      </Slider>
      <FeatureSection />
      <TrialSection />
      <PackageSection id="package-section" />
      <AuthModals />
      <MainFooter />
    </div>
  );
};

export default NewHome;