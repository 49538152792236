import React from 'react';
import { TimePickerFieldProps } from '../../../core/Interface/elements/inputInterface';
import TimePicker from 'react-time-picker';

const TimePickerField: React.FC<TimePickerFieldProps> = ({ label, value, onChange, error, required = true, format = "h:mm A", name }) => {
  return (
    <div className="mb-3">
      {label && (
        <label className="form-label w-100">
          <span>{label}{required && <span className="text-danger"> *</span>}</span>
        </label>
      )}
      <TimePicker
        className="timepicker input-group-text form-control custom-timepicker"
        name={name}
        onChange={(value) => onChange({ target: { name, value } })}
        value={typeof value === 'string' ? value : ''}
        format={format}
        disableClock={true}
      />
      {error && <small className="text-danger">{error}</small>}
    </div>
  );
};

export default TimePickerField;
