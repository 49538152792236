import React from 'react';
import { DropdownField } from '../../../admin/common/input/inputField';
import { CLASS_STATUS } from '../../../../core/data/options/options';
import { getDefaultWeekRange } from '../../../../core/utils/dateUtils';
import { RangeCalendar } from '../../../components/inputs'; // Import RangeCalendar

interface FiltersProps {
    status: string;
    setStatus: (value: string) => void;
    dateRange: [Date, Date];
    setDateRange: (value: [Date, Date]) => void;
    handleDateChange: (value: [Date, Date]) => void; 
    profile: string; // Add profile prop
}

/**
 * Componente de filtros para los horarios.
 * @param {FiltersProps} props - Propiedades del componente.
 * @returns {JSX.Element} Componente de filtros.
 */
const Filters: React.FC<FiltersProps> = ({
    status,
    setStatus,
    dateRange,
    setDateRange,
    handleDateChange,
    profile, // Destructure profile prop
}) => (
    <div className="search-filters card card-shadow shadow-none p-3 mb-4 shadow-sm">
        <div className="d-flex justify-content-between align-items-center mb-1">
            <h6 className="text-dark">Filtros de búsqueda</h6>
            <button
                className="btn btn-outline-primary btn-sm rounded-3"
                onClick={() => {
                    setDateRange(getDefaultWeekRange());
                    setStatus('');
                }}
                title="Limpiar filtros"
            >
                <i className="fa fa-eraser me-2" />
                Limpiar
            </button>
        </div>
        <div className="d-flex align-items-center gap-3">
            <div className="row">
                {profile === 'trainer' && (
                    <div className="col-md-6">
                        <DropdownField
                            label="Estado de la clase"
                            required={false}
                            placeholder="Selecciona un estado"
                            value={status}
                            options={CLASS_STATUS}
                            onChange={(e) => setStatus(e.target.value)}
                        />
                    </div>
                )}
                <div className="col-md-12">
                    <RangeCalendar
                        label="Selecciona Rango de Fechas"
                        value={dateRange}
                        onChange={handleDateChange}
                    />
                </div>
            </div>
        </div>
    </div>
);

export default Filters;