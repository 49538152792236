import React from 'react';
import BaseModal from './baseModal';
import { SuccessModalProps } from '../../../core/Interface/elements/modalInterface';

const SuccessModal: React.FC<SuccessModalProps> = ({
  show,
  onClose,
  title = 'Éxito',
  text = 'La acción se realizó con éxito.',
  onConfirm,
  children,
  ...modalProps
}) => {
  return (
    <BaseModal
      show={show}
      onClose={onClose}
      title={title}
      {...modalProps}
      footer={
        <button onClick={onConfirm} className="btn btn-success">
          Aceptar
        </button>
      }
    >
      {children ? (
        children
      ) : (
        <div className="text-center">
          <i className="fas fa-check-circle text-success fa-3x mb-3"></i>
          <p>{text}</p>
        </div>
      )}
    </BaseModal>
  );
};

export default SuccessModal;
