import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormModalProps } from '../../../core/Interface/elements/modalInterface';

const FormModal: React.FC<FormModalProps> = ({
  show,
  title,
  onClose,
  onConfirm,
  children,
  loading,
  showButtons = true,
  size = "lg",
  error
}) => {
  return (
    <Modal className="admin" show={show} onHide={onClose} centered size={size}>
      <Modal.Header closeButton className="pb-0">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
        {error && <div className="alert alert-danger">{error}</div>} {/* Display error message */}
      </Modal.Body>
      {showButtons && (
        <Modal.Footer className="d-flex justify-content-end">
          <button className="btn btn-secondary me-2" onClick={onClose}>Cerrar</button>
          <button
            className="btn btn-primary-admin"
            onClick={onConfirm}
            disabled={loading}
          >
            {loading ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Guardando...
              </>
            ) : (
              'Guardar'
            )}
          </button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default FormModal;