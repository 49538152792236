import { useState, useEffect } from 'react';
import { handleFormSubmit } from '../../services/formService';
import { apiRepository } from '../../../api/apiRepository';
import { validateUserPhoto } from '../../form/validationForm';
import { convertDateToLocalDateString, getCurrentLocalDate } from "../../utils/dateUtils";
import { UseHandleUpdatePhotoProps } from '../../Interface/hooksInterface';

export const useHandleUpdatePhoto = ({ setSelectedFile, setShowModal, setLoading, fetchUser, userData, selectedFile }: UseHandleUpdatePhotoProps) => {
  const handleUpdatePhoto = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (event) => {
      const target = event.target as HTMLInputElement;
      if (target && target.files && target.files[0]) {
        const file = target.files[0];
        setSelectedFile(file);
        setShowModal(true);
      }
    };
    input.click();
  };

  const handleConfirmUpdate = async () => {
    if (selectedFile) {
      setLoading(true);

      const handleClose = () => {
        setShowModal(false);
        setSelectedFile(null);
      };

      const onUpdateSuccess = () => {
        fetchUser();
      };

      try {

        const { paymentDays, purchasedServices, ...sanitizedUserData } = userData;

        const errors = await handleFormSubmit(
          userData.id,
          sanitizedUserData,
          validateUserPhoto,
          {
            create: apiRepository.createUser,
            update: apiRepository.updateUser,
          },
          () => {
            if (onUpdateSuccess) onUpdateSuccess();
            handleClose();
          },
          (data) => ({
            ...data,
            image: selectedFile,
            birthdate: convertDateToLocalDateString(data.birthdate),
            password: '',
          }),
          "Ocurrió un error al intentar guardar la foto de perfil. Inténtalo de nuevo.",
          true
        );

        if (errors) {
          console.error(errors);
        }
      } catch (error) {
        console.error("Error al procesar la foto de perfil:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return { handleUpdatePhoto, handleConfirmUpdate };
};

export const useFilteredClasses = (classesQuantity: any) => {
  const [filteredClasses, setFilteredClasses] = useState([]);

  useEffect(() => {
    if (!classesQuantity) return;

    const currentDate = getCurrentLocalDate();
    const filtered = classesQuantity.filter(item => {
      const isMembership = item.type === 'Membresias Premium' || item.type === 'Membresias';
      if (isMembership) {
        return new Date(item.endDate) >= currentDate;
      } else {
        return item.quantity > 0 && new Date(item.endDate) >= currentDate;
      }
    });

    // Only update state if the filtered data has changed
    setFilteredClasses(prevFiltered => {
      const isSame = JSON.stringify(prevFiltered) === JSON.stringify(filtered);
      return isSame ? prevFiltered : filtered;
    });
  }, [classesQuantity]);

  return filteredClasses;
};