/**
 * Componente AditionalServices
 * 
 * Este componente se encarga de mostrar y gestionar los servicios adicionales de los usuarios.
 * Permite filtrar los servicios adicionales por nombre de usuario y paginar los resultados.
 * 
 * Funcionalidades:
 * - Filtrado por nombre de usuario.
 * - Paginación de resultados.
 * - Edición de servicios adicionales.
 * 
 * Hooks:
 * - useState: Para manejar el estado local del componente.
 * - useEffect: Para realizar la carga inicial de datos.
 * 
 * Dependencias:
 * - DataTableWrapper: Componente para mostrar los datos en una tabla.
 * - AditionalServicesModal: Componente modal para editar servicios adicionales.
 */

import React, { useState, useContext, useEffect } from 'react';
import usePaginatedFetch from '../../../core/hooks/api/usePaginatedFetch';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import { RangeCalendar } from '../../components/inputs';
import API_ENDPOINTS from '../../../core/constant/API_ENDPOINTS';
import { getDefaultMonthRange, convertDateToLocalDateString } from '../../../core/utils/dateUtils';
import usePost from '../../../core/hooks/api/usePost';
import usePut from '../../../core/hooks/api/usePut';
import { handleInputChange } from '../../../core/form/handleInputChange';
import { ValidationRule } from '../../../core/Interface/validationsInterfaces';
import { validateForm } from '../../../core/form/formValidationRules';
import ServiceFormModal from '../common/modals/ServiceFormModal';
import { format, parse, format as formatDate } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment-timezone';

const AditionalServices = () => {
  const [selectedService, setSelectedService] = useState<string | any>(null);
  const [dateRange, setDateRange] = useState<any>(getDefaultMonthRange());
  const [servicesDate, setServicesDate] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { put, loading: putLoading } = usePut(API_ENDPOINTS.aditionalServices);
  const [formData, setFormData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const { post, loading: postLoading } = usePost(API_ENDPOINTS.aditionalServices);
  const [showFormModal, setShowFormModal] = useState(false);

  // useEffect(() => {
  //   const isValidDate = dateRange[0] instanceof Date && !isNaN(dateRange[0]) &&
  //   dateRange[1] instanceof Date && !isNaN(dateRange[1]);
  //   console.debug(isValidDate, 'isValidDate')
  //   console.debug(dateRange, 'dateRange')
  //   if (isValidDate) {

  //     setServicesDate(isValidDate);
  //   } else {
  //     setServicesDate(false);
  //   }
  // }, [dateRange]);

  // useEffect(() => {
  //   console.debug(servicesDate, 'servicesDate')
  // }, [servicesDate]);

  const { data, totalElements, first, rows, onPageChange, refetch } = usePaginatedFetch(
    API_ENDPOINTS.aditionalServices,
    10,
    0,
    { startDate: convertDateToLocalDateString(dateRange[0]), endDate: convertDateToLocalDateString(dateRange[1]) },
  );

  const handleEditClick = (rowData: any) => {
    setSelectedService(rowData);
    const start = moment(rowData.hourStart, "HH:mm");
    const end = moment(rowData.hourEnd, "HH:mm");
    const minutesDifference = end.diff(start, 'minutes');

    setFormData({
      id: rowData.id,
      date: rowData.date,
      hourStart: rowData.hourStart,
      hourEnd: rowData.hourEnd,
      title: rowData.title,
      serviceId: rowData.serviceId,
      packetId: rowData.packetId,
      minutes: { value: minutesDifference.toString(), label: minutesDifference.toString() }
    });
    setShowFormModal(true);
  };

  const handleCloseClick = () => {
    setShowConfirmModal(false);
    setErrors({});
    setFormData({});
  };

  const handleCreateClick = () => {
    setSelectedService(null);
    setShowFormModal(true);
  };

  const handleFormCloseClick = () => {
    setShowFormModal(false);
    setErrors({});
    setFormData({});
  };

  const validationRules: ValidationRule[] = [
    { field: 'date', methods: ['required'] },
    { field: 'hourStart', methods: ['required'] },
    { field: 'minutes', methods: ['required'] },
    { field: 'title', methods: ['required'] },
    { field: 'serviceId', methods: ['required'] },
    { field: 'packetId', methods: ['required'] }
  ];

  const handleFormSubmit = async () => {
    const newErrors = validateForm(formData, validationRules);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }

    const start = moment(formData.hourStart, "HH:mm");
    const end = start.clone().add(Number(formData.minutes.value), 'minutes');

    const payload = {
      date: formData.date,
      hourStart: formData.hourStart,
      hourEnd: end.format("HH:mm"),
      title: formData.title,
      serviceId: formData.serviceId,
      packetId: formData.packetId,
      typeRecurrent: formData.typeRecurrent || '',
      quantityRecurrent: formData.quantityRecurrent || 0,
    };

    try {
      if (selectedService) {
        await put(selectedService.id, payload);
      } else {
        await post(payload);
      }
      refetch(0, rows);
      setShowFormModal(false);
      setErrors({});
      return true;
    } catch (err: any) {
      const errorMessage = err.response?.data?.message || 'Error al enviar la solicitud. Inténtalo de nuevo.';
      setErrors({ submit: errorMessage });
      return false;
    }
  };

  const columns = [
    {
      field: "fullNameCustomer",
      header: "Nombre Completo del Cliente",
      body: (rowData) => rowData.fullNameCustomer || 'Sin reserva aún',
    },
    { field: "title", header: "Título" },
    { field: "namePacket", header: "Nombre del Paquete" },
    { field: "fullNameService", header: "Encargado de la Sesión" },
    {
      field: "date",
      header: "Fecha y Hora",
      body: (rowData: any) => {
        const formattedDate = format(new Date(rowData.date), 'EEEE dd \'de\' MMMM \'del\' yyyy', { locale: es });
        return `${formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)} de ${rowData.hourStart} a ${rowData.hourEnd}`;
      }
    },
    { field: "reservation", header: "Cantidad de reservas" },
    {
      field: "status",
      header: "Estado",
      body: (rowData: any) => {
        let label = '';
        let className = '';

        switch (rowData.status) {
        case 1:
            label = 'Pendiente';
            className = 'badge-pending';
            break;
        case 2:
            label = 'En Atención';
            className = 'badge-active';
            break;
        case 3:
            label = 'Finalizada';
            className = 'badge-default';
            break;
        case 4:
            label = 'Cancelado';
            className = 'badge-delete';
            break;
            case 5:
            label = 'Asistencia finalizada';
            className = 'badge-active';
            break;
        default:
            label = 'Desconocido';
            className = 'badge-default';
        }

        return <span className={className}>{label}</span>;
      }
    },
    { field: "reservation", header: "Reservación" },
  ];

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit align-items-start">
          <h5>Servicios Adicionales</h5>
          <div className="list-btn">
            <ul>
              <li>
                <button className="btn btn-primary-admin  mt-3" onClick={handleCreateClick}>Crear Servicio</button>
              </li>
              <li>
              <div className="d-flex align-items-end">
                <RangeCalendar
                  label="Rango de Fechas"
                  name="dateRange"
                  value={dateRange}
                  onChange={(e) => setDateRange(e.target.value)}
                />
              </div>
              </li>
            </ul>
          </div>
        </div>

        <DataTableWrapper
          data={data || []}
          columns={columns}
          onEdit={handleEditClick}
          paginator
          showGridlines
          rowsPerPage={rows}
          totalRecords={totalElements}
          first={first}
          onPageChange={onPageChange}
          emptyMessage="No se encontraron servicios adicionales."
        />
      </div>
      <ServiceFormModal
        show={showFormModal}
        onClose={handleFormCloseClick}
        onConfirm={handleFormSubmit}
        formData={formData}
        setFormData={setFormData}
        errors={errors}
        handleInputChange={handleInputChange}
        loading={postLoading || putLoading}
      />
    </div>
  );
};

export default AditionalServices;