import { useState } from "react";
import { getData } from "../../../core/services/api";
import API_ENDPOINTS from "../../../core/constant/API_ENDPOINTS";
import { convertDateToLocalDateString } from "../../../core/utils/dateUtils";

export const useGetClassReservationHistoryExcel = (dateRange?: [Date, Date], selectedUserId?: number | null) => {
    const [classReservationHistoryExcel, setClassReservationHistoryExcel] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchClassReservationHistoryExcel = async () => {
        try {
            setIsLoading(true);
            setError(null);

            const [startDate, endDate] = dateRange;
            const response = await getData(API_ENDPOINTS.reportHistoryExel, {
                startDate: convertDateToLocalDateString(startDate),
                endDate: convertDateToLocalDateString(endDate),
                userId: selectedUserId,
            },
            { responseType: 'blob' });
            const excelFile = response.data;
            setClassReservationHistoryExcel(excelFile);
            return excelFile;
        } catch (err) {
            console.error("Error al obtener el archivo Excel:", err);
            setError(err instanceof Error ? err : new Error("Error desconocido"));
        } finally {
            setIsLoading(false);
        }
    };

    return {
        classReservationHistoryExcel,
        isLoading,
        error,
        refetchClassReservationHistoryExcel: fetchClassReservationHistoryExcel,
    };
};