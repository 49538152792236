import React, { useEffect } from 'react';
import ClassSchedulesAttendance from '../../../admin/class-attendance-management/class-attendance-management';
import { useOutletContext } from 'react-router-dom';
import { BreadcrumbContext } from '../../../../core/Interface/contextInterface';

/**
 * Componente para mostrar el historial de compras del cliente.
 * @returns {JSX.Element} Componente de historial de compras.
 */
const RecepcionSchedulesAttendance = () => {
  const { setBreadcrumbData } = useOutletContext<BreadcrumbContext>();

  useEffect(() => {
    setBreadcrumbData({ title: "Asistencia", item1: "Perfil", item2: "Asistencia" });
  }, [setBreadcrumbData]);

  return (
      <div className="">
        <ClassSchedulesAttendance />
      </div>
  );
};

export default RecepcionSchedulesAttendance;