import { useEffect, useState } from 'react';
import useFetch from './useFetch';

interface Option {
  label: string;
  value: string | number;
}

const useOptionFetch = (endpoint: string, labelProp: string, params: Record<string, any> = {}) => {
  const { data, loading, error, refetch } = useFetch(endpoint, params);
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    const actualData = data?.content || data;
    if (actualData?.length > 0) {
      const formattedOptions = actualData?.map((item: any) => ({
        label: item[labelProp],
        value: item?.id,
      }));
      setOptions(formattedOptions);
    }
  }, [data, labelProp]);

  return { options, loading, error, refetch };
};

export default useOptionFetch;