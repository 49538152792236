import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import * as Icon from 'react-feather';

export const TooltipComponent: React.FC<{ itemType: string }> = ({ itemType }) => {
  const TooltipContent = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Poner pausa a {itemType}
    </Tooltip>
  );
  TooltipContent.displayName = 'TooltipContent';
  return <TooltipContent />;
};

export const ClassCard: React.FC<{
  item: any;
  handlePauseClick: (item: any) => void;
  handlePlayClick: () => void;
  convertStringToLocalDate: (date: string) => Date;
  getCurrentLocalDate: () => Date;
}> = ({ item, handlePauseClick, handlePlayClick, convertStringToLocalDate, getCurrentLocalDate }) => (
  <div
    key={item.id}
    className="class-card mb-3 p-3 border rounded shadow-sm"
    style={{ cursor: 'pointer' }}
    onClick={item.paymentDaysFreeze && item.paymentDaysFreeze.active && item.paymentDaysFreeze.quantityDays && item.paymentDaysFreeze.startDateFreeze ? handlePlayClick : () => handlePauseClick(item)}
  >
    <div className="d-flex justify-content-between align-items-center mb-2">
      <span className="badge bg-primary">{item.type || item.packetName}</span>
      {item.type && (
        item.paymentDaysFreeze && item.paymentDaysFreeze.active && item.paymentDaysFreeze.quantityDays && item.paymentDaysFreeze.startDateFreeze ? (
          <OverlayTrigger placement="top" overlay={<TooltipComponent itemType={item.type} />}>
            <div className="d-flex align-items-center bg-icon-play">
              <Icon.PlayCircle className="pause-icon" size={20} />
            </div>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger placement="top" overlay={<TooltipComponent itemType={item.type} />}>
            <div className="d-flex align-items-center bg-icon-pause">
              <Icon.PauseCircle className="pause-icon" size={20} />
            </div>
          </OverlayTrigger>
        )
    )}


    </div>
    <div className="d-flex justify-content-between">
      <p className="m-0">
        {item.type ? (
          item.type === 'Membresias Premium' ? (
            <>Cantidad: <i className="fas fa-crown text-warning" /></>
          ) : item.type === 'Membresias' ? (
            <>Cantidad: <i className="fas fa-infinity text-primary" /></>
          ) : (
            <>Cantidad: {item.quantity}</>
          )
        ) :
        <>
        Cantidad: {item.serviceCount}
        </>}
      </p>
    </div>
    {item.endDate && (

      <div className="d-flex justify-content-between align-items-center mt-0">
      <span className="">Vence:</span>
      <span className={`badge ${item.endDate ? (convertStringToLocalDate(item.endDate) < getCurrentLocalDate() ? 'bg-danger' : 'bg-success') : 'bg-secondary'} text-white`}>
        {item.endDate ? convertStringToLocalDate(item.endDate).toLocaleDateString() : 'N/A'}
      </span>
    </div>
    )}
  </div>
);
